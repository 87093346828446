import { createReducer } from 'redux-act';

import * as actions from '../actions/widgets';

const DEFAULT_STATE = {
  users: {
    tabValue: false
  }
};

export default createReducer(
  {
    [actions.setUsersTabValue]: (state, value) => ({
      ...state,
      users: {
        ...state.users,
        tabValue: value
      }
    }),
    [actions.resetWidgetReducer]: state => ({
      DEFAULT_STATE
    })
  },
  DEFAULT_STATE
);

import { email as emailRegex } from '../constants/regex';

export const required = value => (!!value ? undefined : 'Required');
export const isArray = value =>
  Array.isArray(value) ? undefined : 'Not an array';
export const notEmpty = value => (value && value.length ? undefined : 'Empty');
export const isNumber = value =>
  !value || isNaN(+value.replaceAll(',', '.')) ? 'Not a number' : undefined;
export const hasLength = length => value =>
  value && value.length === length
    ? undefined
    : `Has not length equal ${length}`;

export const email = value =>
  !emailRegex.test((value || '').trim()) ? 'Invalid email address' : undefined;

export const combine =
  (...args) =>
  value => {
    for (const validator of args) {
      const validateResult = validator(value);
      if (validateResult) {
        return validateResult;
      }
    }
  };

export const password = value =>
  typeof value === 'string' && /^([a-z]+-){3}[a-z]+$/g.test(value) // 4 words with lowercases
    ? undefined
    : 'Invalid password';

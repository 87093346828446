export const COMMON_DOMAINS = [
  'com',
  'edu',
  'net',
  'org',
  'int',
  'info',
  'biz',
  'name'
];

export const getDomainLanguage = () => {
  const hostname = window.location.hostname;

  if (hostname && hostname.indexOf('.') !== -1) {
    const domains = window.location.hostname.split('.');
    const lastDomain = domains[domains.length - 1];

    return COMMON_DOMAINS.indexOf(lastDomain) === -1 && lastDomain.length === 2
      ? lastDomain
      : null;
  }
  return null;
};

// We assume iframe is only used in case of LivaAgent integration
export const isInIFrame = () => window.self !== window.top;

import React from 'react';
import { makeStyles, useTheme } from '@mui/styles';
import { Paper as MaterialPaper, Theme } from '@mui/material';

interface PaperProps {
  className?: string;
  children: React.ReactNode;
}

export const usePaperStyles = makeStyles((theme: Theme) => ({
  root: {
    color: 'inherit'
  },
  rounded: {
    borderRadius: Number(theme.shape.borderRadius) * 4
  },
  elevation1: {
    boxShadow: theme.customShadows.module
  }
}));

const Paper = React.forwardRef<HTMLDivElement, PaperProps>(
  ({ children, className, ...props }, ref) => {
    const theme: Theme = useTheme();
    return (
      <MaterialPaper
        ref={ref}
        className={className}
        {...props}
        sx={{ borderRadius: Number(theme.shape.borderRadius) }}
      >
        {children}
      </MaterialPaper>
    );
  }
);
Paper.displayName = 'Paper';

export default Paper;

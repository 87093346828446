import React from 'react';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import clsx from 'clsx';

import { LanguageFullCodeKey } from '../../../../types';
import { IconVariant } from '../../CustomIcon';
import LanguageSelectItem from '../LanguageSelectItem';
import LanguageSelectMenu from '../LanguageSelectMenu';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'relative',
    display: 'inline-block'
  },
  button: {
    borderRadius: Number(theme.shape.borderRadius) * 2.5,
    background: 'transparent',
    '&:hover, &:focus': {
      background: theme.palette.action.hover
    }
  },
  icon: {
    marginLeft: theme.spacing(1.5)
  }
}));

interface LanguageSelectProps {
  isCompact?: boolean;
  onChange: (value: string) => void;
  languages: Record<string, string>;
  value: LanguageFullCodeKey;
  selectButtonClassName?: string;
  selectButtonLabelClassName?: string;
  selectButtonIconClassName?: string;
  menuItemClassName?: string;
  menuItemLabelClassName?: string;
  checkedIconVariant?: IconVariant;
  expandIconVariant?: IconVariant;
}

const LanguageSelect: React.FC<LanguageSelectProps> = ({
  isCompact,
  onChange,
  languages,
  value,
  selectButtonClassName,
  selectButtonLabelClassName,
  selectButtonIconClassName,
  menuItemClassName,
  menuItemLabelClassName,
  checkedIconVariant,
  expandIconVariant
}) => {
  const classes = useStyles();
  const currentLabel = languages[value];
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const onShowLanguageSelect = (
    event: React.MouseEvent<HTMLButtonElement>
  ): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const handleChange = (value: string): void => {
    handleClose();
    onChange(value);
  };

  return (
    <div className={classes.root}>
      <LanguageSelectItem
        id="header-language-select"
        isCompact={isCompact}
        className={clsx(selectButtonClassName, classes.button)}
        labelClassName={selectButtonLabelClassName}
        onClick={onShowLanguageSelect}
        value={value}
        label={currentLabel}
        icon={!isCompact ? 'expandMore' : undefined}
        iconVariant={expandIconVariant}
        iconClass={clsx(selectButtonIconClassName, classes.icon)}
      />
      <LanguageSelectMenu
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        languages={languages}
        value={value}
        handleChange={handleChange}
        handleClose={handleClose}
        menuItemClassName={menuItemClassName}
        menuItemLabelClassName={menuItemLabelClassName}
        iconVariant={checkedIconVariant}
      />
    </div>
  );
};

export default LanguageSelect;

import React, { useCallback, useEffect, useMemo } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import { useDispatch, useSelector } from 'react-redux';

import { Header, SideMenu } from '../../components/layout';
import { Button, Footer } from '../../components/common';

import {
  getAvailableLanguages,
  logOut,
  selectBrowserLanguage,
  selectLanguage
} from '../../store/actions';
import {
  availableLanguagesSelector,
  decisionsUsernameSelector,
  flagSelectLanguagesSelector,
  isCognitoAuthenticatedSelector,
  languageSelector,
  portalBackgroundGradientSelector
} from '../../store/selectors';

import { createFullLanguageCode } from '@troy/shared/src/utils/translations';

const useStyles = makeStyles(theme => ({
  wrapper: {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    backgroundImage: props => `url(${props.bg})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    backgroundSize: 'cover',
    backgroundAttachment: 'fixed'
  },
  layout: {
    width: '100%',
    margin: '0 auto',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden'
  },
  content: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    flexDirection: 'column',
    position: 'relative',
    overflowY: 'auto',
    overflowX: 'hidden'
  },
  menu: {
    position: 'fixed',
    right: 0,
    top: '50%',
    transform: 'translateY(-50%)'
  },
  button: {
    borderRadius: '0 !important',
    padding: '10px !important'
  },
  icon: {
    height: '25px !important',
    width: '25px !important',
    color: `${theme.palette.primary.contrastText} !important`,
    fill: `${theme.palette.primary.contrastText} !important`
  }
}));

const IconButton = ({ icon: Icon, className, iconClassName, ...props }) => {
  const classes = useStyles();

  return (
    <Button className={clsx(classes.button, className)} {...props}>
      <Icon className={clsx(classes.icon, iconClassName)} />
    </Button>
  );
};

export default ({ children }) => {
  const backgroundGradient = useSelector(portalBackgroundGradientSelector);
  const classes = useStyles({ bg: backgroundGradient });

  const isAuthenticated = useSelector(isCognitoAuthenticatedSelector);
  const userName = useSelector(decisionsUsernameSelector);
  const availableLanguages = useSelector(availableLanguagesSelector);
  const storedLanguage = useSelector(languageSelector);
  const languages = useSelector(flagSelectLanguagesSelector);
  const language = useMemo(
    () => createFullLanguageCode(storedLanguage.toLowerCase()),
    [storedLanguage]
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAvailableLanguages());
  }, []);

  useEffect(() => {
    if (availableLanguages) {
      dispatch(selectBrowserLanguage());
    }
  }, [availableLanguages]);

  const onLogoutClick = useCallback(() => {
    dispatch(logOut());
  }, [dispatch]);

  const onLanguageChange = useCallback(
    code => {
      dispatch(selectLanguage({ code, isAuthenticated }));
    },
    [dispatch, availableLanguages]
  );

  return (
    <div className={classes.wrapper}>
      <div className={classes.layout}>
        <Header
          isAuthenticated={isAuthenticated}
          onLogout={onLogoutClick}
          onLanguageChange={onLanguageChange}
          userName={userName}
          language={language}
          languages={languages}
          iconButton={IconButton}
        />
        {isAuthenticated && (
          <SideMenu className={classes.menu} iconButton={IconButton} />
        )}
        <div className={classes.content}>{children}</div>
        <Footer />
      </div>
    </div>
  );
};

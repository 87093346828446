import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import { Theme, Typography } from '@mui/material';

import { CustomIcon, SupportedIcon } from '@troy/shared/src/components/common';

import { LanguageCodeKey, LanguageFullCodeKey } from '../../../../types';
import flagDE from '../../../../assets/img/flags/flag-de.svg';
import flagGB from '../../../../assets/img/flags/flag-gb.svg';
import flagNL from '../../../../assets/img/flags/flag-nl.svg';
import flagTR from '../../../../assets/img/flags/flag-tr.svg';
import flagSV from '../../../../assets/img/flags/flag-sv.svg';
import flagFr from '../../../../assets/img/flags/flag-fr.svg';
import { languageFromFullCode } from '../../../../utils/translations';
import { IconVariant } from '../../CustomIcon';

export const FLAG_MAP: Record<LanguageCodeKey, string> = {
  de: flagDE,
  en: flagGB,
  nl: flagNL,
  tr: flagTR,
  sv: flagSV,
  fr: flagFr
};

export const getFlagByLanguage = (lang: string): string =>
  FLAG_MAP[languageFromFullCode(lang)];

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: `0 ${theme.spacing(1.5)} 0 ${theme.spacing(1)}`,
    border: 0,
    width: '100%',
    height: 48,
    transition: theme.transitions.custom.fast,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    background: 'inherit',
    color: 'inherit',
    '&:focus': {
      outline: 'none'
    }
  },
  imageWrapper: {
    display: 'inline-flex',
    flexDirection: 'column'
  },
  imageCompact: {
    height: '20px'
  },
  label: {
    marginLeft: theme.spacing(1),
    lineHeight: 1.28571428571,
    color: 'inherit',
    flex: 1,
    textAlign: 'left'
  },
  icon: {
    transition: theme.transitions.custom.fast
  }
}));

interface LanguageSelectItemProps {
  id?: string;
  isCompact?: boolean;
  className?: string;
  labelClassName?: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  value: LanguageFullCodeKey;
  label: string;
  isSelected?: boolean;
  icon?: SupportedIcon;
  iconClass?: string;
  iconVariant?: IconVariant;
}

const LanguageSelectItem: React.FC<LanguageSelectItemProps> = ({
  id,
  isCompact,
  className,
  labelClassName,
  onClick,
  value,
  label,
  isSelected,
  icon,
  iconClass,
  iconVariant
}) => {
  const classes = useStyles();

  return (
    <button
      id={id}
      className={clsx(classes.root, className)}
      onClick={onClick}
      data-selected={isSelected}
      data-language={value}
    >
      <div className={clsx(classes.imageWrapper)}>
        <img
          className={isCompact ? classes.imageCompact : undefined}
          src={getFlagByLanguage(value)}
          alt={label}
        />
      </div>
      <Typography
        className={clsx(labelClassName, classes.label)}
        variant="body2"
        component="span"
      >
        {label}
      </Typography>
      {icon && (
        <CustomIcon
          className={clsx(iconClass, classes.icon)}
          icon={icon}
          size="small"
          variant={iconVariant}
        />
      )}
    </button>
  );
};

export default LanguageSelectItem;

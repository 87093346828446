import { createSelector, Selector } from 'reselect';
import { SnackBarReducerState } from '../reducers/snackbar';
import { StoreState } from '../reducers';

const stateSelector = (state: StoreState): SnackBarReducerState =>
  state.snackbar;

export const snackBarNotificationsSelector = createSelector<
  [Selector<StoreState, SnackBarReducerState>],
  SnackBarReducerState['notifications']
>(stateSelector, state => state.notifications);

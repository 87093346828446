import React, { forwardRef, useCallback, useMemo, useState } from 'react';
import clsx from 'clsx';
import { Box, Divider } from '@mui/material';
import { makeStyles } from '@mui/styles';
import SearchResultsList from './SearchResultsList';
import { TextInput } from '@troy/shared/src/components/common';
import { CircularProgress } from '../../../../../common';

const useStyles = makeStyles(theme => ({
  wrapper: {
    boxSizing: 'border-box',
    background: theme.palette.background.default,
    borderRadius: theme.shape.borderRadius * 6,
    border: `1px solid ${theme.palette.text.secondary}`,
    transition: 'all ease-in-out 200ms'
  },
  wrapperFocused: {
    filter: `drop-shadow(0px 2px 12px rgba(0, 0, 0, 0.2))`,
    borderColor: theme.palette.text.secondary
  },
  wrapperResults: {
    filter: `drop-shadow(0px 2px 12px rgba(26, 199, 184, 0.2))`,
    borderColor: theme.palette.primary.main
  },
  wrapperNoResults: {
    filter: `drop-shadow(0px 2px 12px rgba(255, 53, 53, 0.2))`,
    borderColor: theme.palette.warning.dark
  },
  inputContainer: {
    width: '100%'
  },
  inputRoot: {
    boxShadow: 'none !important',
    background: `${theme.palette.background.default} !important`
  },
  loader: {
    marginRight: theme.spacing(2)
  },
  list: {
    margin: '16px 24px 24px 24px !important'
  },
  divider: {
    margin: '0 24px !important',
    background: theme.palette.text.disabled
  }
}));

const SearchCaseComponent = forwardRef(
  (
    {
      value,
      onChange,
      className,
      rows,
      onRowClick,
      label,
      isLoading,
      maxCount,
      inputRef,
      onFocus,
      onBlur,
      onKeyPress,
      labelTranslated,
      placeholder
    },
    ref
  ) => {
    const classes = useStyles();
    const hasResults = useMemo(
      () => isLoading || (rows && rows.length > 0),
      [isLoading, rows]
    );
    const [isFocused, setFocused] = useState(false);

    const handleInputFocus = useCallback(
      e => {
        onFocus && onFocus(e);
        setFocused(true);
      },
      [onFocus]
    );

    const handleInputBlur = useCallback(
      e => {
        onBlur && onBlur(e);
        setFocused(false);
      },
      [onBlur]
    );

    return (
      <Box
        ref={ref}
        className={clsx(
          classes.wrapper,
          isFocused && classes.wrapperFocused,
          !isLoading && hasResults && !!value && classes.wrapperResults,
          !isLoading &&
            !!value &&
            rows &&
            rows.length === 0 &&
            classes.wrapperNoResults,
          className
        )}
      >
        <TextInput
          noShadow
          id="search-input"
          className={classes.inputContainer}
          inputClassName={classes.inputRoot}
          placeholder={label}
          inputPlaceholder={placeholder}
          startIcon="search"
          startIconVariant="textPrimary"
          button={
            isLoading ? (
              <CircularProgress className={classes.loader} />
            ) : undefined
          }
          value={value}
          onKeyPress={onKeyPress}
          onChange={onChange}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
        />
        {!!value && hasResults && (
          <>
            <Divider className={classes.divider} />
            <SearchResultsList
              className={classes.list}
              count={maxCount}
              isLoading={isLoading}
              rows={rows}
              onRowClick={onRowClick}
            />
          </>
        )}
      </Box>
    );
  }
);

export default SearchCaseComponent;

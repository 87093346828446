import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { withRouter } from 'react-router-dom';
import { Box } from '@mui/material';

import { Translation } from '@troy/shared/src/components/common';

import StripoService from '../../../../services/StripoService';
import { Button, StripoIcon, TemplateIcon, Widget } from '../../../common';
import {
  cognitoTokenSelector,
  languageSelector
} from '../../../../store/selectors';
import { exampleCss } from './exampleTemplate';
import { genericWayneTemplate } from './genericWayneTemplate';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row'
  },
  content: {
    padding: 46,
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  icon: {
    flexShrink: 0
  },
  description: {
    marginLeft: 20,
    fontSize: 14,
    lineHeight: '16px'
  },
  editor: {
    margin: 20,
    display: 'flex',
    flexDirection: 'column'
  },
  frames: {
    display: 'flex'
  },
  settings: {
    width: 375,
    height: 660,
    overflow: 'auto',
    marginRight: 10
  },
  preview: {
    width: 750,
    height: 660,
    overflow: 'hidden',
    marginLeft: 10,
    '& iframe': {
      height: '100% !important'
    },
    '& .esdev-preview-splitter': {
      height: '100% !important'
    }
  },
  save: {
    flexGrow: '1 !important',
    flexBasis: '32px !important',
    margin: '20px 0 0 auto',
    width: 232,
    height: 32,
    padding: '0 10px !important',
    borderRadius: `${theme.shape.borderRadius * 4}px !important`,
    fontFamily: 'CustomFont-Bold',
    fontSize: '1rem !important',
    lineHeight: 1.5
  }
}));

const STRIPO_TEMPLATE_HTML = 'stripo_template_html';
const STRIPO_TEMPLATE_CSS = 'stripo_template_css';

const StripoWidget = withRouter(
  ({ location, history, extended, setExtended, removeItem, ...props }) => {
    const classes = useStyles();
    const language = useSelector(languageSelector);
    const storedHtml = localStorage.getItem(STRIPO_TEMPLATE_HTML);
    const storedCss = localStorage.getItem(STRIPO_TEMPLATE_CSS);

    const [data, setData] = useState({
      html:
        !!storedHtml && storedHtml.length > 1
          ? storedHtml
          : genericWayneTemplate,

      css: !!storedCss && storedCss.length > 1 ? storedCss : exampleCss
    });
    const token = useSelector(cognitoTokenSelector);

    const onButtonClick = useCallback(() => {
      setExtended();
    }, [setExtended]);

    const openEditor = useCallback(() => {
      window.Stripo.init({
        locale: language,
        settingsId: 'stripoSettingsContainer',
        previewId: 'stripoPreviewContainer',
        html: data.html,
        css: data.css,
        apiRequestData: {
          emailId: '1933583'
        },
        getAuthToken: function (callback) {
          StripoService.getStripoToken(token).then(result => {
            callback(result.token);
          });
        }
      });
    }, [data, token, language]);

    const closeEditor = useCallback(() => {
      if (window.StripoApi) {
        window.StripoApi.stop();
      }
    }, []);

    useEffect(() => {
      if (extended) {
        openEditor();
      } else {
        closeEditor();
      }
    }, [extended]);

    const onSave = () => {
      window.StripoApi.getTemplate((html, css) => {
        setData({ html, css });
        localStorage.setItem(STRIPO_TEMPLATE_HTML, html);
        localStorage.setItem(STRIPO_TEMPLATE_CSS, css);
      });
    };

    return (
      <Widget
        {...props}
        icon={StripoIcon}
        onActionClick={removeItem}
        buttonName="CP_START_EMAILDESIGNER_BUTTON"
        onButtonClick={onButtonClick}
        isExtended={extended}
      >
        <div className={classes.root}>
          {!extended && (
            <Box className={classes.content}>
              <TemplateIcon className={classes.icon} />
              <Translation component="span" className={classes.description}>
                CP_START_EMAILDESIGNER_CONTENT
              </Translation>
            </Box>
          )}
          {extended && (
            <Box className={classes.editor}>
              <Box className={classes.frames}>
                <div
                  id="stripoSettingsContainer"
                  className={classes.settings}
                />
                <div id="stripoPreviewContainer" className={classes.preview} />
              </Box>
              <Translation
                component={Button}
                className={classes.save}
                onClick={onSave}
                variant="contained"
                color="primary"
              >
                CP_EMAILDESIGNER_SAFEBTN
              </Translation>
            </Box>
          )}
        </div>
      </Widget>
    );
  }
);

export default StripoWidget;

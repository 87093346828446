import { TableIconSvg } from '../components/common';
import { ReactComponent as PdfIcon } from '../assets/icons/widgets/users-widget/file-upload/pdf.svg';
import { ReactComponent as ImgIcon } from '../assets/icons/widgets/users-widget/file-upload/img.svg';
import { ReactComponent as DocxIcon } from '../assets/icons/widgets/users-widget/file-upload/docx.svg';
import { ReactComponent as XlsIcon } from '../assets/icons/widgets/users-widget/file-upload/xls.svg';

//The files allowed here should be matching the server side on
//server/document-service/src/main/kotlin/de/troybleiben/document/DocumentValidator.kt
export const PDF = 'application/pdf';
export const DOC = 'application/msword';
export const DOCX =
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
export const XLSX =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
export const XLS = 'application/vnd.ms-excel';
export const JPEG = 'image/jpeg';
export const PNG = 'image/png';
export const CSV = '.csv';

export const TYPES_ICONS = {
  [PDF]: PdfIcon,
  [DOC]: DocxIcon,
  [DOCX]: DocxIcon,
  [JPEG]: ImgIcon,
  [PNG]: ImgIcon,
  [XLS]: XlsIcon,
  [XLSX]: XlsIcon,
  [CSV]: TableIconSvg
};

export const TYPES_EXT_ICONS = {
  pdf: PdfIcon,
  doc: DocxIcon,
  docx: DocxIcon,
  jpeg: ImgIcon,
  png: ImgIcon,
  xls: XlsIcon,
  xlsx: XlsIcon,
  csv: TableIconSvg
};

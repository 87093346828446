import React from 'react';
import clsx from 'clsx';
import {
  Button as MaterialButton,
  Fab,
  IconButton,
  Theme
} from '@mui/material';
import { ButtonTypeMap } from '@mui/material/Button/Button';
import makeStyles from '@mui/styles/makeStyles';

import { onClickWrapper } from '../../../utils/ui';
import { svgFill } from '../../../utils/styles';

export const ICON_BUTTON_TEST_ID = 'icon_button_test_id';
export const FAB_TEST_ID = 'fab_test_id';

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    textTransform: 'initial'
  },
  text: {
    backgroundColor: 'transparent !important'
  },
  primaryContained: {
    '&:hover': {
      background: theme.palette.primary.light,
      color: theme.palette.secondary.dark,

      ...svgFill(theme.transitions.custom.fast, theme.palette.secondary.dark)
    }
  }
}));

interface ButtonProps {
  onClick?: (e: React.MouseEvent<HTMLInputElement>) => void;
  type?: 'button' | 'submit' | 'reset';
  children: NonNullable<React.ReactNode>;
  className?: string;
  variant?: 'native' | 'fab' | 'icon' | 'text' | 'outlined' | 'contained';
  fullWidth?: boolean;
  disabled?: boolean;
  id?: string;
  color?: ButtonTypeMap['props']['color'];
  disableElevation?: boolean;
  title?: string;
  testId?: string;
}

const Button: React.FC<ButtonProps> = ({
  onClick,
  type,
  children,
  className,
  variant = 'contained',
  fullWidth,
  disabled,
  id,
  color,
  disableElevation,
  title,
  testId
}) => {
  const c = useStyles();

  const classes = {
    root: clsx(
      c.button,
      variant === 'text' ? c.text : '',
      variant === 'contained' && color === 'primary' && c.primaryContained,
      className
    )
  };

  return (
    <>
      {variant === 'native' && (
        <button
          id={id}
          className={className}
          type={type}
          onClick={onClickWrapper(id, onClick)}
          title={title}
          disabled={disabled}
          data-testid={testId}
        >
          {children}
        </button>
      )}
      {['text', 'outlined', 'contained'].includes(variant) && (
        <MaterialButton
          type={type}
          classes={classes}
          fullWidth={fullWidth}
          id={id}
          color={color}
          variant={variant as 'text' | 'outlined' | 'contained'}
          onClick={onClickWrapper(id, onClick)}
          disabled={disabled} // add for calculator
          disableElevation={disableElevation}
          data-testid={testId}
        >
          {children}
        </MaterialButton>
      )}
      {variant === 'icon' && (
        <IconButton
          type={type}
          classes={classes}
          id={id}
          color={color}
          onClick={onClickWrapper(id, onClick)}
          disabled={disabled}
          size="large"
          data-testid={testId || ICON_BUTTON_TEST_ID}
        >
          {children}
        </IconButton>
      )}
      {variant === 'fab' && (
        <Fab
          type={type}
          classes={classes}
          id={id}
          color={color}
          onClick={onClickWrapper(id, onClick)}
          disabled={disabled}
          data-testid={testId || FAB_TEST_ID}
        >
          {children}
        </Fab>
      )}
    </>
  );
};

export default Button;

import React from 'react';
import { ListItem } from '@mui/material';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';

import { Button } from '../Button';
import { ClearIcon } from '../Icons';
import { TYPES_ICONS } from '../../../constants/file-upload';

const useStyles = makeStyles(theme => ({
  wrapper: {
    padding: 8,
    border: `1px solid ${theme.palette.text.disabled}`,
    borderRadius: theme.shape.borderRadius * 2,
    justifyContent: 'space-between',
    '&:hover': {
      borderColor: theme.palette.text.hint
    },
    '&:hover p': {
      color: theme.palette.text.primary
    }
  },
  nameBlock: {
    display: 'flex',
    alignItems: 'center'
  },
  icon: {
    color: theme.palette.primary.main,
    minWidth: 24,
    minHeight: 24
  },
  name: {
    ...theme.typography.subtitle2,
    color: theme.palette.text.secondary,
    wordBreak: 'break-all',
    margin: '0 20px'
  },
  button: {
    padding: 6,
    color: theme.palette.text.disabled
  },
  buttonIcon: {
    width: 16,
    height: 16
  }
}));

const FilePreview = ({ className, file, onRemove }) => {
  const classes = useStyles();
  const Icon = TYPES_ICONS[file.extension];

  return (
    <ListItem className={clsx(classes.wrapper, className)}>
      <div className={classes.nameBlock}>
        {Icon ? <Icon className={classes.icon} /> : null}
        <p className={classes.name}>{file.fileName}</p>
      </div>
      <Button className={classes.button} variant="icon" onClick={onRemove}>
        <ClearIcon className={classes.buttonIcon} />
      </Button>
    </ListItem>
  );
};

export default FilePreview;

import React, { ReactElement, useMemo } from 'react';
import clsx from 'clsx';
import { Step, StepLabel, Stepper as MuiStepper, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import Text from '../Text';

const useStyles = makeStyles((theme: Theme) => ({
  connector: {
    flex: '1 1 auto',
    height: 1,
    background: theme.palette.text.disabled
  },
  step: {
    padding: 0
  },
  iconContainer: {
    paddingRight: 0
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: theme.spacing(4),
    height: theme.spacing(4),
    borderRadius: '100%',
    background: theme.palette.primary.main
  },
  pastStepIcon: {
    background: theme.palette.primary.light
  },
  nexStepIcon: {
    background: theme.palette.text.disabled
  }
}));

interface StepperProps {
  className?: string;
  activeStep: number;
  stepCount: number;
}

const Stepper: React.FC<StepperProps> = ({
  className,
  activeStep,
  stepCount
}): ReactElement => {
  const classes = useStyles();
  const items = useMemo(() => Array(stepCount).fill(''), [stepCount]);

  return (
    <MuiStepper
      className={className}
      activeStep={activeStep}
      connector={<div className={classes.connector} />}
    >
      {items.map((label, index) => (
        <Step className={classes.step} key={index}>
          <StepLabel
            classes={{
              iconContainer: classes.iconContainer
            }}
            icon={
              <Text
                component="span"
                variant="body1Bold"
                color="primaryContrast"
                className={clsx(
                  classes.icon,
                  index < activeStep && classes.pastStepIcon,
                  index > activeStep && classes.nexStepIcon
                )}
              >
                {index + 1}
              </Text>
            }
          >
            {label}
          </StepLabel>
        </Step>
      ))}
    </MuiStepper>
  );
};

export default Stepper;

import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Switch as MuiSwitch, Theme } from '@mui/material';

import { useTranslationsContext } from '../TranslationProvider';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: 48,
    height: 24,
    padding: 0
  },
  base: {
    padding: 4
  },
  track: {
    opacity: 1,
    borderRadius: 24,
    background: theme.palette.text.disabled,
    border: `1px solid ${theme.palette.text.hint}`,
    boxSizing: 'border-box'
  },
  thumb: {
    width: 16,
    height: 16,
    background: theme.palette.text.hint
  },
  checked: {
    transform: 'translateX(24px) !important',

    '& + .MuiSwitch-track': {
      opacity: '1 !important',
      background: `${theme.palette.primary.light} !important`,
      borderColor: `${theme.palette.primary.main} !important`
    },
    '& .MuiSwitch-thumb': {
      background: theme.palette.primary.dark
    }
  }
}));

interface SwitchProps {
  className?: string;
  isEnabled: boolean;
  toggleEnabled: (checked: boolean) => void;
  title: string;
}

const Switch: React.FC<SwitchProps> = ({
  className,
  isEnabled,
  toggleEnabled,
  title
}): React.ReactElement => {
  const classes = useStyles();
  const { translate } = useTranslationsContext();

  return (
    <MuiSwitch
      checked={isEnabled}
      onChange={(_, checked): void => toggleEnabled(checked)}
      className={className}
      classes={{
        root: classes.root,
        switchBase: classes.base,
        track: classes.track,
        thumb: classes.thumb,
        checked: classes.checked
      }}
      inputProps={{ 'aria-label': translate(title) }}
    />
  );
};

export default Switch;

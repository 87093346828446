import { createAction } from 'redux-act';

export const getDecisions = createAction('Decisions: Get decisions');
export const decisionsReceived = createAction('Decisions: Decisions received');

export const disableWidget = createAction('Decisions: Disable widget');
export const enableWidgets = createAction('Decisions: Enable widgets');

export const setDecisionsUsername = createAction('Decisions: Set username');

export const clearDecisionsStore = createAction('Decisions: Clear store');

import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  FormControl,
  InputBase,
  InputLabel,
  MenuItem,
  Select as MaterialSelect
} from '@mui/material';
import {
  FormField,
  Translation,
  usePaperStyles
} from '@troy/shared/src/components/common';

import withStyles from '@mui/styles/withStyles';
import makeStyles from '@mui/styles/makeStyles';

const SelectInput = withStyles(theme => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(2)
    }
  },
  input: {
    borderRadius: theme.shape.borderRadius * 8,
    position: 'relative',
    border: `1px solid ${theme.palette.text.primary}`,
    fontSize: 16,
    padding: '4px 8px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      borderRadius: theme.shape.borderRadius * 8,
      borderColor: theme.palette.primary.main
      // boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)'
    }
  }
}))(InputBase);

const useStyles = makeStyles(theme => ({
  selectOption: {
    '&:hover:not([aria-selected="true"])': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)'
    },
    '&[aria-selected="true"]': {
      backgroundColor: 'rgba(0, 0, 0, 0.08)'
    },
    '&:hover[aria-selected="true"]': {
      backgroundColor: 'rgba(0, 0, 0, 0.08)'
    }
  }
}));

const SelectField = ({
  options,
  onChange,
  className,
  inputClassName,
  classNameRoot,
  classNameMenuItem,
  maxSelectHeight,
  field,
  value,
  multiple,
  iconComponent
}) => {
  const classes = useStyles();
  const paperClasses = usePaperStyles();

  return (
    <MaterialSelect
      MenuProps={{
        PaperProps: {
          classes: {
            root: paperClasses.root,
            rounded: paperClasses.rounded,
            elevation1: paperClasses.elevation1
          },
          style: {
            maxHeight: maxSelectHeight ? maxSelectHeight : 300
          }
        }
      }}
      {...field}
      IconComponent={iconComponent && (() => iconComponent)}
      multiple={multiple}
      className={className}
      value={field ? field.value : value}
      classes={{
        select: inputClassName,
        root: classNameRoot
      }}
      onChange={field ? field.onChange : onChange}
      renderValue={value => {
        const valueOption =
          options &&
          options.find(({ value: optionValue }) => value === optionValue);
        return valueOption && valueOption.translated ? (
          <Translation component="p">{valueOption.label}</Translation>
        ) : (
          valueOption.label
        );
      }}
      input={<SelectInput />}
    >
      {options.map(({ label, value, id, translated }, key) => {
        const props = {
          value,
          key,
          id,
          classes: {
            root: clsx(classes.selectOption, classNameMenuItem)
          }
        };
        return translated ? (
          <Translation component={MenuItem} {...props}>
            {label}
          </Translation>
        ) : (
          <MenuItem {...props}>{label}</MenuItem>
        );
      })}
    </MaterialSelect>
  );
};

const LabeledSelectField = withStyles(() => ({
  formControl: {
    transform: 'translate(15px, 21px) scale(1)'
  },
  shrink: {
    transform: 'translate(0, 1.5px) scale(0.75)'
  }
}))(({ classes, label, classNameWrapper, classNameLabel, ...props }) => {
  return label ? (
    <FormControl className={classNameWrapper}>
      <InputLabel
        classes={classes}
        className={classNameLabel}
        id="demo-simple-select-label"
      >
        {label}
      </InputLabel>
      <SelectField label={label} {...props} />
    </FormControl>
  ) : (
    <SelectField {...props} />
  );
});

const FormSelectField = ({ name, validate, ...props }) => {
  return name ? (
    <FormField
      component={LabeledSelectField}
      name={name}
      validate={validate}
      {...props}
    />
  ) : (
    <LabeledSelectField {...props} />
  );
};

FormSelectField.propTypes = {
  name: PropTypes.string,
  options: PropTypes.array,
  value: PropTypes.any,
  hasForm: PropTypes.bool,
  onChange: PropTypes.func,
  className: PropTypes.string,
  multiple: PropTypes.bool,
  iconComponent: PropTypes.element
};

export default FormSelectField;

import React, { FC } from 'react';
import { Menu, MenuItem, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import LanguageSelectItem from '../LanguageSelectItem';
import clsx from 'clsx';
import { IconVariant } from '../../CustomIcon';

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    margin: 'auto'
  },
  menu: {
    minWidth: 173,
    boxShadow: ' 0px 2px 13px rgba(0, 0, 0, 0.5)',
    borderRadius: Number(theme.shape.borderRadius) * 2.5,
    overflow: 'hidden'
  },
  menuItem: {
    padding: '0',
    background: theme.palette.background.default,
    '&:hover, &:focus': {
      background: theme.palette.action.hover,
      color: theme.palette.primary.contrastText,
      '& path[fill="red"]': {
        fill: theme.palette.primary.contrastText
      }
    },
    '& path[fill="red"]': {
      fill: theme.palette.text.primary
    }
  },
  menuPaper: {
    backgroundColor: 'transparent'
  },
  focus: {
    backgroundColor: `${theme.palette.action.hover} !important`
  }
}));

export interface LanguageSelectMenuProps {
  open: boolean;
  anchorEl: null | HTMLElement;
  className?: string;
  languages: Record<string, string>;
  value: string;
  handleChange: (value: string) => void;
  handleClose: () => void;

  menuItemClassName?: string;
  menuItemLabelClassName?: string;
  iconVariant?: IconVariant;
}

const LanguageSelectMenu: FC<LanguageSelectMenuProps> = ({
  open,
  anchorEl,
  languages,
  value,
  handleChange,
  handleClose,
  menuItemClassName,
  menuItemLabelClassName,
  iconVariant
}): React.ReactElement => {
  const classes = useStyles();

  return (
    <Menu
      id="menu-language"
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      MenuListProps={{
        classes: {
          root: classes.menu
        },
        disablePadding: true
      }}
      classes={{ paper: classes.menuPaper }}
    >
      {Object.entries(languages).map(([key, label]) => (
        <MenuItem
          key={key}
          focusVisibleClassName={classes.focus}
          classes={{ root: clsx(menuItemClassName, classes.menuItem) }}
          onClick={(): void => handleChange(key)}
        >
          <LanguageSelectItem
            id={`language-${key}`}
            key={key}
            value={key}
            label={label}
            isSelected={key === value}
            icon={key === value ? 'confirm' : undefined}
            iconClass={classes.icon}
            iconVariant={iconVariant}
            labelClassName={menuItemLabelClassName}
          />
        </MenuItem>
      ))}
    </Menu>
  );
};

export default LanguageSelectMenu;

import { call, put, select } from 'redux-saga/effects';

import QuickSightService from '../../services/QuickSightService';

import {
  embeddedUrlsReceived,
  getFederationToken,
  handleError
} from '../actions';
import { cognitoFederationTokenSelector } from '../selectors';

export function* getEmbeddedUrls({ payload: token }) {
  try {
    const items = yield call(QuickSightService.getIframeURLs, token);
    yield put(embeddedUrlsReceived(items));
  } catch (err) {
    yield put(embeddedUrlsReceived([]));
    yield put(handleError(err));
  }
}

const quicksightRedirect = token => {
  const quicksightUrlEncoded = encodeURIComponent(
    'https://quicksight.aws.amazon.com/'
  );

  const quickSightSSO =
    'https://signin.aws.amazon.com/federation?Action=login&Issuer=' +
    encodeURIComponent(window.location.origin) +
    '&Destination=' +
    quicksightUrlEncoded +
    '&SigninToken=' +
    token;

  window.location = quickSightSSO;
};

export function* redirectToQuickSightSSO() {
  let federationToken = yield select(cognitoFederationTokenSelector);
  if (!federationToken) {
    yield put(getFederationToken());
    federationToken = yield select(cognitoFederationTokenSelector);
  }
  yield quicksightRedirect(federationToken);
}

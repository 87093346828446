import React from 'react';
import clsx from 'clsx';

import { Button, DownloadIcon } from '../../../../common';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  button: {
    width: 36,
    height: 36
  },
  icon: {
    width: 22,
    height: 22
  }
}));

const DownloadButton = ({ className, iconClassName, ...props }) => {
  const classes = useStyles();

  return (
    <div>
      <Button
        {...props}
        color="primary"
        className={clsx(classes.button, className)}
        variant="fab"
      >
        <DownloadIcon className={clsx(classes.icon, iconClassName)} />
      </Button>
    </div>
  );
};

export default DownloadButton;

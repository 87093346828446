import { useEffect, useRef } from 'react';
import { useMediaQuery } from '@mui/material';
import {
  oneColumnWidgetListWidth,
  threeColumnWidgetListWidth,
  twoColumnWidgetListWidth
} from '../assets/styles/jss_functions';

// Hook
export function usePrevious(value) {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = useRef();

  // Store current value in ref
  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes

  // Return previous value (happens before update in useEffect above)
  return ref.current;
}

export const useAppWidthClass = () => {
  const isOneColumnWidth = useMediaQuery(
    `(min-width:${oneColumnWidgetListWidth}px)`,
    {
      noSsr: true
    }
  );
  const isTwoColumnWidth = useMediaQuery(
    `(min-width:${twoColumnWidgetListWidth}px)`,
    {
      noSsr: true
    }
  );
  const isThreeColumnWidth = useMediaQuery(
    `(min-width:${threeColumnWidgetListWidth}px)`,
    {
      noSsr: true
    }
  );

  return {
    isOneColumnWidth,
    isTwoColumnWidth,
    isThreeColumnWidth
  };
};

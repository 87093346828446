import React from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';

import { FormField } from '@troy/shared/src/components/common';
import { codeToLocale } from '../../../constants/i18n/countries';

Object.entries(codeToLocale).forEach(([code, locale]) => {
  registerLocale(code, locale);
});

const CustomDatePicker = ({
  field,
  id,
  onChange,
  value,
  placeholderText,
  customInput,
  dateFormat = 'P',
  inputValue,
  ...props
}) => (
  <DatePicker
    {...props}
    {...field}
    value={inputValue}
    onChange={field ? field.onChange : onChange}
    selected={field ? field.value : value}
    dateFormat={dateFormat}
    placeholderText={placeholderText}
    customInput={customInput}
  />
);

export default ({ name, validate, onChange, ...props }) =>
  name ? (
    <FormField
      {...{ name, validate, onChange, ...props }}
      component={CustomDatePicker}
    />
  ) : (
    <CustomDatePicker {...{ name, onChange }} {...props} />
  );

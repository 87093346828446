import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import { Theme, Tooltip as MaterialTooltip, TooltipProps } from '@mui/material';

const useStyles = makeStyles((theme: Theme) => ({
  popper: {
    zIndex: 950
  },
  tooltip: {
    ...theme.typography.subtitle1,
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
    borderRadius: Number(theme.shape.borderRadius) * 2,
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    filter: 'drop-shadow(0px 0px 8px rgba(43, 21, 60, 0.2))'
  },
  secondary: {
    background: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText
  },
  arrow: {
    color: theme.palette.primary.main
  },
  arrowSecondary: {
    color: theme.palette.secondary.main
  }
}));

interface TooltipProperties {
  title: TooltipProps['title'];
  placement: TooltipProps['placement'];
  tooltipClass?: string;
  open?: boolean;
  arrow?: boolean;
  secondary?: boolean;
  children?: TooltipProps['children'] | null;
}

const Tooltip: React.FC<TooltipProperties> = ({
  title,
  placement,
  tooltipClass,
  open,
  arrow,
  secondary,
  children
}) => {
  const classes = useStyles();

  return children ? (
    <MaterialTooltip
      classes={{
        popper: classes.popper,
        tooltip: clsx(
          classes.tooltip,
          secondary && classes.secondary,
          tooltipClass
        ),
        arrow: clsx(classes.arrow, secondary && classes.arrowSecondary)
      }}
      open={open}
      arrow={arrow}
      title={title}
      placement={placement}
    >
      {children}
    </MaterialTooltip>
  ) : null;
};

export default Tooltip;

import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material';

import { ActionButton, Modal, Text, Translation } from '../index';

const useStyles = makeStyles((theme: Theme) => ({
  backdrop: {
    backgroundColor: theme.palette.grey[50],
    opacity: '0.8 !important'
  },
  paper: {
    borderRadius: Number(theme.shape.borderRadius) * 6,
    width: 360,
    maxWidth: `calc(100vw - ${theme.spacing(4)})`,
    padding: `${theme.spacing(2)} ${theme.spacing(5)} ${theme.spacing(
      3
    )} ${theme.spacing(5)}`,
    boxSizing: 'border-box'
  },
  title: {
    wordBreak: 'break-word'
  },
  buttons: {
    marginTop: theme.spacing(3),
    display: 'flex',
    justifyContent: 'center',

    ' & > *:first-child:not(:last-child)': {
      width: '100%',
      marginRight: theme.spacing(0.5)
    },
    ' & > *:last-child:not(:first-child)': {
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
      marginLeft: theme.spacing(0.5)
    }
  },
  button: {
    width: '100%',
    minWidth: 'unset'
  }
}));

interface PostBoxItemContextConfirmationMenuProps {
  isOpen: boolean;
  onCancel: () => void;
  onSave: () => void;
  isItemVisibilityLoading: boolean;
}

const PostBoxItemContextConfirmationMenu: React.FC<
  PostBoxItemContextConfirmationMenuProps
> = ({ isOpen, onCancel, onSave, isItemVisibilityLoading }) => {
  const classes = useStyles();

  return (
    <Modal
      isOpen={isOpen}
      onClose={isItemVisibilityLoading ? undefined : onCancel}
      fullWidth={false}
      backdropRootClassName={classes.backdrop}
      paperClassName={classes.paper}
    >
      <Text
        translated
        className={classes.title}
        component="h2"
        variant="body2"
        color="textPrimary"
        align="center"
      >
        UNIC_UXCOPIES_COPY_NOTREVERTEDWARNING
      </Text>
      <div className={classes.buttons}>
        <div>
          <ActionButton
            className={classes.button}
            id="postbox_item_context_menu_confirmation_cancel"
            color="secondary"
            variant="outlined"
            padding="medium"
            onClick={onCancel}
            disabled={isItemVisibilityLoading}
          >
            <Translation keyIfNoValue isFragment>
              ALL_UXCOPIES_BTN_CANCEL
            </Translation>
          </ActionButton>
        </div>
        <div>
          <ActionButton
            className={classes.button}
            id="postbox_item_context_menu_confirmation_save"
            onClick={onSave}
            padding="medium"
            isLoading={isItemVisibilityLoading}
          >
            <Translation keyIfNoValue isFragment>
              ALL_UXCOPIES_BTN_SAVE
            </Translation>
          </ActionButton>
        </div>
      </div>
    </Modal>
  );
};

export default PostBoxItemContextConfirmationMenu;

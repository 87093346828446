import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache
} from '@apollo/client';

import { GRAPHQL } from '../constants/api';

const authLink = customHeaders =>
  new ApolloLink((operation, forward) => {
    operation.setContext(({ headers }) => ({
      headers: {
        ...headers,
        ...customHeaders
      }
    }));
    return forward(operation);
  });

const client = headers =>
  new ApolloClient({
    link: authLink(headers).concat(
      new HttpLink({
        uri: GRAPHQL,
        fetch: fetch
      })
    ),
    cache: new InMemoryCache(),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'network-only',
        errorPolicy: 'ignore'
      },
      query: {
        fetchPolicy: 'network-only',
        errorPolicy: 'all'
      }
    }
  });

export default client;

import { routerReducer as routing } from 'react-router-redux';

import error from './error';
import trello from './trello';
import quicksight from './quicksight';
import cognito from './cognito';
import decisions from './decisions';
import liability from './liability';
import invoice from './invoice';
import confluence from './confluence';
import widgets from './widgets';
import translations from './translations';
import looker from './looker';
import sharedReducers from '@troy/shared/src/store/reducers';

export default {
  translations,
  quicksight,
  cognito,
  trello,
  confluence,
  decisions,
  liability,
  invoice,
  widgets,
  routing,
  error,
  looker,
  ...sharedReducers
};

export const getAmountWithCurrency = (
  amount,
  currency = 'EUR',
  locale = 'de-DE'
) =>
  Number(amount || 0).toLocaleString(locale, {
    currency: currency,
    style: 'currency'
  });

export const deepEqual = (obj1, obj2) =>
  JSON.stringify(obj1) === JSON.stringify(obj2);

import { createReducer } from 'redux-act';

import * as actions from '../actions/invoice';

const DEFAULT_STATE = {
  invoices: null,
  isLoading: false
};

export default createReducer(
  {
    [actions.invoicesReceived]: (state, invoices) => ({
      ...state,
      invoices: invoices.map(
        (
          {
            fileName: name,
            fileId: id,
            fileSize: size,
            createdAt: creationDate,
            modifiedAt: modificationDate
          },
          index
        ) => ({
          id: id + index, // fix for BE returning duplicates
          name,
          fileId: id,
          size,
          creationDate: new Date(creationDate.replace(' ', 'T')), //Safari strict dates fix
          modificationDate: new Date(modificationDate.replace(' ', 'T'))
        })
      )
    }),
    [actions.setInvoiceLoading]: (state, isLoading) => ({
      ...state,
      isLoading
    }),
    [actions.resetInvoices]: state => ({
      DEFAULT_STATE
    })
  },
  DEFAULT_STATE
);

const TRELLO_API_URL = process.env.REACT_APP_TRELLO_API_URL;
const TRELLO_APP_KEY = process.env.REACT_APP_TRELLO_APP_KEY;

export const TRELLO_AUTHORIZE = ({
  expiration,
  name,
  returnURL,
  callbackMethod
} = {}) =>
  `${TRELLO_API_URL}/authorize?expiration=${expiration || '1day'}&name=${
    name || 'ClientPortal'
  }&scope=read&response_type=token&key=${TRELLO_APP_KEY}&return_url=${encodeURIComponent(
    returnURL || window.location.href
  )}&callback_method=${callbackMethod || 'fragment'}`;

export const TRELLO_USER_INFO = token =>
  `${TRELLO_API_URL}/members/me/?key=${TRELLO_APP_KEY}&token=${token}`;

export const TRELLO_BOARD_INFO = (token, boardID) =>
  `${TRELLO_API_URL}/boards/${boardID}/?key=${TRELLO_APP_KEY}&token=${token}`;

import { createSelector } from 'reselect';

import whitelabelAssets from '@troy/shared/src/assets/whitelabel';
import { anyImageFile } from '@troy/shared/src/utils/images';

import portalAssets from '../../assets/whitelabel';
import { languageCodeSelector } from './';

const stateSelector = state => state.portal;

export const isPortalInitialized = createSelector(
  stateSelector,
  state => state.isInitialized
);

export const portalConfigurationSelector = createSelector(
  stateSelector,
  state => state.portalConf || {}
);

export const portalCountyExperienceSelector = createSelector(
  portalConfigurationSelector,
  conf => conf.countyExperience
);

export const portalBrandNameSelector = createSelector(
  portalConfigurationSelector,
  conf => conf.brandName
);

export const portalIdentifierSelector = createSelector(
  portalConfigurationSelector,
  ({ brand, countyExperience }) =>
    `${brand ? brand.toLowerCase() : 'troy'}_${countyExperience}`
);

export const portalLoginLogoSelector = createSelector(
  portalIdentifierSelector,
  id => anyImageFile(portalAssets, id, 'login_logo')
);

export const portalLoginLogoHeightSelector = createSelector(
  portalConfigurationSelector,
  conf => conf.clientAppLoginLogoHeight
);

export const portalHeaderLogoWideSelector = createSelector(
  portalIdentifierSelector,
  id => anyImageFile(portalAssets, id, 'header_logo_wide')
);

export const portalHeaderLogoNarrowSelector = createSelector(
  portalIdentifierSelector,
  id => anyImageFile(portalAssets, id, 'header_logo_narrow')
);

export const portalHeaderLogoHeightSelector = createSelector(
  portalConfigurationSelector,
  conf => conf.clientAppHeaderLogoHeight
);

export const portalHeaderGradientSelector = createSelector(
  portalIdentifierSelector,
  id => anyImageFile(portalAssets, id, 'header_gradient')
);

export const portalBackgroundGradientSelector = createSelector(
  portalIdentifierSelector,
  id => anyImageFile(portalAssets, id, 'background_gradient')
);

export const portalFooterTextSelector = createSelector(
  languageCodeSelector,
  portalConfigurationSelector,
  (lang, conf) => conf.clientAppFooterText && conf.clientAppFooterText[lang]
);

export const portalFavIconSelector = createSelector(
  portalIdentifierSelector,
  id => whitelabelAssets[`${id}/favicon.png`]
);

import { createReducer } from 'redux-act';

import * as actions from '../actions/liability';

const DEFAULT_STATE = {
  closeReasons: null,
  isLiabilityClosed: false
};

export default createReducer(
  {
    [actions.liabilityCloseReasonsReceived]: (state, closeReasons) => ({
      ...state,
      closeReasons
    }),
    [actions.setLiabilityClosed]: (state, value) => ({
      ...state,
      isLiabilityClosed: value
    }),
    [actions.resetLiability]: state => ({ ...DEFAULT_STATE })
  },
  DEFAULT_STATE
);

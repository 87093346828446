import { WhitelabelConfType } from '../../types/whitelabel';

const conf: WhitelabelConfType = {
  agentAppEnabled: false,
  customerAppEnabled: true,
  clientAppEnabled: false,
  brand: 'TCRS',
  brandName: 'Nutun',
  countyExperience: 'en_EN',
  clientAppLoginLogoHeight: 52,
  clientAppHeaderLogoHeight: 35,
  clientAppFooterText: {
    de: 'CP_FOOTER_COPYRIGHT',
    en: 'CP_FOOTER_COPYRIGHT',
    nl: 'CP_FOOTER_COPYRIGHT',
    fr: 'CP_FOOTER_COPYRIGHT'
  },
  agentAppBackgroundGradient:
    'linear-gradient(180deg, rgba(0, 0, 0, 0.17) 0%, rgba(0, 0, 0, 0.56) 100%), radial-gradient(100% 100% at 100% 100%, #00B495 15.62%, #13B400 31.25%, #008263 100%)',
  agentAppHeaderBackgroundGradient:
    'linear-gradient(270.18deg, #13B400 18.75%, #00B495 56.25%, #008263 92.19%);',
  agentAppLaneBackgroundGradient:
    'linear-gradient(270.18deg, #008263 19.27%, #00B495 56.25%, #13B400 100%)',
  agentAppHeaderLogoHeight: 44,
  agentAppLoginLogoHeight: 80,
  agentAppErrorLogoHeight: 40,
  agentAppFooterText: {
    de: '_agent is a service by troy ©2017-',
    en: '_agent is a service by troy ©2017-',
    nl: '_agent is a service by troy ©2017-',
    fr: '_agent is a service by troy ©2017-'
  },
  agentAppWelcomeText: {
    de: 'Willkommen zurück in _agent!',
    en: 'Welcome back to _agent!',
    nl: 'Welkom terug bij _agent!',
    fr: 'Bienvenue sur le _agent!'
  },
  customerPortalLogoHeight: 44,
  customerPortalFooterLogoHeight: 72,
  customerPortalDirectorName: 'Ntando Shezi',
  customerPortalDirectorDescriptionKey: {
    de: 'Operations Executive: Agency, Nutun Business Services',
    en: 'Operations Executive: Agency, Nutun Business Services',
    nl: 'Operations Executive: Agency, Nutun Business Services',
    tr: 'Operations Executive: Agency, Nutun Business Services',
    sv: 'Operations Executive: Agency, Nutun Business Services',
    fr: 'Directeur des opérations: Agency, Nutun Business Services'
  },
  customerPortalHomeHeaderKey: {
    de: 'Herzlich willkommen im nutun Kundencenter!',
    en: 'Welcome to the nutun customer center!',
    nl: 'Welkom bij de klantenportal van nutun!',
    tr: 'nutun müşteri hizmetine hoş geldiniz!',
    sv: 'Välkommen till nutun kundcenter!',
    fr: "Bienvenue dans l'espace client de Nutun !"
  },
  customerPortalOnBehalfOfClientKey: {
    de: 'nutun im Auftrag von',
    en: 'nutun on behalf of',
    nl: 'nutun in opdracht van',
    tr: 'adına nutun',
    sv: 'nutun på uppdrag av',
    fr: 'nutun pour le compte de'
  },
  customerPortalGreetingMainKey: {
    de: 'herzlich willkommen im nutun Kundencenter. Ich freue mich, dass du dich jetzt um deine offene Forderung kümmerst. Zahle doch jetzt direkt, um den Fall abzuschließen. Wenn du Fragen hast, steht dir das nutun Team natürlich gerne zur Verfügung.\n\nHerzliche Grüße\n\n<img alt="" src=""/>',
    en: 'welcome to the nutun customer portal. It\'s great that you are here to take care of your open invoices. Please pay now to close the case. If you have any questions, the nutun team is always happy to help.\n\nBest regards\n\n<img alt="" src=""/>',
    nl: 'welkom op het nutun klantenportaal. Fijn dat u hier bent om uw openstaande facturen af te handelen. Betaal nu om de zaak af te sluiten. Als u vragen heeft, staat het nutun team altijd voor u klaar.\n\nMet vriendelijke groet\n\n<img alt="" src=""/>',
    tr: 'nutun müşteri̇ portalina hoş geldi̇ni̇z. Açık faturalarınızla ilgilenmek için burada olmanız harika. Dosyayı kapatmak için lütfen şimdi ödeme yapın. Herhangi bir sorunuz olursa, nutun ekibi size yardımcı olmaktan her zaman mutluluk duyar.\n\nSaygılarımla\n\n<img alt="" src=""/>',
    sv: 'Välkommen till nutuns kundportal. Det är bra att du är här för att ta hand om dina öppna fakturor. Vänligen betala nu för att stänga ärendet. Om du har några frågor hjälper nutun-teamet alltid gärna till.\n\nMed vänliga hälsningar\n\n<img alt="" src=""/>',
    fr: 'bienvenue sur le portail client de nutun. Nous sommes heureux que vous soyez ici pour vous occuper de vos factures en cours. Veuillez payer maintenant pour clore le dossier. Si vous avez des questions, l\'équipe de nutun est toujours heureuse de vous aider.\n\nMeilleures salutations\n\n<img alt="" src=""/>'
  },
  customerPortalGreetingMainB2BKey: {
    de: 'es freut uns, dass Sie sich um Ihre offenen Firmenrechnungen kümmern. Sie können direkt hier im Kundencenter ganz unkompliziert und sicher bezahlen, sowie sich über den weiteren Verlauf der Forderungen informieren.\n\nSollten Sie Fragen haben, steht Ihnen das nutun Team natürlich jederzeit zur Verfügung.\n\nMit freundlichen Grüßen\n\n<img alt="" src=""/>',
    en: 'we are pleased that you are taking care of your open company invoices. You can pay easily and securely right here in our customer center -- and find out about the further progress of the receivables.\n\nIf you have any questions, the nutun team is of course always at your disposal.\n\nWith kind regards\n\n<img alt="" src=""/>',
    nl: 'wij zijn blij dat u uw openstaande zakelijke facturen in behandeling neemt. U kunt hier in ons klantenportaal eenvoudig en veilig betalen - en het verdere verloop van de vordering inzien.\n\nAls u vragen heeft, staat het troy-team natuurlijk altijd tot uw beschikking.\n\nMet vriendelijke groet\n\n<img alt="" src=""/>',
    tr: 'Açık şirket faturalarınızla ilgileniyor olmanızdan memnuniyet duyuyoruz. Burada, müşteri merkezimizde kolayca ve güvenli bir şekilde ödeme yapabilir ve alacakların ilerleyişi hakkında bilgi edinebilirsiniz.\n\nHerhangi bir sorunuz olursa, nutun ekibi elbette her zaman hizmetinizdedir.\n\nSaygılarımla\n\n<img alt="" src=""/>',
    sv: 'Vi är glada att du tar hand om dina öppna företagsfakturor. Du kan betala enkelt och säkert här i vårt kundcenter - och ta reda på hur det går med fordringarna.\n\nOm du har några frågor står nutun-teamet naturligtvis alltid till ditt förfogande.\n\nMed vänliga hälsningar\n\n<img alt="" src=""/>',
    fr: 'nous sommes heureux que vous vous occupiez de vos factures d\'entreprise ouvertes. Vous pouvez payer facilement et en toute sécurité ici, dans notre espace client - et vous informer de l\'évolution des créances.\n\nSi vous avez des questions, l\'équipe de nutun est bien sûr toujours à votre disposition.\n\nAvec mes salutations distinguées\n\n<img alt="" src=""/>'
  },
  customerPortalGreetingMainPaidKey: {
    de: 'es freut uns, dass du dich um deine offene Forderung gekümmert hast. Auch wenn wir uns bemühen, Inkasso zu einem angenehmen Erlebnis zu machen, hoffen wir, dass du in Zukunft ohne unsere Unterstützung auskommst.\n\nWir wünschen dir alles Gute.\n\nHerzliche Grüße\n\n<img alt="" src=""/>',
    en: 'we are pleased that you have taken care of your open invoices. Even though we try to make debt collection a pleasant experience, we hope that you will be able to get along without our support in the future.\n\nWe wish you all the best.\n\nBest regards\n\n<img alt="" src=""/>',
    nl: 'we zijn blij dat je je rekening hebt betaald. Ook al hebben wij ons best gedaan jou een positieve incasso ervaring te geven, wij willen graag dat je in toekomst zonder onze hulp kunt!\n\nWe wensen je het allerbeste toe.\n\nMet vriendelijke groet\n\n<img alt="" src=""/>',
    tr: 'borç yükümlülüğünüzle ilgilendiğiniz için teşekkür ederiz. troy olarak tahsilat sürecini keyifli bir deneyim haline getirmeye çalışsak bile, gelecekte desteğimiz olmadan yapabileceğinizi umuyoruz.\n\nSizin için en iyisini diliyoruz.\n\nSaygılarımla\n\n<img alt="" src=""/>',
    sv: 'det gläder oss att du har tagit hand om din öppna fordran. Även om vi försöker göra indrivningen av fordringar till en trevlig upplevelse hoppas vi att du i framtiden kan klara dig utan vårt stöd.\n\nVi önskar dig all lycka till.\n\nMed vänliga hälsningar\n\n<img alt="" src=""/>',
    fr: 'nous sommes heureux que vous vous soyez occupé de vos factures en suspens. Bien que nous nous efforcions de faire du recouvrement de créances une expérience agréable, nous espérons que vous serez en mesure de vous passer de notre aide à l\'avenir. \n\nMeilleures salutations\n\n<img alt="" src=""/>'
  },
  customerPortalContactBar: {
    phoneLabel: '+27 11 560 6111',
    phoneValue: '+27115606111',
    whatsappLabel: 'Whatsapp',
    whatsappValue: '+27600702655',
    SMSLabel: null,
    SMSValue: null,
    emailLabel: 'E-Mail',
    emailValue: 'enquiries@mbd.co.za',
    twitterLabel: null,
    twitterValue: null
  },
  customerPortalCurrencyLocalOverride: null,
  customerPortalHeaderBackgroundGradient: '#001e2c',
  customerPortalModuleRadialBackground:
    'linear-gradient(270deg, #13B400 15.42%, #00FFD2 100.05%)',
  customerPortalTimelineBackground:
    'radial-gradient(100% 138.46% at 0% 0%, #001E2C 27.6%, #00B495 100%)',
  customerPortalPaymentUrl:
    'https://int.mbddc.co.za/ddc/qp/?p=t&l=!tro!&z={CUSTOMER}&a={AMOUNT}',
  hasCustomFooter: true,
  footerData: [
    ['Transaction Capital Risk Services', '(Registration No. 1998/025827/07)'],
    [
      '<b>Postal Address:</b>',
      'PO Box 2413',
      'Pinegowrie',
      'Johannesburg',
      'South Africa',
      '2123'
    ],
    [
      '<b>Physical Address:</b>',
      '342 Jan Smuts Avenue',
      'Hyde Park',
      'Johannesburg',
      'South Africa',
      '2196'
    ],
    ['https://tcriskservices.com/', '+27 (0)11 560 6000']
  ],
  botColor: '#56F13E',
  gradientColor1: '#AD44D0',
  gradientColor2: '#00B495',
  primaryLight: '#00FFD2',
  primaryMain: '#00B495',
  primaryDark: '#008263',
  primaryContrastText: '#FFFFFF',
  secondaryLight: '#1EE600',
  secondaryMain: '#13B400',
  secondaryDark: '#138200',
  secondaryContrastText: '#FFFFFF',
  tertiaryMain: '#0178FB',
  tertiaryLight: '#F4F9FF',
  tertiaryDark: '#053172',
  tertiaryContrastText: '#FFFFFF',
  warningMain: '#FFBC32',
  warningLight: '#FFBC32',
  warningDark: '#EB6767',
  textPrimary: '#001e2c',
  textSecondary: '#666666',
  textDisabled: '#CCCCCC',
  textHint: '#999999',
  backgroundDefault: '#FFFFFF',
  backgroundDark: 'rgba(0, 0, 0, 0.82)',
  actionHover: '#008263',
  actionDisabled: '#E6E6E6',
  grey50: 'rgba(0, 0, 0, 0.82)',
  grey100: '#ECECEC',
  grey400: '#C6C6C6',
  grey500: '#B2B2B2',
  warningDarkFill: '#FDF0F0',
  warningDarkFocus: '#BD5454',
  communicationLane: '#F0F0F0',
  communicationBg: '#F8F8F8',
  actionsInfo: '#F6F6F6',
  warningLightHover: '#E9AC2F',
  h1RegularFontFamily: 'regular',
  h1RegularFontWeight: 400,
  h1RegularFontSize: '2.5rem',
  h1RegularLineHeight: 1.2,
  h1RegularLetterSpacing: 'normal',
  h1FontFamily: 'bold',
  h1FontWeight: 400,
  h1FontSize: '3rem',
  h1LineHeight: 1.20833333333,
  h1LetterSpacing: 'normal',
  h2RegularFontFamily: 'regular',
  h2RegularFontWeight: 400,
  h2RegularFontSize: '1.5rem',
  h2RegularLineHeight: 1.33333333333,
  h2RegularLetterSpacing: 'normal',
  h2FontFamily: 'bold',
  h2FontWeight: 400,
  h2FontSize: '2.25rem',
  h2LineHeight: 1.33333333333,
  h2LetterSpacing: 'normal',
  h3FontFamily: 'bold',
  h3FontWeight: 400,
  h3FontSize: '1.5rem',
  h3LineHeight: 1.33333333333,
  h3LetterSpacing: 'normal',
  h4FontFamily: 'bold',
  h4FontWeight: 400,
  h4FontSize: '1.125rem',
  h4LineHeight: 1.33333333333,
  h4LetterSpacing: 'normal',
  subtitle1FontFamily: 'regular',
  subtitle1FontWeight: 400,
  subtitle1FontSize: '0.625rem',
  subtitle1LineHeight: 1.6,
  subtitle1LetterSpacing: 'normal',
  subtitle2FontFamily: 'regular',
  subtitle2FontWeight: 400,
  subtitle2FontSize: '0.75rem',
  subtitle2LineHeight: 1.33333333333,
  subtitle2LetterSpacing: 'normal',
  subtitle2BoldFontFamily: 'bold',
  subtitle2BoldFontWeight: 400,
  subtitle2BoldFontSize: '0.75rem',
  subtitle2BoldLineHeight: 1.33333333333,
  subtitle2BoldLetterSpacing: 'normal',
  subtitle3FontFamily: 'regular',
  subtitle3FontWeight: 400,
  subtitle3FontSize: '0.75rem',
  subtitle3LineHeight: 1.5,
  subtitle3LetterSpacing: '0.6px',
  body1FontFamily: 'regular',
  body1FontWeight: 400,
  body1FontSize: '1rem',
  body1LineHeight: 1.5,
  body1LetterSpacing: 'normal',
  body1BoldFontFamily: 'bold',
  body1BoldFontWeight: 400,
  body1BoldFontSize: '1rem',
  body1BoldLineHeight: 1.5,
  body1BoldLetterSpacing: 'normal',
  body2FontFamily: 'regular',
  body2FontWeight: 400,
  body2FontSize: '0.875rem',
  body2LineHeight: 1.42857142857,
  body2LetterSpacing: '0.5px',
  body2BoldFontFamily: 'bold',
  body2BoldFontWeight: 400,
  body2BoldFontSize: '0.875rem',
  body2BoldLineHeight: 1.42857142857,
  body2BoldLetterSpacing: '0.5px',
  body3FontFamily: 'regular',
  body3FontWeight: 400,
  body3FontSize: '0.875rem',
  body3LineHeight: 1.714285,
  body3LetterSpacing: 'normal',
  numerical1FontWeight: 500,
  numerical1FontSize: '1rem',
  numerical1LineHeight: 1.5,
  numerical1LetterSpacing: 'normal',
  numerical2FontWeight: 500,
  numerical2FontSize: '0.875rem',
  numerical2LineHeight: 1.42857142857,
  numerical2LetterSpacing: 'normal',
  numerical3FontWeight: 500,
  numerical3FontSize: '0.625rem',
  numerical3LineHeight: 1.6,
  numerical3LetterSpacing: 'normal',
  customShadowsMenu: '0px 0px 12px rgba(0, 180, 149, 0.6)',
  customShadowsModule: '0px 0px 8px rgba(0, 30, 44, 0.2)',
  customShadowsLogin: 'none',
  customShadowsActive: '0px 2px 12px rgba(0, 0, 0, 0.2)',
  customShadowsActivePrimary: '0px 2px 12px rgba(26, 199, 184, 0.25)',
  customShadowsActiveWarning: '0px 2px 12px rgba(75, 26, 26, 0.2)',
  customShadowsActiveWarningLight: '0px 2px 12px rgba(255, 188, 50, 0.2)',
  customShadowsFocus: '0px 2px 12px rgba(92, 24, 177, 0.2)',
  transitionFast: 'all ease-in-out 100ms',
  transitionMedium: 'all cubic-bezier(0.65, 0, 0.35, 1) 600ms',
  transitionHeader: 'top 50ms linear',
  spacing: 8,
  borderRadius: 4
};

export default conf;

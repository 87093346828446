import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  page: {
    borderRadius: '50%',
    height: 5,
    width: 5,
    margin: 3,
    border: `2px solid ${theme.palette.grey[500]}`
  },
  selected: {
    backgroundColor: theme.palette.grey[500]
  },
  pages: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center'
  }
}));

const CarouselPagination = props => {
  const classes = useStyles();
  const { curPage, totalPages, style, wrapperStyle, handleClick } = props;

  const pages = [];
  for (let i = 0; i < totalPages; i++)
    pages.push(
      <div
        key={i}
        className={clsx(classes.page, i === curPage && classes.selected)}
        style={style}
        onClick={() => handleClick(i)}
      />
    );

  return (
    <div className={classes.pages} style={wrapperStyle}>
      {pages}
    </div>
  );
};

export default CarouselPagination;

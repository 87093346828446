import React, { useCallback, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import {
  Translation,
  useTranslationsContext
} from '@troy/shared/src/components/common';

import {
  BookIcon,
  Button,
  CalendarIcon,
  ProfileIcon,
  Widget
} from '../../../common';

import { CONFLUENCE_AUTHORIZE_CODE } from '../../../../constants/api';

import {
  cognitoTokenSelector,
  confluenceAccessibleResourcesSelector,
  confluenceResourceContentSelector,
  confluenceTokenSelector
} from '../../../../store/selectors';
import {
  getConfluenceAccessibleResources,
  getConfluenceResourceContent,
  getConfluenceToken
} from '../../../../store/actions';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  wrapper: {
    padding: 5,
    display: 'flex',
    flexDirection: 'column'
  },
  spaceWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  space: {
    fontSize: '14px',
    margin: '0 10px'
  },
  spaceLabel: {
    color: theme.palette.primary.main
  },
  change: {
    padding: '0 !important',
    fontSize: '0.8rem !important',
    fontStyle: 'italic',
    color: `${theme.palette.grey[500]} !important`
  },
  memberInfoWrapper: {
    height: '100%',
    display: 'flex',
    alignItems: 'center'
  },
  memberInfoTable: {
    display: 'table'
  },
  memberInfoRow: {
    display: 'table-row'
  },
  memberInfoCell: {
    display: 'table-cell',
    verticalAlign: 'middle',
    '&:first-child': {
      padding: '10px 0',
      width: '40px',
      textAlign: 'center'
    }
  },
  memberIcon: {
    height: 24,
    width: 24,
    color: theme.palette.primary.main
  }
}));

const KnowledgeManagementWidget = withRouter(
  ({ location, history, extended, setExtended, removeItem, ...props }) => {
    const classes = useStyles();
    const { translate } = useTranslationsContext();

    const cognitoToken = useSelector(cognitoTokenSelector);
    const confluenceToken = useSelector(confluenceTokenSelector);
    const accessibleResources = useSelector(
      confluenceAccessibleResourcesSelector
    );
    const resourceContent = useSelector(confluenceResourceContentSelector);

    const dispatch = useDispatch();

    const [resourceId, setResourceId] = React.useState('');
    const [contentId, setContentId] = React.useState('');
    const contentUrl = React.useMemo(() => {
      const { _links: { base = '', webui = '' } = {} } =
        ((resourceContent || {})[resourceId] || {})[contentId] || {};
      return base && webui ? base + webui : '';
    }, [resourceId, contentId, resourceContent]);

    useEffect(() => {
      if (resourceContent && resourceContent[resourceId]) {
        if (!contentId) {
          const contentId = Object.keys(resourceContent[resourceId])[0];
          setContentId(contentId);
          dispatch(
            getConfluenceResourceContent({ cloudId: resourceId, contentId })
          );
        }
      }
    }, [resourceId, contentId, resourceContent]);

    useEffect(() => {
      if (accessibleResources && accessibleResources.length) {
        const resourceId = accessibleResources[0].id;
        setResourceId(resourceId);
        dispatch(getConfluenceResourceContent({ cloudId: resourceId }));
      }
    }, [accessibleResources]);

    useEffect(() => {
      const urlParams = new URLSearchParams(location.search);
      const code = urlParams.get('code');

      if (!extended) {
        if (!confluenceToken && !!code) {
          setExtended();
          dispatch(getConfluenceToken(code));
        }
      } else if (confluenceToken) {
        dispatch(getConfluenceAccessibleResources());
      }
    }, [confluenceToken, extended]);

    const onButtonClick = useCallback(() => {
      if (confluenceToken) {
        setExtended();
      } else {
        window.location.href = CONFLUENCE_AUTHORIZE_CODE({
          token: cognitoToken
        });
      }
    }, [confluenceToken, cognitoToken, setExtended]);

    return (
      <Widget
        {...props}
        icon={BookIcon}
        onActionClick={removeItem}
        buttonName={translate('CP_START_KNOWLEDGE_BUTTON') + ' >>'}
        buttonNameTranslated={false}
        onButtonClick={onButtonClick}
        isExtended={extended}
      >
        <div className={classes.wrapper}>
          {!extended ? (
            <>
              <div className={classes.spaceWrapper}>
                <p className={classes.space}>
                  <Translation component="span" className={classes.spaceLabel}>
                    CP_START_KNOWLEDGE_SPACE
                  </Translation>
                  <span>: </span>
                  <a href="https://infomadis.atlassian.net/wiki/spaces/IN/pages/745013252/Mandant+XYZ">
                    Background Infos
                  </a>
                </p>
                <Button className={classes.change}>
                  <Translation inline>CP_START_PROJECT_CHANGE</Translation>
                </Button>
              </div>
              <div className={classes.memberInfoWrapper}>
                <div className={classes.memberInfoTable}>
                  <div className={classes.memberInfoRow}>
                    <div className={classes.memberInfoCell}>
                      <ProfileIcon className={classes.memberIcon} />
                    </div>
                    <div className={classes.memberInfoCell}>
                      <Translation component="p">
                        CP_START_KNOWLEDGE_PARTICIPANTS_PLURAL
                      </Translation>
                    </div>
                  </div>
                  <div className={classes.memberInfoRow}>
                    <div className={classes.memberInfoCell}>
                      <CalendarIcon className={classes.memberIcon} />
                    </div>
                    <div className={classes.memberInfoCell}>
                      <p>
                        <Translation inline>
                          CP_START_KNOWLEDGE_LASTPOST
                        </Translation>
                        :{' '}
                        <Translation inline>
                          CP_START_KNOWLEDGE_DAYBEFORE
                        </Translation>
                        , 17:32
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : contentUrl ? (
            <iframe
              src={contentUrl}
              title="knowledge-management-iframe"
              frameBorder="0"
            />
          ) : null}
        </div>
      </Widget>
    );
  }
);

export default KnowledgeManagementWidget;

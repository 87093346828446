import React, { useCallback } from 'react';
import { makeStyles } from '@mui/styles';

import { Grid } from '../../../../common';

import { format as formatDate } from '../../../../../utils/date-utils';
import { modifyName } from '../../../../../utils/invoice';

const useStyles = makeStyles(theme => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    flex: 1,
    height: '100%',
    padding: '10px 15px'
  },
  preview: {
    padding: '10px 0',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',

    '&:not(:last-child)': {
      borderBottom: `1px ${theme.palette.text.disabled} solid`
    }
  },
  info: {
    cursor: 'pointer'
  },
  date: {
    color: theme.palette.text.hint,
    fontSize: '0.8rem'
  },
  name: {
    color: theme.palette.text.primary,
    wordBreak: 'break-all'
  },
  download: {
    marginLeft: '10px !important'
  }
}));

export default ({
  invoices,
  onDocDownload,
  downloadButton: DownloadButton
}) => {
  const classes = useStyles();
  const handleDocDownloadClick = useCallback(
    id => e => {
      onDocDownload && onDocDownload(id);
      e.preventDefault();
    },
    [onDocDownload]
  );
  return (
    <div className={classes.wrapper}>
      {invoices && invoices.length
        ? invoices.map(({ name, id, fileId, creationDate }) => {
            const modifiedName = modifyName(name);

            return (
              <div key={id} className={classes.preview}>
                <div>
                  <Grid
                    item
                    container
                    direction="column"
                    className={classes.info}
                    onClick={handleDocDownloadClick(fileId)}
                  >
                    <Grid item className={classes.date}>
                      <p>{formatDate(creationDate)}</p>
                    </Grid>
                    <Grid item className={classes.name}>
                      <p>{modifiedName}</p>
                    </Grid>
                  </Grid>
                </div>
                <DownloadButton
                  className={classes.download}
                  onClick={handleDocDownloadClick(fileId)}
                />
              </div>
            );
          })
        : null}
    </div>
  );
};

import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';

import { ArrowLeftIcon, ArrowRightIcon } from '../Icons';

import Pagination from './Pagination';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column'
  },
  carousel: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1
  },
  arrow: {
    cursor: 'pointer',
    height: 'max-content',
    width: 'max-content'
  },
  list: {
    overflow: 'hidden',
    flex: 1,
    height: 150,
    position: 'relative'
  },
  slider: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    transition: 'all 0.5s ease'
  },
  item: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    marginRight: 5,
    cursor: 'pointer'
  }
}));

function Carousel(props) {
  const classes = useStyles();
  const [position, setPosition] = useState(0);
  const [arrowsVisibleLeft, setArrowsVisibleLeft] = useState(false);
  const [arrowsVisibleRight, setArrowsVisibleRight] = useState(true);
  const [pageSize] = useState(1);
  const { items } = props;
  // componentDidUpdate(prevProps) {
  //   if (prevProps.items.length !== this.props.items.length)
  //     if (this.props.items && this.props.items.length <= this.state.pageSize)
  //       this.setState({ arrowsVisibleRight: false });
  //     else
  //       this.setState({ arrowsVisibleRight: true })
  // }

  function checkPosition(pos) {
    setArrowsVisibleLeft(pos > 0);
    setArrowsVisibleRight(pos < items.length - pageSize);
  }

  function nextPage() {
    checkPosition(position + 1);
    if (position < props.items.length - 1) setPosition(position + 1);
  }

  function prevPage() {
    checkPosition(position - 1);
    if (position > 0) setPosition(position - 1);
  }

  function handleChangePage(page) {
    checkPosition(page);
    setPosition(page);
  }

  return (
    <div className={classes.container}>
      <div className={classes.carousel}>
        <ArrowLeftIcon
          style={{ visibility: arrowsVisibleLeft ? 'visible' : 'collapse' }}
          onClick={() => prevPage()}
          className={classes.arrow}
        />
        <div className={classes.list}>
          <div
            className={classes.slider}
            style={{
              transform: `translateX(-${position * 100}%)`,
              left: 0
            }}
          >
            {items.map((item, i) => (
              <div
                className={classes.item}
                style={{ left: `calc(${i * 100}%)` }}
                key={i}
              >
                {item}
              </div>
            ))}
          </div>
        </div>
        <ArrowRightIcon
          style={{ visibility: arrowsVisibleRight ? 'visible' : 'collapse' }}
          onClick={() => nextPage()}
          className={classes.arrow}
        />
      </div>
      <Pagination
        curPage={position}
        totalPages={items.length - pageSize + 1}
        handleClick={handleChangePage}
        wrapperStyle={{
          marginTop: '10px'
        }}
      />
    </div>
  );
}

export default Carousel;

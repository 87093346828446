import React from 'react';
import { makeStyles } from '@mui/styles';

import { Module, Translation } from '@troy/shared/src/components/common';

import { Button, MailIcon } from '../../../common';

const useStyles = makeStyles(theme => ({
  modal: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    width: 328,
    padding: 32,
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center'
  },
  modalTitle: {
    fontWeight: 'bold'
  },
  modalContent: {
    marginTop: 24,
    fontSize: 14
  },
  modalButton: {
    marginTop: 24
  },
  mailIcon: {
    width: 16,
    height: 16,

    '& path[fill="red"]': {
      fill: theme.palette.primary.contrastText
    }
  }
}));

const ContactModal = ({ onClick }) => {
  const classes = useStyles();

  return (
    <Module className={classes.modal}>
      <Translation keyIfNoValue component="h3" className={classes.modalTitle}>
        CP_STATISTICS_LOOKER_MORE_HEADLINE
      </Translation>
      <Translation keyIfNoValue component="p" className={classes.modalContent}>
        CP_STATISTICS_LOOKER_MORE_COPY
      </Translation>
      <Button
        fullWidth
        className={classes.modalButton}
        variant="contained"
        color="primary"
        startIcon={() => (
          <MailIcon alt="mail icon" className={classes.mailIcon} />
        )}
        onClick={onClick}
      >
        <Translation isFragment keyIfNoValue>
          CP_STATISTICS_LOOKER_MORE_BUTTON
        </Translation>
      </Button>
    </Module>
  );
};

export default ContactModal;

import { createReducer } from 'redux-act';

import * as actions from '../actions/decisions';

import * as widgetsKeys from '../../constants/widgets';

const saveSetToStorage = (storage, set, key, username) =>
  storage.setItem(`${key}.${username}`, Array.from(set).join(','));
const getSetFromStorage = (storage, key, username) => {
  const storageItem = storage.getItem(`${key}.${username}`);
  return storageItem ? new Set(storageItem.split(',')) : new Set();
};

const innerFunctions = {
  _setWidgets: (state, decisions) => ({
    ...state,
    widgets: {
      ...state.widgets,
      [widgetsKeys.STATISTIC_WIDGET]:
        decisions['troy.clientportal.widget.STATISTICS.show'],
      [widgetsKeys.STATISTICS_LOOKER_WIDGET]:
        decisions['troy.clientportal.widget.STATISTICS_LOOKER.show'],
      [widgetsKeys.USERS_WIDGET]:
        decisions['troy.clientportal.widget.CASE_SEARCH.show'],
      [widgetsKeys.SOCIAL_MEDIA_WIDGET]:
        decisions['troy.clientportal.widget.SOCIAL_MEDIA_LISTENING.show'],
      [widgetsKeys.PROJECT_MANAGEMENT_WIDGET]:
        decisions['troy.clientportal.widget.PROJECT_MANAGEMENT.show'],
      [widgetsKeys.KNOWLEDGE_MANAGEMENT_WIDGET]:
        decisions['troy.clientportal.widget.KNOWLEDGE_MANAGEMENT.show'],
      [widgetsKeys.WORFLOWS_WIDGET]:
        decisions['troy.clientportal.widget.WORKFLOWS.show'],
      [widgetsKeys.INVOICE_WIDGET]:
        decisions['troy.feature.clientportal.invoice'],
      [widgetsKeys.FAQ_WIDGET]:
        decisions[
          'troy.clientportal.widget.KNOWLEDGE_MANAGEMENT_EMBEDDED.show'
        ],
      [widgetsKeys.STRIPO_WIDGET]:
        decisions['troy.clientportal.widget.TEMPLATE_EDITOR.show']
    }
  }),
  _filterWidgets: state => {
    if (state.widgets) {
      const disabledWidgets = new Set(
        Array.from(state.disabledWidgets).filter(key => state.widgets[key])
      );
      const enabledWidgets = new Set(
        Object.entries(state.widgets)
          .filter(([key, value]) => value && !disabledWidgets.has(key))
          .map(([key]) => key)
      );

      if (state.username) {
        saveSetToStorage(
          localStorage,
          disabledWidgets,
          DISABLED_WIDGETS_STORAGE_KEY,
          state.username
        );
      }
      return { ...state, disabledWidgets, enabledWidgets };
    }
    return { ...state };
  }
};

const DISABLED_WIDGETS_STORAGE_KEY = 'disabled-widgets';

const DEFAULT_STATE = {
  decisions: {},
  username: '',
  disabledWidgets: new Set(),
  enabledWidgets: new Set(),
  isPending: true,
  widgets: null
};

export default createReducer(
  {
    [actions.setDecisionsUsername]: (state, username) => ({
      ...innerFunctions._filterWidgets({
        ...state,
        username,
        disabledWidgets: getSetFromStorage(
          localStorage,
          DISABLED_WIDGETS_STORAGE_KEY,
          username
        )
      })
    }),
    [actions.decisionsReceived]: (state, decisions = {}) =>
      innerFunctions._filterWidgets(
        innerFunctions._setWidgets(
          {
            ...state,
            isPending: false,
            decisions
          },
          decisions
        )
      ),
    [actions.disableWidget]: (state, widgetKeys) => {
      const disabledWidgets = new Set(state.disabledWidgets);
      const enabledWidgets = new Set(state.enabledWidgets);

      if (Array.isArray(widgetKeys)) {
        widgetKeys.forEach(key => {
          disabledWidgets.add(key);
          enabledWidgets.delete(key);
        });
      } else {
        disabledWidgets.add(widgetKeys);
        enabledWidgets.delete(widgetKeys);
      }

      saveSetToStorage(
        localStorage,
        disabledWidgets,
        DISABLED_WIDGETS_STORAGE_KEY,
        state.username
      );
      return { ...state, disabledWidgets, enabledWidgets };
    },
    [actions.enableWidgets]: (state, widgetKeys) => {
      const disabledWidgets = new Set(state.disabledWidgets);
      const enabledWidgets = new Set(state.enabledWidgets);

      widgetKeys.forEach(widgetKey => {
        disabledWidgets.delete(widgetKey);
        enabledWidgets.add(widgetKey);
      });

      saveSetToStorage(
        localStorage,
        disabledWidgets,
        DISABLED_WIDGETS_STORAGE_KEY,
        state.username
      );

      return { ...state, disabledWidgets, enabledWidgets };
    },
    [actions.clearDecisionsStore]: () => ({ ...DEFAULT_STATE })
  },
  DEFAULT_STATE
);

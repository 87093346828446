import { createSelector } from 'reselect';
import { parseJwt } from '../../utils/token';

const cognitoSelector = state => state.cognito;

export const currentUserSelector = store => cognitoSelector(store).user;

export const cognitoFederationTokenSelector = store =>
  cognitoSelector(store).federationToken;

export const cognitoSessionSelector = store => cognitoSelector(store).session;
export const cognitoTokensSelector = store => cognitoSelector(store).tokens;

export const cognitoTokenSelector = createSelector(
  [cognitoTokensSelector],
  tokens => (!tokens ? '' : tokens.id_token)
);

export const cognitoTokenExpirationSelector = createSelector(
  [cognitoSessionSelector],
  session => (!session ? 0 : session.getIdToken().getExpiration() * 1000)
);

export const cognitoTokenTimestampExpirationSelector = createSelector(
  [cognitoTokenSelector],
  token => token && parseJwt(token).exp * 1000
);

function effectiveUsername(token) {
  var jwt = parseJwt(token);

  return (
    jwt['original_username'] ||
    jwt['preferred_username'] ||
    jwt['email'] ||
    jwt['cognito:username']
  );
}

export const cognitoUsernameFromTokenSelector = createSelector(
  [cognitoTokenSelector],
  token => token && effectiveUsername(token)
);

export const isCognitoAuthenticatedSelector = store =>
  !!cognitoTokensSelector(store);

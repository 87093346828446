import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import { Popover, Theme } from '@mui/material';

import { CustomIcon } from '../index';

import PostBoxItemContextMenu from './PostBoxItemContextMenu';
import { APINotificationVisibility } from '../../../types/notifications';
import PostBoxItemContextConfirmationMenu from './PostBoxItemContextConfirmationMenu';

interface PostBoxItemContextMenuButtonProps {
  className: string;
  eventId: string;
  editEnabled: boolean;
  changePostBoxItemVisibility?: (eventId: string, state: string) => void;
  itemVisibility: APINotificationVisibility;
  isItemVisibilityLoading: boolean;
  setContextMenuOpen: (open: boolean) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    position: 'relative',
    display: 'flex',
    alignSelf: 'center'
  },
  button: {
    position: 'relative',
    padding: 0,
    margin: 0,
    border: 'none',
    display: 'flex',
    background: 'transparent',
    cursor: 'pointer'
  },
  dot: {
    '&:after': {
      content: '" "',
      position: 'absolute',
      right: 1,
      top: 3,
      width: theme.spacing(1),
      height: theme.spacing(1),
      borderRadius: '100%',
      background: theme.palette.primary.main
    }
  },
  menu: {
    transform: 'translate(32px, -16px) !important',
    boxShadow: theme.customShadows.active,
    borderRadius: Number(theme.shape.borderRadius) * 4
  }
}));

const PostBoxItemContextMenuButton: React.FC<
  PostBoxItemContextMenuButtonProps
> = ({
  className,
  eventId,
  editEnabled,
  changePostBoxItemVisibility,
  itemVisibility,
  isItemVisibilityLoading,
  setContextMenuOpen
}) => {
  const classes = useStyles();
  const [menuOpen, setMenuOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [itemStartedLoading, setItemStartedLoading] = useState(false);

  useEffect(() => {
    if (
      isConfirmationModalOpen &&
      !itemStartedLoading &&
      isItemVisibilityLoading
    ) {
      setItemStartedLoading(true);
    } else if (
      isConfirmationModalOpen &&
      itemStartedLoading &&
      !isItemVisibilityLoading
    ) {
      setIsConfirmationModalOpen(false);
      setItemStartedLoading(false);
    }
  }, [isConfirmationModalOpen, isItemVisibilityLoading, itemStartedLoading]);

  const openMenu = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setContextMenuOpen(true);
    setAnchorEl(event.currentTarget);
    setMenuOpen(true);
  };

  const closeMenu = (): void => {
    setMenuOpen(false);
    setAnchorEl(null);
    setContextMenuOpen(false);
  };

  const onSaveVisibilityChanges = (state: string): void => {
    if (state === 'HIDDEN_INTERNAL') {
      setIsConfirmationModalOpen(true);
    } else {
      changePostBoxItemVisibility(eventId, state);
    }
  };

  const handleConfirmationCancel = (): void => {
    setIsConfirmationModalOpen(false);
  };

  const handleConfirmationSave = (): void => {
    closeMenu();
    changePostBoxItemVisibility(eventId, 'HIDDEN_INTERNAL');
  };

  const hasDot = ['HIDDEN_CUSTOMER', 'HIDDEN_EXTERNAL'].includes(
    itemVisibility
  );
  const invisible = itemVisibility === 'HIDDEN_INTERNAL';

  return (
    <div className={clsx(className, classes.container)}>
      <button
        className={clsx(classes.button, hasDot && classes.dot)}
        onClick={openMenu}
      >
        <CustomIcon
          icon={invisible ? 'invisibleIcon' : 'visibleIcon'}
          size="small"
          variant="textDisabled"
        />
      </button>
      <Popover
        id="postbox_item_context_menu"
        open={menuOpen && !isConfirmationModalOpen}
        anchorEl={anchorEl}
        onClose={isItemVisibilityLoading ? undefined : closeMenu}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        classes={{
          paper: classes.menu
        }}
        marginThreshold={36}
      >
        <PostBoxItemContextMenu
          editEnabled={editEnabled}
          itemVisibility={itemVisibility}
          onSaveVisibilityChanges={onSaveVisibilityChanges}
          isItemVisibilityLoading={isItemVisibilityLoading}
        />
      </Popover>
      <PostBoxItemContextConfirmationMenu
        isOpen={isConfirmationModalOpen}
        onCancel={handleConfirmationCancel}
        onSave={handleConfirmationSave}
        isItemVisibilityLoading={isItemVisibilityLoading}
      />
    </div>
  );
};

export default PostBoxItemContextMenuButton;

import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import {
  Button as MaterialButton,
  Fab,
  IconButton as MaterialIconButton
} from '@mui/material';
import { svgFill } from '@troy/shared/src/utils/styles';

export const useButtonStyles = makeStyles(theme => ({
  icon: {
    padding: theme.spacing(1.5)
  },
  root: {
    borderRadius: theme.shape.borderRadius * 5,
    padding: '8px',
    fontWeight: 'bold',
    textTransform: 'none',
    lineHeight: '24px',
    fontSize: '16px'
  },
  primaryContained: {
    '&:hover': {
      background: theme.palette.primary.light,
      color: theme.palette.secondary.dark,

      ...svgFill(theme.transitions.custom.fast, theme.palette.secondary.dark)
    }
  },
  contained: {
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none'
    }
  },
  disabled: {
    color: `${theme.palette.primary.contrastText} !important`,
    background: `${theme.palette.text.disabled} !important`,
    backgroundColor: `${theme.palette.text.disabled} !important`
  }
}));

export const Button = ({
  onClick,
  disabled,
  children,
  className,
  variant,
  fullWidth,
  startIcon: Icon,
  endIcon: EndIcon,
  ...props
}) => {
  const classes = useButtonStyles();

  return variant !== 'fab' ? (
    variant !== 'icon' ? (
      <MaterialButton
        classes={{
          root: clsx(
            classes.root,
            variant === 'contained' &&
              props.color === 'primary' &&
              classes.primaryContained,
            className
          ),
          contained: classes.contained,
          disabled: classes.disabled
        }}
        className={className}
        {...{ fullWidth, onClick, disabled }}
        {...props}
        variant={variant || 'text'}
        startIcon={Icon && <Icon />}
        endIcon={EndIcon && <EndIcon />}
      >
        {children}
      </MaterialButton>
    ) : (
      <MaterialIconButton
        className={clsx(classes.icon, className)}
        {...{ onClick, disabled }}
        {...props}
      >
        {children}
      </MaterialIconButton>
    )
  ) : (
    <Fab className={className} {...{ onClick, disabled }} {...props}>
      {children}
    </Fab>
  );
};

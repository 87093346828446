import React from 'react';
import { Field, FieldValidator } from 'formik';
import { FieldProps } from 'formik/dist/Field';

type FormFieldProps = {
  component: React.ElementType;
  name: string;
  validate?: FieldValidator;
  [x: string]: any;
};

const FormField: React.FC<FormFieldProps> = ({
  component: Component,
  name,
  validate,
  ...props
}) => (
  <Field name={name} validate={validate}>
    {(compProps: FieldProps): JSX.Element => (
      <Component {...{ ...props, ...compProps, name }} />
    )}
  </Field>
);

export default FormField;

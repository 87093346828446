import React from 'react';

import { Translation } from '@troy/shared/src/components/common';

const NotFound = () => (
  <div>
    <h4>
      <Translation>CP_NOTFOUND</Translation>
    </h4>
  </div>
);

export default NotFound;

export const widgetWidth = 375;
export const widgetHeaderHeight = 65;
export const widgetContentHeight = 220;
export const widgetHeight = widgetHeaderHeight + widgetContentHeight;

export const widgetWidthExtended = 1185;
export const widgetHeightExtended = 815;

export const widgetMargin = 12;
export const oneColumnWidgetListWidth = widgetWidth + 2 * widgetMargin;
export const twoColumnWidgetListWidth = 2 * oneColumnWidgetListWidth;
export const threeColumnWidgetListWidth = 3 * oneColumnWidgetListWidth;

export const widgetListWidth = {
  maxWidth: oneColumnWidgetListWidth,

  [`@media only screen and (min-width: ${twoColumnWidgetListWidth}px)`]: {
    maxWidth: twoColumnWidgetListWidth
  },
  [`@media only screen and (min-width: ${threeColumnWidgetListWidth}px)`]: {
    maxWidth: threeColumnWidgetListWidth
  }
};

export const widgetListFixWidth = {
  width: `${widgetWidth}px !important`,

  [`@media only screen and (min-width: ${twoColumnWidgetListWidth}px)`]: {
    width: `${twoColumnWidgetListWidth}px !important`
  },

  [`@media only screen and (min-width: ${threeColumnWidgetListWidth}px)`]: {
    width: `${threeColumnWidgetListWidth}px !important`
  }
};

export const widgetWidth3Down = content => ({
  [`@media only screen and (max-width: ${threeColumnWidgetListWidth}px)`]: {
    ...content
  }
});

export const widgetWidth2Down = content => ({
  [`@media only screen and (max-width: ${twoColumnWidgetListWidth}px)`]: {
    ...content
  }
});

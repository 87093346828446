import React, { useCallback, useMemo, useState } from 'react';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';

import { FormField } from '@troy/shared/src/components/common';

import { TextField } from '../../../../../common';

import { getAmountWithCurrency } from '../../../../../../utils/common';
import { withLanguage } from '../../../../../../utils/translations';

const limitValue = value => {
  return value > Number.MAX_SAFE_INTEGER ? Number.MAX_SAFE_INTEGER : value;
};

const formatValue = (value, currency, locale) => {
  const val = value / 100;
  const formattedValue = getAmountWithCurrency(val, currency, locale);
  return formattedValue;
};

const useStyles = makeStyles(theme => ({
  field: {
    width: '100%'
  },
  input: {
    textAlign: 'center',
    padding: 5
  },
  pristine: {
    color: theme.palette.text.hint
  }
}));

export const CurrencyInput = withLanguage(
  ({
    currency,
    locale,
    value: propValue,
    onBlur: propOnBlur,
    onChange: propOnChange,
    onFocus: propOnFocus,
    field,
    className,
    inputClassName,
    inputNotchedOutlineClassName,
    ...props
  }) => {
    const classes = useStyles();
    const [pristine, setPristine] = useState(true);

    const value = useMemo(
      () => (field && !isNaN(+field.value) ? +field.value : propValue) || 0,
      [field, propValue]
    );
    const onBlur = useMemo(
      () => (field && field.onBlur) || propOnBlur,
      [field, propOnBlur]
    );
    const onChange = useMemo(
      () => (field && field.onChange) || propOnChange,
      [field, propOnChange]
    );
    const onFocus = useMemo(
      () => (field && field.onFocus) || propOnFocus,
      [field, propOnFocus]
    );

    const moveCursorToEnd = useCallback(el => {
      const val = el.value;
      const len = val.length;

      const pos = isNaN(parseInt(val[len - 1], 10)) ? len - 2 : len; // is currency in the end or start of line
      el.setSelectionRange(pos, pos);
    }, []);

    const changeValue = useCallback(
      (e, value) => {
        setPristine(false);
        e.target.value = value;
        onChange && onChange(e);
        e.target.value = formatValue(value, currency, locale);
        moveCursorToEnd(e.target);
      },
      [onChange, currency, locale]
    );

    const handleBlur = useCallback(
      e => {
        e.target.value = value;
        onBlur && onBlur(e);
      },
      [onBlur, value]
    );

    const handleFocus = useCallback(
      e => {
        moveCursorToEnd(e.target);
        e.target.value = value;
        onFocus && onFocus(e);
      },
      [moveCursorToEnd, onFocus, value]
    );

    const handleClick = useCallback(
      e => {
        const inputEl = e.currentTarget.getElementsByTagName('input')[0];
        if (document.activeElement === inputEl) {
          moveCursorToEnd(inputEl);
        }
      },
      [moveCursorToEnd]
    );

    const handleKeyPress = useCallback(
      e => {
        e.preventDefault();

        // allow only numbers
        if (!e.key.match(/[0-9]/g)) {
          return;
        }

        const newValue = value * 10 + parseInt(e.key);
        changeValue(e, limitValue(newValue));
      },
      [changeValue, value]
    );

    const handleKeyDown = useCallback(
      e => {
        if (e.key.startsWith('Arrow')) {
          // prevent arrow navigation
          e.preventDefault();
        } else if (['Backspace', 'Delete'].includes(e.key)) {
          // custom handle delete
          e.preventDefault();

          if (+value) {
            const newValue = Math.floor(value / 10);
            changeValue(e, limitValue(newValue));
          }
        }
      },
      [changeValue, value]
    );

    const displayValue = useMemo(
      () => formatValue(value, currency, locale),
      [value, currency, locale]
    );

    return (
      <TextField
        {...props}
        className={clsx(classes.field, className)}
        inputClassName={clsx(
          classes.input,
          pristine ? classes.pristine : null,
          inputClassName
        )}
        inputNotchedOutlineClassName={inputNotchedOutlineClassName}
        value={displayValue}
        onBlur={handleBlur}
        onFocus={handleFocus}
        onClick={handleClick}
        onKeyDown={handleKeyDown}
        onKeyPress={handleKeyPress}
      />
    );
  }
);

export const FormCurrencyInput = ({ name, validate, onChange, ...props }) =>
  name ? (
    <FormField
      name={name}
      component={CurrencyInput}
      validate={validate}
      onChange={onChange}
      {...props}
    />
  ) : (
    <CurrencyInput {...props} />
  );

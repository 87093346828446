const devconfig = {
  region: 'eu-west-1',
  IdentityPoolId: 'eu-west-1:d345be4b-d01b-4bf3-8b50-738402ebb278',
  UserPoolId: 'eu-west-1_eV7YTZiex',
  ClientId: '3lufurh2vbngpgule7ei73f26e'
};

const prodconfig = {
  region: 'eu-west-1',
  IdentityPoolId: 'eu-west-1:d345be4b-d01b-4bf3-8b50-738402ebb278',
  UserPoolId: 'eu-west-1_3O8FvOdwo',
  ClientId: '6cv70ertsf4vl8orr5khko5ksl'
};

let conf = devconfig;
if (process.env.REACT_APP_ENV === 'production') {
  conf = prodconfig;
}

export default conf;

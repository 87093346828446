import * as actions from '../actions/snackbar';
import { createReducer } from 'redux-act';
import { SnackbarNotificationType } from '../../types/snackbar';

export interface SnackBarReducerState {
  notifications: SnackbarNotificationType[];
}

const DEFAULT_STATE = {
  notifications: []
};

const reducer = createReducer<SnackBarReducerState>({}, DEFAULT_STATE);

reducer.on(actions.enqueueSnackbar, (state, notification) => {
  const key = notification.options && notification.options.key;

  return {
    ...state,
    notifications: [
      ...state.notifications,
      {
        ...notification,
        key: key || new Date().getTime() + Math.random()
      }
    ]
  };
});

reducer.on(actions.closeSnackbar, (state, key) => {
  const dismissAll = !key;

  return {
    ...state,
    notifications: state.notifications.map(notification =>
      dismissAll || notification.key === key
        ? { ...notification, dismissed: true }
        : { ...notification }
    )
  };
});

reducer.on(actions.removeSnackbar, (state, key) => ({
  ...state,
  notifications: state.notifications.filter(
    notification => notification.key !== key
  )
}));

export default reducer;

import React, { useEffect } from 'react';
import { Backdrop, LinearProgress, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { Text } from '@troy/shared/src/components/common';

const useStyles = makeStyles((theme: Theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    background: 'rgba(0, 0, 0, 0.656)'
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2),
    background: theme.palette.primary.contrastText,
    borderRadius: Number(theme.shape.borderRadius) * 4
  },
  barRoot: {
    width: 748,
    maxWidth: 'calc(100vw - 140px)',
    height: 8,
    borderRadius: Number(theme.shape.borderRadius)
  },
  barBackground: {
    backgroundColor: theme.palette.primary.light
  },
  bar: {
    borderRadius: Number(theme.shape.borderRadius),
    background: 'linear-gradient(90deg, #540FC9 0%, #C520FF 100%)'
  },
  label: {
    marginLeft: theme.spacing(2)
  }
}));

interface ProgressLoaderProps {
  label?: string;
  reverse?: boolean;
  open: boolean;
  speed?: number;
}

const ProgressLoader: React.FC<ProgressLoaderProps> = ({
  label,
  reverse,
  open,
  speed = 800
}) => {
  const classes = useStyles();
  const isOpen = open === !reverse;

  const [progress, setProgress] = React.useState(1);

  useEffect(() => {
    if (!isOpen) {
      setTimeout(() => {
        setProgress(1);
      }, 500);
    }
  }, [isOpen]);

  React.useEffect(() => {
    let timer: NodeJS.Timeout | number | undefined;

    if (isOpen) {
      timer = setInterval(() => {
        setProgress(prevProgress =>
          prevProgress >= 100 ? 100 : prevProgress + 10
        );
      }, speed);
    } else {
      timer && clearInterval(timer as NodeJS.Timeout);
    }
    return () => {
      timer && clearInterval(timer as NodeJS.Timeout);
    };
  }, [isOpen]);

  return (
    <Backdrop className={classes.backdrop} open={isOpen}>
      <div id="progress-loader" className={classes.container}>
        <LinearProgress
          classes={{
            root: classes.barRoot,
            colorPrimary: classes.barBackground,
            bar: classes.bar
          }}
          variant="determinate"
          value={progress}
        />
        <Text
          translated
          className={classes.label}
          component="h3"
          variant="subtitle1"
          color="textSecondary"
        >
          {label}
        </Text>
      </div>
    </Backdrop>
  );
};

export default ProgressLoader;

import { call, put, select } from 'redux-saga/effects';
import {
  handleError,
  lookerDashboardReceived,
  lookerDashboardsReceived
} from '../actions';
import { cognitoTokenSelector } from '../selectors';
import LookerService from '../../services/LookerService';

export function* getLookerDashboards() {
  try {
    const token = yield select(cognitoTokenSelector);
    let items = yield call(LookerService.getDashboards, token);
    items = [
      ...items,
      { id: '123', name: 'Premium Dashboard' },
      { id: '234', name: 'Admin Dashboard' }
    ];
    yield put(lookerDashboardsReceived(items));
  } catch (err) {
    yield put(lookerDashboardsReceived([]));
    yield put(handleError(err));
  }
}

export function* getLookerDashboard({ payload: id }) {
  try {
    const token = yield select(cognitoTokenSelector);
    const item = yield call(LookerService.getDashboard, token, id);
    yield put(lookerDashboardReceived(item));
  } catch (err) {
    yield put(lookerDashboardReceived({ dashboardId: id, url: '' }));
  }
}

import { createReducer } from 'redux-act';

import * as actions from '../actions/trello';

const DEFAULT_STATE = {
  token: '',
  userInfo: {},
  boardsInfo: {}
};

export default createReducer(
  {
    [actions.userInfoReceived]: (state, info) => ({
      ...state,
      userInfo: { ...state.userInfo, ...info }
    }),
    [actions.boardInfoReceived]: (state, { id, info }) => ({
      ...state,
      boardsInfo: {
        ...state.boardsInfo,
        [id]: { ...state.boardsInfo[id], ...info }
      }
    }),
    [actions.clearTrelloStore]: () => ({ ...DEFAULT_STATE }),
    [actions.setTrelloToken]: (state, token) => ({ ...state, token })
  },
  DEFAULT_STATE
);

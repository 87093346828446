import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel
} from '@mui/material';

import { Translation } from '@troy/shared/src/components/common';

import Grid from '../Grid';
import Pagination from '../Pagination';

const ascComparator = (a, b) => {
  if (a < b) {
    return -1;
  }
  if (a > b) {
    return 1;
  }
  return 0;
};

const getComparator = (order, orderBy) => (a, b) =>
  (order === 'asc' ? 1 : -1) * ascComparator(a[orderBy], b[orderBy]);

const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
};

const useStyles = makeStyles(theme => ({
  row: {
    borderBottom: '1px solid #e0e0e0',
    display: 'flex !important',
    padding: '0 !important',

    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04) !important'
    }
  },
  cell: {
    borderBottom: '0 !important'
  },
  pagination: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing(2)
  }
}));

export const Row = ({ className, ...props }) => {
  const c = useStyles();
  return (
    <Grid
      {...props}
      container
      alignItems="center"
      component={TableRow}
      className={clsx('MuiTableCell-root', c.row, className)}
    />
  );
};

export const Cell = ({ className, ...props }) => {
  const c = useStyles();
  return (
    <Grid
      {...props}
      item
      component={TableCell}
      className={clsx(c.cell, className)}
    />
  );
};

export const gridProps = len => ({
  sm: true, // Math.floor(12 / len),
  xs: Math.floor((2 * 12) / len)
});

const EnhancedTableHead = ({
  order,
  orderBy,
  onSort,
  cells,
  rowClassName,
  cellClassName,
  SortIconComponent
}) => {
  const createSortHandler = useCallback(
    property => () => {
      onSort && onSort(property);
    },
    [onSort]
  );

  return (
    <TableHead>
      <Row className={rowClassName}>
        {cells.map(
          ({ sort, value, label, customCellClassName, key = '' }, idx) => {
            const valueKey = typeof value === 'function' ? key : value;
            const tableCellProps = {
              key: valueKey | idx,
              sortDirection: orderBy === valueKey ? order : false,
              className: clsx(customCellClassName, cellClassName)
            };
            const tableSortLabelProps = {
              active: orderBy === valueKey,
              direction: orderBy === valueKey ? order : 'asc',
              onClick: createSortHandler(valueKey)
            };

            return (
              <Cell
                {...gridProps(cells.length)}
                component={TableCell}
                {...tableCellProps}
                // align={numeric ? 'right' : 'left'}
                // padding={headCell.disablePadding ? 'none' : 'default'}
              >
                {sort ? (
                  <TableSortLabel
                    IconComponent={SortIconComponent}
                    {...tableSortLabelProps}
                  >
                    <Translation isFragment keyIfNoValue>
                      {label}
                    </Translation>
                  </TableSortLabel>
                ) : (
                  <Translation isFragment keyIfNoValue>
                    {label}
                  </Translation>
                )}
              </Cell>
            );
          }
        )}
      </Row>
    </TableHead>
  );
};

EnhancedTableHead.propTypes = {
  cells: PropTypes.arrayOf(
    PropTypes.shape({
      numeric: PropTypes.bool,
      sort: PropTypes.bool,
      value: PropTypes.oneOfType([
        PropTypes.string.isRequired,
        PropTypes.func.isRequired
      ]),
      label: PropTypes.string.isRequired
    })
  ),
  onSort: PropTypes.func,
  order: PropTypes.oneOf(['asc', 'desc']),
  orderBy: PropTypes.string
};

EnhancedTableHead.defaultProps = {
  order: 'asc'
};

const EnhancedTable = ({
  head,
  rows,
  rowsPerPage,
  rowSelected,
  onRowClick,
  isShimmerLoading,
  headRowClassName,
  headCellClassName,
  rowClassName,
  cellClassName,
  shimmerClassName,
  paginationProps,
  wrapperClassName,
  className,
  SortIconComponent,
  initialSortedBy,
  initialSortOrder
}) => {
  const classes = useStyles();
  const [order, setOrder] = React.useState(initialSortOrder || null);
  const [orderBy, setOrderBy] = React.useState(initialSortedBy || null);

  const paginationEnabled = useMemo(() => !!rowsPerPage, [rowsPerPage]);

  const [page, setPage] = React.useState(1);

  useEffect(() => {
    setPage(1);
  }, [rows]);

  const handleSort = useCallback(
    property => {
      const isAsc = orderBy === property && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
    },
    [orderBy, order]
  );

  const handlePageChange = useCallback((event, newPage) => {
    setPage(newPage);
  }, []);

  const handleRowClick = useCallback(
    row => () => {
      onRowClick && onRowClick(row);
    },
    [onRowClick]
  );

  const handleRowSelected = useCallback(
    row => rowSelected && rowSelected(row),
    [rowSelected]
  );

  // const emptyRows = useMemo(
  //   () =>
  //     paginationEnabled
  //       ? rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage)
  //       : 0,
  //   [rowsPerPage, page, rows, paginationEnabled]
  // );

  const headValues = useMemo(
    () =>
      head && head.length
        ? head.map(({ value, translatedValue, customCellClassName }) => ({
            value,
            customCellClassName,
            translated: translatedValue
          }))
        : [],
    [head]
  );

  return (
    <TableContainer className={wrapperClassName}>
      <div className={className}>
        <Table>
          <EnhancedTableHead
            cells={head}
            order={order}
            orderBy={orderBy}
            onSort={handleSort}
            cellClassName={headCellClassName}
            rowClassName={headRowClassName}
            SortIconComponent={SortIconComponent}
          />
          <TableBody>
            {/* {isShimmerLoading ? (
              <TableShimmer
                className={shimmerClassName}
                rowCount={rowsPerPage}
                columnCount={head.length}
                animation="wave"
              />
            ) : (
              <> */}
            {stableSort(rows, getComparator(order, orderBy))
              .slice(
                paginationEnabled ? (page - 1) * rowsPerPage : 0,
                paginationEnabled ? page * rowsPerPage : rows.length
              )
              .map(row => (
                <Row
                  hover
                  className={rowClassName}
                  selected={handleRowSelected(row)}
                  onClick={handleRowClick(row)}
                  tabIndex={-1}
                  key={row.id}
                >
                  {headValues.map(({ value, customCellClassName }, idx) => {
                    const content = value
                      ? typeof value === 'function'
                        ? value(row)
                        : row[value]
                      : null;
                    const cellProps = {
                      key: idx,
                      className: clsx(customCellClassName, cellClassName)
                    };

                    return (
                      <Cell {...gridProps(head.length)} {...cellProps}>
                        {content}
                      </Cell>
                    );
                  })}
                </Row>
              ))}
          </TableBody>
        </Table>
      </div>
      {paginationEnabled && rows.length && rows.length > rowsPerPage ? (
        <div className={classes.pagination}>
          <Pagination
            count={Math.ceil(rows.length / rowsPerPage)}
            page={page}
            onChange={handlePageChange}
            {...paginationProps}
          />
        </div>
      ) : null}
    </TableContainer>
  );
};

EnhancedTable.propTypes = {
  head: PropTypes.arrayOf(
    PropTypes.shape({
      numeric: PropTypes.bool,
      sort: PropTypes.bool,
      value: PropTypes.oneOfType([
        PropTypes.string.isRequired,
        PropTypes.func.isRequired
      ]),
      label: PropTypes.string.isRequired
    })
  ).isRequired,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
    })
  ).isRequired,
  rowsPerPage: PropTypes.number,
  onRowClick: PropTypes.func,
  isShimmerLoading: PropTypes.bool
};

EnhancedTable.defaultProps = {
  isShimmerLoading: false
};

export { EnhancedTable };

export {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@mui/material';

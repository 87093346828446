import { createAction } from 'redux-act';

export const getInvoices = createAction('Get invoices');
export const invoicesReceived = createAction('Invoices received');

export const openInvoice = createAction('Open invoice');

export const setInvoiceLoading = createAction('Set invoice loading');

export const resetInvoices = createAction('Reset invoices');

export const svgFill = (
  transition: string,
  color: string
): Record<string, Record<string, string>> => ({
  '& *[fill="red"]': {
    fill: color,
    transition: transition
  },
  '& *[stroke="red"]': {
    stroke: color,
    transition: transition
  }
});

import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';

import {
  ProgressLoader,
  Translation
} from '@troy/shared/src/components/common';

import {
  hasStaticTextsSelector,
  portalLoginLogoHeightSelector,
  portalLoginLogoSelector
} from '../../store/selectors';
import { Button } from '../../components/common';

import { LOGIN_URL } from '../../constants/api/cognito';

const useStyles = makeStyles(theme => ({
  button: {
    marginTop: 40,
    minWidth: 170
  }
}));

export default () => {
  const classes = useStyles();
  const hasStaticTexts = useSelector(hasStaticTextsSelector);
  const logo = useSelector(portalLoginLogoSelector);
  const logoHeight = useSelector(portalLoginLogoHeightSelector);

  return (
    <>
      <img alt="logo" src={logo} height={logoHeight} />
      {hasStaticTexts && (
        <a href={LOGIN_URL}>
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
          >
            <Translation keyIfNoValue isFragment>
              CP_LOGIN_BUTTON
            </Translation>
          </Button>
        </a>
      )}
      <ProgressLoader reverse open={hasStaticTexts} />
    </>
  );
};

export const codeFromFullCode = (fullCode: string): string =>
  fullCode.slice(0, fullCode.indexOf('_')).toUpperCase();

export const languageFromFullCode = (fullCode: string): string =>
  fullCode ? fullCode.substring(0, fullCode.indexOf('_')) : 'en';

export const createFullLanguageCode = (fullCode: string): string => {
  return fullCode.toLowerCase().startsWith('en')
    ? 'en_GB'
    : fullCode.indexOf('-') !== -1
    ? fullCode.replace(/-/g, '_')
    : fullCode.toLowerCase() + '_' + fullCode.toUpperCase();
};

import React from 'react';
import clsx from 'clsx';
import { IconButton, Theme, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { onClickWrapper } from '../../../utils/ui';
import { SupportedIcon } from '../CustomIcon/iconData';
import { CustomIcon } from '../index';

export const getCloseButtonId = (id: string, type: string): string =>
  `${id ? id : `notification.message.${type}`}-close`;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    maxWidth: '300px',
    display: 'flex',
    flexDirection: 'row',
    padding: theme.spacing(2),
    borderRadius: Number(theme.shape.borderRadius) * 4,
    boxSizing: 'border-box'
  },
  success: {
    background: theme.palette.primary.main
  },
  error: {
    background: theme.palette.warning.dark
  },
  warning: {
    background: theme.palette.warning.light
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    color: theme.palette.primary.contrastText,
    margin: `0 auto 0 0`,
    padding: `0 ${theme.spacing(1)}`
  },
  correlationId: {
    marginTop: theme.spacing(1)
  },
  closeButtonContainer: {
    flexShrink: 0,
    margin: `auto 0`
  },
  closeButton: {
    padding: theme.spacing(0.5),
    margin: theme.spacing(-0.5)
  }
}));

export interface SnackbarNotificationProps {
  id: string;
  className?: string;
  type: 'error' | 'warning' | 'success';
  title?: string;
  message?: string | Record<string, unknown>;
  detail?: string;
  onCloseClick: () => void;
  correlationId?: string;
}

const iconMap: Record<SnackbarNotificationProps['type'], SupportedIcon> = {
  success: 'confirm',
  error: 'alert',
  warning: 'questionIcon'
};

const SnackbarNotification = React.forwardRef<
  HTMLDivElement,
  SnackbarNotificationProps
>(
  (
    {
      id,
      className,
      type,
      title,
      message,
      detail,
      onCloseClick,
      correlationId
    },
    ref
  ) => {
    const classes = useStyles();

    return (
      <div
        ref={ref}
        role="alert"
        id={id}
        className={clsx(classes.root, classes[type], className)}
        data-notification-type={type}
      >
        <CustomIcon
          icon={iconMap[type]}
          size="small"
          variant="primaryContrast"
        />
        <div className={classes.content}>
          <Typography component="h3" variant="body1">
            {title ? title : detail ? message : ''}
          </Typography>
          <Typography component="div" variant="body2">
            {detail ? detail : message}
          </Typography>
          {type !== 'success' && !!correlationId && (
            <Typography
              className={classes.correlationId}
              component="div"
              variant="body1"
            >
              correlationId: {correlationId}
            </Typography>
          )}
        </div>
        <div className={classes.closeButtonContainer}>
          <IconButton
            id={getCloseButtonId(id, type)}
            className={classes.closeButton}
            onClick={onClickWrapper(getCloseButtonId(id, type), onCloseClick)}
            data-notification-close
            size="large"
          >
            <CustomIcon icon="dismiss" size="small" variant="primaryContrast" />
          </IconButton>
        </div>
      </div>
    );
  }
);
SnackbarNotification.displayName = 'SnackbarNotification';

export default SnackbarNotification;

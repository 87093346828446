import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { OptionsObject, useSnackbar } from 'notistack';

import { removeSnackbar } from '../../../store/actions';
import { snackBarNotificationsSelector } from '../../../store/selectors';
import { SnackbarNotificationType } from '../../../types/snackbar';
import { useTranslationsContext } from '../TranslationProvider';
import { SnackbarNotification } from '../';

interface SnackbarManagerProps {
  correlationId?: string;
}

const SnackbarManager: React.FC<SnackbarManagerProps> = ({
  correlationId
}): React.ReactElement | null => {
  const dispatch = useDispatch();
  const { translate } = useTranslationsContext();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const notifications = useSelector(snackBarNotificationsSelector);
  const [displayed, setDisplayed] = useState<OptionsObject['key'][]>([]);

  const storeDisplayed = useCallback(
    id => {
      setDisplayed([...displayed, id]);
    },
    [displayed, setDisplayed]
  );

  const onNotificationExit = (_: never, key: OptionsObject['key']): void => {
    // remove this snackbar from redux store
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(removeSnackbar(key));
    setDisplayed(currentDisplayed => [
      ...currentDisplayed.filter(k => key !== k)
    ]);
  };

  const updateNotifications = useCallback(() => {
    notifications.forEach(
      ({
        key,
        id,
        title,
        message,
        detail,
        options = { variant: 'success' },
        dismissed = false
      }: SnackbarNotificationType) => {
        if (dismissed) {
          // dismiss snackbar using notistack
          closeSnackbar(key);
          return;
        }

        // do nothing if snackbar is already displayed
        if (displayed.includes(key)) return;

        // display snackbar using notistack
        enqueueSnackbar(message, {
          key,
          ...options,
          persist: options.variant !== 'success' && !!correlationId,
          content: key => (
            <SnackbarNotification
              id={id}
              type={options.variant}
              title={title}
              message={
                typeof message === 'string' ? translate(message, true) : message
              }
              detail={detail ? translate(detail, true) : detail}
              onCloseClick={(): void => closeSnackbar(key)}
              correlationId={correlationId}
            />
          ),
          onExited: onNotificationExit,
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right'
          }
        });

        // keep track of snackbars that we've displayed
        storeDisplayed(key);
      }
    );
  }, [
    displayed,
    notifications,
    closeSnackbar,
    enqueueSnackbar,
    dispatch,
    onNotificationExit
  ]);

  useEffect(() => {
    updateNotifications();
  }, [notifications]);

  return null;
};

export default SnackbarManager;

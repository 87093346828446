import React from 'react';
import { SvgIcon } from '@mui/material';

import { ReactComponent as TableIconSvg } from '../../../../assets/icons/widgets/users-widget/claim_overview.svg';
import { ReactComponent as ChatIconSvg } from '../../../../assets/icons/widgets/users-widget/mail.svg';
import { ReactComponent as HourGlassIconSvg } from '../../../../assets/icons/widgets/users-widget/hourglass.svg';
import { ReactComponent as PenIconSvg } from '../../../../assets/icons/widgets/users-widget/pen.svg';

export const TableTabIcon = props => (
  <SvgIcon {...props} component={TableIconSvg} viewBox="0 0 16 16" />
);
export const ChatTabIcon = props => (
  <SvgIcon {...props} component={ChatIconSvg} viewBox="0 0 24 24" />
);

export const PenTabIcon = props => (
  <SvgIcon {...props} component={PenIconSvg} viewBox="0 0 16 16" />
);

export { TableIconSvg, ChatIconSvg, HourGlassIconSvg, PenIconSvg };
export { ReactComponent as UploadIcon } from '../../../../assets/icons/widgets/users-widget/upload.svg';

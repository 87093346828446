const decisionsSelector = state => state.decisions;

export const decisionsDisabledWidgetsSelector = state =>
  decisionsSelector(state).disabledWidgets;

export const decisionsEnabledWidgetsSelector = state =>
  decisionsSelector(state).enabledWidgets;

export const decisionsIsPedingSelector = state =>
  decisionsSelector(state).isPending;

export const decisionsWidgetsSelector = state =>
  decisionsSelector(state).widgets;

export const decisionsUsernameSelector = state =>
  decisionsSelector(state).username;

export const decisionsValuesSelector = state =>
  decisionsSelector(state).decisions;

import queryString from 'query-string';

export const getPureUrl = url => queryString.parseUrl(url).url;

export const getSearchString = url => queryString.extract(url);

export const getUrlParams = (url = window.location.search) =>
  queryString.parse(url);

export const getSearchQuery = keyValueObj => queryString.stringify(keyValueObj);

export const getSearchUrl = ({ url, query }) =>
  queryString.stringifyUrl({ url, query });

export const stringifyUrlParams = params => queryString.stringify(params);

import React from 'react';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material';

import { UINotification } from '../../../types/notifications';
import {
  formatNotificationDate,
  formatNotificationHours
} from '../../../utils/postbox-item';

import { Text } from '../index';
import { CustomColor } from '../Text';

interface PostBoxItemTimeStampProps {
  colorScheme: UINotification['colorScheme'];
  datetime: UINotification['datetime'];
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    padding: `${theme.spacing(0.5)} ${theme.spacing(1)}`,
    background: theme.palette.action.disabled,
    borderRadius: Number(theme.shape.borderRadius)
  },
  containerWarningLight: {
    background: theme.palette.warning.light
  },
  separator: {
    margin: `0 ${theme.spacing(1)}`,
    width: 1,
    height: 16,
    background: theme.palette.text.hint
  }
}));

const PostBoxItemTimeStamp: React.FC<PostBoxItemTimeStampProps> = ({
  colorScheme,
  datetime
}) => {
  const classes = useStyles();

  const color: CustomColor =
    colorScheme === 'Primary'
      ? 'primaryDark'
      : colorScheme === 'Secondary'
      ? 'secondaryDark'
      : colorScheme === 'Tertiary'
      ? 'tertiaryDark'
      : colorScheme === 'Warning' || colorScheme === 'Hidden'
      ? 'textSecondary'
      : 'textPrimary';

  return (
    <div
      className={clsx(
        classes.container,
        colorScheme === 'WarningLight' && classes.containerWarningLight
      )}
    >
      <Text component="span" variant="subtitle2" color={color}>
        {formatNotificationDate(datetime)}
      </Text>
      <div className={classes.separator} />
      <Text component="span" variant="subtitle2" color={color}>
        {formatNotificationHours(datetime)}
      </Text>
    </div>
  );
};

export default PostBoxItemTimeStamp;

import { authorizedGet, authorizedPost } from '../utils/api';
import {
  CONTACT_FORM,
  DECISIONS,
  HOLD_FORM,
  INVOICE_ON_ID,
  INVOICES,
  LIABILITY_CLOSE,
  LIABILITY_CLOSE_REASONS,
  REPORT_CREDIT_MEMO,
  REPORT_PAYMENT
} from '../constants/api';

class ApiService {
  static getDecisions = token => authorizedGet(DECISIONS, token);

  static getLiabilityCloseReasons = token =>
    authorizedGet(LIABILITY_CLOSE_REASONS, token);
  static postCloseReason = (liabilityId, body, token) =>
    authorizedPost(LIABILITY_CLOSE(liabilityId), body, token);
  static postReportCreditMemo = (liabilityId, body, token) =>
    authorizedPost(REPORT_CREDIT_MEMO(liabilityId), body, token);
  static postReportPayment = (liabilityId, body, token) =>
    authorizedPost(REPORT_PAYMENT(liabilityId), body, token);
  static postContactForm = (liabilityId, body, token) =>
    authorizedPost(CONTACT_FORM(liabilityId), body, token);
  static postHold = (liabilityId, body, token) =>
    authorizedPost(HOLD_FORM(liabilityId), body, token);

  static getInvoices = token => authorizedGet(INVOICES, token);
  static getInvoice = (id, token) =>
    authorizedGet(INVOICE_ON_ID(id), token, {
      responseType: 'blob',
      responseHeaders: true
    }).then(
      ({
        responseHeaders: { 'content-disposition': contentDisposition },
        data
      }) => {
        const result = { data };
        const regexResult = /filename="(.*)"/g.exec(contentDisposition || '');

        if (regexResult && regexResult[1]) {
          result.fileName = regexResult[1];
        }
        return result;
      }
    );
}

export default ApiService;

import React from 'react';
import makeStyles from '@mui/styles/makeStyles';

import { UINotification } from '../../../types/notifications';
import { Theme } from '@mui/material';

import { CustomIcon, Text } from '../index';

interface PostBoxItemTitleProps {
  colorScheme: UINotification['colorScheme'];
  icon: UINotification['headerIcon'];
  text: UINotification['headerText'];
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center'
  },
  icon: {
    alignSelf: 'flex-start',
    marginRight: theme.spacing(2)
  }
}));

const PostBoxItemTitle: React.FC<PostBoxItemTitleProps> = ({
  colorScheme,
  icon,
  text
}) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <CustomIcon
        className={classes.icon}
        icon={icon}
        size="small"
        variant={
          colorScheme === 'Hidden'
            ? 'textHint'
            : colorScheme === 'Primary'
            ? 'primary'
            : colorScheme === 'Secondary'
            ? 'secondaryMain'
            : colorScheme === 'Tertiary'
            ? 'tertiary'
            : colorScheme === 'Warning'
            ? 'warningDark'
            : colorScheme === 'WarningLight'
            ? 'warningLight'
            : 'textPrimary'
        }
      />
      <Text component="h3" variant="body2Bold" color="textPrimary">
        {text}
      </Text>
    </div>
  );
};

export default PostBoxItemTitle;

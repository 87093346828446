import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import { Theme, Tooltip, TooltipProps } from '@mui/material';
import { useTranslationsContext } from '@troy/shared/src/components/common';

const useStyles = makeStyles((theme: Theme) => ({
  tooltip: {
    ...theme.typography.subtitle1,
    background: theme.palette.secondary.main,
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
    borderRadius: Number(theme.shape.borderRadius) * 2,
    transition:
      'opacity 200ms cubic-bezier(0.4, 0, 0.2, 1), transform 133ms cubic-bezier(0.4, 0, 0.2, 1), background ease-in-out 100ms !important'
  },
  tooltipCopied: {
    background: theme.palette.primary.main
  },
  arrow: {
    color: theme.palette.secondary.main,
    transition: 'color ease-in-out 100ms'
  },
  arrowCopied: {
    color: theme.palette.primary.main
  }
}));

interface CopyTooltipProps {
  value?: string | number;
  addCopyHoverProp?: boolean;
  hidden?: boolean;
  children: TooltipProps['children'];
}

export interface CopyTooltipChildProps {
  showCopyHover?: boolean;
}

const CopyTooltip: React.FC<CopyTooltipProps> = ({
  value,
  addCopyHoverProp = true,
  hidden,
  children
}) => {
  const classes = useStyles();
  const { translate } = useTranslationsContext();
  const [open, setOpen] = useState(false);
  const [copied, setCopied] = useState(false);
  const [hideTimer, setHideTimer] = useState<number | undefined>(undefined);

  const disabled = value == null || value === '';

  useEffect(() => {
    if (open) {
      setCopied(false);
    }
  }, [open, setCopied]);

  const handleClose = (): void => {
    if (!disabled) {
      clearTimeout(hideTimer);
      setOpen(false);
    }
  };

  const handleOpen = (): void => {
    if (!disabled) {
      setOpen(true);
    }
  };

  const handleClick = (): void => {
    if (open && !copied) {
      setCopied(true);
      if (navigator.clipboard) {
        navigator.clipboard.writeText((value || '').toString());
      }
      const id = setTimeout(() => setOpen(false), 1000) as unknown as number;
      setHideTimer(id);
    }
  };

  return (
    <Tooltip
      arrow
      open={open && !hidden}
      onClose={!hidden ? handleClose : undefined}
      onOpen={!hidden ? handleOpen : undefined}
      onClick={!hidden ? handleClick : undefined}
      title={translate(
        copied ? 'UNIC_UXCOPIES_COPY_COPIED' : 'UNIC_UXCOPIES_COPY_CLICKTOCOPY',
        true
      )}
      classes={{
        tooltip: clsx(classes.tooltip, copied && classes.tooltipCopied),
        arrow: clsx(classes.arrow, copied && classes.arrowCopied)
      }}
    >
      {addCopyHoverProp
        ? React.cloneElement(children, {
            showCopyHover: open
          })
        : children}
    </Tooltip>
  );
};

export default CopyTooltip;

import { createAction } from 'redux-act';
import {
  ApiNotification,
  APINotificationVisibility
} from '../../types/notifications';

export const getNotifications = createAction('Get notifications');

export const notificationsReceived = createAction<{
  items: ApiNotification[];
  isTokenInternal: boolean;
  hasHideCommunicationFeature?: boolean;
}>('Notifications received');

export const notificationsReset = createAction('Reset notifications');

export const changeNotificationVisibility = createAction<{
  eventId: string;
  visibility: APINotificationVisibility;
}>('Change notification visibility / active state');

import {
  ApiMLAttributes,
  ApiNotification,
  ApiNotificationChannel,
  APINotificationVisibility,
  LANotification,
  UINotification,
  UINotificationChannelFilter,
  UINotificationDirectionFilter,
  UINotificationFile
} from '../../types/notifications';
import { SupportedIcon } from '../../components/common';
import {
  detectJSONChat,
  isChatChannel,
  mergeLiveAgentMessageAndFiles,
  parseFileData,
  UIChannelFilerMapping
} from './utils';

export const transformNotifications = (
  notifications: ApiNotification[],
  isTokenInternal: boolean,
  hasHideCommunicationFeature?: boolean
): UINotification[] => {
  return notifications
    .filter(n => isNotificationVisible(n, isTokenInternal))
    .map(n => transformNotification(n, hasHideCommunicationFeature));
};

const isNotificationVisible = (
  n: ApiNotification,
  isTokenInternal: boolean
): boolean => {
  const channelFilter = resolveChannelFilter(n.direction, n.channel);
  if (channelFilter === 'CLIENT' || channelFilter === 'INTERNAL_NOTE') {
    return isTokenInternal;
  }
  return true;
};

const transformNotification = (
  n: ApiNotification,
  hasHideCommunicationFeature?: boolean
): UINotification => {
  const result: UINotification = {
    apiDirection: n.direction,
    apiChannel: n.channel,
    colorScheme: resolveColorScheme(n.direction, n.channel, n.activeState),
    singleLaneSide: resolveCustomerLaneSide(n.direction, n.channel),
    doubleLaneColumn: resolveExternalLaneColumn(n.direction, n.channel),
    doubleLaneSide: resolveExternalLaneSide(n.direction),
    datetime: n.datetime,
    headerIcon: resolveHeaderIcon(n.channel, n.direction),
    headerText: n.subject,
    headerTag: resolveHeaderTag(n.direction, n.channel),
    files: resolveFiles(n.documents),
    rawChannel: n.channel.toLowerCase(),
    directionFilterCategory: resolveDirectionFilter(n.direction, n.channel),
    channelFilterCategory: resolveChannelFilter(n.direction, n.channel),
    activeState: n.activeState,
    eventId: n.event_id,
    contextMenuVisible: hasHideCommunicationFeature && !!n.event_id,
    contextMenuEditEnabled: hasHideCommunicationFeature && !!n.event_id,
    ...resolveContentAndContentType(n.channel, n.text, n.documents),
    ...resolveMLAttributes(n.ml_attributes)
  };

  if (result.contentType === 'laChat') {
    result.files = [];
  }

  return result;
};

export const getDirectionFilterCounts = (
  notifications: ApiNotification[],
  isTokenInternal: boolean
): Record<UINotificationDirectionFilter, number> => {
  const result: Record<UINotificationDirectionFilter, number> = {
    all: 0,
    FILTER_SENT: 0,
    FILTER_INBOX: 0
  };
  const filtered = notifications.filter(n =>
    isNotificationVisible(n, isTokenInternal)
  );
  filtered.forEach(
    n => result[resolveDirectionFilter(n.direction, n.channel)]++
  );
  result['all'] = filtered.length;
  return result;
};

export const resolveColorScheme = (
  direction: ApiNotification['direction'],
  channel: ApiNotification['channel'],
  visibility: APINotificationVisibility
): UINotification['colorScheme'] => {
  if (
    ['HIDDEN_CUSTOMER', 'HIDDEN_EXTERNAL', 'HIDDEN_INTERNAL'].includes(
      visibility
    )
  ) {
    return 'Hidden';
  } else if (
    direction === 'TO_CUSTOMER_FROM_CLIENT' ||
    direction === 'TO_TROY_FROM_CLIENT' ||
    channel === 'CLIENT_CHAT' ||
    channel === 'CLIENT_EMAIL'
  ) {
    return 'Tertiary';
  } else if (direction === 'FROM_CUSTOMER') {
    return 'Primary';
  } else if (
    direction === 'TO_CUSTOMER' ||
    direction === 'TO_CLIENT_FROM_TROY'
  ) {
    return 'Secondary';
  } else if (direction === 'TO_CUSTOMER_FROM_LAWYER') {
    return 'Warning';
  } else if (direction === 'INTERNAL') {
    return 'WarningLight';
  }
  return 'Default';
};

const resolveCustomerLaneSide = (
  direction: ApiNotification['direction'],
  channel: ApiNotification['channel']
): UINotification['singleLaneSide'] => {
  if (channel === 'CLIENT_CHAT' || channel === 'CLIENT_EMAIL') {
    return 'left';
  } else if (direction === 'FROM_CUSTOMER') {
    return 'right';
  }
  return 'left';
};

const resolveExternalLaneColumn = (
  direction: ApiNotification['direction'],
  channel: ApiNotification['channel']
): UINotification['doubleLaneColumn'] => {
  if (
    direction === 'TO_CLIENT_FROM_TROY' ||
    direction === 'TO_TROY_FROM_CLIENT' ||
    channel === 'CLIENT_CHAT'
  ) {
    return 'external';
  }
  return 'internal';
};

const resolveExternalLaneSide = (
  direction: ApiNotification['direction']
): UINotification['doubleLaneSide'] => {
  if (direction === 'FROM_CUSTOMER' || direction === 'TO_CLIENT_FROM_TROY') {
    return 'right';
  }
  return 'left';
};

const resolveHeaderIcon = (
  channel: ApiNotification['channel'],
  direction: ApiNotification['direction']
): SupportedIcon => {
  if (channel === 'PHONE') {
    return direction === 'FROM_CUSTOMER' ? 'telephoneIn' : 'telephoneOut';
  } else if (channel === 'CUSTOMERCENTER') {
    return 'uploadIcon';
  } else if (['LETTER', 'PAYMENT', 'INVOICE'].includes(channel)) {
    return 'letter';
  } else if (['EMAIL', 'DOCUMENT', 'CLIENT_EMAIL'].includes(channel)) {
    return 'mailIcon';
  } else if (['SMS', 'WHATSAPP'].includes(channel)) {
    return 'chatIcon';
  } else if (['CHAT', 'CLIENT_CHAT'].includes(channel)) {
    return 'onSiteChat';
  } else if (['INTERNAL_NOTE', 'ERROR'].includes(channel)) {
    return 'info';
  }
  return 'mailIcon';
};

const resolveHeaderTag = (
  direction: ApiNotification['direction'],
  channel: ApiNotification['channel']
): UINotification['headerTag'] => {
  if (
    direction === 'TO_TROY_FROM_CLIENT' ||
    direction === 'TO_CUSTOMER_FROM_CLIENT' ||
    channel === 'CLIENT_CHAT' ||
    channel === 'CLIENT_EMAIL'
  ) {
    return 'TypeOfOtherSide.CLIENT';
  } else if (direction === 'TO_CUSTOMER_FROM_LAWYER') {
    return 'TypeOfOtherSide.LAWYER';
  }
  return null;
};

const resolveFiles = (
  documents: ApiNotification['documents']
): UINotificationFile[] => {
  return documents.map(d => parseFileData(d));
};

const resolveDirectionFilter = (
  direction: ApiNotification['direction'],
  channel: ApiNotification['channel']
): UINotification['directionFilterCategory'] => {
  if (channel === 'CLIENT_CHAT' || channel === 'CLIENT_EMAIL') {
    return 'FILTER_INBOX';
  }
  if (direction === 'FROM_CUSTOMER') {
    return 'FILTER_SENT';
  }
  return 'FILTER_INBOX';
};

const resolveChannelFilter = (
  direction: ApiNotification['direction'],
  channel: ApiNotification['channel']
): UINotification['channelFilterCategory'] => {
  if (
    direction === 'TO_CUSTOMER_FROM_CLIENT' ||
    direction === 'TO_TROY_FROM_CLIENT'
  ) {
    return 'CLIENT';
  }

  const keys = Object.keys(
    UIChannelFilerMapping
  ) as Array<ApiNotificationChannel>;
  const values = Object.values(
    UIChannelFilerMapping
  ) as Array<UINotificationChannelFilter>;

  const index = keys.indexOf(channel);
  if (index >= -1) {
    return values[index];
  }
  return null;
};

const resolveContentAndContentType = (
  channel: ApiNotification['channel'],
  text: ApiNotification['text'],
  documents: ApiNotification['documents']
): {
  content: UINotification['content'];
  contentType: UINotification['contentType'];
} => {
  let content: LANotification | string | null = text;
  let isChat = isChatChannel(channel);
  let isLAChat = detectJSONChat(channel, text);
  if (isLAChat) {
    content = JSON.parse(text);
    if (content) {
      content = mergeLiveAgentMessageAndFiles(
        content as LANotification,
        documents
      );
    } else {
      content = text;
      isChat = false;
      isLAChat = false;
    }
  }

  // empty live agent chat
  if (isLAChat && (content as LANotification).chatMessages.length === 0) {
    content = null;
  }

  return {
    content: content,
    contentType: isLAChat ? 'laChat' : isChat ? 'htmlChat' : 'text'
  };
};

const MLValuesMapping: Record<
  ApiMLAttributes['predicted_value'],
  UINotification['mlValue']
> = {
  negative: 'NEGATIVE',
  other: 'OTHER',
  positive: 'POSITIVE',
  ml_neutral: 'MLNEUTRAL',
  ml_angry: 'MLANGRY',
  ml_happy: 'MLHAPPY',
  ml_confused: 'MLCONFUSED',
  ml_sad: 'MLSAD',
  ml_annoyed: 'MLANNOYED'
};

const resolveMLAttributes = (
  ml_attributes: ApiNotification['ml_attributes']
): {
  mlValue: UINotification['mlValue'];
  mlConfidence: UINotification['mlConfidence'];
} => {
  if (!ml_attributes || ml_attributes.length === 0) {
    return {
      mlValue: null,
      mlConfidence: null
    };
  }
  const item = ml_attributes[0];
  let confidence = item.confidence;
  if (confidence > 0.99) {
    confidence = 0.99;
  }

  const value = item.predicted_value;
  let mlValue = MLValuesMapping[value];
  if (mlValue == null) {
    mlValue = 'OTHER';
  }

  return {
    mlValue,
    mlConfidence: parseFloat((confidence * 100.0).toString()).toFixed(2) + '%'
  };
};

import { createSelector } from 'reselect';

const stateSelector = state => state.looker;

export const lookerDashboardsSelector = createSelector(
  stateSelector,
  state => state.dashboards
);

export const lookerDashboardsLoadingSelector = createSelector(
  stateSelector,
  state => state.loadingDashboards
);

export const lookerDashboardUrlsSelector = createSelector(
  stateSelector,
  state => state.dashboardUrls
);

export const lookerDashboardUrlsLoadingSelector = createSelector(
  stateSelector,
  state => state.loadingDashboardUrls
);

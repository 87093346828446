import React, { useCallback } from 'react';
import { Form, Formik } from 'formik';
import Validator from 'validatorjs';
import { useDispatch } from 'react-redux';

import { enqueueSnackbar } from '@troy/shared/src/store/actions';

export const errorCatcher = (dispatch, errors) => {
  const keys = Object.keys(errors);
  keys.forEach(key => {
    if (errors[key]) {
      dispatch(
        enqueueSnackbar({
          message: `${key}: ${errors[key]}`,
          options: {
            variant: 'error'
          }
        })
      );
    }
  });
};

const FormWrapper = ({ onSubmit, className, children: Children, ...props }) => {
  return (
    <Formik onSubmit={onSubmit} {...props}>
      {props => (
        <Form className={className}>
          <Children {...props} />
        </Form>
      )}
    </Formik>
  );
};
export default ({ name, rules, onSubmit, ...props }) => {
  const dispatch = useDispatch();
  const validator = useCallback(
    !rules
      ? () => null
      : values => {
          const validator = new Validator(values, rules);
          validator.passes();
          return validator.errors.all();
        },
    [rules]
  );

  const onSubmitWrapper = useCallback(
    values => {
      const errors = validator(values);
      if (errors && Object.keys(errors).length) {
        errorCatcher(dispatch, errors);
      } else {
        onSubmit(values);
      }
    },
    [validator, onSubmit]
  );

  return <FormWrapper form={name} onSubmit={onSubmitWrapper} {...props} />;
};

import images from '../assets/img/matched';

export const getImageFileName = (imagePath: string): string => {
  const imageArr = imagePath.split('/');
  return imageArr.length ? imageArr[imageArr.length - 1] : '';
};

export const getImage = (imagePath: string): string => {
  const imageArr = imagePath.split('/');
  return imageArr.length && images[imageArr[imageArr.length - 1]];
};

const EXTENSIONS = ['jpg', 'png', 'svg', 'gif', 'jpeg'];

export const anyImageFile = (
  assets: Map<string, string>,
  id: string,
  name: string
): string | null => {
  const extension = EXTENSIONS.find(ext => !!assets[`${id}/${name}.${ext}`]);
  if (extension) {
    return assets[`${id}/${name}.${extension}`];
  }
  return null;
};

import * as dateFns from 'date-fns';
import store from '../store';
import { languageSelector } from '../store/selectors';
import { codeFromBrowserCode } from './translations';

const DATE_FORMATES = {
  NL: 'dd-MM-yyyy',
  DE: 'dd.MM.yyyy',
  EN: 'dd/MM/yyyy',
  FR: 'dd/MM/yyyy'
};

const FALLBACK_DATE_FORMAT = 'yyyy-MM-dd';

const getLanguage = () => {
  return languageSelector(store.getState());
};

export const getLocaleFormat = () => {
  const language = getLanguage();
  return DATE_FORMATES[codeFromBrowserCode(language)] || FALLBACK_DATE_FORMAT;
};

const DATE_RANGE_MASK = {
  NL: '99-99-9999 - 99-99-9999',
  DE: '99.99.9999 - 99.99.9999',
  EN: '99/99/9999 - 99/99/9999',
  FR: '99/99/9999 - 99/99/9999'
};
const FALLBACK_DATE_RANGE_MASK = '9999-99-99 - 9999-99-99';

export const getDateRangeMask = () =>
  DATE_RANGE_MASK[codeFromBrowserCode(getLanguage())] ||
  FALLBACK_DATE_RANGE_MASK;

export const format = (dateStr, format) => {
  return dateFns.format(new Date(dateStr), format || getLocaleFormat());
};

export const parse = (dateStr, format, refDate) =>
  dateFns.parse(dateStr, format, refDate || new Date());

export const toUTCISOString = dateStr => {
  let date = new Date(dateStr);
  const offset = date.getTimezoneOffset();
  date = dateFns[Math.sign(offset) === 1 ? 'subMinutes' : 'addMinutes'](
    date,
    Math.abs(offset)
  );
  return date.toISOString();
};

export const addMonths = (date, amount) => dateFns.addMonths(date, amount);

export const getDate = date => dateFns.getDate(date);
export const getMonth = date => dateFns.getMonth(date) + 1;
export const getYear = date => dateFns.getYear(date);

export const setDate = (date, day) => dateFns.setDate(date, day);
export const setMonth = (date, month) => dateFns.setMonth(date, month - 1);
export const setYear = (date, year) => dateFns.setYear(date, year);

export const lastDayOfMonth = date => dateFns.lastDayOfMonth(date);
export const lastDayOfYear = date => dateFns.lastDayOfYear(date);

export const toDate = dateFns.toDate;

export const isValidDate = d => {
  return d instanceof Date && !isNaN(d);
};
export const isSameDay = dateFns.isSameDay;

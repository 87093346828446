import { call, delay, put, select } from 'redux-saga/effects';

import { get, graphqlRequest, post } from '../../utils/api';

import { GET_TRANSLATIONS, LANGUAGE } from '../../constants/api';

import {
  availableLanguagesReceived,
  handleError,
  setLanguageFinal,
  setTranslations
} from '../actions';
import {
  availableLanguagesSelector,
  isCognitoAuthenticatedSelector
} from '../selectors';

import { codeFromFullCode } from '../../utils/translations';
import { BROWSER_LANGUAGE, DOMAIN_LANGUAGE } from '../../constants/browser';
import * as routes from '../../constants/routes';

const modifyFullCode = fullCode => {
  return fullCode.startsWith('en')
    ? 'en_GB'
    : fullCode.indexOf('-') !== -1
    ? fullCode.replace(/-/g, '_')
    : fullCode + '_' + fullCode.toUpperCase();
};

function* getDefaultLanguage() {
  const languages = yield select(availableLanguagesSelector);
  const fullCode = modifyFullCode(BROWSER_LANGUAGE);

  return (
    (languages &&
      !!languages.find(({ fullCode: language }) => fullCode === language) &&
      BROWSER_LANGUAGE) ||
    DOMAIN_LANGUAGE
  );
}

export function* getAvailableLanguages() {
  try {
    const languages = yield call(get, LANGUAGE);
    yield put(availableLanguagesReceived(languages));
  } catch (err) {
    yield put(handleError(err));
  }
}

export function* selectLanguage({ payload: { code: fullCode } }) {
  const request = { fullCode, source: 'LANGUAGE_CHOICE_BUTTON' };
  const code = codeFromFullCode(fullCode);

  yield call(selectLanguageDefault, {
    ...request,
    isDynamicText: false
  });
  yield put(setLanguageFinal(code));
}

export function* selectBrowserLanguage() {
  const defaultLanguage = yield call(getDefaultLanguage);
  const fullCode = modifyFullCode(defaultLanguage);
  const code = codeFromFullCode(fullCode);

  yield call(selectLanguageDefault, {
    fullCode,
    source: 'DEFAULT_BROWSER_SETTING'
  });

  yield put(setLanguageFinal(code));
}

export function* selectLanguageDefault({
  isDynamicText = false,
  withTranslations = true,
  ...request
}) {
  const body = { request };
  const caller = isDynamicText ? null : call;

  try {
    yield caller(post, LANGUAGE, body);
    if (withTranslations) {
      yield call(getTranslations, { isDynamicText, request });
    }
  } catch (err) {
    yield put(handleError(err));
  }
}

export function* getTranslations({ isDynamicText, request }) {
  if (!isDynamicText) {
    yield call(getStaticTranslations, request);
  }
}

export function* getStaticTranslations({ fullCode }) {
  try {
    const t1 = performance.now();
    const data = yield call(
      graphqlRequest,
      GET_TRANSLATIONS(fullCode, DOMAIN_LANGUAGE)
    );
    const res = data.staticText2;
    if (res) {
      const isAuthenticated = yield select(isCognitoAuthenticatedSelector);
      const t2 = performance.now();

      // For login page add small delay to show progress loader for translations
      if (!isAuthenticated && window.location.pathname === routes.HOME) {
        yield delay(1000 - (t2 - t1));
      }

      yield put(
        setTranslations({
          translations: res.notes_list,
          isStaticText: true
        })
      );
    } else {
      throw new Error(data.errors.message);
    }
  } catch (err) {
    yield put(handleError(err));
  }
}

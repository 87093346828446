export const KEY_DEV = 'dev';
export const KEY_TEST = 'test';
export const KEY_DEMO = 'demo';
export const KEY_PROD = 'prod';

export const envKeyString = () =>
  process.env.REACT_APP_ENV === 'production'
    ? KEY_PROD
    : process.env.REACT_APP_ENV === 'staging'
    ? KEY_TEST
    : process.env.REACT_APP_ENV === 'demo'
    ? KEY_DEMO
    : KEY_DEV;

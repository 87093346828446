import { createSelector } from 'reselect';

const liabilitySelector = state => state.liability;

export const liabilityCloseReasonsSelector = createSelector(
  liabilitySelector,
  liability => liability.closeReasons
);

export const isLiabilityClosedSelector = createSelector(
  liabilitySelector,
  liability => liability.isLiabilityClosed
);

import { getDomainLanguage } from '../utils/browser';
import { localeFromBrowserCode } from '../utils/translations';

export const DOMAIN_DE = 'de';
export const DOMAIN_NL = 'nl';
export const DOMAIN_BE = 'be';

export const BROWSER_LANGUAGE = navigator.language || navigator.userLanguage;
export const BROWSER_LOCALE = localeFromBrowserCode(BROWSER_LANGUAGE);
export const DOMAIN_LANGUAGE = getDomainLanguage() || DOMAIN_DE;

export const DATA_TYPE_KEY = 'data-type';
export const DATA_TYPES = {
  listOption: 'list-option'
};

export const isDomainDE = () => DOMAIN_LANGUAGE === DOMAIN_DE;

export const isDomainNL = () => DOMAIN_LANGUAGE === DOMAIN_NL;

export const isDomainBE = () => DOMAIN_LANGUAGE === DOMAIN_BE;
import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import { alpha } from '@mui/material';

import {
  EmailIcon,
  PhoneIcon,
  SMSIcon,
  TwitterIcon,
  WhatsAppIcon
} from '../../common';
import {
  DOMAIN_DE,
  DOMAIN_LANGUAGE,
  DOMAIN_NL
} from '../../../constants/browser';
import { threeColumnWidgetListWidth } from '../../../assets/styles/jss_functions';

const PHONES = {
  [DOMAIN_NL]: 'tel:+310514788109',
  [DOMAIN_DE]: 'tel:+4929417463012'
};
const SMS_PHONES = {
  [DOMAIN_NL]: 'tel:+3197010254944',
  [DOMAIN_DE]: 'smsto:+491575987770'
};
const EMAILS = {
  [DOMAIN_NL]: 'mailto:service@troy.nl',
  [DOMAIN_DE]: 'mailto:service@troy-bleiben.de'
};
const WHATSAPP = {
  [DOMAIN_NL]: 'https://wa.me/0683133384',
  [DOMAIN_DE]: 'https://wa.me/491604288200'
};
const TWITTER = {
  [DOMAIN_NL]: 'https://twitter.com/troy_inkasso',
  [DOMAIN_DE]: 'https://twitter.com/troy_inkasso'
};

let CONTACT_OPTIONS = [
  {
    Icon: PhoneIcon,
    onClick: null,
    href: PHONES[DOMAIN_LANGUAGE]
  },
  {
    Icon: SMSIcon,
    onClick: null,
    href: SMS_PHONES[DOMAIN_LANGUAGE]
  },
  {
    Icon: EmailIcon,
    onClick: null,
    href: EMAILS[DOMAIN_LANGUAGE]
  },
  {
    Icon: WhatsAppIcon,
    onClick: null,
    href: WHATSAPP[DOMAIN_LANGUAGE]
  },
  {
    Icon: TwitterIcon,
    onClick: null,
    href: TWITTER[DOMAIN_LANGUAGE]
  }
];

const sideMenuWidth = 64;

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    zIndex: 10
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    flex: 1
  },
  menu: {
    display: 'flex',
    flexDirection: 'column',

    [`@media only screen and (max-width: ${
      threeColumnWidgetListWidth + 2 * sideMenuWidth
    }px)`]: {
      display: 'none'
    },

    '& > a:first-child > button': {
      borderTopLeftRadius: `${theme.shape.borderRadius * 4}px !important`
    },
    '& > a:last-child > button': {
      borderBottomLeftRadius: `${theme.shape.borderRadius * 4}px !important`
    }
  },
  button: {
    background: `${alpha(theme.palette.primary.contrastText, 0.1)} !important`
  }
}));

export default ({ className, iconButton: IconButton }) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.container, className)}>
      <div className={classes.wrapper}>
        <div className={classes.menu}>
          {CONTACT_OPTIONS &&
            CONTACT_OPTIONS.length &&
            CONTACT_OPTIONS.map(({ Icon, onClick, href }, idx) =>
              href ? (
                <a
                  href={href}
                  target="_blank"
                  rel="noopener noreferrer"
                  key={idx}
                >
                  <IconButton
                    className={classes.button}
                    icon={Icon}
                    onClick={onClick}
                    size="large"
                  />
                </a>
              ) : (
                <IconButton
                  className={classes.button}
                  icon={Icon}
                  onClick={onClick}
                  size="large"
                />
              )
            )}
        </div>
      </div>
    </div>
  );
};

export const modifyName = (name, max_len = 55) => {
  if (name.length > max_len) {
    const extensionIdx = name.lastIndexOf('.');
    const shrink = '(...)';
    if (extensionIdx !== -1) {
      const extensionLen = name.length - extensionIdx;

      if (extensionLen <= name.length / 2) {
        return (
          name.slice(0, max_len - extensionLen - shrink.length) +
          shrink +
          name.slice(extensionIdx)
        );
      } else {
        // if extension is too long
        return (
          name.slice(0, extensionIdx) +
          name.slice(extensionIdx, max_len - shrink.length) +
          shrink
        );
      }
    } else {
      // if there's no extension
      return name.slice(0, max_len - shrink.length) + shrink;
    }
  } else {
    return name;
  }
};

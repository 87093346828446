import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';

interface TranslationsContextProps {
  translate: (translationKey: string, keyIfNoValue?: boolean) => string;
}

const TranslationsContext = React.createContext<TranslationsContextProps>({
  translate: () => ''
});

export const useTranslationsContext = (): TranslationsContextProps => {
  return React.useContext(TranslationsContext);
};

interface TranslationsProviderProps {
  translationsDataSelector: (state: never) => Record<string, string>;
  children: React.ReactNode;
}

const TranslationsProvider: React.FC<TranslationsProviderProps> = ({
  translationsDataSelector,
  children
}) => {
  const translations = useSelector(translationsDataSelector);

  const translate = useCallback(
    (translationKey: string, keyIfNoValue?: boolean): string => {
      return (
        (translations && translations[translationKey]) ||
        (keyIfNoValue ? translationKey : '')
      );
    },
    [translations]
  );

  return (
    <TranslationsContext.Provider
      value={{
        translate
      }}
    >
      {children}
    </TranslationsContext.Provider>
  );
};

export default TranslationsProvider;

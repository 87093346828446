import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

const useStyles = makeStyles(() => ({
  root: {
    boxSizing: 'border-box',
    margin: 'auto',
    width: '100%'
  },
  padding: {
    padding: '0 16px'
  },
  desktop: {
    maxWidth: 960
  },
  tablet: {
    maxWidth: 600
  },
  mobile: {
    maxWidth: 400
  }
}));

export const useAppWidthClass = (
  noPaddingDesktop?: boolean,
  noPaddingTablet?: boolean,
  noPaddingMobile?: boolean
): {
  widthClass: string;
  isTabletWidth: boolean;
  isMobileWidth: boolean;
  isDesktopWidth: boolean;
} => {
  const classes = useStyles();
  const theme = useTheme();
  const isTabletWidth = useMediaQuery(theme.breakpoints.only('sm'), {
    noSsr: true
  });
  const isMobileWidth = useMediaQuery(theme.breakpoints.down('sm'), {
    noSsr: true
  });
  const isDesktopWidth = !isTabletWidth && !isMobileWidth;

  return {
    widthClass: clsx(
      classes.root,
      isDesktopWidth && classes.desktop,
      isDesktopWidth && !noPaddingDesktop && classes.padding,
      isTabletWidth && classes.tablet,
      isTabletWidth && !noPaddingTablet && classes.padding,
      isMobileWidth && classes.mobile,
      isMobileWidth && !noPaddingMobile && classes.padding
    ),
    isTabletWidth,
    isMobileWidth,
    isDesktopWidth
  };
};

export const useSessionStorage = <T>(
  key: string,
  defaultValue: T
): [T, Dispatch<SetStateAction<T>>] => {
  const [value, setValue] = useState<T>(() => {
    const saved = sessionStorage.getItem(key);
    const initial = saved ? JSON.parse(saved) : null;
    return initial || defaultValue;
  });

  useEffect(() => {
    sessionStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);

  return [value, setValue];
};

import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import { alpha } from '@mui/material';
import { useDragControls } from 'framer-motion';

import { CustomIcon, Translation } from '@troy/shared/src/components/common';

import { useAppWidthClass } from '../../../utils/hooks';
import { widgetHeaderHeight } from '../../../assets/styles/jss_functions';
import { ArrowLeftIcon } from '../Icons';
import { Button, MotionGridItem } from '..';

const headerIndent = 20;
const useStyles = makeStyles(theme => ({
  hidden: {
    position: 'absolute',
    left: 0,
    right: 0,
    margin: 0
  },
  wrapper: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  extended: {
    maxWidth: 'none'
  },
  motionWrapper: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    flex: 1
  },
  motionWrapperExtended: {
    marginBottom: '40px'
  },
  header: {
    position: 'relative',
    height: widgetHeaderHeight,
    padding: headerIndent,
    background: alpha(theme.palette.background.default, 0.1),
    color: theme.palette.secondary.contrastText,
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderTopLeftRadius: theme.shape.borderRadius * 6,
    borderTopRightRadius: theme.shape.borderRadius * 6
  },
  headerExtended: {
    justifyContent: 'center',
    cursor: 'default'
  },
  head: {
    display: 'flex',
    alignItems: 'center'
  },
  name: {
    fontWeight: 'bold',
    margin: '0 20px'
  },
  icon: {
    width: 27,
    height: 27,
    fill: theme.palette.secondary.contrastText
  },
  action: {
    position: 'absolute',
    right: theme.spacing(1)
  },
  actionExtended: {
    right: theme.spacing(2)
  },
  actionButton: {
    padding: 0
  },
  actionIcon: {
    color: theme.palette.secondary.contrastText
  },
  content: {
    height: '100%',
    flex: 1,

    '& > *': {
      height: '100%',
      flex: 1
    },
    '& > :first-child': {
      padding: 8
    }
  },
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flex: 1,
    background: theme.palette.background.default,
    borderBottomLeftRadius: theme.shape.borderRadius * 6,
    borderBottomRightRadius: theme.shape.borderRadius * 6
  },
  contentExtended: {
    display: 'flex',
    flexDirection: 'column',

    '& > :first-child': {
      padding: '0',
      borderBottomLeftRadius: theme.shape.borderRadius * 6,
      borderBottomRightRadius: theme.shape.borderRadius * 6
    }
  },
  buttons: {
    padding: 8,
    display: 'flex',

    '& > :not(:last-child)': {
      marginRight: 16
    }
  },
  back: {
    border: `1px solid ${theme.palette.primary.main} !important`
  },
  button: {
    borderRadius: theme.shape.borderRadius * 5
  }
}));

const Widget = ({
  id,
  children,
  className,
  icon: Icon,
  name,
  actionIcon: ActionIcon,
  onActionClick,

  isExtended,

  buttonName,
  buttonNameTranslated = true,
  isButtonDisabled,
  onButtonClick,

  onBackClick,
  onClick,
  'data-cy': dataCy,
  index,
  visibility = true,
  disableAnimation,
  widgetsNumber,
  setPosition,
  moveItem,
  setDragging,
  isDragging,
  style
}) => {
  const classes = useStyles();
  const dragControls = useDragControls();
  const { isThreeColumnWidth, isTwoColumnWidth } = useAppWidthClass();

  return (
    <div
      className={clsx(
        !visibility && !isThreeColumnWidth && classes.hidden,
        classes.wrapper,
        isExtended && classes.extended,
        className
      )}
      data-cy={`widget${dataCy ? ' ' + dataCy : ''}`}
      style={style}
    >
      <MotionGridItem
        id={id}
        index={index}
        widgetsNumber={widgetsNumber}
        isExtended={isExtended}
        visibility={visibility}
        className={clsx(
          classes.motionWrapper,
          isExtended && classes.motionWrapperExtended
        )}
        disableAnimation={disableAnimation}
        setPosition={setPosition}
        setDragging={setDragging}
        isDragging={isDragging}
        moveItem={moveItem}
        dragControls={dragControls}
        dragDisabled={!isTwoColumnWidth}
      >
        {!(Icon || name || ActionIcon || onActionClick) || (
          <div
            onPointerDown={e => {
              dragControls.start(e);
            }}
            className={clsx(
              classes.header,
              isExtended && classes.headerExtended
            )}
          >
            {(Icon || name) && (
              <div className={classes.head}>
                {Icon ? <Icon className={classes.icon} /> : null}
                {!!name && (
                  <Translation
                    keyIfNoValue
                    component="h4"
                    className={classes.name}
                  >
                    {name}
                  </Translation>
                )}
              </div>
            )}
            {(ActionIcon || onActionClick) && (
              <div
                className={clsx(
                  classes.action,
                  isExtended && classes.actionExtended
                )}
              >
                <Button
                  onClick={onActionClick}
                  className={classes.actionButton}
                  variant="icon"
                  data-cy="button_remove"
                >
                  {ActionIcon ? (
                    <ActionIcon className={classes.actionIcon} />
                  ) : (
                    <CustomIcon
                      icon="close"
                      variant="secondaryContrast"
                      size="large"
                    />
                  )}
                </Button>
              </div>
            )}
          </div>
        )}
        <div onClick={onClick} className={classes.contentWrapper}>
          <div
            className={clsx(
              classes.content,
              isExtended && classes.contentExtended
            )}
          >
            {children}
          </div>
          {!isExtended && (buttonName || onBackClick) ? (
            <div className={classes.buttons}>
              {onBackClick ? (
                <Button
                  className={classes.back}
                  onClick={onBackClick}
                  variant="icon"
                >
                  <ArrowLeftIcon />
                </Button>
              ) : null}
              {buttonName ? (
                <Button
                  color="primary"
                  variant="contained"
                  onClick={onButtonClick}
                  className={classes.button}
                  disabled={isButtonDisabled}
                  fullWidth
                >
                  {buttonNameTranslated ? (
                    <Translation component="span">{buttonName}</Translation>
                  ) : (
                    buttonName
                  )}
                </Button>
              ) : null}
            </div>
          ) : null}
        </div>
      </MotionGridItem>
    </div>
  );
};

export default Widget;

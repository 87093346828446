import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Button, Theme } from '@mui/material';

import { CustomIcon, Text } from '../../common';

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    minWidth: 111,
    position: 'absolute',
    right: theme.spacing(2),
    bottom: theme.spacing(1.25),
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    background: theme.palette.grey[100],
    borderRadius: Number(theme.shape.borderRadius) * 8,
    border: 0,
    cursor: 'pointer',
    transition: theme.transitions.custom.fast,
    textTransform: 'none',
    boxShadow: 'none',

    '&:hover': {
      boxShadow: theme.customShadows.active
    }
  },
  icon: {
    transform: 'rotate(180deg)',
    marginRight: theme.spacing(2)
  }
}));

interface NotificationScrollButtonProps {
  onClick: () => void;
}

const NotificationScrollButton: React.FC<NotificationScrollButtonProps> = ({
  onClick
}): React.ReactElement => {
  const classes = useStyles();

  return (
    <Button
      className={classes.button}
      onClick={onClick}
      variant="contained"
      color="inherit"
    >
      <CustomIcon
        className={classes.icon}
        size="small"
        icon="expandMore"
        variant="textPrimary"
      />
      <Text translated component="span" variant="body2Bold" color="textHint">
        POSTBOX_NESTEDCHAT_TOTOP
      </Text>
    </Button>
  );
};

export default NotificationScrollButton;

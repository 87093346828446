import React from 'react';
import { makeStyles } from '@mui/styles';

import { CopyTooltip } from '@troy/shared/src/components/common';
import { LabeledValueCard } from '../../../../../common';
import {
  widgetWidth2Down,
  widgetWidth3Down
} from '../../../../../../assets/styles/jss_functions';

const useStyles = makeStyles(theme => ({
  labels: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',

    ...widgetWidth3Down({
      display: 'flex',
      flexDirection: 'row',
      marginTop: 16,
      marginLeft: -8
    }),
    ...widgetWidth2Down({
      marginLeft: -5,
      marginBottom: 8
    }),
    '& > :not(:last-child)': {
      marginBottom: 8
    }
  },
  label: {
    cursor: 'pointer',
    width: '100%',

    ...widgetWidth3Down({
      width: 'unset',
      marginRight: 16
    }),
    ...widgetWidth2Down({
      flex: 1
    })
  }
}));

const UserInfoLabels = ({ userInfo }) => {
  const classes = useStyles();

  return (
    <div className={classes.labels}>
      <CopyTooltip value={userInfo.caseId}>
        <LabeledValueCard
          className={classes.label}
          label="CP_INDIVIDUALCASE_INFOBOX_FILENUMBER"
          value={userInfo.caseId}
        />
      </CopyTooltip>
      <CopyTooltip value={userInfo.customerId}>
        <LabeledValueCard
          className={classes.label}
          label="CP_INDIVIDUALCASE_INFOBOX_CUSTOMERNUMBER"
          value={userInfo.customerId}
        />
      </CopyTooltip>
    </div>
  );
};

export default UserInfoLabels;

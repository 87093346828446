import React from 'react';
import clsx from 'clsx';
import { Menu, MenuItem } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { CustomIcon, Translation } from '@troy/shared/src/components/common';

import { Button } from '../../../../../common';

const useStyles = makeStyles(theme => ({
  button: {
    width: '100%',
    borderRadius: theme.shape.borderRadius * 2
  },
  tabLabelIcon: {
    fill: '#fff !important',
    height: '15px !important',
    marginBottom: '4px'
  },
  menu: {
    width: '300px'
  },
  buttonRow: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    justifyContent: 'center'
  },
  buttonWrappper: {
    display: 'flex',
    alignItems: 'center',
    width: '100%'
  },
  menuItem: {
    color: theme.palette.primary.main
  },
  menuRow: {
    display: 'flex',
    alignItems: 'center'
  },
  moreIcon: {
    marginBottom: '4px',
    height: '19px',
    padding: '0 10px',
    borderLeft: `2px solid ${theme.palette.primary.contrastText}`,

    '& svg': {
      paddingBottom: '3px'
    }
  }
}));

const HeaderSelect = ({ value, options, onChange }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const selectedTab = options.find(option => option.value === value);
  const SelectedIcon = selectedTab && selectedTab.icon;
  return (
    <>
      <Button
        aria-controls="customized-menu"
        aria-haspopup="true"
        variant="contained"
        color="primary"
        onClick={handleClick}
        className={classes.button}
      >
        {selectedTab && (
          <div className={classes.buttonWrappper}>
            <div className={classes.buttonRow}>
              <SelectedIcon className={classes.tabLabelIcon} />
              <Translation keyIfNoValue>{selectedTab.label}</Translation>
            </div>
            <div className={classes.moreIcon}>
              <CustomIcon
                icon="expandMore"
                size="small"
                variant="primaryContrast"
              />
            </div>
          </div>
        )}
      </Button>
      <Menu
        classes={{ paper: classes.menu }}
        keepMounted
        anchorEl={anchorEl}
        id="customized-menu"
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        {options.map(({ label, value, icon: Icon }) => (
          <MenuItem
            key={value}
            onClick={event => {
              onChange && onChange(event, value);
              handleClose();
            }}
            className={classes.menuItem}
          >
            <div className={classes.menuRow}>
              <Icon className={clsx(classes.tabLabelIcon, classes.menuIcon)} />
              <Translation keyIfNoValue>{label}</Translation>
            </div>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default HeaderSelect;

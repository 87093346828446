import { createReducer } from 'redux-act';

import * as actions from '../actions/translations';

import { mapToObj } from '../../utils/array';
import { codeFromFullCode, DISABLED_LANG_LIST } from '../../utils/translations';
import { BROWSER_LOCALE } from '../../constants/browser';

const mapTypeToText = arr => (arr ? mapToObj(arr, 'type', 'text') : {});

const DEFAULT_STATE = {
  availableLanguages: null,
  language: null || BROWSER_LOCALE,
  hasStaticTexts: false,
  hasCustomerTexts: false,
  translations: {}
};

export default createReducer(
  {
    [actions.setTranslations]: (state, { translations, isStaticText }) => ({
      ...state,
      hasStaticTexts: state.hasStaticTexts || isStaticText,
      hasCustomerTexts: state.hasCustomerTexts || !isStaticText,
      translations: {
        ...state.translations,
        ...mapTypeToText(translations)
      }
    }),
    [actions.translationsReset]: () => {
      return DEFAULT_STATE;
    },
    [actions.availableLanguagesReceived]: (state, availableLanguages) => ({
      ...state,
      availableLanguages: availableLanguages.filter(
        lang =>
          !DISABLED_LANG_LIST.find(
            code => code === codeFromFullCode(lang.fullCode)
          )
      )
    }),
    [actions.setLanguageFinal]: (state, language) => ({
      ...state,
      language
    })
  },
  DEFAULT_STATE
);

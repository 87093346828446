import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material';

import {
  ActionButton,
  CustomIcon,
  FormCheckbox,
  Text,
  Translation
} from '../index';
import { APINotificationVisibility } from '../../../types/notifications';

const useStyles = makeStyles((theme: Theme) => ({
  menu: {
    padding: `${theme.spacing(1)} 0`,
    background: theme.palette.primary.contrastText,
    borderRadius: Number(theme.shape.borderRadius) * 4
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    padding: `${theme.spacing(1.5)} ${theme.spacing(2)}`
  },
  checkbox: {
    marginTop: theme.spacing(-1),
    marginBottom: theme.spacing(-1)
  },
  checkboxLabel: {
    paddingLeft: theme.spacing(1)
  },
  text: {
    marginLeft: theme.spacing(2),
    whiteSpace: 'nowrap'
  },
  buttons: {
    display: 'flex',
    justifyContent: 'center',
    padding: `${theme.spacing(2)} ${theme.spacing(2)} ${theme.spacing(
      1
    )} ${theme.spacing(2)}`,

    ' & > *:first-child:not(:last-child)': {
      width: '100%',
      marginRight: theme.spacing(0.5)
    },
    ' & > *:last-child:not(:first-child)': {
      display: 'flex',
      width: '100%',
      marginLeft: theme.spacing(0.5)
    }
  },
  button: {
    width: '100%',
    height: 32,
    margin: 'auto'
  }
}));

const resolveValue = (
  visibility: APINotificationVisibility,
  id: 'customer' | 'client' | 'internal'
): boolean => {
  if (id === 'customer') {
    return visibility === 'ACTIVE';
  } else if (id === 'client') {
    return ['ACTIVE', 'HIDDEN_CUSTOMER'].includes(visibility);
  } else if (id === 'internal') {
    return ['ACTIVE', 'HIDDEN_CUSTOMER', 'HIDDEN_EXTERNAL'].includes(
      visibility
    );
  }
};

interface PostBoxItemContextMenuProps {
  editEnabled?: boolean;
  onSaveVisibilityChanges: (state: string) => void;
  itemVisibility: APINotificationVisibility;
  isItemVisibilityLoading: boolean;
}

interface Entry {
  id: 'customer' | 'client' | 'internal';
  label: string;
  storedValue: boolean;
  value: boolean;
  initialDisabled: boolean;
  disabled: boolean;
  enabledKey: APINotificationVisibility;
  disabledKey: APINotificationVisibility;
}

const resolveEntries = (itemVisibility: APINotificationVisibility): Entry[] => [
  {
    id: 'customer',
    label: 'UNIC_PARTIES_CUSTOMER',
    storedValue: resolveValue(itemVisibility, 'customer'),
    value: resolveValue(itemVisibility, 'customer'),
    initialDisabled: !resolveValue(itemVisibility, 'client'),
    disabled: !resolveValue(itemVisibility, 'client'),
    enabledKey: 'ACTIVE',
    disabledKey: 'HIDDEN_CUSTOMER'
  },
  {
    id: 'client',
    label: 'UNIC_PARTIES_CLIENT',
    storedValue: resolveValue(itemVisibility, 'client'),
    value: resolveValue(itemVisibility, 'client'),
    initialDisabled: !resolveValue(itemVisibility, 'internal'),
    disabled: !resolveValue(itemVisibility, 'internal'),
    enabledKey: 'HIDDEN_CUSTOMER',
    disabledKey: 'HIDDEN_EXTERNAL'
  },
  {
    id: 'internal',
    label: 'UNIC_PARTIES_AGENTS',
    storedValue: resolveValue(itemVisibility, 'internal'),
    value: resolveValue(itemVisibility, 'internal'),
    initialDisabled: false,
    disabled: false,
    enabledKey: 'HIDDEN_EXTERNAL',
    disabledKey: 'HIDDEN_INTERNAL'
  }
];

const PostBoxItemContextMenu = React.forwardRef<
  HTMLDivElement,
  PostBoxItemContextMenuProps
>(
  (
    {
      editEnabled,
      onSaveVisibilityChanges,
      itemVisibility,
      isItemVisibilityLoading
    },
    ref
  ) => {
    const classes = useStyles();

    const [isEdit, setIsEdit] = useState(false);
    const [visibilityValue, setVisibilityValue] = useState(itemVisibility);
    const [entries, setEntries] = useState<Entry[]>(
      resolveEntries(itemVisibility)
    );

    useEffect(() => {
      setVisibilityValue(itemVisibility);
      setEntries(resolveEntries(itemVisibility));
    }, [itemVisibility]);

    const onRowValueClick = (index: number): void => {
      const e = JSON.parse(JSON.stringify(entries));
      if (!e[index].value) {
        e[index].value = true;
        if (index - 1 >= 0) {
          e[index - 1].disabled = false;
        }

        setVisibilityValue(e[index].enabledKey);
      } else {
        e[index].value = false;
        for (let i = 0; i < index; i++) {
          e[i].value = false;
          e[i].disabled = true;
        }
        setVisibilityValue(e[index].disabledKey);
      }
      setEntries(e);
    };

    const handleEdit = (): void => setIsEdit(true);

    const handleCancel = (): void => {
      setVisibilityValue(itemVisibility);
      entries.forEach(e => {
        e.value = e.storedValue;
        e.disabled = e.initialDisabled;
      });
      setIsEdit(false);
    };

    const handleSave = (): void => {
      if (visibilityValue !== itemVisibility) {
        onSaveVisibilityChanges(visibilityValue);
      }
      setIsEdit(false);
    };

    const showEdit = isEdit || isItemVisibilityLoading;

    return (
      <div ref={ref} className={clsx(classes.menu)}>
        <div className={classes.row}>
          <CustomIcon icon="visibleIcon" size="small" variant="textPrimary" />
          <Text
            translated
            className={classes.text}
            component="h3"
            variant="body2Bold"
            color="textPrimary"
          >
            UNIC_UXCOPIES_COPY_WHOCANSEE
          </Text>
        </div>
        {entries.map(({ id, label, storedValue, value, disabled }, index) => (
          <div key={id} className={classes.row}>
            {!showEdit && (
              <>
                <CustomIcon
                  icon={storedValue ? 'confirm' : 'dismiss'}
                  size="small"
                  variant={storedValue ? 'primary' : 'warningDark'}
                />
                <Text
                  translated
                  className={classes.text}
                  component="span"
                  variant="body2"
                  color="textPrimary"
                >
                  {label}
                </Text>
              </>
            )}
            {showEdit && (
              <FormCheckbox
                id={`postbox_item_context_menu_option_${id}`}
                className={classes.checkbox}
                labelClassName={classes.checkboxLabel}
                variant="normal"
                color="textPrimary"
                label={label}
                value={value}
                disabled={disabled || isItemVisibilityLoading}
                onClick={(): void => onRowValueClick(index)}
              />
            )}
          </div>
        ))}
        {editEnabled && (
          <div className={classes.buttons}>
            {!showEdit && (
              <ActionButton
                id="postbox_item_context_menu_edit"
                variant="text"
                color="textPrimary"
                onClick={handleEdit}
              >
                <Translation keyIfNoValue isFragment>
                  CP_INDIVIDUALCASE_EDIT
                </Translation>
              </ActionButton>
            )}
            {showEdit && (
              <>
                <div>
                  <ActionButton
                    className={classes.button}
                    id="postbox_item_context_menu_cancel"
                    color="secondary"
                    variant="outlined"
                    onClick={handleCancel}
                    disabled={isItemVisibilityLoading}
                  >
                    <Translation keyIfNoValue isFragment>
                      ALL_UXCOPIES_BTN_CANCEL
                    </Translation>
                  </ActionButton>
                </div>
                <div>
                  <ActionButton
                    className={classes.button}
                    id="postbox_item_context_menu_save"
                    onClick={handleSave}
                    isLoading={isItemVisibilityLoading}
                  >
                    <Translation keyIfNoValue isFragment>
                      ALL_UXCOPIES_BTN_SAVE
                    </Translation>
                  </ActionButton>
                </div>
              </>
            )}
          </div>
        )}
      </div>
    );
  }
);
PostBoxItemContextMenu.displayName = 'PostBoxItemContextMenu';

export default PostBoxItemContextMenu;

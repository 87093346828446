import { createAction } from 'redux-act';

export const getLiabilityCloseReasons = createAction(
  'Get liability close reasons'
);
export const liabilityCloseReasonsReceived = createAction(
  'Liability close reasons received'
);
export const closeLiability = createAction('Close liability');
export const setLiabilityClosed = createAction('Set liability closed');

export const reportCreditMemo = createAction('Report credit memo');
export const reportPayment = createAction('Report payment');
export const contactForm = createAction('Contact Form');
export const onHold = createAction('On Hold');

export const resetLiability = createAction('Reset liability');

import { WhitelabelConfType } from '../../types/whitelabel';

const conf: WhitelabelConfType = {
  agentAppEnabled: true,
  customerAppEnabled: true,
  clientAppEnabled: true,
  brand: null,
  brandName: 'Troy',
  countyExperience: 'nl_NL',
  clientAppLoginLogoHeight: 52,
  clientAppHeaderLogoHeight: 35,
  clientAppFooterText: {
    de: 'CP_FOOTER_COPYRIGHT',
    en: 'CP_FOOTER_COPYRIGHT',
    nl: 'CP_FOOTER_COPYRIGHT',
    fr: 'CP_FOOTER_COPYRIGHT'
  },
  agentAppBackgroundGradient:
    'linear-gradient(to top, rgba(0, 0, 0, 0.26), rgba(0, 0, 0, 0.5)) fixed,radial-gradient(circle at 0 0, #ac2bed, #540fc9, #ac2bed) fixed',
  agentAppHeaderBackgroundGradient:
    'radial-gradient(circle at 100% 100%, #ac2bed, #540fc9 64%, #ac2bed 100%)',
  agentAppLaneBackgroundGradient:
    'radial-gradient(117.92% 200% at 106.25% 153.75%, #AC2BED 0%, #540FC9 23.17%, #AC2BED 100%)',
  agentAppHeaderLogoHeight: 32,
  agentAppLoginLogoHeight: 54,
  agentAppErrorLogoHeight: 40,
  agentAppFooterText: {
    de: 'UNIC_FOOTER_COPYRIGHT',
    en: 'UNIC_FOOTER_COPYRIGHT',
    nl: 'UNIC_FOOTER_COPYRIGHT',
    fr: 'UNIC_FOOTER_COPYRIGHT'
  },
  agentAppWelcomeText: {
    de: 'Welcome back to troy_agent!',
    en: 'Welcome back to troy_agent!',
    nl: 'Welcome back to troy_agent!',
    fr: 'Welcome back to troy_agent!'
  },
  customerPortalLogoHeight: 40,
  customerPortalFooterLogoHeight: 34,
  customerPortalDirectorName: 'Dennis Wever',
  customerPortalDirectorDescriptionKey: {
    de: 'TROY_DIRECTOR',
    en: 'TROY_DIRECTOR',
    nl: 'TROY_DIRECTOR',
    tr: 'TROY_DIRECTOR',
    sv: 'TROY_DIRECTOR',
    fr: 'TROY_DIRECTOR'
  },
  customerPortalHomeHeaderKey: {
    de: 'HEADER',
    en: 'HEADER',
    nl: 'HEADER',
    tr: 'HEADER',
    sv: 'HEADER',
    fr: 'HEADER'
  },
  customerPortalOnBehalfOfClientKey: {
    de: 'TROY_ON_BEHALF',
    en: 'TROY_ON_BEHALF',
    nl: 'TROY_ON_BEHALF',
    tr: 'TROY_ON_BEHALF',
    sv: 'TROY_ON_BEHALF',
    fr: 'TROY_ON_BEHALF'
  },
  customerPortalGreetingMainKey: {
    de: 'MAIN',
    en: 'MAIN',
    nl: 'MAIN',
    tr: 'MAIN',
    sv: 'MAIN',
    fr: 'MAIN'
  },
  customerPortalGreetingMainB2BKey: {
    de: 'MAIN_B2B',
    en: 'MAIN_B2B',
    nl: 'MAIN_B2B',
    tr: 'MAIN_B2B',
    sv: 'MAIN_B2B',
    fr: 'MAIN_B2B'
  },
  customerPortalGreetingMainPaidKey: {
    de: 'MAIN_PAID',
    en: 'MAIN_PAID',
    nl: 'MAIN_PAID',
    tr: 'MAIN_PAID',
    sv: 'MAIN_PAID',
    fr: 'MAIN_PAID'
  },
  customerPortalContactBar: {
    phoneLabel: 'TROY_PHONE',
    phoneValue: 'TROY_PHONE',
    whatsappLabel: 'Whatsapp',
    whatsappValue: 'TROY_WHATSAPP',
    SMSLabel: 'SMS',
    SMSValue: 'TROY_SMS',
    emailLabel: 'E-Mail',
    emailValue: 'TROY_EMAIL',
    twitterLabel: 'Twitter',
    twitterValue: 'TROY_TWITTER'
  },
  customerPortalCurrencyLocalOverride: null,
  customerPortalHeaderBackgroundGradient:
    'radial-gradient(117.92% 200% at 106.25% 153.75%, #AC2BED 0%, #540FC9 23.17%, #AC2BED 100%)',
  customerPortalModuleRadialBackground:
    'radial-gradient(117.92% 200% at 106.25% 153.75%, #AC2BED 0%, #540FC9 23.17%, #AC2BED 100%)',
  customerPortalTimelineBackground:
    'radial-gradient(100% 138.46% at 0% 0%, #1AC7B8 27.6%, #A3E9E3 100%)',
  customerPortalPaymentUrl: null,
  hasCustomFooter: false,
  footerData: null,
  botColor: '#8523FC',
  gradientColor1: '#AC2BED',
  gradientColor2: '#540FC9',
  primaryLight: '#A3E9E3',
  primaryMain: '#1AC7B8',
  primaryDark: '#164743',
  primaryContrastText: '#FFFFFF',
  secondaryLight: '#B39FCD',
  secondaryMain: '#5C18B1',
  secondaryDark: '#410E81',
  secondaryContrastText: '#FFFFFF',
  tertiaryMain: '#0178FB',
  tertiaryLight: '#F4F9FF',
  tertiaryDark: '#053172',
  tertiaryContrastText: '#FFFFFF',
  warningMain: '#FFBC32',
  warningLight: '#FFBC32',
  warningDark: '#EB6767',
  textPrimary: '#313131',
  textSecondary: '#666666',
  textDisabled: '#CCCCCC',
  textHint: '#999999',
  backgroundDefault: '#FFFFFF',
  backgroundDark: 'rgba(0, 0, 0, 0.82)',
  actionHover: '#5C18B1',
  actionDisabled: '#E6E6E6',
  grey50: 'rgba(0, 0, 0, 0.82)',
  grey100: '#ECECEC',
  grey400: '#C6C6C6',
  grey500: '#B2B2B2',
  warningDarkFill: '#FDF0F0',
  warningDarkFocus: '#BD5454',
  communicationLane: '#F0F0F0',
  communicationBg: '#F8F8F8',
  actionsInfo: '#F6F6F6',
  warningLightHover: '#E9AC2F',
  h1RegularFontFamily: 'regular',
  h1RegularFontWeight: 400,
  h1RegularFontSize: '2.5rem',
  h1RegularLineHeight: 1.2,
  h1RegularLetterSpacing: 'normal',
  h1FontFamily: 'bold',
  h1FontWeight: 400,
  h1FontSize: '3rem',
  h1LineHeight: 1.20833333333,
  h1LetterSpacing: 'normal',
  h2RegularFontFamily: 'regular',
  h2RegularFontWeight: 400,
  h2RegularFontSize: '1.5rem',
  h2RegularLineHeight: 1.33333333333,
  h2RegularLetterSpacing: 'normal',
  h2FontFamily: 'bold',
  h2FontWeight: 400,
  h2FontSize: '2.25rem',
  h2LineHeight: 1.33333333333,
  h2LetterSpacing: 'normal',
  h3FontFamily: 'bold',
  h3FontWeight: 400,
  h3FontSize: '1.5rem',
  h3LineHeight: 1.33333333333,
  h3LetterSpacing: 'normal',
  h4FontFamily: 'bold',
  h4FontWeight: 400,
  h4FontSize: '1.125rem',
  h4LineHeight: 1.33333333333,
  h4LetterSpacing: 'normal',
  subtitle1FontFamily: 'regular',
  subtitle1FontWeight: 400,
  subtitle1FontSize: '0.625rem',
  subtitle1LineHeight: 1.6,
  subtitle1LetterSpacing: 'normal',
  subtitle2FontFamily: 'regular',
  subtitle2FontWeight: 400,
  subtitle2FontSize: '0.75rem',
  subtitle2LineHeight: 1.33333333333,
  subtitle2LetterSpacing: 'normal',
  subtitle2BoldFontFamily: 'bold',
  subtitle2BoldFontWeight: 400,
  subtitle2BoldFontSize: '0.75rem',
  subtitle2BoldLineHeight: 1.33333333333,
  subtitle2BoldLetterSpacing: 'normal',
  subtitle3FontFamily: 'regular',
  subtitle3FontWeight: 400,
  subtitle3FontSize: '0.75rem',
  subtitle3LineHeight: 1.5,
  subtitle3LetterSpacing: '0.6px',
  body1FontFamily: 'regular',
  body1FontWeight: 400,
  body1FontSize: '1rem',
  body1LineHeight: 1.5,
  body1LetterSpacing: 'normal',
  body1BoldFontFamily: 'bold',
  body1BoldFontWeight: 400,
  body1BoldFontSize: '1rem',
  body1BoldLineHeight: 1.5,
  body1BoldLetterSpacing: 'normal',
  body2FontFamily: 'regular',
  body2FontWeight: 400,
  body2FontSize: '0.875rem',
  body2LineHeight: 1.42857142857,
  body2LetterSpacing: '0.5px',
  body2BoldFontFamily: 'bold',
  body2BoldFontWeight: 400,
  body2BoldFontSize: '0.875rem',
  body2BoldLineHeight: 1.42857142857,
  body2BoldLetterSpacing: '0.5px',
  body3FontFamily: 'regular',
  body3FontWeight: 400,
  body3FontSize: '0.875rem',
  body3LineHeight: 1.714285,
  body3LetterSpacing: 'normal',
  numerical1FontWeight: 500,
  numerical1FontSize: '1rem',
  numerical1LineHeight: 1.5,
  numerical1LetterSpacing: 'normal',
  numerical2FontWeight: 500,
  numerical2FontSize: '0.875rem',
  numerical2LineHeight: 1.42857142857,
  numerical2LetterSpacing: 'normal',
  numerical3FontWeight: 500,
  numerical3FontSize: '0.625rem',
  numerical3LineHeight: 1.6,
  numerical3LetterSpacing: 'normal',
  customShadowsMenu: '0px 0px 12px rgba(98, 57, 130, 0.6)',
  customShadowsModule: '0px 0px 8px rgba(43, 21, 60, 0.2)',
  customShadowsLogin: '0px 0px 20px rgba(23, 77, 72, 0.7)',
  customShadowsActive: '0px 2px 12px rgba(0, 0, 0, 0.2)',
  customShadowsActivePrimary: '0px 2px 12px rgba(26, 199, 184, 0.25)',
  customShadowsActiveWarning: '0px 2px 12px rgba(75, 26, 26, 0.2)',
  customShadowsActiveWarningLight: '0px 2px 12px rgba(255, 188, 50, 0.2)',
  customShadowsFocus: '0px 2px 12px rgba(92, 24, 177, 0.2)',
  transitionFast: 'all ease-in-out 100ms',
  transitionMedium: 'all cubic-bezier(0.65, 0, 0.35, 1) 600ms',
  transitionHeader: 'top 50ms linear',
  spacing: 8,
  borderRadius: 4
};

export default conf;

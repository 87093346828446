import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';

import { Translation } from '@troy/shared/src/components/common';

import {
  languageSelector,
  portalFooterTextSelector
} from '../../../store/selectors';
import { DE, EN, NL } from '../../../constants/i18n/countries';
import {BE} from "@troy/unic/src/constants/browser";

export const FOOTER_LINK = {
  [EN]: 'https://www.troy-bleiben.com',
  [DE]: 'https://www.troy-bleiben.de',
  [NL]: 'https://www.troy.nl',
  [BE]: 'https://www.troy-incasso.be'
};

const useStyles = makeStyles(theme => ({
  root: {
    ...theme.typography.subtitle1,
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(0.75),
    color: theme.palette.secondary.contrastText,
    backgroundColor: theme.palette.grey['50']
  }
}));

const Footer = () => {
  const classes = useStyles();
  const locale = useSelector(languageSelector);
  const footerText = useSelector(portalFooterTextSelector);

  return (
    <footer>
      <a
        href={FOOTER_LINK[locale || DE]}
        target="_blank"
        rel="noopener noreferrer"
        className={classes.root}
      >
        <Translation keyIfNoValue>{footerText}</Translation>
        <span>{new Date().getFullYear()}</span>
      </a>
    </footer>
  );
};

export default Footer;

import axios from 'axios';

import client from './graphql';

export function randomCorrelationID() {
  const length = 8;
  const chars =
    '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';

  let result = 'CL_';
  for (let i = length; i > 0; --i) {
    result += chars[Math.floor(Math.random() * chars.length)];
  }
  // log to console to simplify issue tracking
  console.log('Correlation ID:', result);
  return result;
}

const promiseCatch = err => {
  const responseData = (err.response || {}).data;
  const responseStatus = (err.response || {}).status;

  let errorText = err.message;
  if (responseData) {
    if (responseStatus === 422) {
      errorText = responseData.detail;
    } else if (responseData.errors) {
      const errorsObj = responseData.errors;
      errorText = Object.values(errorsObj)[0][0];
    } else {
      errorText = responseData.title;
    }
  }
  throw new Error(errorText);
};

export const get = (url, { responseHeaders = false, ...config } = {}) =>
  axios
    .get(url, config)
    .catch(promiseCatch)
    .then((res = {}) => {
      if (responseHeaders) {
        return { data: res.data, responseHeaders: res.headers };
      } else {
        return res.data;
      }
    });
export const post = (url, data, config) =>
  axios
    .post(url, data, config)
    .catch(promiseCatch)
    .then((res = {}) => res.data);
export const put = (url, data) =>
  axios
    .put(url, data)
    .catch(promiseCatch)
    .then((res = {}) => res.data);
// export const delete = (url) => axios.delete(url).catch(promiseCatch);\
export const graphqlRequest = (query, headers = {}, variables) =>
  client(headers)
    .query({ query, variables })
    .catch(promiseCatch)
    .then((res = {}) => res.data);

export const authorizedGet = (url, token, config) =>
  get(url, {
    ...config,
    headers: { ...(config && config.headers), Authorization: `Bearer ${token}` }
  });
export const authorizedPost = (url, data, token, config) =>
  post(url, data, {
    ...config,
    headers: { ...(config && config.headers), Authorization: `Bearer ${token}` }
  });
export const authorizedGraphqlRequest = (query, token, variables) =>
  graphqlRequest(query, { Authorization: `Bearer ${token}` }, variables);

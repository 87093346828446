import { PaletteColorOptions } from '@mui/material/styles/createPalette';
import { createTheme, PaletteColor, Theme } from '@mui/material';
import { CSSProperties } from '@mui/styles';

const regularFont = '"CustomFont-Regular", sans-serif';
const boldFont = '"CustomFont-Bold", sans-serif';
const numericalFont = '"IBM Plex Mono", sans-serif';

declare module '@mui/material/styles/createPalette' {
  interface TypeText {
    primary: string;
    secondary: string;
    disabled: string;
    hint: string;
  }

  interface TypeBackground {
    default: string;
    paper: string;
    dark: string;
  }

  interface Palette {
    tertiary: PaletteColor;
    text: TypeText;
  }

  // eslint-disable-next-line
  interface PaletteOptions {
    tertiary?: PaletteColorOptions;
  }
}

declare module '@mui/material/styles' {
  interface TypographyVariants {
    h1Regular: CSSProperties;
    h2Regular: CSSProperties;
    body1Bold: CSSProperties;
    body2Bold: CSSProperties;
    body3: CSSProperties;
    numerical1: CSSProperties;
    numerical2: CSSProperties;
    numerical3: CSSProperties;
    subtitle2Bold: CSSProperties;
    subtitle3: CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    h1Regular?: CSSProperties;
    h2Regular?: CSSProperties;
    body1Bold?: CSSProperties;
    body2Bold?: CSSProperties;
    body3?: CSSProperties;
    numerical1?: CSSProperties;
    numerical2?: CSSProperties;
    numerical3?: CSSProperties;
    subtitle2Bold?: CSSProperties;
    subtitle3?: CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    h1Regular: true;
    h2Regular: true;
    body1Bold: true;
    body2Bold: true;
    body3: true;
    numerical1: true;
    numerical2: true;
    numerical3: true;
    subtitle2Bold: true;
    subtitle3: true;
  }
}

declare module '@mui/material/styles/createTransitions' {
  interface Transitions {
    custom: {
      fast: string;
      medium: string;
      header: string;
    };
  }

  interface TransitionsOptions {
    custom?: {
      fast: string;
      medium: string;
      header: string;
    };
  }
}

declare module '@mui/material/styles' {
  interface Theme {
    customColors: {
      warningDarkFill: string;
      warningDarkFocus: string;
      communicationLane: string;
      communicationBg: string;
      actionsInfo: string;
      warningLightHover: string;
      botColor: string;
      gradientColor1: string;
      gradientColor2: string;
    };
    customShadows: {
      menu: string;
      module: string;
      login: string;
      active: string;
      activePrimary: string;
      activeWarning: string;
      activeWarningLight: string;
      focus: string;
    };
  }
  interface ThemeOptions {
    customColors?: {
      warningDarkFill: string;
      warningDarkFocus: string;
      communicationLane: string;
      communicationBg: string;
      actionsInfo: string;
      warningLightHover: string;
      botColor: string;
      gradientColor1: string;
      gradientColor2: string;
    };
    customShadows?: {
      menu: string;
      module: string;
      login: string;
      active: string;
      activePrimary: string;
      activeWarning: string;
      activeWarningLight: string;
      focus: string;
    };
  }
}

type FontFamily = 'regular' | 'bold';

interface CustomThemeParams {
  primaryLight?: string;
  primaryMain?: string;
  primaryDark?: string;
  primaryContrastText?: string;
  secondaryLight?: string;
  secondaryMain?: string;
  secondaryDark?: string;
  secondaryContrastText?: string;
  tertiaryMain?: string;
  tertiaryLight?: string;
  tertiaryDark?: string;
  tertiaryContrastText?: string;
  warningMain?: string;
  warningLight?: string;
  warningDark?: string;
  textPrimary?: string;
  textSecondary?: string;
  textDisabled?: string;
  textHint?: string;
  backgroundDefault?: string;
  backgroundDark?: string;
  actionHover?: string;
  actionDisabled?: string;
  grey50?: string;
  grey100?: string;
  grey400?: string;
  grey500?: string;
  warningDarkFill?: string;
  warningDarkFocus?: string;
  communicationLane?: string;
  communicationBg?: string;
  actionsInfo?: string;
  warningLightHover?: string;
  botColor?: string;
  gradientColor1?: string;
  gradientColor2?: string;
  h1RegularFontFamily?: FontFamily;
  h1RegularFontWeight?: number;
  h1RegularFontSize?: string;
  h1RegularLineHeight?: number;
  h1RegularLetterSpacing?: string;
  h1FontFamily?: FontFamily;
  h1FontWeight?: number;
  h1FontSize?: string;
  h1LineHeight?: number;
  h1LetterSpacing?: string;
  h2RegularFontFamily?: FontFamily;
  h2RegularFontWeight?: number;
  h2RegularFontSize?: string;
  h2RegularLineHeight?: number;
  h2RegularLetterSpacing?: string;
  h2FontFamily?: FontFamily;
  h2FontWeight?: number;
  h2FontSize?: string;
  h2LineHeight?: number;
  h2LetterSpacing?: string;
  h3FontFamily?: FontFamily;
  h3FontWeight?: number;
  h3FontSize?: string;
  h3LineHeight?: number;
  h3LetterSpacing?: string;
  h4FontFamily?: FontFamily;
  h4FontWeight?: number;
  h4FontSize?: string;
  h4LineHeight?: number;
  h4LetterSpacing?: string;
  subtitle1FontFamily?: FontFamily;
  subtitle1FontWeight?: number;
  subtitle1FontSize?: string;
  subtitle1LineHeight?: number;
  subtitle1LetterSpacing?: string;
  subtitle2FontFamily?: FontFamily;
  subtitle2FontWeight?: number;
  subtitle2FontSize?: string;
  subtitle2LineHeight?: number;
  subtitle2LetterSpacing?: string;
  subtitle2BoldFontFamily?: FontFamily;
  subtitle2BoldFontWeight?: number;
  subtitle2BoldFontSize?: string;
  subtitle2BoldLineHeight?: number;
  subtitle2BoldLetterSpacing?: string;
  subtitle3FontFamily?: FontFamily;
  subtitle3FontWeight?: number;
  subtitle3FontSize?: string;
  subtitle3LineHeight?: number;
  subtitle3LetterSpacing?: string;
  body1FontFamily?: FontFamily;
  body1FontWeight?: number;
  body1FontSize?: string;
  body1LineHeight?: number;
  body1LetterSpacing?: string;
  body1BoldFontFamily?: FontFamily;
  body1BoldFontWeight?: number;
  body1BoldFontSize?: string;
  body1BoldLineHeight?: number;
  body1BoldLetterSpacing?: string;
  body2FontFamily?: FontFamily;
  body2FontWeight?: number;
  body2FontSize?: string;
  body2LineHeight?: number;
  body2LetterSpacing?: string;
  body2BoldFontFamily?: FontFamily;
  body2BoldFontWeight?: number;
  body2BoldFontSize?: string;
  body2BoldLineHeight?: number;
  body2BoldLetterSpacing?: string;
  body3FontFamily?: FontFamily;
  body3FontWeight?: number;
  body3FontSize?: string;
  body3LineHeight?: number;
  body3LetterSpacing?: string;
  numerical1FontWeight?: number;
  numerical1FontSize?: string;
  numerical1LineHeight?: number;
  numerical1LetterSpacing?: string;
  numerical2FontWeight?: number;
  numerical2FontSize?: string;
  numerical2LineHeight?: number;
  numerical2LetterSpacing?: string;
  numerical3FontWeight?: number;
  numerical3FontSize?: string;
  numerical3LineHeight?: number;
  numerical3LetterSpacing?: string;
  customShadowsMenu?: string;
  customShadowsModule?: string;
  customShadowsLogin?: string;
  customShadowsActive?: string;
  customShadowsActivePrimary?: string;
  customShadowsActiveWarning?: string;
  customShadowsActiveWarningLight?: string;
  customShadowsFocus?: string;
  transitionFast?: string;
  transitionMedium?: string;
  transitionHeader?: string;
  spacing?: number;
  borderRadius?: number;
}

const createMuiTroyTheme = ({
  primaryLight,
  primaryMain,
  primaryDark,
  primaryContrastText,
  secondaryLight,
  secondaryMain,
  secondaryDark,
  secondaryContrastText,
  tertiaryMain,
  tertiaryLight,
  tertiaryDark,
  tertiaryContrastText,
  warningMain,
  warningLight,
  warningDark,
  textPrimary,
  textSecondary,
  textDisabled,
  textHint,
  backgroundDefault,
  backgroundDark,
  actionHover,
  actionDisabled,
  grey50,
  grey100,
  grey400,
  grey500,
  warningDarkFill,
  warningDarkFocus,
  communicationLane,
  communicationBg,
  actionsInfo,
  warningLightHover,
  botColor,
  gradientColor1,
  gradientColor2,
  h1RegularFontFamily,
  h1RegularFontWeight,
  h1RegularFontSize,
  h1RegularLineHeight,
  h1RegularLetterSpacing,
  h1FontFamily,
  h1FontWeight,
  h1FontSize,
  h1LineHeight,
  h1LetterSpacing,
  h2RegularFontFamily,
  h2RegularFontWeight,
  h2RegularFontSize,
  h2RegularLineHeight,
  h2RegularLetterSpacing,
  h2FontFamily,
  h2FontWeight,
  h2FontSize,
  h2LineHeight,
  h2LetterSpacing,
  h3FontFamily,
  h3FontWeight,
  h3FontSize,
  h3LineHeight,
  h3LetterSpacing,
  h4FontFamily,
  h4FontWeight,
  h4FontSize,
  h4LineHeight,
  h4LetterSpacing,
  subtitle1FontFamily,
  subtitle1FontWeight,
  subtitle1FontSize,
  subtitle1LineHeight,
  subtitle1LetterSpacing,
  subtitle2FontFamily,
  subtitle2FontWeight,
  subtitle2FontSize,
  subtitle2LineHeight,
  subtitle2LetterSpacing,
  subtitle2BoldFontFamily,
  subtitle2BoldFontWeight,
  subtitle2BoldFontSize,
  subtitle2BoldLineHeight,
  subtitle2BoldLetterSpacing,
  subtitle3FontFamily,
  subtitle3FontWeight,
  subtitle3FontSize,
  subtitle3LineHeight,
  subtitle3LetterSpacing,
  body1FontFamily,
  body1FontWeight,
  body1FontSize,
  body1LineHeight,
  body1LetterSpacing,
  body1BoldFontFamily,
  body1BoldFontWeight,
  body1BoldFontSize,
  body1BoldLineHeight,
  body1BoldLetterSpacing,
  body2FontFamily,
  body2FontWeight,
  body2FontSize,
  body2LineHeight,
  body2LetterSpacing,
  body2BoldFontFamily,
  body2BoldFontWeight,
  body2BoldFontSize,
  body2BoldLineHeight,
  body2BoldLetterSpacing,
  body3FontFamily,
  body3FontWeight,
  body3FontSize,
  body3LineHeight,
  body3LetterSpacing,
  numerical1FontWeight,
  numerical1FontSize,
  numerical1LineHeight,
  numerical1LetterSpacing,
  numerical2FontWeight,
  numerical2FontSize,
  numerical2LineHeight,
  numerical2LetterSpacing,
  numerical3FontWeight,
  numerical3FontSize,
  numerical3LineHeight,
  numerical3LetterSpacing,
  customShadowsMenu,
  customShadowsModule,
  customShadowsLogin,
  customShadowsActive,
  customShadowsActivePrimary,
  customShadowsActiveWarning,
  customShadowsActiveWarningLight,
  customShadowsFocus,
  transitionFast,
  transitionMedium,
  transitionHeader,
  spacing,
  borderRadius
}: CustomThemeParams): Theme =>
  createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 800,
        lg: 1280,
        xl: 1920
      }
    },
    mixins: {
      toolbar: {
        minHeight: '80px'
      }
    },
    palette: {
      primary: {
        light: primaryLight || '#A3E9E3',
        main: primaryMain || '#1AC7B8',
        dark: primaryDark || '#164743',
        contrastText: primaryContrastText || '#FFFFFF'
      },
      secondary: {
        light: secondaryLight || '#B39FCD',
        main: secondaryMain || '#5C18B1',
        dark: secondaryDark || '#410E81',
        contrastText: secondaryContrastText || '#FFFFFF'
      },
      tertiary: {
        main: tertiaryMain || '#0178FB',
        light: tertiaryLight || '#F4F9FF',
        dark: tertiaryDark || '#053172',
        contrastText: tertiaryContrastText || '#FFFFFF'
      },
      warning: {
        main: warningMain || '#FFBC32',
        light: warningLight || '#FFBC32',
        dark: warningDark || '#EB6767'
      },
      text: {
        primary: textPrimary || '#313131',
        secondary: textSecondary || '#666666',
        disabled: textDisabled || '#CCCCCC',
        hint: textHint || '#999999'
      },
      background: {
        default: backgroundDefault || '#FFFFFF',
        dark: backgroundDark || 'rgba(0, 0, 0, 0.82)'
      },
      action: {
        hover: actionHover || '#5C18B1',
        disabled: actionDisabled || '#E6E6E6'
      },
      grey: {
        50: grey50 || 'rgba(0, 0, 0, 0.82)',
        100: grey100 || '#ECECEC',
        400: grey400 || '#C6C6C6',
        500: grey500 || '#B2B2B2'
      }
    },
    customColors: {
      warningDarkFill: warningDarkFill || '#FDF0F0',
      warningDarkFocus: warningDarkFocus || '#BD5454',
      communicationLane: communicationLane || '#F0F0F0',
      communicationBg: communicationBg || '#F8F8F8',
      actionsInfo: actionsInfo || '#F6F6F6',
      warningLightHover: warningLightHover || '#E9AC2F',
      botColor: botColor || '#8523FC',
      gradientColor1: gradientColor1 || '#AC2BED',
      gradientColor2: gradientColor2 || '#540FC9'
    },
    typography: {
      fontFamily: regularFont,
      h1Regular: {
        fontFamily: h1RegularFontFamily === 'bold' ? boldFont : regularFont,
        fontWeight: h1RegularFontWeight || 400,
        fontSize: h1RegularFontSize || '2.5rem', // 40px
        lineHeight: h1RegularLineHeight || 1.2,
        letterSpacing: h1RegularLetterSpacing || 'normal'
      },
      h1: {
        fontFamily: h1FontFamily === 'regular' ? regularFont : boldFont,
        fontWeight: h1FontWeight || 400,
        fontSize: h1FontSize || '3rem', // 48px
        lineHeight: h1LineHeight || 1.20833333333, // 58px
        letterSpacing: h1LetterSpacing || 'normal'
      },
      h2Regular: {
        fontFamily: h2RegularFontFamily === 'bold' ? boldFont : regularFont,
        fontWeight: h2RegularFontWeight || 400,
        fontSize: h2RegularFontSize || '1.5rem', // 24px
        lineHeight: h2RegularLineHeight || 1.33333333333, // 32px
        letterSpacing: h2RegularLetterSpacing || 'normal'
      },
      h2: {
        fontFamily: h2FontFamily === 'regular' ? regularFont : boldFont,
        fontWeight: h2FontWeight || 400,
        fontSize: h2FontSize || '2.25rem', // 36px
        lineHeight: h2LineHeight || 1.33333333333, // 48px
        letterSpacing: h2LetterSpacing || 'normal'
      },
      h3: {
        fontFamily: h3FontFamily === 'regular' ? regularFont : boldFont,
        fontWeight: h3FontWeight || 400,
        fontSize: h3FontSize || '1.5rem', // 24px
        lineHeight: h3LineHeight || 1.33333333333, // 32px
        letterSpacing: h3LetterSpacing || 'normal'
      },
      h4: {
        fontFamily: h4FontFamily === 'regular' ? regularFont : boldFont,
        fontWeight: h4FontWeight || 400,
        fontSize: h4FontSize || '1.125rem', // 18px
        lineHeight: h4LineHeight || 1.33333333333, // 24px
        letterSpacing: h4LetterSpacing || 'normal'
      },
      subtitle1: {
        fontFamily: subtitle1FontFamily === 'bold' ? boldFont : regularFont,
        fontWeight: subtitle1FontWeight || 400,
        fontSize: subtitle1FontSize || '0.625rem', // 10px
        lineHeight: subtitle1LineHeight || 1.6, // 16px
        letterSpacing: subtitle1LetterSpacing || 'normal'
      },
      subtitle2: {
        fontFamily: subtitle2FontFamily === 'bold' ? boldFont : regularFont,
        fontWeight: subtitle2FontWeight || 400,
        fontSize: subtitle2FontSize || '0.75rem', // 12px
        lineHeight: subtitle2LineHeight || 1.33333333333, // 16px
        letterSpacing: subtitle2LetterSpacing || 'normal'
      },
      subtitle2Bold: {
        fontFamily:
          subtitle2BoldFontFamily === 'regular' ? regularFont : boldFont,
        fontWeight: subtitle2BoldFontWeight || 400,
        fontSize: subtitle2BoldFontSize || '0.75rem', // 12px
        lineHeight: subtitle2BoldLineHeight || 1.33333333333, // 16px
        letterSpacing: subtitle2BoldLetterSpacing || 'normal'
      },
      subtitle3: {
        fontFamily: subtitle3FontFamily === 'bold' ? boldFont : regularFont,
        fontWeight: subtitle3FontWeight || 400,
        fontSize: subtitle3FontSize || '0.75rem', // 12px
        lineHeight: subtitle3LineHeight || 1.5, // 18px
        letterSpacing: subtitle3LetterSpacing || '0.6px'
      },
      body1: {
        fontFamily: body1FontFamily === 'bold' ? boldFont : regularFont,
        fontWeight: body1FontWeight || 400,
        fontSize: body1FontSize || '1rem', // 16px
        lineHeight: body1LineHeight || 1.5,
        letterSpacing: body1LetterSpacing || 'normal'
      },
      body1Bold: {
        fontFamily: body1BoldFontFamily === 'regular' ? regularFont : boldFont,
        fontWeight: body1BoldFontWeight || 400,
        fontSize: body1BoldFontSize || '1rem', // 16px
        lineHeight: body1BoldLineHeight || 1.5,
        letterSpacing: body1BoldLetterSpacing || 'normal'
      },
      body2: {
        fontFamily: body2FontFamily === 'bold' ? boldFont : regularFont,
        fontWeight: body2FontWeight || 400,
        fontSize: body2FontSize || '0.875rem', // 14px
        lineHeight: body2LineHeight || 1.42857142857, // 20px
        letterSpacing: body2LetterSpacing || 'normal'
      },
      body2Bold: {
        fontFamily: body2BoldFontFamily === 'regular' ? regularFont : boldFont,
        fontWeight: body2BoldFontWeight || 400,
        fontSize: body2BoldFontSize || '0.875rem', // 14px
        lineHeight: body2BoldLineHeight || 1.42857142857, // 20px
        letterSpacing: body2BoldLetterSpacing || 'normal'
      },
      body3: {
        fontFamily: body3FontFamily === 'bold' ? boldFont : regularFont,
        fontWeight: body3FontWeight || 400,
        fontSize: body3FontSize || '0.875rem', // 14px
        lineHeight: body3LineHeight || 1.714285, // 24px
        letterSpacing: body3LetterSpacing || 'normal'
      },
      numerical1: {
        fontFamily: numericalFont,
        fontWeight: numerical1FontWeight || 500,
        fontSize: numerical1FontSize || '1rem', // 16px
        lineHeight: numerical1LineHeight || 1.5, // 24px
        letterSpacing: numerical1LetterSpacing || 'normal'
      },
      numerical2: {
        fontFamily: numericalFont,
        fontWeight: numerical2FontWeight || 500,
        fontSize: numerical2FontSize || '0.875rem', // 14px
        lineHeight: numerical2LineHeight || 1.42857142857, // 20px
        letterSpacing: numerical2LetterSpacing || 'normal'
      },
      numerical3: {
        fontFamily: numericalFont,
        fontWeight: numerical3FontWeight || 500,
        fontSize: numerical3FontSize || '0.625rem', // 10px
        lineHeight: numerical3LineHeight || 1.6, // 16px
        letterSpacing: numerical3LetterSpacing || 'normal'
      }
    },
    customShadows: {
      menu: customShadowsMenu || '0px 0px 12px rgba(98, 57, 130, 0.6)',
      module: customShadowsModule || '0px 0px 8px rgba(43, 21, 60, 0.2)',
      login: customShadowsLogin || '0px 0px 20px rgba(23, 77, 72, 0.7)',
      active: customShadowsActive || '0px 2px 12px rgba(0, 0, 0, 0.2)',
      activePrimary:
        customShadowsActivePrimary || '0px 2px 12px rgba(26, 199, 184, 0.25)',
      activeWarning:
        customShadowsActiveWarning || '0px 2px 12px rgba(75, 26, 26, 0.2)',
      activeWarningLight:
        customShadowsActiveWarningLight ||
        '0px 2px 12px rgba(255, 188, 50, 0.2)',
      focus: customShadowsFocus || '0px 2px 12px rgba(92, 24, 177, 0.2)'
    },
    transitions: {
      custom: {
        fast: transitionFast || 'all ease-in-out 100ms',
        medium: transitionMedium || 'all cubic-bezier(0.65, 0, 0.35, 1) 600ms',
        header: transitionHeader || 'top 50ms linear'
      }
    },
    spacing: (val: number) => `${val * (spacing || 8)}px`,
    shape: {
      borderRadius: borderRadius || 4
    }
  });

export { createMuiTroyTheme };

/**
 * Documentation: https://github.com/aws/aws-amplify/tree/master/packages/amazon-cognito-identity-js
 */
import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserPool,
  CookieStorage
} from 'amazon-cognito-identity-js';
import * as AWS from 'aws-sdk';

import { post } from '../utils/api';
import config from '../config/aws-config';

const setAWSConfiguration = (region, identityPoolId) => {
  AWS.config.update({
    region: region,
    credentials: new AWS.CognitoIdentityCredentials({
      IdentityPoolId: identityPoolId
    }),
    accessKeyId: 'null',
    secretAccessKey: 'null'
  });
};

class CognitoService {
  constructor(userPoolId, clientId, region, identityPoolId) {
    this.localUserPool = new CognitoUserPool({
      UserPoolId: userPoolId,
      ClientId: clientId
    });
    this.cookieStorageConfig = {
      domain: window.location.hostname,
      secure: false,
      expires: 0.5
    };
    this.cookieUserPool = new CognitoUserPool({
      UserPoolId: userPoolId,
      ClientId: clientId,
      Storage: new CookieStorage(this.cookieStorageConfig)
    });

    this.userPool = userPoolId;
    this.region = region;
    this.identityPool = identityPoolId;

    this.completeUser = null;

    setAWSConfiguration(region, identityPoolId);
  }

  instantiateCognitoUser(email, userPool) {
    return new CognitoUser({
      Username: email,
      Pool: userPool,
      Storage:
        userPool === this.cookieUserPool
          ? new CookieStorage(this.cookieStorageConfig)
          : null
    });
  }

  loadCognitoUserFromLocalStorage() {
    return (
      this.cookieUserPool.getCurrentUser() ||
      this.localUserPool.getCurrentUser()
    );
  }

  getSession(cognitoUser) {
    return new Promise((resolve, reject) => {
      if (cognitoUser) {
        cognitoUser.getSession((error, session) => {
          if (error) {
            return reject(error);
          }
          if (!session.isValid()) {
            return reject(new Error('Session is invalid. Log in again.'));
          }
          resolve(session);
        });
      } else {
        resolve(null);
      }
    });
  }

  refreshSession(cognitoUser, session) {
    return new Promise((resolve, reject) => {
      if (cognitoUser) {
        cognitoUser.refreshSession(
          session.getRefreshToken(),
          (error, session) => {
            if (error) {
              return reject(error);
            }
            if (!session.isValid()) {
              return reject(new Error('Session is invalid. Log in again.'));
            }
            resolve(session);
          }
        );
      } else {
        resolve(null);
      }
    });
  }

  signup(email, password) {
    return this.cognitoService
      .signUp(email, password) // does not log in
      .then(() => this.login(email, password));
  }

  logIn(email, password, rememberUser, redirectCompletePassword) {
    if (!email || !password) {
      return Promise.reject(
        new Error('Email address and password are required')
      );
    }
    const cognitoUser = this.instantiateCognitoUser(
      email,
      rememberUser ? this.localUserPool : this.cookieUserPool
    );
    return new Promise((resolve, reject) => {
      cognitoUser.authenticateUser(
        new AuthenticationDetails({
          Username: email,
          Password: password
        }),
        {
          onSuccess: resolve,
          onFailure: err => {
            reject(err);
          },
          mfaRequired: (challengeName, challengeParam) => {
            cognitoUser['challengeName'] = challengeName;
            cognitoUser['challengeParam'] = challengeParam;
            resolve(cognitoUser);
          },
          newPasswordRequired: (userAttributes, requiredAttributes) => {
            delete userAttributes.email_verified;

            // unsure about this field, but I don't send this back
            delete userAttributes.phone_number_verified;
            this.completeUser = cognitoUser;
            redirectCompletePassword();
          }
        }
      );
    });
  }

  getCompleteUser() {
    return !!this.completeUser;
  }

  completePassword(password) {
    if (!password) {
      return Promise.reject(new Error('Password are required'));
    }
    return new Promise((resolve, reject) => {
      this.completeUser.completeNewPasswordChallenge(
        password,
        {},
        {
          onFailure: reject,
          onSuccess: () => {
            this.completeUser = null;
            return resolve();
          }
        }
      );
    });
  }

  logOut() {
    const user = this.loadCognitoUserFromLocalStorage();

    return new Promise((resolve, reject) => {
      if (user) user.signOut();
      resolve();
    });
  }

  sendForgotPasswordRequest(email) {
    return new Promise((resolve, reject) => {
      const user = this.instantiateCognitoUser(email, this.cookieUserPool);
      user.forgotPassword({
        onFailure: reject,
        onSuccess: () => resolve(user)
      });
    });
  }

  resetPassword(user, code, password) {
    return new Promise((resolve, reject) => {
      user.confirmPassword(code, password, {
        onFailure: reject,
        onSuccess: resolve
      });
    });
  }

  setCredentials(idToken) {
    const params = {
      IdentityPoolId: this.identityPool,
      Logins: {
        ['cognito-idp.' + this.region + '.amazonaws.com/' + this.userPool]:
          idToken
      }
    };
    AWS.config.credentials = new AWS.CognitoIdentityCredentials(params);

    return new Promise((resolve, reject) => {
      AWS.config.getCredentials(err => {
        if (err) {
          reject(err);
        }
        resolve();
      });
    }).then(() => {
      const creds = {
        sessionId: AWS.config.credentials.accessKeyId,
        sessionKey: AWS.config.credentials.secretAccessKey,
        sessionToken: AWS.config.credentials.sessionToken
      };

      const signInAmazonURL =
        'https://signin.aws.amazon.com/federation?Action=getSigninToken&SessionDuration=43200&Session=';

      let url = signInAmazonURL + encodeURIComponent(JSON.stringify(creds));
      return post(process.env.REACT_APP_URL, url, {
        headers: { Authorization: idToken }
      }).then((data = {}) => data.SigninToken);
    });
  }
}

export default new CognitoService(
  config.UserPoolId,
  config.ClientId,
  config.region,
  config.IdentityPoolId
);

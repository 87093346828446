import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import { svgFill } from '../../../utils/styles';
import ICONS, { SupportedIcon } from './iconData';

export type IconVariant =
  | 'light'
  | 'primary'
  | 'primaryContrast'
  | 'secondary'
  | 'secondaryMain'
  | 'secondaryContrast'
  | 'secondaryLight'
  | 'secondaryDark'
  | 'grey50'
  | 'warning'
  | 'warningDark'
  | 'warningLight'
  | 'textPrimary'
  | 'textSecondary'
  | 'disabled'
  | 'textDisabled'
  | 'hint'
  | 'textHint'
  | 'actionDisabled'
  | 'tertiary';

export type IconSize = 'xss' | 'xs' | 'small' | 'medium' | 'large' | 'custom';

const useStyles = makeStyles((theme: Theme) => ({
  custom: {
    flexShrink: 0
  },
  large: {
    width: 48,
    height: 48,
    flexShrink: 0
  },
  medium: {
    width: 32,
    height: 32,
    flexShrink: 0
  },
  small: {
    width: 24,
    height: 24,
    flexShrink: 0
  },
  xs: {
    width: 16,
    height: 16,
    flexShrink: 0
  },
  xss: {
    width: 12,
    height: 12,
    flexShrink: 0
  },
  light: {
    ...svgFill(theme.transitions.custom.fast, theme.palette.background.default)
  },
  primary: {
    ...svgFill(theme.transitions.custom.fast, theme.palette.primary.main)
  },
  primaryContrast: {
    ...svgFill(
      theme.transitions.custom.fast,
      theme.palette.primary.contrastText
    )
  },
  secondary: {
    ...svgFill(theme.transitions.custom.fast, theme.palette.secondary.main)
  },
  secondaryMain: {
    ...svgFill(theme.transitions.custom.fast, theme.palette.secondary.main)
  },
  secondaryContrast: {
    ...svgFill(
      theme.transitions.custom.fast,
      theme.palette.secondary.contrastText
    )
  },
  secondaryLight: {
    ...svgFill(theme.transitions.custom.fast, theme.palette.secondary.light)
  },
  secondaryDark: {
    ...svgFill(theme.transitions.custom.fast, theme.palette.secondary.dark)
  },
  grey50: {
    ...svgFill(theme.transitions.custom.fast, theme.palette.grey['50'])
  },
  warning: {
    ...svgFill(theme.transitions.custom.fast, theme.palette.warning.light)
  },
  warningDark: {
    ...svgFill(theme.transitions.custom.fast, theme.palette.warning.dark)
  },
  warningLight: {
    ...svgFill(theme.transitions.custom.fast, theme.palette.warning.light)
  },
  textPrimary: {
    ...svgFill(theme.transitions.custom.fast, theme.palette.text.primary)
  },
  textSecondary: {
    ...svgFill(theme.transitions.custom.fast, theme.palette.text.secondary)
  },
  disabled: {
    ...svgFill(theme.transitions.custom.fast, theme.palette.text.disabled)
  },
  textDisabled: {
    ...svgFill(theme.transitions.custom.fast, theme.palette.text.disabled)
  },
  hint: {
    ...svgFill(theme.transitions.custom.fast, theme.palette.text.hint)
  },
  textHint: {
    ...svgFill(theme.transitions.custom.fast, theme.palette.text.hint)
  },
  actionDisabled: {
    ...svgFill(theme.transitions.custom.fast, theme.palette.action.disabled)
  },
  tertiary: {
    ...svgFill(theme.transitions.custom.fast, theme.palette.tertiary.main)
  }
}));

interface IconProps {
  id?: string;
  className?: string;
  icon: SupportedIcon;
  size: IconSize;
  variant?: IconVariant;
  ariaHidden?: boolean;
}

const CustomIcon: React.FC<IconProps> = ({
  id,
  className,
  icon,
  size,
  variant,
  ariaHidden
}) => {
  const classes = useStyles();
  const SvgIcon = ICONS[icon];

  return (
    <SvgIcon
      id={id}
      className={clsx(classes[size], classes[variant || 'primary'], className)}
      aria-hidden={ariaHidden}
    />
  );
};

export default CustomIcon;

import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';
import { Collapse, Theme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';
import {
  convertUniCodes,
  formatText,
  MAX_COLLAPSED_TEXT_SIZE,
  onTextCopy
} from '../../../utils/post-box/utils';

import { Button, Translation } from '../index';
import PostBoxItemAddCopyIbanBic from './PostBoxItemAddCopyIbanBic';

interface PostBoxItemTextContentProps {
  content: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  content: {
    ...theme.typography.body2,
    paddingTop: theme.spacing(2),
    color: theme.palette.text.primary,
    wordBreak: 'break-word',
    whiteSpace: 'pre-line',

    '& a': {
      color: theme.palette.secondary.main
    }
  },
  copy: {
    cursor: 'pointer',
    '&:hover': {
      fontWeight: 'bolder'
    }
  },
  showMore: {
    ...theme.typography.subtitle1,
    alignSelf: 'flex-start',
    marginTop: theme.spacing(1),
    padding: 0,
    border: 0,
    background: 'none',
    color: theme.palette.text.secondary,
    textDecoration: 'underline',
    textAlign: 'left',
    cursor: 'pointer'
  },
  collapse: {
    position: 'relative'
  },
  fullTextHidden: {
    visibility: 'hidden'
  },
  fullTextVisible: {
    visibility: 'visible'
  },
  collapsedTextVisible: {
    position: 'absolute',
    left: 0,
    top: 0
  },
  collapsedTextHidden: {
    display: 'none'
  }
}));

const PostBoxItemTextContent: React.FC<PostBoxItemTextContentProps> = ({
  content
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const collapsedTextRef = useRef<HTMLDivElement>(null);
  const timerRef = useRef<number>(0);
  const [isOpen, setIsOpen] = useState(false);
  const [fullTextVisible, setFullTextVisible] = useState(false);
  const [collapsedTextHeight, setCollapsedTextHeight] = useState(0);

  useEffect(() => {
    return () => clearTimeout(timerRef.current);
  }, []);

  // collapsing needs to wait for animation to finish
  // before switching from full text to partial text
  useEffect(() => {
    if (isOpen) {
      setFullTextVisible(true);
    } else {
      const timeout = setTimeout(() => setFullTextVisible(false), 300);
      timerRef.current = timeout as unknown as number;
    }
  }, [isOpen]);

  const handleClick = (): void => {
    setIsOpen(!isOpen);
  };

  const onContentMount = (): void => {
    if (collapsedTextRef?.current?.offsetHeight ?? -1 > 0) {
      setCollapsedTextHeight(collapsedTextRef?.current?.offsetHeight ?? 0);
    }
  };

  const needsCollapse =
    convertUniCodes(content).length > MAX_COLLAPSED_TEXT_SIZE;

  const showMoreLessId = `case-view.communication.notification.show-${
    isOpen ? 'less' : 'more'
  }`;

  return (
    <>
      <div className={clsx(classes.content)}>
        {!needsCollapse && (
          <>
            <PostBoxItemAddCopyIbanBic
              text={formatText(content, isOpen, MAX_COLLAPSED_TEXT_SIZE)}
              onCopy={(): void => {
                onTextCopy(dispatch);
              }}
              className={classes.copy}
            />
          </>
        )}
        {needsCollapse && (
          <Collapse
            className={classes.collapse}
            in={isOpen}
            collapsedSize={collapsedTextHeight}
          >
            <div
              className={clsx(
                classes.fullTextHidden,
                fullTextVisible && classes.fullTextVisible
              )}
            >
              <PostBoxItemAddCopyIbanBic
                text={formatText(content, true, MAX_COLLAPSED_TEXT_SIZE)}
                onCopy={(): void => {
                  onTextCopy(dispatch);
                }}
                className={classes.copy}
              />
            </div>
            <div
              ref={collapsedTextRef}
              className={clsx(
                classes.collapsedTextVisible,
                fullTextVisible && classes.collapsedTextHidden
              )}
            >
              <PostBoxItemAddCopyIbanBic
                text={formatText(content, false, MAX_COLLAPSED_TEXT_SIZE)}
                onCopy={(): void => {
                  onTextCopy(dispatch);
                }}
                className={classes.copy}
                mountCallback={onContentMount}
              />
            </div>
          </Collapse>
        )}
      </div>
      {needsCollapse && (
        <Button
          variant="native"
          id={showMoreLessId}
          onClick={handleClick}
          className={clsx(classes.showMore)}
        >
          <Translation keyIfNoValue inline>
            {isOpen ? 'SHOW_LESS' : 'SHOW_MORE'}
          </Translation>
        </Button>
      )}
    </>
  );
};

export default PostBoxItemTextContent;

import {
  importAllFiles,
  IS_MOCK_IMPORT,
  MOCK_FILE
} from '@troy/shared/src/assets/whitelabel';

export default IS_MOCK_IMPORT
  ? MOCK_FILE
  : importAllFiles(
      require.context(
        './',
        true,
        /\.(png|PNG|jpg|JPG|jpeg|JPEG|svg|SVG|bmp|BMP|gif|GIF|woff2|woff|ttf)$/
      )
    );

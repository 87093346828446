import React from 'react';

import PaymentForm from '../PaymentForm';

import { REPORT_PAYMENT_FORM_NAME } from '../../../../../constants/formNames';

export default ({
  onSubmit,
  className,
  iframeSrc,
  token,
  onIframeLoadingStart,
  onIframeLoadingEnd,
  currency,
  locale
}) => (
  <PaymentForm
    formName={REPORT_PAYMENT_FORM_NAME}
    {...{
      onSubmit,
      className,
      iframeSrc,
      token,
      onIframeLoadingStart,
      onIframeLoadingEnd,
      currency,
      locale
    }}
    translationHeaderKey="CP_INDIVIDUALCASE_EDIT_RECORDPAYMENT_INFO"
    translationAmountKey="CP_INDIVIDUALCASE_EDIT_RECORDPAYMENT_AMOUNT"
    translationDateKey="CP_INDIVIDUALCASE_EDIT_RECORDPAYMENT_DATE"
    translationOptionalInfoKey="CP_INDIVIDUALCASE_EDIT_RECORDPAYMENT_OPTIONALINFO"
    translationPromptKey="CP_INDIVIDUALCASE_EDIT_RECORDPAYMENT_OPTIONALINFO_PROMPTTEXT"
    translationSendKey="CP_INDIVIDUALCASE_EDIT_RECORDPAYMENT_SENDBUTTON"
    translationNoteKey="CP_INDIVIDUALCASE_EDIT_RECORDPAYMENT_NOTE"
  />
);

import { takeLatest } from 'redux-saga/effects';

import * as trelloActions from '../actions/trello';
import * as trelloSagas from './trello';

import * as errorActions from '../actions/error';
import * as errorSagas from './error';

import * as quicksightActions from '../actions/quicksight';
import * as quicksightSagas from './quicksight';

import * as cognitoActions from '../actions/cognito';
import * as cognitoSagas from './cognito';

import * as decisionsActions from '../actions/decisions';
import * as decisionsSagas from './decisions';

import * as confluenceActions from '../actions/confluence';
import * as confluenceSagas from './confluence';

import * as liabilityActions from '../actions/liability';
import * as liabilitySagas from './liability';

import * as invoiceActions from '../actions/invoice';
import * as invoiceSagas from './invoice';

import * as translationsActions from '../actions/translations';
import * as translationsSagas from './translations';

import * as lookerActions from '../actions/looker';
import * as lookerSagas from './looker';

import * as portalActions from '@troy/shared/src/store/actions/portal';
import * as portalSagas from '@troy/shared/src/store/sagas/portal';

export default function* saga() {
  const relations = [
    [trelloActions, trelloSagas],
    [errorActions, errorSagas],
    [quicksightActions, quicksightSagas],
    [cognitoActions, cognitoSagas],
    [decisionsActions, decisionsSagas],
    [confluenceActions, confluenceSagas],
    [liabilityActions, liabilitySagas],
    [invoiceActions, invoiceSagas],
    [translationsActions, translationsSagas],
    [lookerActions, lookerSagas],
    [portalActions, portalSagas]
  ];

  for (const [actions, sagas] of relations) {
    for (const [actionName, action] of Object.entries(actions)) {
      const saga = sagas[actionName];

      if (saga) yield takeLatest(action.getType(), saga); // for multiple same async requests running at the same time use takeEvery (e.g. nodes for TreeView loading)
    }
  }
}

// Prevent rapid reverse swapping
import { widgetHeight } from '../assets/styles/jss_functions';

const buffer = 10;

export const findIndex = (i, point, positions) => {
  let x = point.x;
  let y = point.y + widgetHeight / 2;

  let target = positions.findIndex(pos => {
    if (
      x + buffer < pos.right &&
      x - buffer > pos.left &&
      y - buffer > pos.top &&
      y + buffer < pos.bottom
    ) {
      return true;
    }
    return false;
  });

  return target === -1 ? i : target;
};

export const move = (list, i, j) => {
  var b = list[i];
  list[i] = list[j];
  list[j] = b;
  return list;
};

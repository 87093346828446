import React from 'react';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/material';

import { Translation } from '@troy/shared/src/components/common';

import { Chart, LineDiagramIcon } from '../../../common';

const useStyles = makeStyles(theme => ({
  preview: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  graphs: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  chartStart: {
    stopColor: theme.customColors.gradientColor1
  },
  chartEnd: {
    stopColor: theme.customColors.gradientColor2
  },
  chartLabel: {
    color: theme.palette.secondary.main
  },
  lineContainer: {
    position: 'relative',
    marginLeft: 21
  },
  lineValue1: {
    position: 'absolute',
    left: 90,
    top: 0,
    fontSize: 14,
    color: theme.palette.primary.main
  },
  lineValue2: {
    position: 'absolute',
    left: 195,
    top: 37,
    fontSize: 14,
    color: theme.palette.secondary.main
  },
  description: {
    marginTop: 16,
    fontSize: 14,
    lineHeight: '16px'
  }
}));

const StatisticsLookerMiniWidget = () => {
  const classes = useStyles();

  return (
    <Box className={classes.preview}>
      <Box className={classes.graphs}>
        <Chart
          small
          id="looker-mini-chart-left"
          value={65}
          displayValue={`${55}%`}
          startClassName={classes.chartStart}
          endClassName={classes.chartEnd}
          labelClassName={classes.chartLabel}
        />
        <Box className={classes.lineContainer}>
          <LineDiagramIcon />
          <span className={classes.lineValue1}>+40%</span>
          <span className={classes.lineValue2}>4.2</span>
        </Box>
      </Box>
      <Translation
        keyIfNoValue
        component="span"
        className={classes.description}
      >
        CP_STATISTICS_MINI_WIDGET_LABEL
      </Translation>
    </Box>
  );
};

export default StatisticsLookerMiniWidget;

import React, { useCallback, useState } from 'react';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import debounce from 'lodash.debounce';

import { CopyTooltip, Translation } from '@troy/shared/src/components/common';

import {
  ChatIcon,
  CircularProgress,
  LabeledValueCard,
  TableIconSvg
} from '../../../../common';
import SearchComponent from './SearchComponent';

import { authorizedGet } from '../../../../../utils/api';
import { SEARCH_USERS } from '../../../../../constants/api';

import { getAmountWithCurrency } from '../../../../../utils/common';

const MAX_VIEWED_SUGGESTIONS = 4;

const useStyles = makeStyles(theme => ({
  wrapper: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  selectedUser: {
    padding: 8
  },
  nameBlock: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 16,

    '& > :not(:last-child)': {
      marginRight: 20
    }
  },
  adviceIcon: {
    width: 24,
    height: 24,
    color: theme.palette.primary.main
  },
  userName: {
    fontWeight: 'bold'
  },
  userBlocks: {
    display: 'flex',

    '& > :not(:last-child)': {
      marginRight: 16
    }
  },
  progress: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center'
  },
  tether: {
    zIndex: 2,
    width: 359 // tether's width
  },
  tetherRoot: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  advicesWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    padding: 8
  },
  advices: {
    display: 'table',
    borderSpacing: '0 16px'
  },
  advice: {
    display: 'table-row'
  },
  cell: {
    display: 'table-cell',
    padding: '4px 5px',
    verticalAlign: 'middle'
  },
  adviceText: {
    paddingRight: 0
  },
  headerSearch: {
    position: 'relative',
    minHeight: 62 // tether's place
  },
  searchField: {
    position: 'absolute',
    width: '100%',
    zIndex: 1051
  }
}));

const getCustomers = (query, token) =>
  authorizedGet(SEARCH_USERS(query), token);

let debouncedHandler = null;

const UserWidgetContent = ({
  userInfo,
  userLoading,
  searchQuery,
  onSearchQueryChange,
  onSelectedValueChange,
  token
}) => {
  const classes = useStyles();
  const [searchList, setSearchList] = useState([]);
  const [isSearchLoading, setSearchLoading] = useState(false);

  const onAutocompleteSelect = useCallback(
    (event, value) => {
      onSelectedValueChange && onSelectedValueChange(value);
    },
    [onSelectedValueChange]
  );

  const searchFetchRequested = useCallback(
    value => {
      setSearchLoading(true);
      if (debouncedHandler) {
        debouncedHandler.cancel();
      }
      debouncedHandler = debounce(() => {
        getCustomers(value, token).then((data = {}) => {
          const items = data.items;

          if (items) {
            setSearchList(
              items.map(
                ({
                  liabilityId,
                  customerFirstName,
                  customerLastname,
                  liabilityRefNo
                }) => ({
                  value: liabilityId,
                  name: `${customerFirstName} ${customerLastname}`,
                  liabilityRefNo
                })
              )
            );
            setSearchLoading(false);
          }
        });
      }, 300);
      debouncedHandler();
    },
    [token]
  );

  const searchOptionsClearRequested = useCallback(() => {
    setSearchList([]);
  }, []);

  const handleChangeSearchQuery = useCallback(
    event => {
      const value = event.target.value;
      if (!value) {
        onSelectedValueChange && onSelectedValueChange({ name: '', value: '' });
        searchOptionsClearRequested();
      } else {
        searchFetchRequested(value);
      }
      onSearchQueryChange(value);
    },
    [
      onSelectedValueChange,
      searchOptionsClearRequested,
      searchFetchRequested,
      onSearchQueryChange
    ]
  );

  const searchKeyPress = useCallback(
    event => {
      if (event.key === 'Enter' && searchList[0]) {
        onSearchQueryChange(searchList[0].name);
      }
    },
    [searchList, onSearchQueryChange]
  );

  return (
    <div className={classes.wrapper}>
      {userInfo ? (
        !userLoading ? (
          <div className={classes.selectedUser}>
            <div className={classes.nameBlock}>
              <TableIconSvg className={classes.adviceIcon} />
              <p className={classes.userName}>{userInfo.name}</p>
            </div>
            <div className={classes.userBlocks}>
              <LabeledValueCard
                label="CP_INDIVIDUALCASE_EDIT_RECORDPAYMENT_AMOUNT"
                value={
                  userInfo && typeof userInfo.amount === 'number'
                    ? getAmountWithCurrency(
                        userInfo.amount,
                        userInfo.currency,
                        userInfo.locale
                      )
                    : null
                }
              />
              <CopyTooltip value={userInfo.caseId}>
                <LabeledValueCard
                  label="CP_INDIVIDUALCASE_INFOBOX_FILENUMBER"
                  value={userInfo.caseId}
                />
              </CopyTooltip>
            </div>
          </div>
        ) : (
          <Box className={classes.progress}>
            <CircularProgress />
          </Box>
        )
      ) : (
        <div className={classes.tetherRoot}>
          <div className={classes.advicesWrapper}>
            <div className={classes.advices}>
              <div className={classes.advice}>
                <div className={classes.cell}>
                  <TableIconSvg className={classes.adviceIcon} />
                </div>
                <Translation
                  component="p"
                  className={clsx(classes.cell, classes.adviceText)}
                >
                  CP_START_INDIVIDUALCASE_CONTENT_TOP
                </Translation>
              </div>
              <div className={classes.advice}>
                <div className={classes.cell}>
                  <ChatIcon className={classes.adviceIcon} />
                </div>
                <Translation
                  component="p"
                  className={clsx(classes.cell, classes.adviceText)}
                >
                  CP_START_INDIVIDUALCASE_CONTENT_BOTTOM
                </Translation>
              </div>
            </div>
          </div>
          <div className={classes.headerSearch}>
            <SearchComponent
              value={searchQuery}
              onChange={handleChangeSearchQuery}
              onRowClick={isSearchLoading ? null : onAutocompleteSelect}
              rows={searchList}
              maxCount={MAX_VIEWED_SUGGESTIONS}
              onKeyPress={searchKeyPress}
              label="CP_START_INDIVIDUALCASE_CONTENT_SEARCHBAR"
              labelTranslated
              placeholder="CP_BILLING_SEARCH"
              isLoading={isSearchLoading}
              className={classes.searchField}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default UserWidgetContent;

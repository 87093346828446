import { createReducer } from 'redux-act';
import * as a from '../actions';
import { WhitelabelConfType } from '../../types/whitelabel';

export interface PortalReducerState {
  isInitialized: boolean;
  portalConf: null | WhitelabelConfType;
}

const DEFAULT_STATE = {
  isInitialized: false,
  portalConf: null
};

const reducer = createReducer<PortalReducerState>({}, DEFAULT_STATE);

reducer.on(a.setPortalInitialized, (state, initialized) => ({
  ...state,
  isInitialized: initialized
}));

reducer.on(a.portalConfigurationReceived, (state, conf) => ({
  ...state,
  portalConf: conf
}));

export default reducer;

import { authorizedPost } from './api';
import { ONE_TIME_TOKEN } from '../constants/api';

import { handleError } from '../store/actions';

import store from '../store';

export const getOneTimeTokenRequest = jwtToken =>
  authorizedPost(ONE_TIME_TOKEN, {}, jwtToken).then(body => body.token);

export const getOneTimeToken = async jwtToken => {
  try {
    const token = await getOneTimeTokenRequest(jwtToken);
    return token;
  } catch (err) {
    store.dispatch(handleError(err));
  }
};

export function parseJwt(token) {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );

  return JSON.parse(jsonPayload);
}

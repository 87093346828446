import { call, put, select } from 'redux-saga/effects';

import TrelloService from '../../services/TrelloService';

import { boardInfoReceived, handleError, userInfoReceived } from '../actions';
import { getTrelloToken } from '../selectors/trello';

export function* getUserInfo() {
  const token = yield select(getTrelloToken);
  try {
    const info = yield call(TrelloService.getUserInfo, token);
    yield put(userInfoReceived(info));
  } catch (err) {
    yield put(handleError(err));
  }
}

export function* getBoardInfo({ payload: id }) {
  const token = yield select(getTrelloToken);
  try {
    const info = yield call(TrelloService.getBoardInfo, token, id);
    yield put(boardInfoReceived({ id, info }));
  } catch (err) {
    yield put(handleError(err));
  }
}

import React, { useCallback, useMemo, useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import { alpha } from '@mui/material';
import { useSelector } from 'react-redux';

import { Translation } from '@troy/shared/src/components/common';

import { LanguageSelect, Menu, MenuIcon } from '../../common';
import { useAppWidthClass } from '../../../utils/hooks';
import {
  oneColumnWidgetListWidth,
  threeColumnWidgetListWidth,
  twoColumnWidgetListWidth,
  widgetWidth2Down
} from '../../../assets/styles/jss_functions';
import {
  portalHeaderGradientSelector,
  portalHeaderLogoHeightSelector,
  portalHeaderLogoNarrowSelector,
  portalHeaderLogoWideSelector
} from '../../../store/selectors';

const MENU_ID = 'main-menu';

const HEADER_FORMAT = name => welcomeTranslation =>
  `${welcomeTranslation}, ${name}!`;

const useStyles = makeStyles(theme => ({
  wrapper: {
    position: 'relative',
    backgroundImage: props => `url(${props.bg})`,
    backgroundSize: 'cover'
  },
  content: {
    minHeight: 64,
    padding: 8,
    marginTop: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.primary.contrastText
  },
  authenticated: {
    maxWidth: oneColumnWidgetListWidth,

    [`@media only screen and (min-width: ${twoColumnWidgetListWidth}px)`]: {
      maxWidth: `min(${twoColumnWidgetListWidth}px, calc(100% - 128px))`
    },
    [`@media only screen and (min-width: ${threeColumnWidgetListWidth}px)`]: {
      maxWidth: `min(${threeColumnWidgetListWidth}px, calc(100% - 128px))`
    },
    justifyContent: 'space-between',
    margin: 'auto'
  },
  languageName: {
    display: 'flex',
    alignItems: 'center'
  },
  languageMenu: {
    width: 170,
    textAlign: 'right',
    ...widgetWidth2Down({ width: '90px', marginRight: '50px' }),
    [`@media only screen and (max-width: 470px)`]: {
      marginRight: '84px'
    }
  },
  menu: {
    position: 'absolute',
    right: 0,
    top: 0,
    height: '100%'
  },
  menuButton: {
    height: '100%',
    background: `${alpha(theme.palette.primary.contrastText, 0.1)} !important`
  }
}));

export default ({
  isAuthenticated,
  onLogout,
  onLanguageChange: onLanguageChangeProp,
  userName,
  language,
  languages,
  iconButton: IconButton
}) => {
  const backgroundGradient = useSelector(portalHeaderGradientSelector);
  const classes = useStyles({ bg: backgroundGradient });
  const logoWide = useSelector(portalHeaderLogoWideSelector);
  const logoNarrow = useSelector(portalHeaderLogoNarrowSelector);
  const logoHeight = useSelector(portalHeaderLogoHeightSelector);
  const [anchorEl, setAnchorEl] = useState(null);

  const onMenuClick = useCallback(event => {
    setAnchorEl(event.currentTarget);
  }, []);

  const onMenuClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const onLanguageChange = useCallback(
    code => {
      onLanguageChangeProp(code);
    },
    [onLanguageChangeProp]
  );

  const handleLogoutClick = useCallback(() => {
    onLogout();
    onMenuClose();
  }, [onLogout, onMenuClose]);

  const menuItems = useMemo(
    () => [
      {
        name: 'CP_START_LOGOUT',
        onClick: handleLogoutClick,
        translated: true
      }
    ],
    [handleLogoutClick]
  );
  const { isTwoColumnWidth } = useAppWidthClass();
  return (
    <div className={classes.wrapper}>
      <div className={clsx(classes.content, classes.authenticated)}>
        {isAuthenticated && isTwoColumnWidth ? (
          <img alt="logo" src={logoWide} height={logoHeight} />
        ) : (
          <img alt="logo" src={logoNarrow} height={logoHeight} />
        )}
        <div className={classes.languageName}>
          {isAuthenticated && isTwoColumnWidth && (
            <Translation component="h4" format={HEADER_FORMAT(userName)}>
              CP_START_WELCOME
            </Translation>
          )}
          <div className={classes.languageMenu}>
            <LanguageSelect
              isCompact={!isTwoColumnWidth}
              onChange={onLanguageChange}
              languages={languages}
              value={language}
            />
          </div>
        </div>
      </div>
      {isAuthenticated ? (
        <div className={classes.menu}>
          <IconButton
            className={classes.menuButton}
            icon={MenuIcon}
            onClick={onMenuClick}
            aria-owns={anchorEl ? MENU_ID : undefined}
            aria-haspopup="true"
            size="large"
          />
          <Menu
            id={MENU_ID}
            options={menuItems}
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={onMenuClose}
          />
        </div>
      ) : null}
    </div>
  );
};

import { createSelector, Selector } from 'reselect';
import { StoreState } from '../reducers';
import { PostBoxReducerState } from '../reducers/post-box';

const stateSelector = (state: StoreState): PostBoxReducerState => state.postBox;

export const postBoxItemsSelector = createSelector<
  [Selector<StoreState, PostBoxReducerState>],
  PostBoxReducerState['list']
>(stateSelector, state => state.list);

export const postBoxDirectionFilterCountsSelector = createSelector<
  [Selector<StoreState, PostBoxReducerState>],
  PostBoxReducerState['directionFilterCounts']
>(stateSelector, state => state.directionFilterCounts);

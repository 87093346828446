import React, { useCallback, useState } from 'react';

import { WidgetList } from '../../components/home';

import { useDispatch, useSelector } from '../../store/utils';
import {
  cognitoTokenTimestampExpirationSelector,
  decisionsDisabledWidgetsSelector,
  decisionsEnabledWidgetsSelector,
  decisionsIsPedingSelector,
  decisionsWidgetsSelector
} from '../../store/selectors';
import {
  disableWidget as disableWidgetAction,
  enableWidgets as enableWidgetsAction,
  getDecisions as getDecisionsAction
} from '../../store/actions';

export default () => {
  const enabledWidgets = useSelector(decisionsEnabledWidgetsSelector);
  const tokenExpiration = useSelector(cognitoTokenTimestampExpirationSelector);
  const disabledWidgets = useSelector(decisionsDisabledWidgetsSelector);
  const widgets = useSelector(decisionsWidgetsSelector);
  const isPending = useSelector(decisionsIsPedingSelector);

  const dispatch = useDispatch();
  const getDecisions = useCallback(
    () => dispatch(getDecisionsAction()),
    [dispatch]
  );
  const disableWidget = useCallback(
    widgetKeys => dispatch(disableWidgetAction(widgetKeys)),
    [dispatch]
  );
  const enableWidgets = useCallback(
    widgetKeys => dispatch(enableWidgetsAction(widgetKeys)),
    [dispatch]
  );

  const [extendedComponent, setExtendedComponent] = useState(null);

  const onExtendedClose = useCallback(() => setExtendedComponent(null), []);
  const setExtended = useCallback(key => setExtendedComponent(key), []);

  return (
    <WidgetList
      {...{
        extendedComponent,
        onExtendedClose,
        setExtended,
        tokenExpiration,
        widgets,
        disabledWidgets,
        enabledWidgets,
        getDecisions,
        disableWidget,
        enableWidgets,
        isPending
      }}
    />
  );
};

import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import LabeledValueCard from '../LabeledValueCard';

const useStyles = makeStyles(theme => ({
  dot: {
    height: 13,
    width: 13,
    borderRadius: '50%'
  },
  online: {
    backgroundColor: theme.palette.primary.main
  },
  offline: {
    backgroundColor: theme.palette.warning.dark
  },
  statusValue: {
    display: 'flex',
    alignItems: 'baseline'
  },
  text: {
    marginLeft: 5
  }
}));

const Status = ({ online, className }) => {
  const classes = useStyles();
  return (
    <LabeledValueCard
      className={className}
      labelTranslated={false}
      label="Status"
      value={
        <div className={classes.statusValue}>
          <div
            className={clsx(
              classes.dot,
              online ? classes.online : classes.offline
            )}
          />
          <span className={classes.text}>{online ? 'Online' : 'Offline'}</span>
        </div>
      }
    />
  );
};

export default Status;

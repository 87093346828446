const CONFLUENCE_APP_KEY = process.env.REACT_APP_CONFLUENCE_APP_KEY;
const CONFLUENCE_AUTH_URL = process.env.REACT_APP_CONFLUENCE_AUTH_URL;
const ATLASSIAN_API_URL = process.env.REACT_APP_ATLASSIAN_API_URL;
const ATLASSIAN_API_URL_FULL = process.env.REACT_APP_ATLASSIAN_API_URL_FULL;
const CONFLUENCE_API_URL = cloudId =>
  `${ATLASSIAN_API_URL_FULL}/ex/confluence/${cloudId}/rest/api`;

export const CONFLUENCE_AUTHORIZE_CODE = ({
  callbackURL,
  scopes,
  token,
  responseType
}) => `${CONFLUENCE_AUTH_URL}/authorize?
audience=${ATLASSIAN_API_URL}&client_id=${CONFLUENCE_APP_KEY}&scope=${
  scopes || 'read:confluence-content.summary'
}&redirect_uri=${encodeURIComponent(
  callbackURL || window.location.href
)}&state=${token}&response_type=${responseType || 'code'}&prompt=consent`;

export const CONFLUENCE_GET_TOKEN = `${CONFLUENCE_AUTH_URL}/oauth/token`;
export const CONFLUENCE_GET_ACCESSIBLE_RESOURCES = `${ATLASSIAN_API_URL_FULL}/oauth/token/accessible-resources`;
export const CONFLUENCE_GET_CONTENT = (cloudId, contentId) =>
  `${CONFLUENCE_API_URL(cloudId)}/content${contentId ? `/${contentId}` : ''}`;

// ?expand=body.export_view.webresource.tags.all

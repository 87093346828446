import { call, put, select } from 'redux-saga/effects';

import ConfluenceService from '../../services/ConfluenceService';

import {
  confluenceAccessibleResourcesReceived,
  confluenceResourceContentReceived,
  confluenceTokenReceived,
  handleError
} from '../actions';
import { confluenceTokenSelector } from '../selectors';

import { historyReplace } from '../utils';

export function* getConfluenceToken({ payload: code }) {
  try {
    const { access_token } =
      (yield call(ConfluenceService.getAccessToken, code)) || {};
    yield put(confluenceTokenReceived(access_token));
    yield put(historyReplace(window.location.pathname));
  } catch (err) {
    yield put(handleError(err));
  }
}

export function* getConfluenceAccessibleResources() {
  const token = yield select(confluenceTokenSelector);
  try {
    const resources = yield call(
      ConfluenceService.getAccessibleResources,
      token
    );
    yield put(confluenceAccessibleResourcesReceived(resources));
  } catch (err) {
    yield put(handleError(err));
  }
}

export function* getConfluenceResourceContent({
  payload: { cloudId, contentId }
}) {
  const token = yield select(confluenceTokenSelector);
  try {
    const content = yield call(
      ConfluenceService.getContent,
      token,
      cloudId,
      contentId
    );

    yield put(
      confluenceResourceContentReceived({ content, contentId, cloudId })
    );
  } catch (err) {
    yield put(handleError(err));
  }
}

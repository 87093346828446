import React from 'react';

import PaymentForm from '../PaymentForm';

import { CREDIT_MEMO_FORM_NAME } from '../../../../../constants/formNames';

export default ({
  onSubmit,
  className,
  iframeSrc,
  token,
  onIframeLoadingStart,
  onIframeLoadingEnd,
  currency,
  locale
}) => (
  <PaymentForm
    formName={CREDIT_MEMO_FORM_NAME}
    {...{
      onSubmit,
      className,
      iframeSrc,
      token,
      onIframeLoadingStart,
      onIframeLoadingEnd,
      currency,
      locale
    }}
    translationHeaderKey="CP_INDIVIDUALCASE_EDIT_ENTERCREDITNOTE_INFO"
    translationAmountKey="CP_INDIVIDUALCASE_EDIT_ENTERCREDITNOTE_AMOUNT"
    translationDateKey="CP_INDIVIDUALCASE_EDIT_ENTERCREDITNOTE_DATE"
    translationOptionalInfoKey="CP_INDIVIDUALCASE_EDIT_ENTERCREDITNOTE_OPTIONALINFO"
    translationPromptKey="CP_INDIVIDUALCASE_EDIT_ENTERCREDITNOTE_OPTIONALINFO_PROMPTTEXT"
    translationSendKey="CP_INDIVIDUALCASE_EDIT_ENTERCREDITNOTE_SENDBUTTON"
    translationNoteKey="CP_INDIVIDUALCASE_EDIT_ENTERCREDITNOTE_NOTE"
  />
);

import { ReactComponent as ImageIcon } from '../../../assets/img/post-box/icon-image.svg';
import { ReactComponent as MailIcon } from '../../../assets/img/post-box/icon-mail.svg';
import { ReactComponent as PdfIcon } from '../../../assets/img/post-box/icon-pdf.svg';
import { ReactComponent as WordIcon } from '../../../assets/img/post-box/icon-word.svg';
import { ReactComponent as UploadLogo } from '../../../assets/img/post-box/icon-upload.svg';
import { ReactComponent as ChatIcon } from '../../../assets/img/post-box/icons-chat.svg';
import { ReactComponent as WhatsappIcon } from '../../../assets/img/post-box/icons-content-whatsapp.svg';

import { ReactComponent as NavHome } from '../../../assets/img/menu/icon_home.svg';
import { ReactComponent as NavPostBox } from '../../../assets/img/menu/icon_postbox.svg';

import { ReactComponent as ContactChat } from '../../../assets/img/contacts/icons-content-chat.svg';
import { ReactComponent as ContactMail } from '../../../assets/img/contacts/icons-content-mail.svg';
import { ReactComponent as ContactTel } from '../../../assets/img/contacts/icons-content-tel.svg';
import { ReactComponent as ContactTwitter } from '../../../assets/img/contacts/icons-content-twitter.svg';
import { ReactComponent as ContactWhatsapp } from '../../../assets/img/contacts/icons-content-whatsapp.svg';

import { ReactComponent as CheckedCheckbox } from '../../../assets/img/icons/checked-checkbox.svg';
import { ReactComponent as UncheckedCheckbox } from '../../../assets/img/icons/unchecked-checkbox.svg';
import { ReactComponent as Confirm } from '../../../assets/img/icons/icons-content-confirm.svg';
import { ReactComponent as ExpandMore } from '../../../assets/img/icons/icons-content-expand-more.svg';
import { ReactComponent as Dismiss } from '../../../assets/img/icons/icons-content-dismiss.svg';
import { ReactComponent as Add } from '../../../assets/img/icons/icons-content-add.svg';
import { ReactComponent as QuestionIcon } from '../../../assets/img/icons/icons-alert-question.svg';
import { ReactComponent as Alert } from '../../../assets/img/icons/icons-alert-error.svg';
import { ReactComponent as DownloadIcon } from '../../../assets/img/icons/icons-content-download.svg';
import { ReactComponent as ChevronLeft } from '../../../assets/img/icons/icons-content-chevron-left.svg';
import { ReactComponent as ChevronRight } from '../../../assets/img/icons/icons-content-chevron-right.svg';
import { ReactComponent as CopyPaste } from '../../../assets/img/icons/icon-copy-paste.svg';
import { ReactComponent as Profile } from '../../../assets/img/icons/icons-content-profile.svg';
import { ReactComponent as Key } from '../../../assets/img/icons/icons-content-key.svg';
import { ReactComponent as StarEmpty } from '../../../assets/img/icons/icons-content-star-empty.svg';
import { ReactComponent as StarFilled } from '../../../assets/img/icons/icons-content-star-filled.svg';
import { ReactComponent as StarFocus } from '../../../assets/img/icons/icons-content-star-focus.svg';
import { ReactComponent as StarFocusFilled } from '../../../assets/img/icons/icons-content-star-focus-filled.svg';
import { ReactComponent as Letter } from '../../../assets/img/icons/icons-content-letter.svg';
import { ReactComponent as LanguageIcon } from '../../../assets/img/icons/icons-content-language.svg';
import { ReactComponent as Info } from '../../../assets/img/icons/icons-content-info.svg';
import { ReactComponent as OnSiteChat } from '../../../assets/img/icons/icons-content-on-site-chat.svg';
import { ReactComponent as CalendarIcon } from '../../../assets/img/icons/icons-content-calendar.svg';
import { ReactComponent as TelephoneIcon } from '../../../assets/img/icons/icons-content-tel.svg';
import { ReactComponent as TelephoneOut } from '../../../assets/img/icons/icons-content-tel-out.svg';
import { ReactComponent as TelephoneIn } from '../../../assets/img/icons/icons-content-tel-in.svg';
import { ReactComponent as UploadIcon } from '../../../assets/img/icons/icons-content-upload.svg';
import { ReactComponent as Burger } from '../../../assets/img/icons/icons-actions-burger.svg';
import { ReactComponent as Search } from '../../../assets/img/icons/icons-actions-search.svg';
import { ReactComponent as Logout } from '../../../assets/img/icons/icons-content-logout.svg';
import { ReactComponent as Close } from '../../../assets/img/icons/icons-actions-close.svg';
import { ReactComponent as NavigationExpand } from '../../../assets/img/icons/icons-navigation-expand-more.svg';
import { ReactComponent as PaginationLeft } from '../../../assets/img/icons/pagination-left.svg';
import { ReactComponent as PaginationRight } from '../../../assets/img/icons/pagination-right.svg';
import { ReactComponent as Settings } from '../../../assets/img/icons/icons-content-settings.svg';
import { ReactComponent as Star } from '../../../assets/img/icons/icons-content-star.svg';
import { ReactComponent as ContextMenu } from '../../../assets/img/icons/icons-context-menu.svg';
import { ReactComponent as PencilIcon } from '../../../assets/img/icons/icons-content-pencil.svg';
import { ReactComponent as Play } from '../../../assets/img/icons/icons-content-play.svg';
import { ReactComponent as ArrowLeft } from '../../../assets/img/icons/icon-arrow-left.svg';
import { ReactComponent as VisibleIcon } from '../../../assets/img/icons/icons-content-visible.svg';
import { ReactComponent as InvisibleIcon } from '../../../assets/img/icons/icons-content-invisible.svg';
import { ReactComponent as Briefcase } from '../../../assets/img/icons/icons-content-briefcase.svg';
import { ReactComponent as RadixUpdate } from '../../../assets/img/icons/radix-icons_update.svg';
import { ReactComponent as RocketChat } from '../../../assets/img/icons/icon-rocket-chat.svg';

const ICONS = {
  imageIcon: ImageIcon,
  mailIcon: MailIcon,
  pdfIcon: PdfIcon,
  wordIcon: WordIcon,
  uploadLogo: UploadLogo,
  chatIcon: ChatIcon,
  whatsappIcon: WhatsappIcon,

  navHome: NavHome,
  navPostBox: NavPostBox,

  contactChat: ContactChat,
  contactMail: ContactMail,
  contactTel: ContactTel,
  contactTwitter: ContactTwitter,
  contactWhatsapp: ContactWhatsapp,

  checkedCheckbox: CheckedCheckbox,
  uncheckedCheckbox: UncheckedCheckbox,
  confirm: Confirm,
  expandMore: ExpandMore,
  dismiss: Dismiss,
  add: Add,
  questionIcon: QuestionIcon,
  alert: Alert,
  downloadIcon: DownloadIcon,
  chevronLeft: ChevronLeft,
  chevronRight: ChevronRight,
  copyPaste: CopyPaste,
  profile: Profile,
  key: Key,
  starEmpty: StarEmpty,
  starFilled: StarFilled,
  starFocus: StarFocus,
  starFocusFilled: StarFocusFilled,
  letter: Letter,
  languageIcon: LanguageIcon,
  info: Info,
  onSiteChat: OnSiteChat,
  calendar: CalendarIcon,
  phone: TelephoneIcon,
  telephoneIn: TelephoneIn,
  telephoneOut: TelephoneOut,
  uploadIcon: UploadIcon,
  burger: Burger,
  search: Search,
  logout: Logout,
  close: Close,
  navigationExpand: NavigationExpand,
  paginationLeft: PaginationLeft,
  paginationRight: PaginationRight,
  settings: Settings,
  star: Star,
  contextMenu: ContextMenu,
  pencilIcon: PencilIcon,
  play: Play,
  arrowLeft: ArrowLeft,
  visibleIcon: VisibleIcon,
  invisibleIcon: InvisibleIcon,
  briefcase: Briefcase,
  radixUpdate: RadixUpdate,
  rocketChat: RocketChat
};

export default ICONS;

export type SupportedIcon = keyof typeof ICONS;

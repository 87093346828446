import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

import { CustomColor, CustomIcon, IconVariant, Text } from '../index';

const useStyles = makeStyles((theme: Theme) => ({
  companyLine: {
    display: 'flex'
  },
  company: {
    marginLeft: theme.spacing(1)
  },
}));

interface CompanyNameProps {
  className?: string;
  companyName?: string;
  iconVariant?: IconVariant;
  textColor?: CustomColor;
}

const CompanyName: React.FC<CompanyNameProps> = ({
  className,
  companyName,
  iconVariant = 'secondaryDark',
  textColor = 'secondaryDark'
}) => {
  const classes = useStyles();
  const hasCompany = !!companyName;

  return hasCompany ? (
    <div className={clsx(classes.companyLine, className)}>
      <CustomIcon icon="briefcase" size="xs" variant={iconVariant} />
      <Text
        className={classes.company}
        component="div"
        variant="body2"
        color={textColor}
      >
        {companyName}
      </Text>
    </div>
  ) : null;
};

export default CompanyName;

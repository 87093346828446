import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import {
  getPureUrl,
  getSearchString,
  getSearchUrl,
  getUrlParams
} from '../../../utils/search';
import { languageSelector } from '../../../store/selectors';
import { getOneTimeToken } from '../../../utils/token';

export default ({
  token,
  src,
  title,
  className,
  frameBorder,
  onOneTimeTokenStart,
  onOneTimeTokenEnd
}) => {
  const [oneTimeToken, setOneTimeToken] = useState('');

  const language = useSelector(languageSelector);

  useEffect(() => {
    setOneTimeToken('');
    onOneTimeTokenStart && onOneTimeTokenStart();
    getOneTimeToken(token).then(token => {
      setOneTimeToken(token || '');
    });
  }, [token, src, title, className, frameBorder, language]);

  useEffect(() => {
    return () => {
      onOneTimeTokenEnd && onOneTimeTokenEnd();
    };
  }, []);

  const iframeSrc = useMemo(() => {
    if (oneTimeToken) {
      const url = getPureUrl(src);
      const search = getSearchString(src);

      const params = getUrlParams(search);

      return getSearchUrl({
        url,
        query: { ...params, oneTimeToken: oneTimeToken, lang: language }
      });
    }
    return '';
  }, [src, oneTimeToken, language]);

  return iframeSrc ? (
    <iframe
      src={iframeSrc}
      {...{ title, className, frameBorder }}
      onLoad={onOneTimeTokenEnd}
    />
  ) : (
    <div></div>
  );
};

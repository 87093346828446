import { createAction } from 'redux-act';

export const getUserInfo = createAction('Trello: Get user info');
export const userInfoReceived = createAction('Trello: User info received');

export const getBoardInfo = createAction('Trello: Get board info');
export const boardInfoReceived = createAction('Trello: Board info received');

export const clearTrelloStore = createAction('Trello: Clear state');
export const setTrelloToken = createAction('Trello: Set token');

import React from 'react';
import { Backdrop } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { motion } from 'framer-motion';

import { CustomIcon, Paper } from '@troy/shared/src/components/common';

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 2,
    background: 'rgba(0, 0, 0, 0.656)',
    width: '100%',
    padding: 10
  },
  box: {
    padding: '40px'
  }
}));

const SuccessLoader = () => {
  const classes = useStyles();

  return (
    <Backdrop className={classes.backdrop} open>
      <motion.div
        transition={{ duration: 1.5 }}
        animate={{ opacity: 1, y: 0 }}
        initial={{ opacity: 0, y: 100 }}
      >
        <Paper className={classes.box}>
          <CustomIcon icon="confirm" variant="primary" size="custom" />
        </Paper>
      </motion.div>
    </Backdrop>
  );
};

export default SuccessLoader;

import React, { useLayoutEffect, useMemo, useRef, useState } from 'react';
import { motion } from 'framer-motion';
import { usePrevious } from '../../../utils/hooks';

const item = {
  hidden: {
    y: 30,
    opacity: 0
  },
  visible: {
    y: 0,
    opacity: 1
  },
  removed: {
    y: 30,
    opacity: 0,
    transition: {
      delay: 0
    }
  }
};

const DELAY = 0.1;
const DURATION = 0.3;

const MotionGridItem = ({
  id,
  index,
  children,
  className,
  disableAnimation,
  isExtended,
  widgetsNumber,
  moveItem,
  setPosition,
  visibility = true,
  setDragging,
  isDragging,
  dragControls,
  dragDisabled,
  ...props
}) => {
  const prevExtended = usePrevious(isExtended);
  const prevDragging = usePrevious(isDragging);

  const [localDragging, setLocalDragging] = useState(false);

  const delay = useMemo(() => {
    if (!visibility) {
      return index * DELAY;
    }

    if (isDragging || (prevDragging && !isDragging)) {
      return 0;
    }
    // close the item without animation delay
    if (!isExtended && prevExtended) {
      return 0;
    }
    // open the item last after all the others items
    if (isExtended && !prevExtended) {
      return widgetsNumber * DELAY;
    }
    // default delay
    return index * DELAY;
  }, [isExtended, visibility, widgetsNumber, isDragging]);

  const ref = useRef(null);
  // Update the measured position of the item so we can calculate when we should rearrange.
  useLayoutEffect(() => {
    function updateSize() {
      const pos = ref.current.getBoundingClientRect();
      setPosition && setPosition(ref.current.id, pos);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  return (
    <motion.div
      id={index}
      animate={visibility ? 'visible' : 'hidden'}
      exit="removed"
      variants={item}
      initial={disableAnimation ? false : 'hidden'}
      className={className}
      transition={{ duration: DURATION, delay }}
      layout
      ref={ref}
      dragConstraints={{ top: 0, bottom: 0, left: 0, right: 0 }}
      drag={!isExtended && !dragDisabled}
      dragControls={dragControls}
      dragListener={!!dragDisabled}
      dragElastic={1}
      onDragStart={() => {
        setDragging(true);
        setLocalDragging(true);
      }}
      onDragEnd={() => {
        setDragging(false);
        setLocalDragging(false);
      }}
      onDrag={(e, data) => {
        moveItem(index, data.offset, data.point);
      }}
      style={{ zIndex: localDragging ? 1052 : 'unset' }}
      {...props}
    >
      {children}
    </motion.div>
  );
};

export default MotionGridItem;

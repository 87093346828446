import { createReducer } from 'redux-act';

import * as actions from '../actions';
import {
  getDirectionFilterCounts,
  resolveColorScheme,
  transformNotifications
} from '../../utils/post-box';
import {
  UINotification,
  UINotificationDirectionFilter
} from '../../types/notifications';

export interface PostBoxReducerState {
  list: UINotification[] | null;
  directionFilterCounts: Record<UINotificationDirectionFilter, number>;
}

const DEFAULT_STATE = {
  list: null,
  directionFilterCounts: {
    all: 0,
    FILTER_SENT: 0,
    FILTER_INBOX: 0
  }
};

const reducer = createReducer<PostBoxReducerState>({}, DEFAULT_STATE);

reducer.on(
  actions.notificationsReceived,
  (state, { items, isTokenInternal, hasHideCommunicationFeature }) => ({
    list: transformNotifications(
      items,
      isTokenInternal,
      hasHideCommunicationFeature
    ),
    directionFilterCounts: getDirectionFilterCounts(items, isTokenInternal)
  })
);

reducer.on(actions.notificationsReset, (state, notifications) => ({
  ...DEFAULT_STATE
}));

reducer.on(
  actions.changeNotificationVisibility,
  (state, { eventId, visibility }) => {
    const l = state.list.slice();
    const itemIndex = l.findIndex(n => n.eventId === eventId);

    if (itemIndex >= 0) {
      if (visibility === 'HIDDEN_INTERNAL') {
        l.splice(itemIndex, 1);
      } else {
        const item = l[itemIndex];
        item.activeState = visibility;
        item.colorScheme = resolveColorScheme(
          item.apiDirection,
          item.apiChannel,
          visibility
        );
      }
    }

    return {
      ...state,
      list: l
    };
  }
);

export default reducer;

import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';

import { Translation } from '@troy/shared/src/components/common';

import {
  Button,
  CustomerCenterIframe,
  Form,
  FormTextField,
  Grid
} from '../../../../common';
import { FormCurrencyInput } from './CurrencyInput';

import { FormDateInput } from '../DateInput';
import { combine, isNumber, required } from '../../../../../utils/form';
import {
  format as formatDate,
  getLocaleFormat
} from '../../../../../utils/date-utils';
import { useAppWidthClass } from '../../../../../utils/hooks';
import {
  widgetWidth2Down,
  widgetWidth3Down
} from '../../../../../assets/styles/jss_functions';
import { languageSelector } from '../../../../../store/selectors';
import { localeFromBrowserCode } from '../../../../../utils/translations';

const amountValidator = value => combine(required, isNumber)(value);

const useStyles = makeStyles(theme => ({
  sendWrapper: {
    alignSelf: 'flex-end',
    width: '100%'
  },
  send: {
    width: '100%',
    padding: '4px 8px',

    ...widgetWidth3Down({
      marginTop: '20px'
    })
  },
  wrapper: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  header: {
    fontWeight: 'bold',
    margin: '4px 0 12px 0',

    ...widgetWidth2Down({
      ...theme.typography.h4
    })
  },
  description: {
    ...theme.typography.body2,
    color: theme.palette.text.secondary,
    marginBottom: 32
  },
  content: {
    ...widgetWidth3Down({
      flex: 1,
      flexDirection: 'row-reverse'
    }),
    ...widgetWidth2Down({
      flexDirection: 'column-reverse'
    })
  },
  container: {
    maxWidth: 280,

    ...widgetWidth3Down({
      paddingLeft: 20
    }),
    ...widgetWidth2Down({
      padding: 0
    })
  },
  fields: {
    marginBottom: 16,

    ...widgetWidth3Down({
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      flex: 1
    })
  },
  field: {
    '&:not(:last-child)': {
      marginBottom: 16
    }
  },
  label: {
    fontWeight: 'bold',
    marginBottom: 8
  },
  outline: {
    borderColor: theme.palette.text.primary
  },
  dateInput: {
    width: '100%',
    maxWidth: 'unset'
  },
  dateInputWrapper: {
    width: '100%'
  },
  additionalInfo: {
    width: '100%'
  },
  iframe: {
    width: 400,
    height: '100%',

    ...widgetWidth3Down({
      width: 350
    })
  },
  iframeWrapper: {
    flex: 1,
    display: 'flex',

    ...widgetWidth2Down({
      minHeight: 300
    })
  }
}));

const SubmitButton = ({ label, disabled }) => {
  const classes = useStyles();
  return (
    <Grid item className={classes.sendWrapper}>
      <Translation
        component={Button}
        className={classes.send}
        color="primary"
        variant="contained"
        type="submit"
        disabled={disabled}
      >
        {label}
      </Translation>
    </Grid>
  );
};

export default ({
  className,
  formName,
  iframeSrc,
  token,
  onIframeLoadingStart,
  onIframeLoadingEnd,
  onSubmit,
  translationHeaderKey,
  translationAmountKey,
  translationDateKey,
  translationOptionalInfoKey,
  translationPromptKey,
  translationSendKey,
  translationNoteKey,
  currency,
  locale
}) => {
  const classes = useStyles();
  const handleSubmit = useCallback(
    ({ amountInCents, ...values }) => {
      return onSubmit({ ...values, amount: amountInCents / 100 });
    },
    [onSubmit]
  );
  const language = useSelector(languageSelector);
  const { isThreeColumnWidth, isTwoColumnWidth } = useAppWidthClass();

  return (
    <Form
      name={formName}
      className={className}
      initialValues={{
        amountInCents: 0,
        date: new Date()
      }}
      onSubmit={handleSubmit}
    >
      {/* Report payment form */}
      {({ isValid, dirty, isSubmitting, values }) => (
        <Grid className={classes.wrapper}>
          <Translation component="h3" className={classes.header}>
            {translationHeaderKey}
          </Translation>
          <Translation component="p" className={classes.description}>
            {translationNoteKey}
          </Translation>
          <Grid
            container
            direction={isTwoColumnWidth ? 'row' : 'column'}
            wrap="nowrap"
            className={classes.content}
          >
            <Grid
              item
              container
              direction="column"
              classes={{ root: classes.container }}
            >
              <Grid item className={classes.fields}>
                <Grid className={classes.field}>
                  <Translation component="p" className={classes.label}>
                    {translationAmountKey}
                  </Translation>
                  <FormCurrencyInput
                    name="amountInCents"
                    currency={currency}
                    locale={locale}
                    validate={amountValidator}
                    variant="outlined"
                    inputNotchedOutlineClassName={classes.outline}
                  />
                </Grid>
                <Grid className={classes.field}>
                  <Translation component="p" className={classes.label}>
                    {translationDateKey}
                  </Translation>
                  <FormDateInput
                    name="date"
                    placeholder={formatDate(new Date())}
                    validate={required}
                    dateFormat={getLocaleFormat()}
                    locale={localeFromBrowserCode(language)}
                    popperPlacement="bottom-end"
                    maxDate={new Date()}
                    className={classes.dateInput}
                    wrapperClassName={classes.dateInputWrapper}
                    inputNotchedOutlineClassName={classes.outline}
                    inputProps={{
                      variant: 'outlined'
                    }}
                  />
                </Grid>
                <Grid className={classes.field}>
                  <Translation component="p" className={classes.label}>
                    {translationOptionalInfoKey}
                  </Translation>
                  <Translation
                    component={FormTextField}
                    propPlace="placeholder"
                    className={classes.additionalInfo}
                    inputNotchedOutlineClassName={classes.outline}
                    name="optionalInfo"
                    variant="outlined"
                    multiline
                    rows={5}
                  >
                    {translationPromptKey}
                  </Translation>
                </Grid>
              </Grid>
              {isThreeColumnWidth && (
                <Grid item className={classes.sendWrapper}>
                  <SubmitButton
                    label={translationSendKey}
                    disabled={!dirty || !isValid || isSubmitting}
                  />
                </Grid>
              )}
            </Grid>
            {/* Customer payment table */}
            <Grid
              item
              justifyContent="center"
              className={classes.iframeWrapper}
            >
              <CustomerCenterIframe
                className={classes.iframe}
                src={iframeSrc}
                token={token}
                title="users-iframe"
                frameBorder="0"
                onOneTimeTokenStart={onIframeLoadingStart}
                onOneTimeTokenEnd={onIframeLoadingEnd}
              />
            </Grid>
          </Grid>
          {isThreeColumnWidth || (
            <SubmitButton
              label={translationSendKey}
              disabled={!dirty || !isValid || isSubmitting}
            />
          )}
        </Grid>
      )}
    </Form>
  );
};

import gql from 'graphql-tag';

export const GET_CUSTOMER_INFO = liabilityId => gql`
  {
    clientPortal(liabilityId: "${liabilityId}") {
      customer {
        firstname
        lastname
        companyName
        address {
          datetime
          address_line_2
          street_and_house_nr
          city
          zip
          country
        }
        liabilities {
          locale
          ikaros_ref_no
          clientCustomerRefNo
          mandant {
            name
            client_no
          }
          liability_status {
            total_outstanding_balance
            due_currency
          }
        }
      }
    }
  }
`;

export const GET_TRANSLATIONS = (language, countryExperience) => gql`
  {
    staticText2(language: "${language}", countryExperience: "${countryExperience}") {
      notes_list {
        text
        type
      }
    }
  }
`;

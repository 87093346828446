import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';

import { Translation } from '@troy/shared/src/components/common';

const useStyles = makeStyles(theme => ({
  card: {
    padding: '8px 16px 8px 8px',
    border: `1px solid ${theme.palette.text.disabled}`,
    borderRadius: theme.shape.borderRadius * 2
  },
  copyHover: {
    boxShadow: theme.customShadows.module
  },
  label: {
    ...theme.typography.body2,
    color: theme.palette.text.secondary,
    marginBottom: '4px'
  },
  value: {
    color: theme.palette.text.primary,
    lineHeight: '24px'
  }
}));

const LabeledValueCard = ({
  className,
  labelTranslated = true,
  label,
  value,
  myRef,
  showCopyHover,
  ...props
}) => {
  const classes = useStyles();

  return (
    <div
      ref={myRef}
      {...props}
      className={clsx(
        classes.card,
        showCopyHover && classes.copyHover,
        className
      )}
    >
      {labelTranslated ? (
        <Translation component="p" className={classes.label}>
          {label}
        </Translation>
      ) : (
        <p className={classes.label}>{label}</p>
      )}
      <p className={classes.value}>{value}</p>
    </div>
  );
};

const LabeledValueCardRef = React.forwardRef((props, ref) => {
  return <LabeledValueCard {...props} myRef={ref} />;
});

export default LabeledValueCardRef;

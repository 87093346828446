import React, { useCallback, useEffect, useRef } from 'react';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  fade: {
    height: '0 !important',
    margin: '0 !important'
  }
}));

const easeIn = (t, alpha) => Math.pow(t, alpha);

const getMask =
  opacity => `linear-gradient(180deg, black, rgba(255, 255, 255, ${opacity})) center bottom/100%
    56px no-repeat,
linear-gradient(180deg, black, black) center top/100% calc(100% - 56px)
    no-repeat`;

const ScrollFade = () => {
  const rootRef = useRef(null);
  const classes = useStyles();

  const onScroll = useCallback(() => {
    const scrollElement = rootRef.current && rootRef.current.parentElement;
    if (scrollElement) {
      const {
        offsetHeight: elementHeight,
        scrollHeight: elementWidth,
        scrollTop
      } = scrollElement;
      const opacity = easeIn(scrollTop / (elementHeight - elementWidth), 10);
      const mask = getMask(opacity);

      scrollElement.style.mask = mask;
      scrollElement.style.webkitMask = mask;
    }
  }, []);

  useEffect(() => {
    const scrollElement = rootRef.current && rootRef.current.parentElement;

    if (scrollElement) {
      const { offsetHeight, scrollHeight } = scrollElement;
      if (offsetHeight !== scrollHeight) {
        const mask = getMask(0);
        scrollElement.style.mask = mask;
        scrollElement.style.webkitMask = mask;
      }

      scrollElement.addEventListener('scroll', onScroll);
      return () => {
        scrollElement.style.mask = '';
        scrollElement.style.webkitMask = '';
        scrollElement.removeEventListener('scroll', onScroll);
      };
    }
  }, []);

  return <div ref={rootRef} className={classes.fade} />;
};

export default ScrollFade;

import makeStyles from '@mui/styles/makeStyles';
import React from 'react';

import { Tabs } from '../../../../../common';
import { useAppWidthClass } from '../../../../../../utils/hooks';

const useStyles = ({ isTwoColumnWidth }) =>
  makeStyles(theme => ({
    tabsRoot: {
      minHeight: 40,
      flex: 1
    },
    tabTextColorPrimary: {
      '&.Mui-selected': {
        background: theme.palette.primary.main,
        color: `${theme.palette.primary.contrastText} !important`
      },
      color: theme.palette.primary.main,
      border: `1px solid ${theme.palette.primary.main}`,
      '&:not(:first-child)': {
        borderLeftWidth: 0.5
      },
      '&:not(:last-child)': {
        borderRightWidth: 0.5
      }
    },
    tabRoot: {
      ...theme.typography.body1Bold,
      flexDirection: 'row',
      alignItems: 'center',
      fontWeight: 'normal',
      whiteSpace: 'nowrap',
      flex: 1,
      padding: '8px 16px',
      textTransform: 'none',
      minWidth: isTwoColumnWidth ? '210px' : '84px',
      '&:first-child': {
        borderTopLeftRadius: theme.shape.borderRadius * 2,
        borderBottomLeftRadius: theme.shape.borderRadius * 2
      },
      '&:last-child': {
        borderTopRightRadius: theme.shape.borderRadius * 2,
        borderBottomRightRadius: theme.shape.borderRadius * 2
      }
    },
    tabLabelIcon: {
      minHeight: 40,
      maxHeight: 40
    }
  }));

export default props => {
  const { isTwoColumnWidth } = useAppWidthClass();
  const classes = useStyles({ isTwoColumnWidth })();

  return (
    <Tabs
      {...props}
      classes={{
        root: classes.tabsRoot
      }}
      tabClasses={{
        root: classes.tabRoot,
        textColorPrimary: classes.tabTextColorPrimary,
        labelIcon: classes.tabLabelIcon
      }}
    />
  );
};

import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';

import { Paper, Translation } from '@troy/shared/src/components/common';

import {
  availableLanguagesSelector,
  portalLoginLogoSelector
} from '../../store/selectors';
import * as routes from '../../constants/routes';

import { Button, Status } from '../../components/common';
import { widgetWidth2Down } from '../../assets/styles/jss_functions';
import gitInfo from './git-info.json';

const useStyles = makeStyles(theme => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  logo: {
    marginBottom: 80,
    width: 270
  },
  infoPaper: {
    padding: '40px 57.5px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    ...widgetWidth2Down({
      padding: '40px 24px'
    })
  },
  info: {
    ...theme.typography.body2,
    lineHeight: '20px',
    marginLeft: 16,
    ...widgetWidth2Down({
      marginLeft: 0,
      marginTop: 16
    })
  },
  button: {
    marginTop: 40,
    minWidth: 170,
    ...widgetWidth2Down({
      minWidth: '100%'
    })
  },
  link: {
    ...widgetWidth2Down({
      width: '100%'
    })
  },
  status: {
    ...widgetWidth2Down({
      width: '100%'
    })
  },
  body: {
    display: 'flex',
    alignItems: 'center',
    ...widgetWidth2Down({
      flexDirection: 'column'
    })
  }
}));

const Version = () => {
  const classes = useStyles();
  const logo = useSelector(portalLoginLogoSelector);
  const avaibleLanguage = useSelector(availableLanguagesSelector);
  const online = avaibleLanguage !== null;

  return (
    <div className={classes.wrapper}>
      <Link to={routes.HOME}>
        <img src={logo} className={classes.logo} alt="logo" />
      </Link>
      <Paper className={classes.infoPaper}>
        <div className={classes.body}>
          <Status className={classes.status} online={online} />
          <div className={classes.info}>
            <div>
              <span>hash: </span>
              <span>{(gitInfo && gitInfo.hash) || 'No data about hash'}</span>
            </div>
            <div>
              <span>message: </span>
              <span>
                {(gitInfo && gitInfo.message) || 'No data about message'}
              </span>
            </div>
          </div>
        </div>
        <Link className={classes.link} to={routes.HOME}>
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
          >
            <Translation keyIfNoValue isFragment>
              CP_LOGIN_BUTTON
            </Translation>
          </Button>
        </Link>
      </Paper>
    </div>
  );
};

export default Version;

import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';

import { Translation } from '@troy/shared/src/components/common';

import {
  DownloadIcon,
  PhoneIcon,
  SocialMediaIcon,
  Tabs,
  Widget
} from '../../../common';
import { cognitoTokenSelector } from '../../../../store/selectors';
import { redirectToQuickSightSSO } from '../../../../store/actions';

const useStyles = makeStyles(theme => {
  const ratingHeight = '16px';
  return {
    wrapper: {
      display: 'flex',
      flexDirection: 'column'
    },
    content: {
      flex: '1 1',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center'
    },
    header: {
      marginBottom: '24px',
      color: theme.palette.primary.main,
      fontWeight: 'bolder',
      textAlign: 'center',
      textTransform: 'capitalize'
    },
    rating: {
      display: 'flex'
    },
    ratingNegative: {
      height: ratingHeight,
      background: theme.palette.grey[400]
    },
    ratingNeutral: {
      height: ratingHeight,
      background: theme.palette.primary.light
    },
    ratingPositive: {
      height: ratingHeight,
      background: theme.palette.primary.main
    },
    ratingLabel: {
      marginTop: '8px',
      fontSize: '14px',
      textAlign: 'center',
      '& b': {
        lineHeight: '24px'
      }
    }
  };
});

const TABS_ICONS = {
  'Technischer Support': PhoneIcon,
  'Ansicht exportieren': DownloadIcon
};

const NEUTRAL_MOCK_VALUE = 35;
const POSITIVE_MOCK_VALUE = 58;

const SocialMediaWidget = ({ setExtended, removeItem, extended, ...props }) => {
  const classes = useStyles();
  const token = useSelector(cognitoTokenSelector);
  const dispatch = useDispatch();

  const [tabs, setTabs] = useState([]);
  const [tabValue, setTabValue] = useState(0);

  const onTabChange = useCallback((event, value) => {
    setTabValue(value);
  }, []);

  const onButtonClick = useCallback(() => {
    dispatch(redirectToQuickSightSSO());
  }, [dispatch]);

  const openIframe = useCallback(() => {
    if (token) {
      setExtended();
      const tabs = [
        { name: 'Technischer Support' },
        { name: 'Ansicht exportieren' }
      ].map(({ name }) => ({
        label: name,
        value: name,
        icon: TABS_ICONS[name]
      }));
      setTabs(tabs);
      setTabValue(tabs[0].value);
    }
  }, [token, setExtended]);

  return (
    <Widget
      {...props}
      icon={SocialMediaIcon}
      onActionClick={removeItem}
      buttonName="CP_START_SOCIALMEDIAWIDGET_BUTTON"
      onButtonClick={onButtonClick}
      isExtended={extended}
      onClick={openIframe}
    >
      <div className={classes.wrapper}>
        {!extended ? (
          <div className={classes.content}>
            <Translation component="h3" className={classes.header}>
              CP_START_SOCIAL_POSITIVE
            </Translation>
            <div className={classes.rating}>
              <div
                className={classes.ratingNegative}
                style={{
                  width: `${100 - NEUTRAL_MOCK_VALUE - POSITIVE_MOCK_VALUE}%`
                }}
              />
              <div style={{ width: `${NEUTRAL_MOCK_VALUE}%` }}>
                <div className={classes.ratingNeutral} />
                <div className={classes.ratingLabel}>
                  <b>{NEUTRAL_MOCK_VALUE}%</b>
                  <Translation component="p">
                    CP_START_SOCIAL_NEUTRAL
                  </Translation>
                </div>
              </div>
              <div style={{ width: `${POSITIVE_MOCK_VALUE}%` }}>
                <div className={classes.ratingPositive} />
                <div className={classes.ratingLabel}>
                  <b>{POSITIVE_MOCK_VALUE}%</b>
                  <Translation component="p">
                    CP_START_SOCIAL_POSITIVE
                  </Translation>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <Tabs
            options={tabs}
            value={tabValue}
            onChange={onTabChange}
            pageable
          />
        )}
      </div>
    </Widget>
  );
};

export default SocialMediaWidget;

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@mui/styles';

import {
  Translation,
  useTranslationsContext
} from '@troy/shared/src/components/common';

import {
  AddIcon,
  Button,
  ChatIcon,
  ProjectFilterIcon,
  Widget
} from '../../../common';

import { TRELLO_AUTHORIZE } from '../../../../constants/api';

import {
  getBoardInfo,
  getUserInfo,
  setTrelloToken
} from '../../../../store/actions';

const useStyles = makeStyles(theme => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  spaceWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  space: {
    fontSize: '14px',
    margin: '0 10px'
  },
  spaceLabel: {
    color: theme.palette.primary.main
  },
  change: {
    padding: '0 !important',
    fontSize: '0.8rem !important',
    fontStyle: 'italic',
    color: `${theme.palette.grey[500]} !important`
  },
  projectInfo: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  projectInfoRow: {
    display: 'flex',
    alignItems: 'center'
  },
  wrapperChatIcon: {
    width: '40px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '10px 0'
  },
  wrapperAddIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 'auto',
    marginRight: '10px'
  },
  icon: {
    color: theme.palette.primary.main
  },
  iframe: {
    flex: 1
  },
  createButton: {
    fontWeight: 'normal !important',
    padding: '10px !important'
  }
}));

const ProjectManagementWidget = withRouter(
  ({ location, history, extended, setExtended, removeItem, ...props }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { translate } = useTranslationsContext();
    const trello = useSelector(({ trello }) => trello);
    const [boardId, setBoardId] = useState('');
    const boardUrl = useMemo(() => {
      const { url } = trello.boardsInfo[boardId] || {};
      return url;
    }, [boardId, trello.boardsInfo[boardId]]);

    useEffect(() => {
      if (boardId) {
        dispatch(getBoardInfo(boardId));
      }
    }, [boardId]);

    useEffect(() => {
      if (trello.userInfo.idBoards) {
        setBoardId(trello.userInfo.idBoards[0]);
      }
    }, [trello.userInfo.idBoards]);

    useEffect(() => {
      if (trello.token) {
        dispatch(getUserInfo());
      }
    }, [trello.token]);

    useEffect(() => {
      if (!extended && !trello.token && location.hash) {
        const re = /#*?token=([A-Za-z0-9]+)/;
        const token = location.hash.match(re).pop();

        dispatch(setTrelloToken(token));
        history.push(window.location.pathname);
        setExtended();
      }
    }, [trello.token, location.hash, extended]);

    const onButtonClick = useCallback(() => {
      if (trello.token) {
        setExtended();
        dispatch(getUserInfo());
      } else {
        window.location.href = TRELLO_AUTHORIZE();
      }
    }, [trello.token, setExtended, dispatch]);

    return (
      <Widget
        {...props}
        icon={ProjectFilterIcon}
        onActionClick={removeItem}
        buttonName={translate('CP_START_PROJECT_BUTTON') + ' >>'}
        buttonNameTranslated={false}
        onButtonClick={onButtonClick}
        isExtended={extended}
      >
        <div className={classes.wrapper}>
          {!extended ? (
            <>
              <div className={classes.spaceWrapper}>
                <p className={classes.space}>
                  <Translation component="span" className={classes.spaceLabel}>
                    CP_START_PROJECT_BOARD
                  </Translation>
                  <span>: </span>
                  <a
                    href="https://trello.com/b/tXv7l2l0/fashionette"
                    target="new"
                  >
                    Optimierung Zusammenarbeit
                  </a>
                </p>
                <Button className={classes.change}>
                  <Translation inline>CP_START_PROJECT_CHANGE</Translation>
                </Button>
              </div>
              <div className={classes.projectInfo}>
                <div className={classes.projectInfoRow}>
                  <div className={classes.wrapperChatIcon}>
                    <ChatIcon className={classes.icon} />
                  </div>
                  <p>
                    <Translation inline>
                      CP_START_PROJECT_NEWENTRIES_PLURAL
                    </Translation>{' '}
                    <Translation inline>
                      CP_START_PROJECT_LAST_LOGIN
                    </Translation>
                  </p>
                </div>
                <div className={classes.projectInfoRow}>
                  <Button className={classes.createButton}>
                    <div className={classes.wrapperAddIcon}>
                      <AddIcon className={classes.icon} />
                    </div>
                    <Translation component="p">
                      CP_START_PROJECT_LAST_CREATE
                    </Translation>
                  </Button>
                </div>
              </div>
            </>
          ) : boardUrl ? (
            <iframe
              className={classes.iframe}
              src={boardUrl}
              title="project-management-iframe"
              frameBorder="0"
            />
          ) : null}
        </div>
      </Widget>
    );
  }
);

export default ProjectManagementWidget;

import enLocale from 'date-fns/locale/en-GB';
import deLocale from 'date-fns/locale/de';
import nlLocale from 'date-fns/locale/nl';
import frLocale from 'date-fns/locale/fr';

export const EN = 'EN';
export const DE = 'DE';
export const NL = 'NL';

export const countriesLabels = { GB: EN, DE };
export const countries = Object.keys(countriesLabels);
export const codeToLocale = {
  en: enLocale,
  de: deLocale,
  nl: nlLocale,
  fr: frLocale
};

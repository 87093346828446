import { createAction } from 'redux-act';

export const getConfluenceToken = createAction('Confluence: Get token');
export const confluenceTokenReceived = createAction(
  'Confluence: Token received'
);

export const getConfluenceAccessibleResources = createAction(
  'Confluence: Get accessible resources'
);
export const confluenceAccessibleResourcesReceived = createAction(
  'Confluence: Accessible resources received'
);

export const getConfluenceResourceContent = createAction(
  'Confluence: Get resource content'
);
export const confluenceResourceContentReceived = createAction(
  'Confluence: Resource content received'
);

export const clearConfluenceStore = createAction('Confluence: Clear store');

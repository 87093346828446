import React, { useRef, useState } from 'react';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import { alpha } from '@mui/material/styles';
import { Theme } from '@mui/material';

import { useAppWidthClass } from '../../../utils/hooks';
import { LANotification, UINotification } from '../../../types/notifications';

import PostBoxItemTimeStamp from './PostBoxItemTimeStamp';
import PostBoxItemHeader from './PostBoxItemHeader';
import PostBoxItemTextContent from './PostBoxItemTextContent';
import PostBoxItemFileList from './PostBoxItemFileList';
import PostBoxItemHTMLChatContent from './PostBoxItemHTMLChatContent';
import PostBoxItemLAChatContent from './PostBoxItemLAChatContent';
import PostBoxItemTag from './PostBoxItemTag';
import PostBoxItemSentiment from './PostBoxItemSentiment';
import PostBoxItemContextMenuButton from './PostBoxItemContextMenuButton';
import { DecisionProps } from '../Decision';

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    position: 'relative',
    marginTop: theme.spacing(2),
    padding: `0 ${theme.spacing(1)}`,
    display: 'flex',
    justifyContent: 'flex-start'
  },
  wrapperRight: {
    justifyContent: 'flex-end'
  },
  item: {
    width: '58.33333%',
    minWidth: 500,
    maxWidth: '100%',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    background: theme.palette.background.default,
    justifyContent: 'space-between',
    color: theme.palette.text.primary,
    filter: `drop-shadow(0px 2px 12px ${alpha(
      theme.palette.text.primary,
      0.2
    )})`,
    borderRadius: Number(theme.shape.borderRadius) * 4,
    padding: theme.spacing(2),
    boxSizing: 'border-box',

    '&::before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      left: 0,
      top: 'calc(20px + 2.34314575051px)',
      width: 11.313708499,
      height: 11.313708499,
      transform: 'translate(-50%) rotate(45deg)',
      background: theme.palette.background.default
    }
  },
  itemArrowRight: {
    '&::before': {
      left: 'unset',
      right: 0,
      transform: 'translate(50%) rotate(45deg)'
    }
  },
  itemInDoubleLane: {
    width: 494,
    minWidth: 200
  },
  itemPrimary: {
    filter: `drop-shadow(0px 2px 12px ${alpha(
      theme.palette.primary.main,
      0.2
    )})`
  },
  itemSecondary: {
    filter: `drop-shadow(0px 2px 12px ${alpha(
      theme.palette.secondary.main,
      0.2
    )})`
  },
  itemTertiary: {
    background: theme.palette.tertiary.light,
    filter: 'drop-shadow(0px 2px 12px rgba(6, 62, 146, 0.2))',

    '&::before': {
      background: theme.palette.tertiary.light
    }
  },
  itemWarning: {
    filter: 'drop-shadow(0px 2px 12px rgba(189, 84, 84, 0.2))'
  },
  itemWarningLight: {
    filter: `drop-shadow(0px 2px 12px ${alpha(
      theme.palette.warning.light,
      0.2
    )})`
  },
  hidden: {
    opacity: 0.35,
    transition: 'opacity 100ms ease-in-out',

    '&:hover': {
      opacity: 1
    }
  },
  hiddenHover: {
    opacity: 1
  },
  header: {
    display: 'flex',
    alignItems: 'center',

    '& > *:not(:last-child)': {
      marginRight: theme.spacing(4)
    }
  },
  contextMenuButton: {
    marginLeft: 'auto'
  },
  title: {
    marginTop: theme.spacing(2)
  },
  divider: {
    marginTop: theme.spacing(2),
    width: '100%',
    height: 2,
    background: theme.palette.text.hint
  },
  dividerHidden: {
    background: theme.palette.text.disabled
  },
  dividerPrimary: {
    background: theme.palette.primary.light
  },
  dividerSecondary: {
    background: theme.palette.secondary.light
  },
  dividerTertiary: {
    background: theme.palette.tertiary.main
  },
  dividerWarning: {
    background: theme.customColors.warningDarkFill
  },
  dividerWarningLight: {
    background: theme.palette.warning.light
  }
}));

interface PostBoxItemProps {
  item: UINotification;
  itemClass?: string;
  laneMode: 'single' | 'double';
  isLast: boolean;
  customerToken: string;
  onFileAttachmentClick: (customerToken: string, url: string) => () => void;
  showPostboxLiveAgentChatDebugSelector: DecisionProps['selector'];
  scrollToNotificationStart: (itemRef: React.RefObject<HTMLLIElement>) => void;
  isItemVisibilityEditEnabled?: boolean;
  changePostBoxItemVisibility?: (eventId: string, state: string) => void;
  isItemVisibilityLoading?: boolean;
}

const PostBoxItem: React.FC<PostBoxItemProps> = ({
  item,
  itemClass,
  laneMode,
  isLast,
  customerToken,
  onFileAttachmentClick,
  showPostboxLiveAgentChatDebugSelector,
  scrollToNotificationStart,
  isItemVisibilityEditEnabled,
  changePostBoxItemVisibility,
  isItemVisibilityLoading
}): React.ReactElement => {
  const classes = useStyles();
  const { isTabletWidth, isMobileWidth } = useAppWidthClass();

  const notificationRef = useRef<HTMLLIElement>(null);
  const notificationContentRef = useRef<HTMLDivElement>(null);
  const [contextMenuOpen, setContextMenuOpen] = useState(false);

  const side =
    laneMode === 'single' ? item.singleLaneSide : item.doubleLaneSide;

  return (
    <li
      ref={notificationRef}
      className={clsx(
        classes.wrapper,
        side === 'right' && classes.wrapperRight
      )}
      data-id="notification-wrapper"
      data-notification-channel={item.rawChannel}
      data-last-notification={isLast}
    >
      <div
        ref={notificationContentRef}
        className={clsx(
          classes.item,
          side === 'right' && classes.itemArrowRight,
          laneMode === 'double' && classes.itemInDoubleLane,
          item.colorScheme === 'Primary' && classes.itemPrimary,
          item.colorScheme === 'Secondary' && classes.itemSecondary,
          item.colorScheme === 'Tertiary' && classes.itemTertiary,
          item.colorScheme === 'Warning' && classes.itemWarning,
          item.colorScheme === 'WarningLight' && classes.itemWarningLight,
          item.colorScheme === 'Hidden' && classes.hidden,
          item.colorScheme === 'Hidden' &&
            contextMenuOpen &&
            classes.hiddenHover,

          itemClass
        )}
        data-notification-side={
          side === 'right' ? 'from-customer' : 'to-customer'
        }
      >
        <div className={classes.header}>
          <PostBoxItemTimeStamp
            colorScheme={item.colorScheme}
            datetime={item.datetime}
          />
          {!!item.headerTag && (
            <PostBoxItemTag
              colorScheme={item.colorScheme}
              tag={item.headerTag}
            />
          )}
          {item.mlValue != null && (
            <PostBoxItemSentiment
              colorScheme={item.colorScheme}
              value={item.mlValue}
              confidence={item.mlConfidence}
            />
          )}
          {item.contextMenuVisible && (
            <PostBoxItemContextMenuButton
              className={classes.contextMenuButton}
              eventId={item.eventId}
              editEnabled={isItemVisibilityEditEnabled}
              changePostBoxItemVisibility={changePostBoxItemVisibility}
              itemVisibility={item.activeState}
              isItemVisibilityLoading={isItemVisibilityLoading}
              setContextMenuOpen={setContextMenuOpen}
            />
          )}
        </div>
        <div className={classes.title}>
          <PostBoxItemHeader
            colorScheme={item.colorScheme}
            icon={item.headerIcon}
            text={item.headerText}
          />
        </div>
        {item.content !== null && item.content !== '' && (
          <>
            <div
              className={clsx(
                classes.divider,
                item.colorScheme === 'Hidden' && classes.dividerHidden,
                item.colorScheme === 'Primary' && classes.dividerPrimary,
                item.colorScheme === 'Secondary' && classes.dividerSecondary,
                item.colorScheme === 'Tertiary' && classes.dividerTertiary,
                item.colorScheme === 'Warning' && classes.dividerWarning,
                item.colorScheme === 'WarningLight' &&
                  classes.dividerWarningLight
              )}
            />
            {item.contentType === 'text' && (
              <PostBoxItemTextContent content={item.content as string} />
            )}
            {item.contentType === 'htmlChat' && (
              <PostBoxItemHTMLChatContent content={item.content as string} />
            )}
            {item.contentType === 'laChat' && (
              <PostBoxItemLAChatContent
                notificationRef={notificationRef}
                data={item.content as LANotification}
                colorScheme={item.colorScheme}
                isTabletWidth={isTabletWidth}
                isMobileWidth={isMobileWidth}
                customerToken={customerToken}
                onFileAttachmentClick={onFileAttachmentClick}
                showPostboxLiveAgentChatDebugSelector={
                  showPostboxLiveAgentChatDebugSelector
                }
                scrollToNotificationStart={scrollToNotificationStart}
              />
            )}
          </>
        )}
        {item.files.length > 0 && (
          <PostBoxItemFileList
            colorScheme={item.colorScheme}
            files={item.files}
            isTabletWidth={isTabletWidth}
            isMobileWidth={isMobileWidth}
            customerToken={customerToken}
            onFileAttachmentClick={onFileAttachmentClick}
          />
        )}
      </div>
    </li>
  );
};

export default PostBoxItem;

export const COMMON_DOMAINS = [
  'com',
  'edu',
  'net',
  'org',
  'int',
  'info',
  'biz',
  'name'
];

export const getDomainLanguage = () => {
  const hostname = window.location.hostname;

  if (hostname && hostname.indexOf('.') !== -1) {
    const domains = window.location.hostname.split('.');
    const lastDomain = domains[domains.length - 1];

    return COMMON_DOMAINS.indexOf(lastDomain) === -1 && lastDomain.length === 2
      ? lastDomain
      : null;
  }
  return null;
};

export const isInIframe = () => {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
};

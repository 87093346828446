import { createReducer } from 'redux-act';

import * as actions from '../actions/quicksight';

const DEFAULT_STATE = {
  embeddedUrls: null
};

export default createReducer(
  {
    [actions.embeddedUrlsReceived]: (state, embeddedUrls) => ({
      ...state,
      embeddedUrls
    }),
    [actions.clearQuickSightStore]: () => ({ ...DEFAULT_STATE })
  },
  DEFAULT_STATE
);

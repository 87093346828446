import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import { HOME } from '../../constants/routes';

import { getTokensByCode } from '../../store/actions';
import { isCognitoAuthenticatedSelector } from '../../store/selectors';

const AuthRedirect = () => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(isCognitoAuthenticatedSelector);

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const code = urlParams.get('code');
    if (code) {
      dispatch(getTokensByCode({ code }));
    }
  }, []);

  return isAuthenticated ? <Redirect to={HOME} /> : <>Redirect...</>;
};

export default AuthRedirect;

export const STATISTIC_WIDGET = 'widget.statistic';
export const USERS_WIDGET = 'widget.individual-cases';
export const SOCIAL_MEDIA_WIDGET = 'widget.social-media';
export const PROJECT_MANAGEMENT_WIDGET = 'widget.project-management';
export const KNOWLEDGE_MANAGEMENT_WIDGET = 'widget.knowledge-management';
export const WORFLOWS_WIDGET = 'widget.workflows';
export const INVOICE_WIDGET = 'widget.invoice';
export const FAQ_WIDGET = 'widget.faq';
export const STRIPO_WIDGET = 'widget.stripo';
export const STATISTICS_LOOKER_WIDGET = 'widget.statistics-looker';

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { Box, Tab, Tabs } from '@mui/material';
import {
  lookerDashboardsLoadingSelector,
  lookerDashboardsSelector,
  lookerDashboardUrlsLoadingSelector,
  lookerDashboardUrlsSelector
} from '../../../../store/selectors/looker';
import {
  getLookerDashboard,
  getLookerDashboards
} from '../../../../store/actions';

import { Module } from '@troy/shared/src/components/common';

import blurred from '../../../../assets/img/statistics-looker/blurred-mockup.png';
import ContactModal from './ContactModal';

const useStyles = makeStyles(theme => ({
  content: {
    width: '100%',
    margin: '32px 16px 16px 16px',
    display: 'flex',
    flexDirection: 'column'
  },
  dash: {
    height: '100%',
    display: 'flex',
    flexDirection: 'row'
  },
  menu: {
    flexShrink: 0,
    display: 'flex',
    width: 177
  },
  tabScrollButton: {
    height: 24
  },
  tabButton: {
    padding: '8px 16px',
    fontSize: '16px',
    fontWeight: 'bold',
    lineHeight: '24px',
    opacity: 1,
    color: theme.palette.text.hint,
    alignItems: 'flex-start',
    textAlign: 'left',
    textTransform: 'none'
  },
  tabButtonSelected: {
    color: theme.palette.text.primary
  },
  iframeWrapper: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    background: theme.palette.text.disabled
  },
  iframe: {
    width: '100%',
    height: '100%'
  },
  blurImage: {
    width: '100%',
    height: '100%',
    background: `url('${blurred}')`,
    backgroundPosition: 'left center',
    backgroundSize: 'cover'
  }
}));

const StatisticsLookerExtendedWidget = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const dashboards = useSelector(lookerDashboardsSelector);
  const loadingDashboards = useSelector(lookerDashboardsLoadingSelector);
  const dashboardUrls = useSelector(lookerDashboardUrlsSelector);
  const dashboardUrlsLoading = useSelector(lookerDashboardUrlsLoadingSelector);

  const [currentItem, setCurrentItem] = useState(null);
  const [tabValue, setTabValue] = useState(0);
  const [modalOpen, setModalOpen] = useState(true);

  useEffect(() => {
    if (!loadingDashboards && dashboards.length === 0) {
      dispatch(getLookerDashboards());
    }
  }, [dashboards, loadingDashboards]);

  useEffect(() => {
    if (currentItem === null && dashboards.length > 0) {
      setCurrentItem(dashboards[0]);
    }
  }, [currentItem, dashboards, setCurrentItem]);

  useEffect(() => {
    if (
      currentItem &&
      !dashboardUrls[currentItem.id] &&
      !dashboardUrlsLoading[currentItem.id]
    ) {
      dispatch(getLookerDashboard(currentItem.id));
    }
  }, [currentItem, dashboardUrls, dashboardUrlsLoading]);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    setCurrentItem(dashboards[newValue]);
  };

  const closeModal = () => setModalOpen(false);

  const iframeUrl =
    currentItem &&
    dashboardUrls[currentItem.id] &&
    dashboardUrls[currentItem.id].url;

  return (
    <Box className={classes.content}>
      <Module className={classes.dash}>
        <Box className={classes.menu}>
          <Tabs
            orientation="vertical"
            variant="scrollable"
            scrollButtons
            indicatorColor="primary"
            value={tabValue}
            onChange={handleTabChange}
            aria-label="Dashboard tabs"
            classes={{
              scrollButtons: classes.tabScrollButton
            }}
            allowScrollButtonsMobile
          >
            {dashboards.map(item => {
              return (
                <Tab
                  key={item.name}
                  label={item.name}
                  id={`dashboard-tab-${item.id}`}
                  classes={{
                    root: classes.tabButton,
                    selected: classes.tabButtonSelected
                  }}
                />
              );
            })}
          </Tabs>
        </Box>
        <Box className={classes.iframeWrapper}>
          {!!iframeUrl && (
            <iframe
              title={currentItem.title}
              className={classes.iframe}
              src={iframeUrl}
            />
          )}
          {iframeUrl === '' && (
            <>
              <div className={classes.blurImage} />
              {modalOpen && <ContactModal onClick={closeModal} />}
            </>
          )}
        </Box>
      </Module>
    </Box>
  );
};

export default StatisticsLookerExtendedWidget;

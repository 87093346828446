import React, { useCallback, useMemo } from 'react';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import { alpha } from '@mui/material/styles';
import { Theme } from '@mui/material';

import { SelectOption } from '../../../types/select';
import { useTranslationsContext } from '../TranslationProvider';
import {
  CustomIcon,
  FieldErrorMessage,
  FormSelectField,
  Text,
  Translation
} from '../index';

export const useFormSelectStyles = makeStyles((theme: Theme) => ({
  select: {
    border: 'none',
    borderRadius: Number(theme.shape.borderRadius) * 8,
    overflow: 'hidden',
    minWidth: 152,
    maxWidth: '100%',

    '&:before, &:after': {
      content: 'unset'
    },
    '& > fieldset': {
      borderColor: theme.palette.text.primary,
      top: -4
    }
  },
  wide: {
    width: 248
  },
  fullWidth: {
    width: '100%'
  },
  selectMenu: {
    borderRadius: Number(theme.shape.borderRadius) * 4,
    boxShadow: '0px 2px 12px rgba(0, 0, 0, 0.2)',
    overflow: 'hidden'
  },
  selectError: {
    borderColor: theme.palette.warning.dark,
    background: alpha(theme.palette.warning.dark, 0.1)
  },
  selectRoot: {
    padding: `${theme.spacing(0.5)} ${theme.spacing(3)} ${theme.spacing(
      0.5
    )} ${theme.spacing(2)}`
  },
  selectRootLarge: {
    padding: `${theme.spacing(1)} ${theme.spacing(5)} ${theme.spacing(
      1
    )}  calc(${theme.spacing(3)} - 2px) !important`
  },
  extraDisplay: {
    marginLeft: theme.spacing(1)
  },
  selectIcon: {
    top: '50%',
    right: theme.spacing(1),
    transform: 'translateY(-50%)'
  },
  selectIconLarge: {
    top: '50%',
    right: theme.spacing(1.75),
    transform: 'translateY(-50%)'
  },
  selectItem: {
    ...theme.typography.body2,
    padding: 16,
    color: theme.palette.text.primary,

    '&:hover:not([aria-selected="true"])': {
      color: theme.palette.secondary.contrastText
    },
    '&[aria-selected="true"]': {
      backgroundColor: 'rgba(0, 0, 0, 0.08)'
    },
    '&:hover[aria-selected="true"]': {
      backgroundColor: 'rgba(0, 0, 0, 0.08)'
    },
    '&:focus': {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.secondary.contrastText
    }
  },
  selectItemNoSelect: {
    cursor: 'default',
    whiteSpace: 'normal',

    '& span': {
      display: 'none'
    },
    '&:hover': {
      backgroundColor: 'inherit'
    },
    '&:hover:not([aria-selected="true"])': {
      color: 'inherit'
    },
    '&[aria-selected="true"]': {
      backgroundColor: 'inherit'
    },
    '&:hover[aria-selected="true"]': {
      backgroundColor: 'inherit'
    }
  },
  labelContainer: {
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  error: {
    width: 216,
    maxWidth: '100%'
  },
  errorFullWidth: {
    width: '100%'
  },
  details: {
    ...theme.typography.body1,
    marginLeft: theme.spacing(2),
    display: 'inline-block',
    color: theme.palette.text.primary
  }
}));

interface FormSelectProps {
  id?: string;
  className?: string;
  classNameMenuItem?: string;
  name?: string;
  options?: SelectOption[];
  value?: SelectOption['value'];
  onChange?: (value: SelectOption['value']) => void;
  bold?: boolean;
  large?: boolean;
  wide?: boolean;
  fullWidth?: boolean;
  detailText?: string;
  errorMessageKey?: string;
  errorFullWidth?: boolean;
}

const FormSelect: React.FC<FormSelectProps> = ({
  id,
  className,
  classNameMenuItem,
  name,
  options = [],
  value,
  onChange,
  bold,
  large,
  wide,
  fullWidth,
  detailText,
  errorMessageKey,
  errorFullWidth
}) => {
  const classes = useFormSelectStyles();
  const { translate } = useTranslationsContext();

  const translatedOptions = useMemo(() => {
    return options.map(o => {
      const translated = translate(o.label, true);
      const extra = o.extraLabel;

      return {
        ...o,
        originalLabel: translated,
        label: `${translated}${!!extra ? ` ${extra}` : ''}`
      };
    });
  }, [options]);

  const handleChange = useCallback(
    e => {
      if (onChange) {
        onChange(e.target.value);
      }
    },
    [onChange]
  );

  const error = !!errorMessageKey;

  return (
    <>
      <FormSelectField
        id={id}
        className={clsx(
          classes.select,
          wide && classes.wide,
          fullWidth && classes.fullWidth,
          error && classes.selectError,
          className
        )}
        classNameRoot={large ? classes.selectRootLarge : classes.selectRoot}
        classNamePaper={classes.selectMenu}
        classNameMenuItem={clsx(classes.selectItem, classNameMenuItem)}
        name={name}
        options={translatedOptions}
        value={value}
        onChange={handleChange}
        renderValue={(v: SelectOption['value']): React.ReactElement => {
          const option = translatedOptions.find(o => o.value === v);

          return (
            <div className={classes.labelContainer}>
              <Text
                component="span"
                variant={bold ? 'body1Bold' : 'body1'}
                color={error ? 'warningDark' : 'textPrimary'}
              >
                {option?.originalLabel}
              </Text>
              {!!option?.extraLabel && (
                <Text
                  className={classes.extraDisplay}
                  component="span"
                  variant={bold ? 'body1Bold' : 'body1'}
                  color="textSecondary"
                >
                  {option?.extraLabel}
                </Text>
              )}
            </div>
          );
        }}
        iconComponent={({
          className
        }: {
          className?: string;
        }): React.ReactElement => (
          <CustomIcon
            className={clsx(
              className,
              large ? classes.selectIconLarge : classes.selectIcon
            )}
            icon="expandMore"
            size={large ? 'small' : 'xs'}
            variant="textPrimary"
          />
        )}
      />
      <FieldErrorMessage
        visible
        className={clsx(
          classes.error,
          errorFullWidth && classes.errorFullWidth
        )}
        errorMessageKey={errorMessageKey}
      />
      {detailText && (
        <Translation className={classes.details}>{detailText}</Translation>
      )}
    </>
  );
};

export default FormSelect;

import { authorizedGet } from '../utils/api';
import { LOOKER_DASHBOARD, LOOKER_DASHBOARDS } from '../constants/api';

class LookerService {
  static getDashboards = token => authorizedGet(LOOKER_DASHBOARDS, token);
  static getDashboard = (token, dashboardId) =>
    authorizedGet(LOOKER_DASHBOARD(dashboardId), token);
}

export default LookerService;

import React, { useEffect } from 'react';
import { Route, Router, Switch } from 'react-router-dom';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { AnimatePresence, AnimateSharedLayout } from 'framer-motion';

import { createMuiTroyTheme } from '@troy/shared/src/config/theme';
import { getPortalConfiguration } from '@troy/shared/src/store/actions';
import {
  LoggerComponent,
  SnackbarManager,
  SnackbarProviderStyled,
  TranslationProvider
} from '@troy/shared/src/components/common';
import FontsNumerical from '@troy/shared/src/components/fonts/FontsNumerical';
import fontsLoader from '@troy/shared/src/components/fonts/fontsWhitelabel';
import {
  cognitoTokenTimestampExpirationSelector,
  cognitoUsernameFromTokenSelector,
  isCognitoAuthenticatedSelector,
  portalBrandNameSelector,
  portalConfigurationSelector,
  portalCountyExperienceSelector,
  portalFavIconSelector,
  portalIdentifierSelector,
  translationsValuesSelector
} from './store/selectors';
import { setDynamicFavIcon } from '@troy/shared/src/utils/ui';

import store from './store';
import logger from './utils/logger';
import sagaMiddleware from './store/middleware/saga';
import saga from './store/sagas';

import * as routes from './constants/routes';
import history from './config/history';
import {
  handleError,
  refreshTokens,
  setDecisionsUsername
} from './store/actions';

import { registerOnClickWrapperHandler } from './utils/ui';
import AuthRedirect from './pages/AuthRedirect';
import { MainLayout } from './layouts';
import { Home, Login, NotFound, Version } from './pages';

const ConnectedApp = () => {
  registerOnClickWrapperHandler();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      getPortalConfiguration({
        portalKeyBoolean: 'clientAppEnabled',
        handleErrorAction: handleError
      })
    );
  }, []);

  const username = useSelector(cognitoUsernameFromTokenSelector);
  const tokenExpiration = useSelector(cognitoTokenTimestampExpirationSelector);
  const isAuthenticated = useSelector(isCognitoAuthenticatedSelector);

  const portalCountyExperience = useSelector(portalCountyExperienceSelector);
  const portalBrand = useSelector(portalBrandNameSelector);
  const portalIdentifier = useSelector(portalIdentifierSelector);
  const portalConf = useSelector(portalConfigurationSelector);
  const favIcon = useSelector(portalFavIconSelector);

  useEffect(() => {
    if (!!portalCountyExperience && !!portalIdentifier) {
      setDynamicFavIcon(favIcon);
      fontsLoader(portalIdentifier).catch(err => console.error(err));
    }
  }, [portalCountyExperience, portalIdentifier, favIcon]);

  useEffect(() => {
    if (portalBrand) {
      document.title = `${portalBrand.toLowerCase()} | _client`;
    }
  }, [portalBrand]);

  useEffect(() => {
    let timeout;

    if (new Date().getTime() >= tokenExpiration) {
      dispatch(refreshTokens());
    } else {
      if (timeout) {
        clearTimeout(timeout);
      }
      timeout = setTimeout(() => {
        dispatch(refreshTokens());
      }, tokenExpiration - new Date().getTime());
    }

    return () => clearTimeout(timeout);
  }, [tokenExpiration]);

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(setDecisionsUsername(username));
    }
  }, [isAuthenticated]);

  return (
    <>
      <Router history={history}>
        <TranslationProvider
          translationsDataSelector={translationsValuesSelector}
        >
          {portalCountyExperience && (
            <StyledEngineProvider injectFirst>
              <ThemeProvider theme={createMuiTroyTheme(portalConf)}>
                <AnimateSharedLayout>
                  <AnimatePresence>
                    <SnackbarProviderStyled>
                      <MainLayout>
                        {isAuthenticated ? (
                          <Switch>
                            <Route exact path={routes.HOME} component={Home} />
                            <Route
                              exact
                              path={routes.AUTH_REDIRECT}
                              component={AuthRedirect}
                            />
                            <Route path={routes.VERSION} component={Version} />
                            <Route component={NotFound} />
                          </Switch>
                        ) : (
                          <Switch>
                            <Route exact path={routes.HOME} component={Login} />
                            <Route
                              exact
                              path={routes.AUTH_REDIRECT}
                              component={AuthRedirect}
                            />
                            <Route path={routes.VERSION} component={Version} />
                            <Route component={NotFound} />
                          </Switch>
                        )}
                      </MainLayout>
                      <SnackbarManager />
                    </SnackbarProviderStyled>
                  </AnimatePresence>
                </AnimateSharedLayout>
              </ThemeProvider>
            </StyledEngineProvider>
          )}
        </TranslationProvider>
      </Router>
      <FontsNumerical />
    </>
  );
};

const App = () => (
  <LoggerComponent logger={logger}>
    <Provider store={store}>
      <ConnectedApp />
    </Provider>
  </LoggerComponent>
);

export default App;

sagaMiddleware.run(saga);

import { call, put, select } from 'redux-saga/effects';

import ApiService from '../../services/ApiService';
import { enqueueSnackbar } from '@troy/shared/src/store/actions';
import { Translation } from '@troy/shared/src/components/common';

import {
  handleError,
  liabilityCloseReasonsReceived,
  setLiabilityClosed,
  setUsersTabValue
} from '../actions';
import { cognitoTokenSelector } from '../selectors';

import { LIABILITY_TAB } from '../../constants/users-tabs';

import { toUTCISOString as formatDate } from '../../utils/date-utils';

export function* getLiabilityCloseReasons() {
  try {
    const token = yield select(cognitoTokenSelector);
    const closeReasons = yield call(ApiService.getLiabilityCloseReasons, token);
    yield put(liabilityCloseReasonsReceived(closeReasons));
  } catch (err) {
    yield put(handleError(err));
  }
}

export function* closeLiability({ payload: { closeReason, liabilityId } }) {
  try {
    const token = yield select(cognitoTokenSelector);
    const body = {
      request: {
        reason: closeReason
      }
    };
    yield call(ApiService.postCloseReason, liabilityId, body, token);
    yield put(
      enqueueSnackbar({
        message: (
          <Translation isFragment>
            CP_INDIVIDUALCASE_EDIT_CLOSECASE_CONFIRM
          </Translation>
        ),
        options: {
          variant: 'success'
        }
      })
    );
    yield put(setLiabilityClosed(true));
  } catch (err) {
    yield put(handleError(err));
  }
}

export function* reportCreditMemo({
  payload: { value, date, optionalInfo, liabilityId }
}) {
  try {
    const token = yield select(cognitoTokenSelector);
    const body = {
      request: {
        amount: value,
        date: formatDate(date),
        description: optionalInfo || null
      }
    };
    yield call(ApiService.postReportCreditMemo, liabilityId, body, token);
    yield put(
      enqueueSnackbar({
        message: (
          <Translation isFragment>
            CP_INDIVIDUALCASE_EDIT_ENTERCREDITNOTE_CONFIRM
          </Translation>
        ),
        options: {
          variant: 'success'
        }
      })
    );
    yield put(setUsersTabValue(LIABILITY_TAB));
  } catch (err) {
    yield put(handleError(err));
  } finally {
  }
}

export function* reportPayment({
  payload: { reportAmount, reportDate, additionalInfo, liabilityId }
}) {
  try {
    const token = yield select(cognitoTokenSelector);
    const body = {
      request: {
        amount: {
          amount: reportAmount,
          currency: 'EUR'
        },
        paymentDate: formatDate(reportDate),
        note: additionalInfo || null
      }
    };
    yield call(ApiService.postReportPayment, liabilityId, body, token);
    yield put(
      enqueueSnackbar({
        message: (
          <Translation isFragment>
            CP_INDIVIDUALCASE_EDIT_REPORTPAYMENT_CONFIRM
          </Translation>
        ),
        options: {
          variant: 'success'
        }
      })
    );

    yield put(setUsersTabValue(LIABILITY_TAB));
  } catch (err) {
    yield put(handleError(err));
  }
}

export function* contactForm({
  payload: { message = '', documents = [], liabilityId, onLoad }
}) {
  try {
    const token = yield select(cognitoTokenSelector);
    const body = {
      request: {
        message: message,
        documents: documents
      }
    };
    yield call(ApiService.postContactForm, liabilityId, body, token);
    yield put(
      enqueueSnackbar({
        message: (
          <Translation isFragment>CP_CLIENT_CONTACTFORM_CONFIRM</Translation>
        ),
        options: {
          variant: 'success'
        }
      })
    );
    onLoad && onLoad();
  } catch (err) {
    yield put(handleError(err));
  }
}

export function* onHold({ payload: { date, isEndless, liabilityId } }) {
  try {
    const token = yield select(cognitoTokenSelector);
    const body = {
      request: {
        date: date ? formatDate(date) : null,
        endlessOnHold: isEndless || false
      }
    };
    yield call(ApiService.postHold, liabilityId, body, token);
    yield put(
      enqueueSnackbar({
        message: (
          <Translation isFragment>
            CP_INDIVIDUALCASE_EDIT_DUNNINGSTOP_CONFIRM
          </Translation>
        ),
        options: {
          variant: 'success'
        }
      })
    );

    yield put(setUsersTabValue(LIABILITY_TAB));
  } catch (err) {
    yield put(handleError(err));
  }
}

import { createAction } from 'redux-act';
import { WhitelabelConfType } from '../../types/whitelabel';

export const initPortal = createAction('Initialize portal');

export const getPortalConfiguration = createAction<{
  portalKeyBoolean:
    | 'customerAppEnabled'
    | 'agentAppEnabled'
    | 'clientAppEnabled';
  handleErrorAction: (err: undefined) => {};
}>('Get portal configuration');
export const portalConfigurationReceived = createAction<WhitelabelConfType>(
  'Portal configuration received'
);

export const setPortalInitialized = createAction<boolean>(
  'Set portal initialized'
);

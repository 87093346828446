import { call, put, select } from 'redux-saga/effects';

import {
  clearDecisionsStore,
  clearQuickSightStore,
  clearTrelloStore,
  federationTokenReceived,
  handleError,
  sessionReceived,
  tokensReceived
} from '../actions';
import {
  cognitoSessionSelector,
  cognitoTokensSelector,
  currentUserSelector
} from '../selectors';
import { historyReplace } from '../utils';

import { HOME } from '../../constants/routes';

import { LOGIN_FORM } from '../../constants/formNames';

import cognitoService from '../../services/CognitoService';
import config from '../../config/sso-config';
import { TOKEN_URL } from '../../constants/api/cognito';
import { post } from '../../utils/api';

export function* logIn({
  payload: { email, password, rememberUser, redirectCompletePassword }
}) {
  try {
    const authData = yield cognitoService.logIn(
      email,
      password,
      rememberUser,
      redirectCompletePassword
    );
    yield put(sessionReceived(authData));
    yield put(historyReplace(HOME));
  } catch (err) {
    yield put(handleError(err));
  }
}

export function* logOut() {
  try {
    yield put(tokensReceived(null));

    yield cognitoService.logOut();
    yield put(clearTrelloStore());
    yield put(clearQuickSightStore());
    yield put(clearDecisionsStore());
  } catch (err) {
    yield put(handleError(err));
  }
}

export function* getSession() {
  try {
    const session = yield call(
      cognitoService.getSession,
      cognitoService.loadCognitoUserFromLocalStorage()
    );
    yield put(sessionReceived(session));
  } catch (err) {
    yield put(handleError(err));
  }
}

export function* refreshSession() {
  const session = yield select(cognitoSessionSelector);
  const user = yield select(currentUserSelector);
  try {
    const newSession = yield call(cognitoService.refreshSession, user, session);
    yield put(sessionReceived(newSession));
  } catch (err) {
    yield put(handleError(err));
  }
}

export function* getFederationToken() {
  let session = yield select(cognitoSessionSelector);
  try {
    if (!session) {
      session = yield call(
        cognitoService.getSession,
        cognitoService.loadCognitoUserFromLocalStorage()
      );
    }
    const token = yield call(
      cognitoService.setCredentials,
      session.getIdToken().getJwtToken()
    );
    yield put(federationTokenReceived(token));
  } catch (err) {
    yield put(handleError(err));
  }
}

export function* getTokensByCode({ payload }) {
  const { code } = payload;
  try {
    const data = {
      code,
      grant_type: 'authorization_code',
      redirect_uri: config.redirect_uri,
      client_id: config.client_id
    };

    const formData = new URLSearchParams({
      ...data
    }).toString();
    const tokens = yield call(post, TOKEN_URL, formData);
    yield put(tokensReceived(tokens));
    yield put(historyReplace(HOME));
  } catch (err) {
    yield put(handleError(err));
    yield put(historyReplace(HOME));
  }
}

export function* refreshTokens() {
  const tokens = yield select(cognitoTokensSelector);
  if (!tokens) {
    return;
  }
  try {
    const data = {
      refresh_token: tokens.refresh_token,
      grant_type: 'refresh_token',
      client_id: config.client_id
    };

    const formData = new URLSearchParams({
      ...data
    }).toString();

    const response = yield call(post, TOKEN_URL, formData);
    yield put(tokensReceived(response));
  } catch (err) {
    yield call(logOut);
    yield put(historyReplace(HOME));
    yield put(handleError(err));
  }
}

import React from 'react';
import { SnackbarProvider } from 'notistack';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

const useStyles = makeStyles((theme: Theme) => ({
  bar: {
    minWidth: 210
  }
}));

export interface DecisionProps {
  children: JSX.Element;
}

const SnackbarProviderStyled = ({
  children
}: DecisionProps): React.ReactElement => {
  const classes = useStyles();

  return (
    <SnackbarProvider classes={{ root: classes.bar }}>
      {children}
    </SnackbarProvider>
  );
};

export default SnackbarProviderStyled;

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { makeStyles } from '@mui/styles';

import { Grid } from '../../common';
import DragableGrid from '../DragableGrid';
import AddWidgetModal from '../AddWidgetModal';

import {
  FAQWidget,
  InvoiceWidget,
  KnowledgeManagementWidget,
  ProjectManagementWidget,
  SocialMediaWidget,
  StatisticsLookerWidget,
  StatisticWidget,
  StripoWidget,
  UsersWidget,
  WorkFlowsWidget
} from '../Widgets';
import * as widgetConstants from '../../../constants/widgets';

import { widgetListWidth } from '../../../assets/styles/jss_functions';

const useStyles = makeStyles(theme => ({
  list: {
    flex: 1
  },
  content: {
    margin: `${theme.spacing(5)} auto ${theme.spacing(2.5)} auto`,
    ...widgetListWidth
  }
}));

const WidgetList = ({
  onExtendedClose,
  extendedComponent,
  setExtended,
  tokenExpiration,
  disabledWidgets,
  widgets,
  enabledWidgets,
  getDecisions,
  disableWidget,
  enableWidgets,
  isPending
}) => {
  const classes = useStyles();

  const WIDGETS = useMemo(
    () => [
      {
        key: widgetConstants.STATISTIC_WIDGET,
        component: StatisticWidget,
        name: 'CP_START_STATISTICS_HEADER',
        nameTranslated: true
      },
      {
        key: widgetConstants.STATISTICS_LOOKER_WIDGET,
        component: StatisticsLookerWidget,
        name: 'CP_START_STATISTICS_HEADER',
        nameTranslated: true
      },
      {
        key: widgetConstants.FAQ_WIDGET,
        component: FAQWidget,
        name: 'CP_START_FAQ_HEADER',
        nameTranslated: true
      },
      {
        key: widgetConstants.STRIPO_WIDGET,
        component: StripoWidget,
        name: 'CP_START_EMAILDESIGNER_HEADER',
        nameTranslated: true
      },
      {
        key: widgetConstants.USERS_WIDGET,
        component: UsersWidget,
        name: 'CP_START_INDIVIDUALCASE_HEADER',
        nameTranslated: true
      },
      {
        key: widgetConstants.SOCIAL_MEDIA_WIDGET,
        component: SocialMediaWidget,
        name: 'CP_START_SOCIALMEDIAWIDGET_HEADER',
        nameTranslated: true
      },
      {
        key: widgetConstants.PROJECT_MANAGEMENT_WIDGET,
        component: ProjectManagementWidget,
        name: 'CP_START_PROJECTMANAGEMENT_HEADER',
        nameTranslated: true
      },
      {
        key: widgetConstants.KNOWLEDGE_MANAGEMENT_WIDGET,
        component: KnowledgeManagementWidget,
        name: 'CP_START_KNOWLEDGE',
        nameTranslated: false
      },
      {
        key: widgetConstants.WORFLOWS_WIDGET,
        component: WorkFlowsWidget,
        name: 'CP_START_WORKFLOWS_HEADER',
        nameTranslated: true
      },
      {
        key: widgetConstants.INVOICE_WIDGET,
        component: InvoiceWidget,
        name: 'CP_START_BILLING_HEADER',
        nameTranslated: true
      }
    ],
    []
  );

  const [orderedWidgets, setWidgets] = useState(WIDGETS);

  const KEY_TO_WIDGET_PROPS = useMemo(
    () =>
      WIDGETS.reduce(
        (acc, { key, ...props }) => ({ ...acc, [key]: props }),
        {}
      ),
    [WIDGETS]
  );

  const showedWidgets = useMemo(() => {
    return orderedWidgets.filter(
      ({ key }) => enabledWidgets && enabledWidgets.has(key)
    );
  }, [enabledWidgets, orderedWidgets]);

  const removedWidgets = useMemo(
    () =>
      disabledWidgets
        ? Array.from(disabledWidgets).map(key => ({
            key,
            ...KEY_TO_WIDGET_PROPS[key]
          }))
        : [],
    [disabledWidgets, KEY_TO_WIDGET_PROPS]
  );

  useEffect(() => {
    if (!widgets && new Date().getTime() < tokenExpiration) {
      getDecisions();
    }
  }, [tokenExpiration]);

  const onWidgetElementRemove = useCallback(
    key => {
      disableWidget(key);
    },
    [disableWidget]
  );

  const [isAddModalOpen, setAddModalOpened] = useState(false);
  const onAddWidgetClick = () => {
    setAddModalOpened(true);
  };
  const onAddModalClose = () => {
    setAddModalOpened(false);
  };

  const onItemsAdd = useCallback(
    items => {
      onAddModalClose();
      enableWidgets(items);
    },
    [onAddModalClose, enableWidgets]
  );

  return (
    <div className={classes.list}>
      <Grid container className={classes.content}>
        <DragableGrid
          {...{
            widgets: showedWidgets,
            onExtendedClose,
            extendedComponent,
            setExtended,
            setWidgets,
            isPending,
            maxWidgetsLength: WIDGETS.length
          }}
          onElementRemove={onWidgetElementRemove}
          onAddWidgetClick={onAddWidgetClick}
        />
      </Grid>
      <AddWidgetModal
        isOpen={isAddModalOpen}
        onClose={onAddModalClose}
        widgets={removedWidgets}
        onAdd={onItemsAdd}
      />
    </div>
  );
};

export default WidgetList;

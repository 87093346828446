export const importAllFiles = r => {
  let keys = r.keys();
  const values = keys.map(r);
  return keys.reduce(
    (acc, key, ind) => ({ ...acc, [key.substring(2)]: values[ind] }),
    {}
  );
};

export const IS_MOCK_IMPORT =
  process.env.REACT_APP_ENV === 'test' ||
  process.env.JEST_WORKER_ID !== undefined;

export const MOCK_FILE = { image: { default: 'image' } };

export default IS_MOCK_IMPORT
  ? MOCK_FILE
  : importAllFiles(
      require.context(
        './',
        true,
        /\.(png|PNG|jpg|JPG|jpeg|JPEG|svg|SVG|bmp|BMP|gif|GIF|woff2|woff|ttf)$/
      )
    );

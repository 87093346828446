import React from 'react';

import { Pagination } from '@mui/material';
import { PaginationItem } from '@mui/lab';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  item: {
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)'
    }
  },
  disabledButton: {
    backgroundColor: 'transparent',
    color: theme.palette.text.disabled
  }
}));

export default ({ color = 'primary', paginationItemProps, ...props }) => {
  const classes = useStyles();
  return (
    <Pagination
      color={color}
      renderItem={item => (
        <PaginationItem
          classes={{ root: classes.item, disabled: classes.disabledButton }}
          {...item}
          {...paginationItemProps}
        />
      )}
      {...props}
    />
  );
};

import snackbar, { SnackBarReducerState } from './snackbar';
import postBox, { PostBoxReducerState } from './post-box';
import portal, { PortalReducerState } from './portal';

export interface StoreState {
  snackbar: SnackBarReducerState;
  postBox: PostBoxReducerState;
  portal: PortalReducerState;
}

const reducer = {
  snackbar,
  postBox,
  portal
};

export default reducer;

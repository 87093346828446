import { createAction } from 'redux-act';

export const getLookerDashboards = createAction(
  'Looker: Get available dashboards list'
);

export const lookerDashboardsReceived = createAction(
  'Looker: Got dashboards list'
);

export const getLookerDashboard = createAction('Looker: Get one dashboard url');
export const lookerDashboardReceived = createAction(
  'Looker: Got a dashboard url'
);

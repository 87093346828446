import React, { DOMAttributes, useMemo } from 'react';
import clsx from 'clsx';
import { Theme, Typography, TypographyTypeMap } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTranslationsContext } from '../TranslationProvider';

const useStyles = makeStyles((theme: Theme) => {
  const {
    h1Regular,
    h1,
    h2Regular,
    h2,
    h3,
    h4,
    h5,
    h6,
    subtitle1,
    subtitle2,
    subtitle2Bold,
    subtitle3,
    body1,
    body1Bold,
    body2,
    body2Bold,
    body3,
    numerical1,
    numerical2,
    numerical3,
    button,
    caption,
    overline
  } = theme.typography;

  return {
    h1Regular,
    h1,
    h2Regular,
    h2,
    h3,
    h4,
    h5,
    h6,
    subtitle1,
    subtitle2,
    subtitle2Bold,
    subtitle3,
    body1,
    body2,
    button,
    caption,
    overline,
    body1Bold,
    body2Bold,
    body3,
    numerical1,
    numerical2,
    numerical3,
    primaryContrast: {
      color: theme.palette.primary.contrastText
    },
    primaryDark: {
      color: theme.palette.primary.dark
    },
    secondaryContrast: {
      color: theme.palette.secondary.contrastText
    },
    secondaryDark: {
      color: theme.palette.secondary.dark
    },
    tertiaryDark: {
      color: theme.palette.tertiary.dark
    },
    textDisabled: {
      color: theme.palette.text.disabled
    },
    textHint: {
      color: theme.palette.text.hint
    },
    textSecondary: {
      color: theme.palette.text.secondary
    },
    warning: {
      color: theme.palette.warning.main
    },
    warningLight: {
      color: theme.palette.warning.light
    },
    warningDark: {
      color: theme.palette.warning.dark
    },
    warningDarkFill: {
      color: theme.customColors.warningDarkFill
    },
    warningDarkFocus: {
      color: theme.customColors.warningDarkFocus
    },
    grey500: {
      color: theme.palette.grey['500']
    }
  };
});

export const CustomColors = [
  'primaryContrast',
  'primaryDark',
  'secondaryContrast',
  'secondaryDark',
  'tertiaryDark',
  'textDisabled',
  'textHint',
  'textSecondary',
  'warning',
  'warningLight',
  'warningDark',
  'warningDarkFill',
  'warningDarkFocus',
  'grey500'
] as const;
export type CustomColorsType = typeof CustomColors[number];

export type CustomColor =
  | TypographyTypeMap['props']['color']
  | CustomColorsType;

interface TextProps {
  className?: string;
  id?: string;
  translated?: boolean;
  variant?: TypographyTypeMap['props']['variant'];
  component: React.ElementType;
  color?: CustomColor;
  align?: TypographyTypeMap['props']['align'];
  htmlFor?: string;
  dangerouslySetInnerHTML?: DOMAttributes<HTMLElement>['dangerouslySetInnerHTML'];
  children?:
    | JSX.Element
    | JSX.Element[]
    | string
    | string[]
    | number
    | boolean
    | null;
}

const Text: React.FC<TextProps> = ({
  className,
  id,
  translated,
  variant = 'body1',
  component,
  color = 'textPrimary',
  align,
  htmlFor,
  dangerouslySetInnerHTML,
  children
}) => {
  const classes = useStyles();
  const { translate } = useTranslationsContext();

  const colorClass = useMemo(() => {
    let cClass;
    if (CustomColors.includes(color as CustomColorsType)) {
      cClass = classes[color as CustomColorsType];
    }
    return cClass;
  }, [color, classes]);

  return (
    <Typography
      className={clsx(colorClass, className)}
      id={id}
      variant={variant}
      component={component}
      color={
        colorClass ? undefined : (color as TypographyTypeMap['props']['color'])
      }
      align={align}
      htmlFor={htmlFor}
      dangerouslySetInnerHTML={dangerouslySetInnerHTML}
    >
      {translated ? translate(children?.toString() ?? '', true) : children}
    </Typography>
  );
};

export default Text;

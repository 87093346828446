import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';

import {
  CustomIcon,
  FormCheckbox,
  Translation,
  useTranslationsContext
} from '@troy/shared/src/components/common';

import { languageSelector } from '../../../store/selectors';

import { DE } from '../../../constants/i18n/countries';
import { codeFromBrowserCode } from '../../../utils/translations';
import {
  widgetHeight,
  widgetWidth
} from '../../../assets/styles/jss_functions';

import { Button, CheckIcon, Modal } from '../../common';

const ADD_KEY = 'CP_LABEL_ADD_SMALL';

const paperPadding = 32;
const headerMargin = 24;
const buttonMargin = 24;

const useStyles = makeStyles(theme => ({
  modal: {
    maxHeight: 'calc(100vh - 64px)',
    padding: 0
  },
  content: {
    position: 'relative',
    padding: paperPadding
  },
  closeWrapper: {
    position: 'absolute !important',
    top: 8,
    right: 8
  },
  close: {
    padding: 0
  },
  header: {
    textAlign: 'center',
    marginBottom: headerMargin,
    fontWeight: 'bold'
  },
  list: {
    overflow: 'auto',
    // 100vh - 2 * paper padding - header height - header margin - button height - button margin - dialog margin
    maxHeight: `calc(100vh - 2 * ${paperPadding}px - 24px - ${headerMargin}px - 40px - ${buttonMargin}px - 64px)`,

    '& > *:not(:last-child)': {
      paddingBottom: 40,
      borderBottom: `1px solid ${theme.palette.text.disabled}`,
      marginBottom: 40
    }
  },
  itemWrapper: {
    paddingBottom: 16,
    cursor: 'pointer'
  },
  item: {
    padding: 16,
    background: theme.palette.secondary.dark,
    borderRadius: theme.shape.borderRadius * 8,
    pointerEvents: 'none'
  },
  widgetWrapper: {
    height: widgetHeight,
    width: widgetWidth
  },
  addWrapper: {
    marginTop: buttonMargin,
    display: 'flex',
    justifyContent: 'flex-end'
  },
  add: {
    minWidth: '130px !important'
  },
  noWidgets: {
    padding: 60,
    margin: 0
  }
}));

export default ({ className, isOpen, onClose, widgets, onAdd }) => {
  const language = useSelector(languageSelector);
  const classes = useStyles();
  const { translate } = useTranslationsContext();

  const [addWidgetsState, setAddWidgetsState] = useState(
    (widgets || []).reduce((acc, { key }) => ({ ...acc, [key]: false }), {})
  );
  useEffect(() => {
    setAddWidgetsState(state => ({
      ...widgets.reduce((acc, { key }) => ({ ...acc, [key]: false }), {}),
      ...state
    }));
  }, [widgets]);

  const onItemCheck = useCallback(
    itemId => (event, value) => {
      setAddWidgetsState(state => ({
        ...state,
        [itemId]: typeof value === 'undefined' ? !state[itemId] : value
      }));
    },
    []
  );

  const onAddClick = useCallback(() => {
    if (onAdd) {
      onAdd(Object.keys(addWidgetsState).filter(key => addWidgetsState[key]));
    }
    setAddWidgetsState(state =>
      Object.keys(state).reduce((acc, key) => ({ ...acc, [key]: false }), {})
    );
  }, [onAdd, addWidgetsState]);

  const addText = translate(ADD_KEY);
  const hasPostFix = codeFromBrowserCode(language) === DE;

  return (
    <Modal className={clsx(classes.modal, className)} {...{ isOpen }}>
      <div className={classes.content}>
        <div className={classes.closeWrapper}>
          <Button className={classes.close} onClick={onClose} variant="icon">
            <CustomIcon icon="close" variant="textPrimary" size="large" />
          </Button>
        </div>
        <Translation component="h3" className={classes.header}>
          CP_START_ADDWIDGET
        </Translation>
        {widgets && widgets.length ? (
          <div>
            <div className={classes.list}>
              {widgets.map(
                ({ key, component: Component, name, nameTranslated }, idx) => {
                  const itemName = nameTranslated ? translate(name) : name;
                  const label = `${
                    !hasPostFix ? `${addText} ` : ''
                  }${itemName}${hasPostFix ? ` ${addText}` : ''}`;

                  return (
                    <div key={idx}>
                      <div
                        className={classes.itemWrapper}
                        onClick={onItemCheck(key)}
                      >
                        <div className={classes.item}>
                          <div className={classes.widgetWrapper}>
                            <Component
                              {...{ name, nameTranslated }}
                              disableAnimation
                            />
                          </div>
                        </div>
                      </div>
                      <FormCheckbox
                        label={label}
                        variant="medium"
                        value={addWidgetsState[key]}
                        onClick={onItemCheck(key)}
                      />
                    </div>
                  );
                }
              )}
            </div>
            <div className={classes.addWrapper}>
              <Button
                startIcon={CheckIcon}
                className={classes.add}
                disabled={Object.values(addWidgetsState).every(value => !value)}
                variant="contained"
                color="primary"
                onClick={onAddClick}
              >
                <Translation isFragment keyIfNoValue>
                  CP_LABEL_ADD
                </Translation>
              </Button>
            </div>
          </div>
        ) : (
          <Translation component="p" className={classes.noWidgets}>
            CP_START_ADDWIDGET_INFO
          </Translation>
        )}
      </div>
    </Modal>
  );
};

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { useDispatch, useSelector } from 'react-redux';

import { Translation } from '@troy/shared/src/components/common';

import {
  Chart,
  ChatTabIcon,
  CircularProgress,
  GraphIcon,
  TableTabIcon,
  Tabs,
  Widget
} from '../../../common';
import { enqueueSnackbar } from '@troy/shared/src/store/actions';

import { getEmbeddedUrls } from '../../../../store/actions';
import { cognitoTokenSelector } from '../../../../store/selectors';

const useStyles = makeStyles(theme => ({
  wrapper: {
    padding: '0 !important'
  },
  wrapperExtended: {
    position: 'relative',
    background: theme.palette.grey[100],

    display: 'flex',
    flexDirection: 'column'
  },
  info: {
    height: '100%',
    width: '100%'
  },
  infoRow: {
    display: 'flex',
    justifyContent: 'center'
  },
  infoRowLabel: {
    display: 'flex',
    justifyContent: 'center',
    fontSize: '0.75em'
  },
  infoCell: {
    width: '120px',
    textAlign: 'center',
    fontSize: '14px',
    margin: '0 20px',
    whiteSpace: 'initial !important'
  },
  chartLeftStart: {
    stopColor: theme.customColors.gradientColor1
  },
  chartLeftEnd: {
    stopColor: theme.customColors.gradientColor2
  },
  chartLabel: {
    color: theme.palette.secondary.main
  },
  loader: {
    width: '80px !important',
    height: '80px !important'
  },
  loaderWrapper: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  },
  extendedContent: {
    padding: '20px',
    flex: 1,
    display: 'flex'
  },
  extendedIframe: {
    flex: 1,
    border: 0
  }
}));

const TABS_ICONS = {
  'Print Dashboard': TableTabIcon,
  'KPIs Dashboard': ChatTabIcon
};

const StatisticWidget = ({ removeItem, setExtended, extended, ...props }) => {
  const classes = useStyles();
  const quicksight = useSelector(({ quicksight }) => quicksight);
  const token = useSelector(cognitoTokenSelector);

  const dispatch = useDispatch();

  const [tabValue, setTabValue] = useState(0);

  const tabs = useMemo(
    () =>
      quicksight.embeddedUrls &&
      quicksight.embeddedUrls.map(({ name }) => ({
        label: name,
        value: name,
        icon: TABS_ICONS[name]
      })),
    [quicksight.embeddedUrls]
  );
  const iframeURLs = useMemo(
    () =>
      quicksight.embeddedUrls &&
      quicksight.embeddedUrls.reduce(
        (acc, { name, url }) => ({ ...acc, [name]: url }),
        {}
      ),
    [quicksight.embeddedUrls]
  );

  useEffect(() => {
    if (tabs && extended) {
      if (tabs.length) {
        setTabValue(tabs[0].value);
      } else {
        dispatch(
          enqueueSnackbar({
            message: (
              <Translation isFragment>CP_START_STATISTICS_ERROR</Translation>
            ),
            options: {
              variant: 'error'
            }
          })
        );
        removeItem();
      }
    }
  }, [tabs, extended]);

  const onTabChange = useCallback((event, value) => {
    setTabValue(value);
  }, []);

  const openIframe = useCallback(() => {
    if (!extended && !(tabs && !tabs.length)) {
      setExtended();
    }
    if (token) {
      dispatch(getEmbeddedUrls(token));
    } else if (!tabs.length) {
      dispatch(
        enqueueSnackbar({
          message: (
            <Translation isFragment>CP_START_STATISTICS_ERROR</Translation>
          ),
          options: {
            variant: 'error'
          }
        })
      );
    }
  }, [token, setExtended, extended, dispatch, tabs]);

  return (
    <>
      <Widget
        {...props}
        icon={GraphIcon}
        onActionClick={removeItem}
        buttonName="CP_START_STATISTICS_BUTTON"
        onClick={openIframe}
        isExtended={extended}
      >
        <div className={classes[extended ? 'wrapperExtended' : 'wrapper']}>
          {!extended ? (
            <div className={classes.info}>
              <div className={classes.infoRow}>
                <div className={classes.infoCell}>
                  <Chart
                    id="chart-left"
                    value={60}
                    displayValue={`${40}%`}
                    startClassName={classes.chartLeftStart}
                    endClassName={classes.chartLeftEnd}
                    labelClassName={classes.chartLabel}
                  />
                </div>
                <div className={classes.infoCell}>
                  <Chart
                    id="chart-right"
                    value={78}
                    displayValue={`${78}%`}
                    labelClassName={classes.chartLabel}
                  />
                </div>
              </div>
              <div className={classes.infoRowLabel}>
                <Translation component="p" className={classes.infoCell}>
                  CP_START_STATISTICS_CONTENT_LEFT
                </Translation>
                <Translation component="p" className={classes.infoCell}>
                  CP_START_STATISTICS_CONTENT_RIGHT
                </Translation>
              </div>
            </div>
          ) : (
            (!tabs && !iframeURLs && (
              <div className={classes.loaderWrapper}>
                <CircularProgress className={classes.loader} />
              </div>
            )) || (
              <>
                <Tabs
                  options={tabs}
                  value={tabValue}
                  onChange={onTabChange}
                  pageable
                />
                <div className={classes.extendedContent}>
                  {(iframeURLs && (
                    <iframe
                      src={iframeURLs[tabValue]}
                      title="statistic-iframe"
                      className={classes.extendedIframe}
                    />
                  )) ||
                    null}
                </div>
              </>
            )
          )}
        </div>
      </Widget>
    </>
  );
};

export default StatisticWidget;

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { findLatestAddress } from '@troy/shared/src/utils/utils';

import {
  ChatTabIcon,
  PenTabIcon,
  ProfileIcon,
  TableTabIcon,
  Widget
} from '../../../common';
import ExtendedUserWidgetContent from './ExtendedUserWidgetContent';
import UserWidgetContent from './UserWidgetContent';

import {
  authorizedGraphqlRequest,
  authorizedPost
} from '../../../../utils/api';
import { CLIENT_TOKEN, GET_CUSTOMER_INFO } from '../../../../constants/api';

import {
  closeLiability,
  contactForm,
  getLiabilityCloseReasons,
  onHold,
  reportCreditMemo,
  reportPayment,
  setLiabilityClosed,
  setUsersTabValue
} from '../../../../store/actions';
import {
  cognitoTokenSelector,
  decisionsValuesSelector,
  isLiabilityClosedSelector,
  liabilityCloseReasonsSelector,
  usersTabValueSelector
} from '../../../../store/selectors';

import {
  CANCEL_TAB,
  CREDIT_MEMO_TAB,
  EDIT_TAB,
  LIABILITY_TAB,
  ON_HOLD_TAB,
  POSTBOX_TAB,
  REPORT_PAYMENT_TAB
} from '../../../../constants/users-tabs';
import { useAppWidthClass } from '../../../../utils/hooks';

const getCustomerInfo = (liabilityId, token) =>
  authorizedGraphqlRequest(GET_CUSTOMER_INFO(liabilityId), token).catch(
    () => {}
  );
const getUserToken = (data, token) =>
  authorizedPost(CLIENT_TOKEN, { request: data }, token).catch(() => {});

const CLOSE_REASON_TO_LABEL_KEY = {
  FRAUD: 'CP_INDIVIDUALCASE_EDIT_CLOSECASE_DROPDOWN_FRAUD',
  CLIENT_REQUESTS_CLOSE:
    'CP_INDIVIDUALCASE_EDIT_CLOSECASE_DROPDOWN_REQUESTCLOSING',
  DEATH: 'CP_INDIVIDUALCASE_EDIT_CLOSECASE_DROPDOWN_DEATH'
};

const UsersWidget = ({ removeItem, extended, setExtended, ...props }) => {
  const history = useHistory();
  const token = useSelector(cognitoTokenSelector);
  const liabilityCloseReasons = useSelector(liabilityCloseReasonsSelector);
  const isLiabilityClosed = useSelector(isLiabilityClosedSelector);
  const decisions = useSelector(decisionsValuesSelector);
  const contactFormDecision =
    decisions['troy.feature.clientportal.contact-form'];
  const globalTabValue = useSelector(usersTabValueSelector);

  const dispatch = useDispatch();

  const onGlobalTabValueChange = useCallback(
    value => {
      dispatch(setUsersTabValue(value));
    },
    [dispatch]
  );

  const [selectedValue, setSelectedValue] = useState('');
  useEffect(() => {
    if (selectedValue) {
      if (!extended) {
        onGlobalTabValueChange(false);
      } else {
        onGlobalTabValueChange(LIABILITY_TAB);
      }
    }
  }, [extended, selectedValue]);

  const [userToken, setUserToken] = useState('');
  const [userInfo, setUserInfo] = useState(null);
  const [userLoading, setUserLoading] = useState(false);

  const menuTabValueToLabelKey = useMemo(
    () => ({
      [false]: 'CP_INDIVIDUALCASE_EDIT',
      [CANCEL_TAB]: 'CP_INDIVIDUALCASE_EDIT_CLOSECASE',
      [REPORT_PAYMENT_TAB]: 'CP_INDIVIDUALCASE_EDIT_RECORDPAYMENT',
      [CREDIT_MEMO_TAB]: 'CP_INDIVIDUALCASE_EDIT_ENTERCREDITNOTE',
      [ON_HOLD_TAB]: 'CP_INDIVIDUALCASE_EDIT_DUNNINGSTOP'
    }),
    []
  );

  const MENU_TABS = useMemo(() => {
    let menuTabs = [];

    if (decisions['troy.feature.clientportal.cancel-case']) {
      menuTabs.push(CANCEL_TAB);
    }

    if (decisions['troy.feature.clientportal.report-payment']) {
      menuTabs.push(REPORT_PAYMENT_TAB);
    }

    if (decisions['troy.feature.clientportal.report-credit-memo']) {
      menuTabs.push(CREDIT_MEMO_TAB);
    }

    if (decisions['troy.feature.clientportal.on-hold.liability']) {
      menuTabs.push(ON_HOLD_TAB);
    }

    menuTabs = menuTabs.map(value => ({
      label: menuTabValueToLabelKey[value],
      value,
      translated: true
    }));

    return menuTabs.length ? menuTabs : null;
  }, [decisions, menuTabValueToLabelKey]);

  const { isTwoColumnWidth } = useAppWidthClass();

  const TABS = useMemo(() => {
    let tabs = [
      {
        label: 'CP_INDIVIDUALCASE_CLAIMANDSTATUS',
        icon: TableTabIcon,
        value: LIABILITY_TAB,
        translated: true
      },
      {
        label: 'CP_INDIVIDUALCASE_POSTBOX',
        icon: ChatTabIcon,
        value: POSTBOX_TAB,
        translated: true
      }
    ];

    if (MENU_TABS) {
      tabs.push({
        label: 'CP_INDIVIDUALCASE_EDIT',
        value: EDIT_TAB,
        icon: PenTabIcon,
        translated: true
      });
    }

    return tabs;
  }, [decisions, MENU_TABS, isTwoColumnWidth]);

  const onBackButtonClick = useCallback(() => {
    setSelectedValue('');
    setUserToken('');
    setUserInfo(null);
  }, []);

  const fetchUserInfo = useCallback(
    value => {
      getUserToken({ liabilityId: value }, token)
        .then(({ token: userToken } = {}) => {
          if (userToken) {
            setUserToken(userToken);
            return getCustomerInfo(value, token).finally(() =>
              setUserLoading(false)
            );
          }
        })
        .then(({ clientPortal } = {}) => {
          if (clientPortal && clientPortal.customer) {
            const {
              companyName,
              address,
              liabilities: [
                {
                  locale,
                  ikaros_ref_no: caseId,
                  clientCustomerRefNo: customerId,
                  liability_status: {
                    total_outstanding_balance: amount,
                    due_currency: currency
                  }
                }
              ]
            } = clientPortal.customer;

            const addr = findLatestAddress(address);

            setUserInfo(state => ({
              ...state,
              streetHouse: addr.street_and_house_nr,
              zipCity: `${addr.zip} ${addr.city}`,
              zip: addr.zip,
              city: addr.city,
              country: addr.country,
              amount,
              locale,
              currency,
              customerId,
              caseId,
              companyName
            }));
          }
        });
    },
    [token]
  );

  const onButtonClick = useCallback(() => {
    setExtended({ caseId: selectedValue });
  }, [setExtended, selectedValue]);

  const [searchQuery, setSearchQuery] = useState('');
  const onCloseClick = useCallback(() => {
    onBackButtonClick();
    setSearchQuery('');
    removeItem();
  }, [onBackButtonClick, removeItem]);

  const onAutocompleteSelect = useCallback(
    value => {
      const newValue = value.value;
      setSelectedValue(newValue);

      if (newValue) {
        setUserLoading(true);
        setUserInfo(state => ({ ...state, name: value.name }));
        fetchUserInfo(newValue);
      }
    },
    [fetchUserInfo]
  );

  const fetchUserInfoByUrlParam = location => {
    const urlParams = new URLSearchParams(location.search);
    const caseId = urlParams.get('caseId');
    caseId && fetchUserInfo(caseId);
  };

  useEffect(() => {
    fetchUserInfoByUrlParam(window.location);
    return history.listen(location => {
      if (history.action === 'POP') {
        fetchUserInfoByUrlParam(location);
      }
    });
  }, []);

  useEffect(() => {
    if (extended && globalTabValue === CANCEL_TAB && !liabilityCloseReasons) {
      dispatch(getLiabilityCloseReasons());
    }
  }, [extended, globalTabValue]);

  const closeReasons = useMemo(
    () =>
      liabilityCloseReasons
        ? liabilityCloseReasons.map(value => ({
            label: CLOSE_REASON_TO_LABEL_KEY[value],
            value,
            translated: true
          }))
        : [],
    [liabilityCloseReasons]
  );

  useEffect(() => {
    if (isLiabilityClosed) {
      dispatch(setLiabilityClosed(false));
      removeItem();
    }
  }, [isLiabilityClosed]);

  const onCloseCaseSubmit = useCallback(
    closeReason => {
      dispatch(
        closeLiability({
          closeReason,
          liabilityId: selectedValue
        })
      );
    },
    [dispatch, closeLiability, selectedValue]
  );

  const onCreditMemoSubmit = useCallback(
    ({ amount, date, optionalInfo }) => {
      dispatch(
        reportCreditMemo({
          value: amount || '',
          date,
          optionalInfo,
          liabilityId: selectedValue
        })
      );
    },
    [dispatch, reportCreditMemo, selectedValue]
  );

  const onReportedPaymentSubmit = useCallback(
    ({ amount, date, optionalInfo }) => {
      dispatch(
        reportPayment({
          reportAmount: amount || '',
          reportDate: date,
          additionalInfo: optionalInfo,
          liabilityId: selectedValue
        })
      );
    },
    [dispatch, reportPayment, selectedValue]
  );

  const onContactFormSubmit = useCallback(
    ({ message, files }, onLoad) => {
      dispatch(
        contactForm({
          message,
          documents: files,
          liabilityId: selectedValue,
          onLoad
        })
      );
    },
    [dispatch, contactForm, selectedValue]
  );

  const onHoldFormSubmit = useCallback(
    ({ date, isEndless }) => {
      dispatch(onHold({ date, isEndless, liabilityId: selectedValue }));
    },
    [dispatch, onHold, selectedValue]
  );

  return (
    <Widget
      {...props}
      icon={ProfileIcon}
      onActionClick={extended ? onCloseClick : removeItem}
      onBackClick={selectedValue && !userLoading ? onBackButtonClick : null}
      isExtended={extended}
      buttonName={
        selectedValue && !userLoading ? 'CP_START_INDIVIDUALCASE_BUTTON' : null
      }
      onButtonClick={onButtonClick}
      isButtonDisabled={!selectedValue}
    >
      {extended ? (
        userInfo ? (
          <ExtendedUserWidgetContent
            {...{
              globalTabValue,
              onGlobalTabValueChange,
              userInfo,
              userToken,
              onCloseCaseSubmit,
              closeReasons,
              onCreditMemoSubmit,
              onReportedPaymentSubmit,
              onContactFormSubmit,
              onHoldFormSubmit,
              contactFormDecision
            }}
            tabs={TABS}
            menuTabs={MENU_TABS}
          />
        ) : null
      ) : (
        <UserWidgetContent
          {...{ userInfo, userLoading, token, searchQuery }}
          onSearchQueryChange={setSearchQuery}
          onSelectedValueChange={onAutocompleteSelect}
        />
      )}
    </Widget>
  );
};

export default UsersWidget;

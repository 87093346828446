import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import InputMask from 'react-input-mask';

import { Translation } from '@troy/shared/src/components/common';

import {
  CalendarIcon,
  CircularProgress,
  DatePicker,
  EnhancedTable,
  KeyboardDownArrow,
  SearchIcon,
  TextField
} from '../../../../common';

import {
  format as formatDate,
  getDateRangeMask,
  getLocaleFormat,
  isSameDay,
  isValidDate,
  parse
} from '../../../../../utils/date-utils';
import { modifyName } from '../../../../../utils/invoice';
import { TYPES_EXT_ICONS } from '../../../../../constants/file-upload';
import { languageSelector } from '../../../../../store/selectors';
import { localeFromBrowserCode } from '../../../../../utils/translations';

const useStyles = makeStyles(theme => ({
  loader: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  },
  wrapper: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: '20px 16px',
    flex: 1
  },
  controls: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '10px',
    marginBottom: '20px',
    '& > :not(:last-child)': {
      marginRight: '10px'
    }
  },
  inputLabel: {
    fontWeight: 'bold',
    marginBottom: '8px'
  },
  datepicker: {
    marginLeft: '40px',

    '& > :not(:last-child)': {
      marginRight: '10px'
    }
  },
  inputWrapper: {
    position: 'relative',
    width: '270px'
  },
  inputField: {
    width: '100%'
  },
  inputInput: {
    padding: '6.5px 0 6.5px 14px !important'
  },
  inputIcon: {
    height: '19px !important',
    color: theme.palette.primary.main
  },
  fileIcon: {
    width: '20px',
    height: '20px',
    color: theme.palette.primary.main
  },
  fileIconCell: {
    flexGrow: '0 !important',
    flexBasis: '24px !important',
    padding: '0 !important',
    textAlign: 'center !important'
  },
  actionIconCell: {
    flexGrow: '0 !important',
    flexBasis: '60px !important'
  },
  table: {
    flex: 1
  },
  tableWrapper: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  tableHeadCell: {
    fontWeight: 'bolder !important',
    padding: '12px !important'
  },
  tableRow: {
    cursor: 'pointer'
  },
  tableCell: {
    wordBreak: 'break-all',
    padding: '12px'
  }
}));

const InvoiceTextInput = ({
  inputIcon: InputIcon,
  wrapperClassName,
  className,
  iconClassName,
  ...props
}) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.inputWrapper, wrapperClassName)}>
      <TextField
        variant="outlined"
        className={clsx(className, classes.inputField)}
        inputClassName={classes.inputInput}
        endIcon={InputIcon}
        endIconClassName={clsx(classes.inputIcon, iconClassName)}
        {...props}
      />
    </div>
  );
};

const MaskedInvoiceTextInput = React.forwardRef(
  (
    {
      inputIcon: InputIcon,
      wrapperClassName,
      className,
      iconClassName,
      mask,
      ...props
    },
    ref
  ) => {
    const classes = useStyles();
    return (
      <div ref={ref} className={clsx(classes.inputWrapper, wrapperClassName)}>
        <InputMask mask={mask} {...props}>
          {inputProps => (
            <TextField
              variant="outlined"
              className={clsx(className, classes.inputField)}
              inputClassName={classes.inputInput}
              endIcon={InputIcon}
              endIconClassName={clsx(classes.inputIcon, iconClassName)}
              {...inputProps}
            />
          )}
        </InputMask>
      </div>
    );
  }
);

export default ({
  invoices,
  onDocDownload,
  onDateRangeChange,
  downloadButton: DownloadButton
}) => {
  const classes = useStyles();
  const language = useSelector(languageSelector);

  const handleDocDownloadClick = useCallback(
    id => e => {
      e.stopPropagation();
      onDocDownload && onDocDownload(id);
    },
    [onDocDownload]
  );

  const HEAD = useMemo(
    () => [
      {
        value: ({ name }) => {
          const Icon = TYPES_EXT_ICONS[name.split('.').pop()];
          return Icon ? <Icon className={classes.fileIcon} /> : null;
        },
        label: '',
        customCellClassName: classes.fileIconCell
      },
      {
        value: ({ name }) => modifyName(name, 45),
        label: 'CP_BILLING_FILENAME'
      },
      {
        value: ({ creationDate }) => formatDate(creationDate),
        label: 'CP_BILLING_CREATIONDATE',
        sort: true,
        key: 'creationDate'
      },
      {
        value: ({ modificationDate }) => formatDate(modificationDate),
        label: 'CP_BILLING_MODIFICATIONDATE'
      },
      {
        value: 'size',
        label: 'CP_BILLING_FILESIZE'
      },
      {
        value: ({ fileId }) => (
          <DownloadButton onClick={handleDocDownloadClick(fileId)} />
        ),
        label: '',
        customCellClassName: classes.actionIconCell
      }
    ],
    [handleDocDownloadClick]
  );

  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => setIsLoading(false), 500);
  }, []);

  const [searchValue, setSearchValue] = useState('');
  const filteredValues = useMemo(
    () =>
      searchValue && invoices && invoices.length
        ? invoices.filter(({ name, creationDate }) => {
            const date = parse(searchValue, getLocaleFormat());
            return isValidDate(date)
              ? isSameDay(creationDate, date)
              : name.toLowerCase().includes(searchValue.toLowerCase());
          })
        : null,
    [searchValue, invoices]
  );

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const handleDateChange = useCallback(
    ([start, end]) => {
      if (start) {
        start.setHours(0, 0, 0, 0); // start of day
      }
      setStartDate(start);

      if (end) {
        end.setHours(23, 59, 59, 999); // end of day
      }
      setEndDate(end);

      if (onDateRangeChange) {
        onDateRangeChange(start, end);
      }
    },
    [onDateRangeChange]
  );

  const onSearchChange = useCallback(e => {
    setSearchValue(e.target.value);
  }, []);

  const handleRowClick = useCallback(
    ({ fileId }) => {
      onDocDownload && onDocDownload(fileId);
    },
    [onDocDownload]
  );

  return isLoading ? (
    <div className={classes.loader}>
      <CircularProgress />
    </div>
  ) : (
    <div className={classes.wrapper}>
      <div className={classes.controls}>
        <div>
          <Translation component="p" className={classes.inputLabel}>
            CP_BILLING_SEARCH
          </Translation>
          <InvoiceTextInput
            value={searchValue}
            onChange={onSearchChange}
            inputIcon={SearchIcon}
            placeholder="CP_BILLING_SEARCH"
          />
        </div>
        <div className={classes.datepicker}>
          <Translation component="p" className={classes.inputLabel}>
            CP_BILLING_PERIOD
          </Translation>
          <div>
            <DatePicker
              onChange={handleDateChange}
              onChangeRaw={e => {
                if (e.target.value) {
                  const dates = e.target.value.split(' - ').map(date => {
                    const d = parse(date, getLocaleFormat());
                    return isValidDate(d) ? d : null;
                  });
                  handleDateChange(dates);
                }
              }}
              locale={localeFromBrowserCode(language)}
              selectsRange
              startDate={startDate}
              endDate={endDate}
              dateFormat={getLocaleFormat()}
              placeholderText={`${formatDate(new Date())} – ${formatDate(
                new Date()
              )}`}
              customInput={
                <MaskedInvoiceTextInput
                  inputIcon={CalendarIcon}
                  mask={getDateRangeMask()}
                />
              }
            />
          </div>
        </div>
      </div>
      <EnhancedTable
        onRowClick={handleRowClick}
        headCellClassName={classes.tableHeadCell}
        wrapperClassName={classes.tableWrapper}
        className={classes.table}
        cellClassName={classes.tableCell}
        rowClassName={classes.tableRow}
        head={HEAD}
        rows={filteredValues || invoices || []}
        rowsPerPage={8}
        SortIconComponent={KeyboardDownArrow}
        initialSortedBy="creationDate"
        initialSortOrder="desc"
      />
    </div>
  );
};

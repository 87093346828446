import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { usePaperStyles } from '@troy/shared/src/components/common';
import clsx from 'clsx';

const useStyles = makeStyles(() => ({
  title: {
    padding: 32,
    paddingBottom: 12,
    fontSize: 24,
    fontWeight: 'bold',
    textAlign: 'center'
  },
  content: {
    padding: '12px 32px',
    textAlign: 'center'
  },
  actions: {
    padding: 32,
    paddingTop: 12,
    justifyContent: 'center',

    '& > .MuiButton-root': {
      minWidth: 98
    }
  }
}));

export default ({ open, className, title, children, actions }) => {
  const classes = useStyles();
  const paperClasses = usePaperStyles();

  return (
    <Dialog
      PaperProps={{
        classes: {
          root: clsx(paperClasses.root, className),
          rounded: paperClasses.rounded,
          elevation1: paperClasses.elevation1
        }
      }}
      open={open}
    >
      {title ? (
        <DialogTitle className={classes.title}>{title}</DialogTitle>
      ) : null}
      <DialogContent className={classes.content}>{children}</DialogContent>
      {actions ? (
        <DialogActions className={classes.actions}>{actions}</DialogActions>
      ) : null}
    </Dialog>
  );
};

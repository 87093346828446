import React, { useCallback } from 'react';
import { makeStyles } from '@mui/styles';
import { withRouter } from 'react-router-dom';

import { Translation } from '@troy/shared/src/components/common';

import { FAQIcon, TemplateIcon, Widget } from '../../../common';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column'
  },
  content: {
    padding: 40,
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  description: {
    marginLeft: 20,
    fontSize: 14,
    lineHeight: '16px'
  },
  iframe: {
    width: '100%',
    height: 700,
    padding: '20px',
    border: 'none',
    display: 'block'
  }
}));

const FAQWidget = withRouter(
  ({ location, history, extended, setExtended, removeItem, ...props }) => {
    const classes = useStyles();

    const onButtonClick = useCallback(() => {
      setExtended();
    }, [setExtended]);

    return (
      <Widget
        {...props}
        icon={FAQIcon}
        onActionClick={removeItem}
        buttonName="CP_START_FAQ_BUTTON"
        onButtonClick={onButtonClick}
        isExtended={extended}
      >
        <div className={classes.root}>
          {!extended && (
            <div className={classes.content}>
              <TemplateIcon />
              <Translation component="span" className={classes.description}>
                CP_START_FAQ_CONTENT
              </Translation>
            </div>
          )}
          {extended && (
            <iframe
              className={classes.iframe}
              title="faq-iframe"
              src="https://embedder.upraise.io/ext/widget/home?embedType=2&amp;widgetId=74823600-777d-11eb-8483-1b6b206b9447&amp;clientKey=9e8f22a9-8071-3f34-8149-32a818aea80c"
              frameBorder="0"
            />
          )}
        </div>
      </Widget>
    );
  }
);

export default FAQWidget;

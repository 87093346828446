import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import DatePicker from '@mui/lab/DatePicker';
import { TextField, Theme } from '@mui/material';
import { isAfter, isBefore, isSameDay, isValid } from 'date-fns';
import clsx from 'clsx';

import { CustomIcon } from '@troy/shared/src/components/common';
import FormField from '../FormField';
import { DatePickerView } from '@mui/lab/DatePicker/shared';
import { FieldInputProps, FormikProps } from 'formik';

export const DEFAULT_DATE_FORMAT = 'dd.MM.yyyy';
export const DEFAULT_DATE_MASK = '__.__.____';

const useStyles = makeStyles((theme: Theme) => ({
  inputRoot: {
    ...theme.typography.body1,
    justifyContent: 'center',
    borderRadius: Number(theme.shape.borderRadius) * 8,
    color: theme.palette.text.primary,

    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.text.primary
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.text.primary
    }
  },
  inputHint: {
    color: theme.palette.text.hint
  },
  inputRootError: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.warning.dark
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.warning.dark
    }
  },
  inputRootFilled: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.text.primary
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.primary.main
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.primary.main
    }
  },
  input: {
    padding: `${theme.spacing(0.5)} 0 ${theme.spacing(0.5)} ${theme.spacing(
      2
    )}`,
    width: 84,
    height: 'auto'
  },
  adornment: {
    marginLeft: 0
  }
}));

interface FormDateSelectProps {
  className?: string;
  disabled?: boolean;
  minDate?: number | Date;
  maxDate?: number | Date;
  id?: string;
  onPickerClose?: () => void;
  name?: string;
  label?: string;
  value?: Date | null;
  error?: boolean;
  shouldDisableDateCheck?: (day: any) => boolean;
  hintMode?: boolean;
  views?: DatePickerView[];
  field?: FieldInputProps<string>;
  form?: FormikProps<any>;
}

const FormDateSelectComponent = React.forwardRef<
  HTMLInputElement,
  FormDateSelectProps
>(
  (
    {
      className,
      field,
      form,
      name,
      disabled,
      id,
      label,
      error,
      minDate,
      maxDate,
      shouldDisableDateCheck,
      hintMode,
      views,
      onPickerClose
    },
    ref
  ) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);

    const handleOpen = () => setOpen(true);

    const handleClose = (): void => {
      setOpen(false);
      onPickerClose && onPickerClose();
    };

    const handleChange = (date: Date | number, selectionState: string) => {
      if (selectionState !== undefined) {
        if (form.setFieldValue) {
          if (date) {
            if (minDate && maxDate) {
              if (
                (isSameDay(date, minDate) || isAfter(date, minDate)) &&
                (isSameDay(date, maxDate) || isBefore(date, maxDate))
              ) {
                form.setFieldValue(name, date);
              } else {
                form.setFieldValue(name, null);
              }
            } else {
              form.setFieldValue(name, date);
            }
          } else {
            form.setFieldValue(name, null);
          }
          form.setFieldTouched(name, true);
        }
      }
    };

    const handleAccept = date => {
      setOpen(false);

      if (date) {
        form.setFieldValue(name, date);
        form.setFieldTouched(name, true);
      }
    };

    const val = isValid(field.value) ? field.value : null;

    return (
      <DatePicker
        ref={ref}
        allowSameDateSelection
        showToolbar={false}
        open={open}
        value={val}
        renderInput={props => (
          <TextField
            name={field.name}
            onBlur={field.onBlur}
            classes={{ root: className }}
            id={id}
            {...props}
          />
        )}
        inputFormat={DEFAULT_DATE_FORMAT}
        mask={DEFAULT_DATE_MASK}
        disabled={disabled}
        label={label}
        onChange={handleChange}
        onOpen={handleOpen}
        onClose={handleClose}
        onAccept={handleAccept}
        shouldDisableDate={shouldDisableDateCheck}
        minDate={minDate}
        maxDate={maxDate}
        views={views}
        InputProps={{
          error: error,
          classes: {
            root: clsx(
              classes.inputRoot,
              hintMode && classes.inputHint,
              error && classes.inputRootError,
              !error && field.value != null && classes.inputRootFilled
            ),
            input: classes.input
          }
        }}
        InputAdornmentProps={{
          classes: {
            root: classes.adornment
          }
        }}
        OpenPickerButtonProps={{
          id: `${id}-button`,
          'aria-label': 'change date'
        }}
        components={{
          OpenPickerIcon: () => (
            <CustomIcon icon="calendar" size="xs" variant="primary" />
          )
        }}
      />
    );
  }
);

FormDateSelectComponent.displayName = 'FormDateSelectComponent';

const FormDateSelect = React.forwardRef<HTMLInputElement, any>(
  ({ name, ...props }, ref) =>
    name ? (
      <FormField
        component={FormDateSelectComponent}
        name={name}
        {...{ ...props, ref }}
      />
    ) : (
      <FormDateSelectComponent {...props} {...{ ref }} />
    )
);

FormDateSelect.displayName = 'FormDateSelect';

export default FormDateSelect;

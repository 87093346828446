import React from 'react';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

import CloseCaseForm from '../../CloseCaseForm';
import HoldForm from '../../HoldForm';
import ReportPaymentForm from '../../ReportPaymentForm';
import CreditMemoForm from '../../CreditMemoForm';

import {
  CANCEL_TAB,
  CREDIT_MEMO_TAB,
  ON_HOLD_TAB,
  REPORT_PAYMENT_TAB
} from '../../../../../../constants/users-tabs';

import { CUSTOMER_DETAIL_URL } from '../../../../../../constants/api';

const liabilityTableMethod = 'liability-table';

const useStyles = makeStyles(theme => ({
  flexible: {
    flex: 1
  },
  paymentForm: {
    height: '100%'
  }
}));

const EditExtendedContentComponent = ({
  value,
  userToken,
  onIframeLoadingStart,
  onIframeLoadingEnd,
  onCloseCaseSubmit,
  closeReasons,
  onCreditMemoSubmit,
  onReportedPaymentSubmit,
  currency,
  locale,
  onHoldFormSubmit
}) => {
  const classes = useStyles();

  switch (value) {
    case CANCEL_TAB:
      return (
        <CloseCaseForm
          onSubmit={onCloseCaseSubmit}
          closeReasons={closeReasons}
          className={classes.flexible}
        />
      );
    case CREDIT_MEMO_TAB:
      return (
        <CreditMemoForm
          onSubmit={onCreditMemoSubmit}
          iframeSrc={CUSTOMER_DETAIL_URL(liabilityTableMethod)}
          token={userToken}
          currency={currency}
          locale={locale}
          onIframeLoadingStart={onIframeLoadingStart}
          onIframeLoadingEnd={onIframeLoadingEnd}
          className={clsx(classes.paymentForm, classes.flexible)}
        />
      );
    case REPORT_PAYMENT_TAB:
      return (
        <ReportPaymentForm
          onSubmit={onReportedPaymentSubmit}
          className={clsx(classes.paymentForm, classes.flexible)}
          iframeSrc={CUSTOMER_DETAIL_URL(liabilityTableMethod)}
          token={userToken}
          currency={currency}
          locale={locale}
          onIframeLoadingStart={onIframeLoadingStart}
          onIframeLoadingEnd={onIframeLoadingEnd}
        />
      );
    case ON_HOLD_TAB:
      return (
        <HoldForm onSubmit={onHoldFormSubmit} className={classes.flexible} />
      );
    default:
      return null;
  }
};

export default EditExtendedContentComponent;

import React from 'react';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material';

import { Translation } from '../index';

const useStyles = makeStyles((theme: Theme) => ({
  error: {
    ...theme.typography.subtitle2,
    paddingLeft: 16,
    marginTop: 4,
    color: theme.palette.warning.dark
  }
}));

interface FieldErrorMessageProps {
  className?: string;
  visible?: boolean;
  error?: string;
  errorMessageKey?: string;
}

const FieldErrorMessage: React.FC<FieldErrorMessageProps> = ({
  className,
  visible,
  error,
  errorMessageKey
}) => {
  const classes = useStyles();

  return !!visible && !!error && !!errorMessageKey ? (
    <div
      className={clsx(classes.error, className)}
      data-role="field-error-message"
    >
      <Translation keyIfNoValue inline>
        {errorMessageKey}
      </Translation>
    </div>
  ) : null;
};

export default FieldErrorMessage;

import React from 'react';
import { useSelector } from 'react-redux';

import { languageSelector } from '../store/selectors';

// Locale
const TR = 'TR';
const SV = 'SV';

export const DISABLED_LANG_LIST = [TR, SV];

export const codeFromFullCode = fullCode =>
  fullCode
    .slice(0, fullCode.includes('_') ? fullCode.indexOf('_') : fullCode.length)
    .toUpperCase();

export const codeFromBrowserCode = fullCode =>
  fullCode
    .slice(0, fullCode.includes('-') ? fullCode.indexOf('-') : fullCode.length)
    .toUpperCase();

export const localeFromBrowserCode = fullCode =>
  fullCode
    .slice(0, fullCode.includes('-') ? fullCode.indexOf('-') : fullCode.length)
    .toLowerCase();

export const modifyCode = code => (code === 'EN' ? 'GB' : code);

export const withLanguage = Component => props => {
  const language = useSelector(languageSelector);

  return <Component {...props} language={language} />;
};

import { call, put, select } from 'redux-saga/effects';

import ApiService from '../../services/ApiService';

import { handleError, invoicesReceived, setInvoiceLoading } from '../actions';
import { cognitoTokenSelector } from '../selectors';

export function* getInvoices() {
  try {
    yield put(setInvoiceLoading(true));
    const token = yield select(cognitoTokenSelector);
    const { invoices } = yield call(ApiService.getInvoices, token);
    yield put(invoicesReceived(invoices));
  } catch (err) {
    yield put(handleError(err));
  } finally {
    yield put(setInvoiceLoading(false));
  }
}

export function* openInvoice({ payload: id }) {
  try {
    yield put(setInvoiceLoading(true));

    const token = yield select(cognitoTokenSelector);
    const { data: invoice, fileName } = yield call(
      ApiService.getInvoice,
      id,
      token
    );

    const elem = document.createElement('a');
    elem.style.display = 'none';
    elem.download = fileName || '';
    elem.href = window.URL.createObjectURL(invoice);
    elem.click();
    window.URL.revokeObjectURL(elem.href);
  } catch (err) {
    yield put(handleError(err));
  } finally {
    yield put(setInvoiceLoading(false));
  }
}

import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    borderRadius: Number(theme.shape.borderRadius) * 6,
    background: theme.palette.background.default,
    boxShadow: theme.customShadows.module,
    overflow: 'hidden'
  },
  smallRadius: {
    borderRadius: Number(theme.shape.borderRadius) * 4
  }
}));

interface ModuleProps {
  id?: string;
  className?: string;
  smallRadius?: boolean;
}

const Module: React.FC<ModuleProps> = ({
  id,
  className,
  smallRadius,
  children
}) => {
  const classes = useStyles();

  return (
    <div
      id={id}
      className={clsx(
        classes.root,
        smallRadius && classes.smallRadius,
        className
      )}
    >
      {children}
    </div>
  );
};

export default Module;

import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  wrapper: {
    position: 'relative',
    display: 'inline-block',
    width: 120,
    height: 120
  },
  wrapperSmall: {
    width: 65,
    height: 65
  },
  value: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    borderRadius: '50%',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  content: {
    fontWeight: 'bold'
  },
  chart: {
    width: '100%',
    height: '100%'
  },
  start: {
    stopColor: theme.palette.primary.main
  },
  stop: {
    stopColor: theme.palette.secondary.main
  },
  background: {
    fill: 'none',
    stroke: theme.palette.text.disabled
  },
  progress: {
    fill: 'none',
    strokeLinecap: 'round'
  }
}));

export default ({
  small,
  value,
  displayValue,
  labelClassName,
  startClassName,
  endClassName,
  id
}) => {
  const classes = useStyles();
  let pathStrokeWidth, circleWidth, circleRadius, center, size, circleLength;

  if (small) {
    circleRadius = 29;
    circleWidth = 2;
    pathStrokeWidth = 7;
    center = circleRadius + pathStrokeWidth / 2;
    size = 2 * center;
    circleLength = 2 * Math.PI * circleRadius;
  } else {
    circleRadius = 200;
    circleWidth = 10;
    pathStrokeWidth = 60;
    center = circleRadius + pathStrokeWidth;
    size = 2 * (pathStrokeWidth + circleRadius);
    circleLength = 2 * Math.PI * circleRadius;
  }

  const LabelTag = small ? 'h4' : 'h3';

  return (
    <div className={clsx(classes.wrapper, small && classes.wrapperSmall)}>
      <div className={classes.value}>
        <LabelTag className={clsx(classes.content, labelClassName)}>
          {displayValue}
        </LabelTag>
      </div>
      <svg
        viewBox={`0 0 ${size} ${size}`}
        xmlns="http://www.w3.org/2000/svg"
        className={classes.chart}
      >
        <defs>
          <linearGradient id={id} x1="0%" y1="100%" x2="20%" y2="0%">
            <stop
              offset="0%"
              className={clsx(classes.start, startClassName)}
            />
            <stop
              offset="100%"
              className={clsx(classes.stop, endClassName)}
            />
          </linearGradient>
        </defs>
        <circle
          cx={center}
          cy={center}
          r={circleRadius}
          strokeWidth={circleWidth}
          className={classes.background}
        />
        {!small && (
          <path
            d={`M ${center},${pathStrokeWidth} A ${circleRadius} ${circleRadius} 0 1 1 ${center} ${
              2 * circleRadius + pathStrokeWidth
            } A ${circleRadius} ${circleRadius} 0 1 1 ${center} ${pathStrokeWidth}`}
            stroke={`url(#${id})`}
            strokeWidth={pathStrokeWidth}
            strokeDasharray={`${(value / 100) * circleLength} ${circleLength}`}
            className={classes.progress}
          />
        )}
        {small && (
          <path
            d={`M ${center},${
              pathStrokeWidth / 2
            } A ${circleRadius} ${circleRadius} 0 1 1 ${center} ${
              center + circleRadius
            } A ${circleRadius} ${circleRadius} 0 1 1 ${center} ${
              pathStrokeWidth / 2
            }`}
            stroke={`url(#${id})`}
            strokeWidth={pathStrokeWidth}
            strokeDasharray={`${(value / 100) * circleLength} ${circleLength}`}
            className={classes.progress}
          />
        )}
      </svg>
    </div>
  );
};

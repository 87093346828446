import React, { useCallback, useMemo, useState } from 'react';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';

import { FormField } from '@troy/shared/src/components/common';

import { CalendarIcon, DatePicker, TextField } from '../../../../common';

import {
  format as formatDate,
  getDate,
  getMonth,
  lastDayOfMonth,
  lastDayOfYear,
  parse as parseDate,
  setDate,
  setMonth
} from '../../../../../utils/date-utils';

const DAY = 'DAY';
const YEAR = 'YEAR';
const MONTH = 'MONTH';
const UNKNOWN = 'UNKNOWN';

const useStyles = makeStyles(theme => ({
  field: {
    maxWidth: 160
  },
  input: {
    textAlign: 'center',
    padding: 5
  },
  pristine: {
    color: theme.palette.text.hint
  },
  root: {
    paddingRight: 0
  },
  icon: {
    position: 'absolute',
    width: 16,
    height: 16,
    top: 'calc(50% - 1px)',
    right: 20,
    transform: 'translateY(-50%)',
    color: theme.palette.primary.main
  }
}));

export const DateInput = ({
  dateFormat,
  value: propValue,
  onChange: propOnChange,
  onBlur,
  field,
  name,
  form,
  inputProps,
  maxDate,
  minDate,
  wrapperClassName,
  inputNotchedOutlineClassName,
  ...props
}) => {
  const classes = useStyles();
  const [pristine, setPristine] = useState(true);

  const value = useMemo(
    () => (field && field.value) || propValue,
    [field, propValue]
  );
  const onChange = useMemo(
    () => (value => form.setFieldValue(name, value)) || propOnChange,
    [field, propOnChange]
  );

  const blockCodes = useMemo(
    () => (dateFormat ? dateFormat.split(/[^A-Za-z]/) : []),
    [dateFormat]
  );
  const blocks = useMemo(
    () =>
      blockCodes.filter(Boolean).map(blockCode => {
        if (/^d{2}$/.test(blockCode)) {
          // only 2 chars of days
          return DAY;
        } else if (/^M{2}$/.test(blockCode)) {
          // only 2 chars of months
          return MONTH;
        } else if (/^y{4}$/.test(blockCode)) {
          // only 4 chars of years
          return YEAR;
        } else {
          return UNKNOWN;
        }
      }),
    [blockCodes]
  );

  const blockRanges = useMemo(() => {
    if (blockCodes) {
      const ranges = [];
      let currentIdx = 0;
      for (const block of blockCodes) {
        if (block) {
          ranges.push([currentIdx, (currentIdx += block.length - 1)]);
        }
        currentIdx += 2;
      }
      return ranges;
    }
    return null;
  }, [blockCodes]);

  const getCursorPosition = useCallback(el => el.selectionStart, []);
  const moveCursorTo = useCallback(
    (el, caret, { absolute = false } = {}) => {
      const position = getCursorPosition(el);
      const nextPosition = absolute ? caret : position + caret;

      el.setSelectionRange(nextPosition, nextPosition);
    },
    [getCursorPosition]
  );

  const getCurrentBlock = useCallback(
    el => {
      const cursorPosition = getCursorPosition(el);

      return blockRanges.findIndex(
        blockRange =>
          blockRange[0] <= cursorPosition && blockRange[1] + 1 >= cursorPosition
      );
    },
    [getCursorPosition, blockRanges]
  );

  const handleChange = useCallback(
    value => {
      setPristine(false);
      onChange && onChange(value);
    },
    [onChange]
  );

  const handleKeyPress = useCallback(
    e => {
      e.preventDefault();

      // allow only numbers
      if (!e.key.match(/[0-9]/g)) {
        return;
      }

      const typed = e.key;
      const cursorPosition = getCursorPosition(e.target);

      const blockUnderCursor = getCurrentBlock(e.target);

      const cursorInBlock = cursorPosition - blockRanges[blockUnderCursor][0];
      const isCursorBlockEnd =
        cursorPosition === blockRanges[blockUnderCursor][1] + 1;
      const isNextPosEnding =
        cursorPosition === blockRanges[blockUnderCursor][1];

      const blockCode = blockCodes[blockUnderCursor];
      const blockType = blocks[blockUnderCursor];

      const valueBlockFormated = formatDate(value, blockCode);
      const splitted = String(valueBlockFormated).split('');
      if (!isCursorBlockEnd) {
        splitted[cursorInBlock] = typed;
      } else {
        splitted[splitted.length - 1] = typed;
      }

      let newValue = parseDate(splitted.join(''), blockCode, value);
      switch (blockType) {
        case DAY: {
          if (!newValue.getTime()) {
            newValue = lastDayOfMonth(value);
          }
          break;
        }
        case MONTH: {
          if (!newValue.getTime()) {
            newValue = setDate(lastDayOfYear(value), getDate(value));
          } else {
            newValue = setDate(newValue, getDate(value));
          }
          break;
        }
        case YEAR: {
          newValue = setMonth(
            setDate(newValue, getDate(value)),
            getMonth(value)
          );
          break;
        }
        default:
          break;
      }

      handleChange(newValue);
      setIsTyping(true);

      setTimeout(
        (el, prevPosition, isNextEnding) => {
          moveCursorTo(el, prevPosition + (isNextEnding ? 2 : 1), {
            absolute: true
          });
        },
        1,
        e.target,
        cursorPosition,
        isNextPosEnding
      );
    },
    [
      getCursorPosition,
      getCurrentBlock,
      blockRanges,
      blocks,
      blockCodes,
      value,
      handleChange
    ]
  );

  const [isTyping, setIsTyping] = useState(false);

  const handleKeyDown = useCallback(
    e => {
      if (['Backspace', 'Delete'].includes(e.key)) {
        // custom handle delete
        e.preventDefault();
      }
    },
    [value, getCursorPosition]
  );

  const handleBlur = useCallback(
    e => {
      if (isTyping) {
        setIsTyping(false);

        const isMoreMaxDate = maxDate && value > maxDate;
        const isLessMinDate = minDate && value < minDate;
        if (isMoreMaxDate || isLessMinDate) {
          if (isMoreMaxDate) {
            handleChange(maxDate);
          } else {
            handleChange(minDate);
          }
        }
      }
      onBlur && onBlur(e);
    },
    [onBlur, isTyping, value, maxDate, minDate, handleChange]
  );

  return (
    <DatePicker
      {...props}
      wrapperClassName={wrapperClassName}
      inputValue={formatDate(value, dateFormat)}
      onKeyDown={handleKeyDown}
      onBlur={handleBlur}
      onSelect={handleChange}
      value={value}
      dateFormat={dateFormat}
      maxDate={maxDate}
      minDate={minDate}
      customInput={
        <TextField
          {...inputProps}
          className={clsx(classes.field, inputProps && inputProps.className)}
          inputClassName={clsx(
            classes.input,
            pristine ? classes.pristine : null,
            inputProps && inputProps.inputClassName
          )}
          inputRootClassName={clsx(
            classes.root,
            inputProps && inputProps.inputRootClassName
          )}
          inputNotchedOutlineClassName={inputNotchedOutlineClassName}
          endIcon={(inputProps && inputProps.endIcon) || CalendarIcon}
          endIconClassName={clsx(
            classes.icon,
            inputProps && inputProps.endIconClassName
          )}
          onKeyPress={handleKeyPress}
        />
      }
    />
  );
};

export const FormDateInput = ({ name, validate, onChange, ...props }) =>
  name ? (
    <FormField
      name={name}
      component={DateInput}
      validate={validate}
      onChange={onChange}
      {...props}
    />
  ) : (
    <DateInput {...props} />
  );

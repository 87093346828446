import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.grey[100],
    borderRadius: theme.shape.borderRadius * 2
  }
}));
export default ({ width, height, className }) => {
  const classes = useStyles();
  return (
    <Skeleton
      variant="rectangular"
      className={clsx(classes.root, className)}
      width={width}
      height={height}
    />
  );
};

import { createAction } from 'redux-act';

export const logIn = createAction('Cognito: Log in');
export const authDataReceived = createAction(
  'Cognito: Authorization data received'
);

export const logOut = createAction('Cognito: Log out');
export const logOutMade = createAction('Cognito: Log out made');

export const getSession = createAction('Cognito: Get session');
export const refreshSession = createAction('Cognito: Refresh session');
export const sessionReceived = createAction('Cognito: Session received');

export const getFederationToken = createAction('Cognito: Get federation token');
export const federationTokenReceived = createAction(
  'Cognito: Federation token received'
);

export const getTokensByCode = createAction('Cognito: Get tokens by code');
export const tokensReceived = createAction('Cognito: Tokens received');

export const refreshTokens = createAction('Cognito: get Refresh Token');

const importAllImages = r => {
  let keys = r.keys();
  const values = keys.map(r);
  keys = keys.map(key => {
    const splitArr = key.split('/');
    return splitArr[splitArr.length - 1];
  });
  return keys.reduce((acc, key, ind) => ({ ...acc, [key]: values[ind] }), {});
};

export default process.env.REACT_APP_ENV === 'test' ||
process.env.JEST_WORKER_ID !== undefined
  ? { image: { default: 'image' } }
  : importAllImages(
      require.context(
        './',
        false,
        /\.([pP][nN][gG]|[jJ][pP][eE]?[gG]|[sS][vV][gG]|[bB][mM][pP]|[gG][iI][fF])$/
      )
    );

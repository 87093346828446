import { createReducer } from 'redux-act';

import * as actions from '../actions/cognito';

import cognitoService from '../../services/CognitoService';
import {
  loadTokensFromLocalStorage,
  loadTokensToLocalStorage
} from '../../services/SsoService';

const DEFAULT_STATE = {
  federationToken: '',
  session: null,
  tokens: loadTokensFromLocalStorage(),
  user: cognitoService.loadCognitoUserFromLocalStorage()
};

export default createReducer(
  {
    [actions.logOutMade]: state => ({
      ...DEFAULT_STATE,
      user: null
    }),
    [actions.sessionReceived]: (state, session) => ({
      ...state,
      session,
      user: cognitoService.loadCognitoUserFromLocalStorage()
    }),
    [actions.federationTokenReceived]: (state, federationToken) => ({
      ...state,
      federationToken
    }),
    [actions.tokensReceived]: (state, tokens) => {
      let newTokens = tokens && { ...state.tokens, ...tokens };
      loadTokensToLocalStorage(newTokens);
      return {
        ...state,
        tokens: newTokens
      };
    }
  },
  DEFAULT_STATE
);

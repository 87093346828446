import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

import {
  CompanyName,
  CopyTooltip,
  Paper,
  ProgressLoader,
  Translation,
  usePaperStyles
} from '@troy/shared/src/components/common';

import {
  CustomerCenterIframe,
  LabeledValueCard,
  SuccessModal,
  Tabs
} from '../../../../common';
import HeaderTabs from './HeaderTabs';
import HeaderSelect from './HeaderSelect';
import ContactForm from '../ContactForm';

import {
  CONTACT_FORM_TAB,
  EDIT_TAB,
  LIABILITY_TAB,
  POSTBOX_TAB
} from '../../../../../constants/users-tabs';

import { CUSTOMER_DETAIL_URL } from '../../../../../constants/api';
import { useAppWidthClass } from '../../../../../utils/hooks';
import {
  widgetWidth2Down,
  widgetWidth3Down
} from '../../../../../assets/styles/jss_functions';
import EditExtendedContentComponent from './EditExtendedContentComponent';
import UserInfoLabels from './UserInfoLabels';

const useStyles = makeStyles(theme => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    padding: '16px !important',
    paddingTop: '32px !important'
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  headerInfo: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flex: 1
  },
  headerBar: {
    ...widgetWidth3Down({
      display: 'flex'
    }),
    ...widgetWidth2Down({
      flexDirection: 'column'
    })
  },
  headerUser: {
    display: 'flex',
    flexDirection: 'column',
    // alignItems: 'flex-end',
    padding: 8,

    ...widgetWidth3Down({
      flexDirection: 'column',
      // alignItems: 'flex-start',
      flex: 1
    }),
    ...widgetWidth2Down({
      padding: '8px 0 0 0'
    })
  },
  headerCompany: {
    marginLeft: theme.spacing(-1),
    marginBottom: theme.spacing(1)
  },
  headerUserName: {
    color: theme.palette.text.primary,
    fontWeight: 'bold',
    marginRight: '16px'
  },
  headerUserInfo: {
    color: theme.palette.text.secondary
  },
  headerUserInfoList: {
    display: 'flex',
    margin: '18px 0 48px -8px',
    ...widgetWidth3Down({
      margin: '16px 0 16px -8px',
      justifyContent: 'space-between'
    }),
    ...widgetWidth2Down({
      flexWrap: 'wrap'
    }),
    '& > :not(:last-child)': {
      marginRight: 16
    }
  },
  tabsGroup: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  tabsWrapper: {
    ...widgetWidth3Down({
      flex: 1
    })
  },
  customerAddress: {
    border: 0
  },
  content: {
    marginTop: 20,
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    flex: 1
  },
  contentTabs: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    marginBottom: 16
  },
  postBoxHeader: {
    textAlign: 'center',
    fontWeight: 'bold',
    color: '$new-gray',
    margin: '16px 0'
  },
  contactForm: {
    flex: 1,
    padding: 16
  },
  edit: {
    display: 'flex',
    flex: 1,

    ...widgetWidth2Down({
      flexDirection: 'column'
    })
  },
  editTabs: {
    borderRight: `1px solid ${theme.palette.text.disabled}`,
    padding: '10px 0 0 4px',

    ...widgetWidth2Down({
      borderRight: 'unset',
      minHeight: '40px !important',
      padding: '0'
    })
  },
  tab: {
    minWidth: 'unset !important',
    ...theme.typography.body1,
    alignItems: 'flex-start !important'
  },
  selectTab: {
    ...theme.typography.body1Bold
  },
  tabArrow: {
    color: `${theme.palette.primary.main} !important`
  },
  editContent: {
    flex: 1,
    padding: 16
  },
  iframe: {
    minHeight: 520,
    padding: 0,
    flex: 1,
    margin: '0 -12px',
    border: 'none'
  }
}));

export default ({
  globalTabValue,
  onGlobalTabValueChange,
  tabs,
  menuTabs,
  userInfo,
  userToken,
  onCloseCaseSubmit,
  closeReasons,
  onCreditMemoSubmit,
  onReportedPaymentSubmit,
  onContactFormSubmit,
  onHoldFormSubmit,
  contactFormDecision
}) => {
  const classes = useStyles();
  const paperClasses = usePaperStyles();
  const [autoScroll, setAutoScroll] = useState(false);
  const [iframeKey, setIframeKey] = useState(0);
  const [isIframeLoading, setIframeLoading] = useState(false);
  const [submitDone, setSubmitDone] = useState(false);
  const handleIframeLoadingStart = useCallback(() => {
    setIframeLoading(true);
  }, []);
  const handleIframeLoadingEnd = useCallback(() => {
    setIframeLoading(false);
  }, []);

  const [tabValue, setTabValue] = useState(false);
  useEffect(() => {
    if (tabs && tabs.length && !tabValue) {
      setTabValue(tabs[0].value);
    }
  }, [tabs]);

  const [editTabsValue, setEditTabsValue] = useState(false);
  const setDefaultEditTab = useCallback(() => {
    if (menuTabs && menuTabs.length && editTabsValue !== menuTabs[0].value) {
      setEditTabsValue(menuTabs[0].value);
    }
  }, [menuTabs, editTabsValue]);

  useEffect(() => {
    setDefaultEditTab();
  }, [menuTabs]);

  useEffect(() => {
    if (tabValue) {
      onGlobalTabValueChange(tabValue !== EDIT_TAB ? tabValue : editTabsValue);
    }
  }, [tabValue, editTabsValue]);

  useEffect(() => {
    if (globalTabValue !== tabValue && globalTabValue !== editTabsValue) {
      if (
        globalTabValue !== tabValue &&
        tabs.map(({ value }) => value).includes(globalTabValue)
      ) {
        setTabValue(globalTabValue);
      } else {
        setEditTabsValue(globalTabValue);
      }
    }
  }, [globalTabValue]);

  const onTabChange = useCallback(
    (event, value) => {
      setTabValue(value);
      setDefaultEditTab();
    },
    [setDefaultEditTab]
  );

  const onEditTabChange = useCallback(
    (event, value) => {
      setEditTabsValue(value);
    },
    [onGlobalTabValueChange]
  );

  const onContactFormLoad = useCallback(() => {
    setSubmitDone(true);
    setTimeout(() => setSubmitDone(false), 3000);
    setAutoScroll(true);
    setIframeKey(val => val + 1);
  }, []);

  const customerCenterIframeProps = useMemo(
    () => ({
      src: CUSTOMER_DETAIL_URL(tabValue) + (autoScroll ? '&scroll=true' : ''),
      token: userToken,
      onOneTimeTokenStart: handleIframeLoadingStart,
      onOneTimeTokenEnd: handleIframeLoadingEnd,
      title: 'users-iframe',
      frameBorder: '0'
    }),
    [
      tabValue,
      userToken,
      autoScroll,
      handleIframeLoadingStart,
      handleIframeLoadingEnd
    ]
  );

  const EditExtendedContent = useMemo(
    () => (
      <EditExtendedContentComponent
        {...{
          userToken,
          onCloseCaseSubmit,
          closeReasons,
          onCreditMemoSubmit,
          onReportedPaymentSubmit,
          onHoldFormSubmit
        }}
        value={editTabsValue}
        onIframeLoadingStart={handleIframeLoadingStart}
        onIframeLoadingEnd={handleIframeLoadingEnd}
        currency={userInfo.currency}
        locale={userInfo.locale}
      />
    ),
    [
      userToken,
      userInfo,
      onCloseCaseSubmit,
      closeReasons,
      onCreditMemoSubmit,
      onReportedPaymentSubmit,
      onHoldFormSubmit,
      editTabsValue,
      handleIframeLoadingStart,
      handleIframeLoadingEnd
    ]
  );

  const { isThreeColumnWidth, isTwoColumnWidth } = useAppWidthClass();

  return (
    <div className={classes.wrapper}>
      <div className={classes.header}>
        <div className={classes.headerInfo}>
          <div className={classes.headerBar}>
            <div className={classes.headerUser}>
              <CompanyName
                className={classes.headerCompany}
                companyName={userInfo.companyName}
                iconVariant="textPrimary"
                textColor="textPrimary"
              />
              <h3 className={classes.headerUserName}>{userInfo.name}</h3>
              {isThreeColumnWidth || <UserInfoLabels userInfo={userInfo} />}
              <div className={classes.headerUserInfoList}>
                {[
                  {
                    label: 'CP_INDIVIDUALCASE_INFOBOX_STREET',
                    value: userInfo.streetHouse
                  },
                  {
                    label: 'CP_INDIVIDUALCASE_INFOBOX_ZIP',
                    value: userInfo.zip
                  },
                  {
                    label: 'CP_INDIVIDUALCASE_INFOBOX_CITY',
                    value: userInfo.city
                  },
                  {
                    label: 'CP_INDIVIDUALCASE_INFOBOX_COUNTRY',
                    value: userInfo.country
                  }
                ].map(({ label, value }) => (
                  <CopyTooltip value={value}>
                    <LabeledValueCard
                      className={classes.customerAddress}
                      label={label}
                      value={value}
                    />
                  </CopyTooltip>
                ))}
              </div>
            </div>
          </div>
          <div className={classes.tabsGroup}>
            {isTwoColumnWidth ? (
              <HeaderTabs
                options={tabs || []}
                value={tabValue}
                onChange={isIframeLoading ? undefined : onTabChange}
                tabsWrapperClassName={classes.tabsWrapper}
                withoutIndicator
              />
            ) : (
              <HeaderSelect
                value={tabValue}
                options={tabs || []}
                onChange={isIframeLoading ? undefined : onTabChange}
              />
            )}
          </div>
        </div>
        <div>
          {isThreeColumnWidth && <UserInfoLabels userInfo={userInfo} />}
        </div>
      </div>
      <div className={classes.content}>
        {!submitDone && <ProgressLoader open={isIframeLoading} />}
        {submitDone && <SuccessModal />}
        {tabValue === LIABILITY_TAB ? (
          <CustomerCenterIframe
            {...customerCenterIframeProps}
            className={classes.iframe}
          />
        ) : [POSTBOX_TAB, EDIT_TAB, CONTACT_FORM_TAB].includes(tabValue) ? (
          <div className={classes.contentTabs} key={iframeKey}>
            {tabValue === POSTBOX_TAB ? (
              <>
                <Paper className={clsx(classes.paper, paperClasses.elevation1)}>
                  <Translation component="p" className={classes.postBoxHeader}>
                    CP_INDIVIDUALCASE_POSTBOX_INFO
                  </Translation>
                  <CustomerCenterIframe
                    {...customerCenterIframeProps}
                    className={classes.iframe}
                  />
                </Paper>
                {contactFormDecision && (
                  <Paper className={paperClasses.elevation1}>
                    <ContactForm
                      onSubmit={values =>
                        onContactFormSubmit(values, onContactFormLoad)
                      }
                      className={classes.contactForm}
                    />
                  </Paper>
                )}
              </>
            ) : tabValue === EDIT_TAB ? (
              <Paper className={clsx(classes.edit, paperClasses.elevation1)}>
                <Tabs
                  TabScrollButtonProps={{
                    classes: {
                      root: classes.tabArrow
                    }
                  }}
                  variant="scrollable"
                  scrollButtons="auto"
                  tabClassName={classes.tab}
                  selectedTabClassName={classes.selectTab}
                  className={classes.editTabs}
                  options={menuTabs || []}
                  value={editTabsValue}
                  onChange={isIframeLoading ? undefined : onEditTabChange}
                  vertical={isTwoColumnWidth}
                />
                <div className={classes.editContent}>{EditExtendedContent}</div>
              </Paper>
            ) : null}
          </div>
        ) : null}
      </div>
    </div>
  );
};

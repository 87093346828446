import { createAction } from 'redux-act';

export const getEmbeddedUrls = createAction('QuickSight: Get embedded urls');
export const embeddedUrlsReceived = createAction(
  'QuickSight: Embedded urls received'
);

export const redirectToQuickSightSSO = createAction(
  'QuickSight: Redirect to QuickSight SSO'
);

export const clearQuickSightStore = createAction(
  'QuickSight: Clear QuickSight store'
);

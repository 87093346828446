import { call, put, select } from 'redux-saga/effects';

import ApiService from '../../services/ApiService';

import { cognitoTokenSelector } from '../selectors';
import { decisionsReceived, handleError } from '../actions';

export function* getDecisions() {
  try {
    const token = yield select(cognitoTokenSelector);
    const decisions = yield call(ApiService.getDecisions, token);
    yield put(decisionsReceived(decisions));
  } catch (err) {
    yield put(handleError(err));
  }
}

import { AUTH_REDIRECT } from '../constants/routes';

const devconfig = {
  redirect_uri: window.location.origin + AUTH_REDIRECT,
  response_type: 'code',
  client_id: '3lufurh2vbngpgule7ei73f26e',
  state: null,
  scope: ['email', 'openid', 'profile'],
  identity_provider: 'TroyOkta'
};

const prodconfig = {
  redirect_uri: window.location.origin + AUTH_REDIRECT,
  response_type: 'code',
  client_id: '6cv70ertsf4vl8orr5khko5ksl',
  state: null,
  scope: ['email', 'openid', 'profile'],
  identity_provider: 'TroyOkta'
};

let conf = devconfig;
if (process.env.REACT_APP_ENV === 'production') {
  conf = prodconfig;
}

export default conf;

import { randomCorrelationID } from '../../utils/api';

const BACKEND_URL = process.env.REACT_APP_API_URL;

export const CORRELATION_ID = randomCorrelationID();

export const GRAPHQL = `${BACKEND_URL}/graphql`;
const BACKEND = `${BACKEND_URL}/api`;

export const STATISTIC_IFRAME_INFO = `${BACKEND}/quicksight/embedded-url`;
export const SEARCH_USERS = query =>
  `${BACKEND}/search/customer?query=${query}`;
export const CLIENT_TOKEN = `${BACKEND}/customer/clienttoken`;

export const DECISIONS = `${BACKEND}/clientdecisions`;

export const LIABILITY_CLOSE_REASONS = `${BACKEND}/liability-close-reason`;
export const LIABILITY_CLOSE = liabilityId =>
  `${BACKEND}/liability/${liabilityId}/close`;
export const REPORT_CREDIT_MEMO = liabilityId =>
  `${BACKEND}/liability/${liabilityId}/creditMemo`;
export const REPORT_PAYMENT = liabilityId =>
  `${BACKEND}/liability/${liabilityId}/payment`;
export const CONTACT_FORM = liabilityId =>
  `${BACKEND}/liability/${liabilityId}/contact-form`;
export const HOLD_FORM = liabilityId =>
  `${BACKEND}/liability/${liabilityId}/on-hold`;

export const INVOICES = `${BACKEND}/invoices`;
export const INVOICE_ON_ID = id => `${INVOICES}/${id}/download`;

export const ONE_TIME_TOKEN = `${BACKEND}/one-time-token/create`;

export const STRIPO_TOKEN = `${BACKEND}/stripo/authentication`;

export const LANGUAGE = `${BACKEND}/language`;

export const LOOKER_DASHBOARDS = `${BACKEND}/looker-embed/dashboards`;
export const LOOKER_DASHBOARD = dashboardId =>
  `${BACKEND}/looker-embed/dashboards/${dashboardId}/url`;

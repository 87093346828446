import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';

import { Translation } from '@troy/shared/src/components/common';

import { AddIcon, Button } from '../../../common';
import {
  widgetHeight,
  widgetWidth
} from '../../../../assets/styles/jss_functions';

const iconSize = 14;

const useStyles = makeStyles(theme => {
  return {
    wrapper: {
      border: `1px solid ${theme.palette.primary.contrastText} !important`,
      borderRadius: `${theme.shape.borderRadius * 6}px !important`,
      minWidth: `${widgetWidth}px !important`,
      height: `${widgetHeight}px !important`,
      backgroundColor: `hsla(0, 0%, 100%, .15) !important`
    },
    content: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      border: `1px solid ${theme.palette.primary.contrastText}`,
      padding: '10px 90px',
      borderRadius: `${theme.shape.borderRadius * 6}px !important`
    },
    label: {
      textAlign: 'center',
      color: theme.palette.primary.contrastText
    },
    addIcon: {
      color: theme.palette.primary.contrastText,
      fontWeight: 100
    },
    addButton: {
      marginRight: '7px',
      marginBottom: '3px',
      width: iconSize,
      height: iconSize,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }
  };
});

const AddWidget = ({ onClick, className }) => {
  const classes = useStyles();
  return (
    <Button
      className={clsx(classes.wrapper, className)}
      onClick={onClick}
      data-cy="widget__add"
    >
      <div className={classes.content}>
        <div className={classes.addButton}>
          <AddIcon className={classes.addIcon} />
        </div>
        <Translation component="p" className={classes.label}>
          CP_START_ADDWIDGET
        </Translation>
      </div>
    </Button>
  );
};

export default AddWidget;

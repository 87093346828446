import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { AnimatePresence } from 'framer-motion';
import clsx from 'clsx';

import { AddWidget } from '../Widgets';
import MotionGridItem from '../../common/MotionGridItem';
import { findIndex, move } from '../../../utils/grid';
import {
  widgetHeight,
  widgetHeightExtended,
  widgetListFixWidth,
  widgetMargin,
  widgetWidth
} from '../../../assets/styles/jss_functions';
import { stringifyUrlParams } from '../../../utils/search';
import { HOME } from '../../../constants/routes';

const useStyles = makeStyles(theme => ({
  item: {
    margin: widgetMargin,
    width: `${widgetWidth}px !important`,
    height: `${widgetHeight}px !important`,
    opacity: 1,
    userSelect: 'none'
  },
  animatable: {
    zIndex: 10
  },
  extended: {
    position: 'absolute',
    left: 0,
    right: 0,
    margin: '0 auto !important',
    ...widgetListFixWidth,
    height: 'unset !important',
    minHeight: `${widgetHeightExtended}px !important`,
    transform: 'translate(0, 0) !important'
  },
  itemWrapper: {
    margin: widgetMargin
  }
}));

const Item = ({
  component: Component,
  extended,
  extendedComponent,
  id,
  ...compProps
}) => {
  const classes = useStyles();
  const [showExtended, setShowExtended] = useState(false);
  const [showAnimatable, setShowAnimatable] = useState(false);
  const [prevExtendedValue, setPrevExtendedValue] = useState('');
  const [visibility, setVisibility] = useState(true);

  useEffect(() => {
    if (extendedComponent) {
      if (extended) {
        setShowExtended(true);
      } else {
        setVisibility(false);
      }

      setPrevExtendedValue(extendedComponent);
    } else {
      if (prevExtendedValue) {
        const isExtended = prevExtendedValue === id;
        if (isExtended) {
          setShowExtended(false);
          setShowAnimatable(true);
        }

        setTimeout(
          isExtended => {
            if (isExtended) {
              setShowAnimatable(false);
            } else {
              setVisibility(true);
            }
          },
          600,
          isExtended
        );
        setPrevExtendedValue('');
      }
    }
  }, [extendedComponent]);

  return (
    <Component
      id={id}
      {...compProps}
      extended={extended}
      className={clsx(
        classes.item,
        showExtended && classes.animatable,
        showExtended && classes.extended,
        showAnimatable && classes.animatable
      )}
      visibility={visibility}
    />
  );
};

const AddWidgetItem = ({ isHide, onAddWidgetClick, index }) => {
  const classes = useStyles();
  // Disable dragging for this item.
  return (
    <div className={classes.itemWrapper}>
      <MotionGridItem index={index}>
        {!isHide ? <AddWidget onClick={onAddWidgetClick} /> : null}
      </MotionGridItem>
    </div>
  );
};

export default ({
  widgets,
  onElementRemove,
  setExtended,
  extendedComponent: extended,
  maxWidgetsLength,
  onAddWidgetClick,
  onExtendedClose,
  setWidgets,
  isPending
}) => {
  const history = useHistory();
  const handleSetExtended = useCallback(
    (key, params) => {
      history.push(`${HOME}?${stringifyUrlParams({ widget: key, ...params })}`);
      setExtended(key);
    },
    [setExtended]
  );
  const handleRemoveItem = useCallback(
    key =>
      extended === key
        ? () => {
            history.push(HOME);
            onExtendedClose();
          }
        : () => onElementRemove(key),
    [extended, onExtendedClose, onElementRemove]
  );
  const [isDragging, setDragging] = useState(false);

  const positions = useRef([]).current;
  const setPosition = (i, offset) => (positions[i] = offset);
  // Find the ideal index for a dragging item based on its position in the array, and its
  // current drag offset. If it's different to its current index, we swap this item with that
  // sibling.
  const moveItem = (i, offset, point) => {
    const targetIndex = findIndex(i, point, positions);
    if (targetIndex !== i && targetIndex < widgets.length) {
      setWidgets(allWidgets => {
        let wTargetIndex = allWidgets.findIndex(
          ({ key }) => key === widgets[targetIndex].key
        );
        let wIndex = allWidgets.findIndex(({ key }) => key === widgets[i].key);
        return [...move(allWidgets, wTargetIndex, wIndex)];
      });
    }
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const widgetKey = urlParams.get('widget');
    widgetKey && setExtended(widgetKey);
    return history.listen(location => {
      if (history.action === 'POP') {
        const urlParams = new URLSearchParams(location.search);
        const widgetKey = urlParams.get('widget');
        widgetKey ? setExtended(widgetKey) : onExtendedClose();
      }
    });
  }, []);

  return (
    !isPending && (
      <AnimatePresence>
        {widgets.map(({ component, key, ...compProps }, index) => (
          <Item
            {...{ component, key, ...compProps }}
            id={key}
            setExtended={params => handleSetExtended(key, params)}
            extended={extended === key}
            extendedComponent={extended}
            removeItem={handleRemoveItem(key)}
            setPosition={setPosition}
            moveItem={moveItem}
            widgetsNumber={widgets.length}
            index={index}
            setDragging={setDragging}
            isDragging={isDragging}
          />
        ))}
        <AddWidgetItem
          isHide={extended}
          onAddWidgetClick={onAddWidgetClick}
          index={maxWidgetsLength}
        />
      </AnimatePresence>
    )
  );
};

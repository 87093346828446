import { delay, put } from 'redux-saga/effects';
import { SimpleActionCreator } from 'redux-act';

import { portalConfigurationReceived } from '../actions';

import troyDeConf from '../../config/whitelabel/troy_de';
import troyNlConf from '../../config/whitelabel/troy_nl';
import nutunConf from '../../config/whitelabel/nutun';

export function* getPortalConfiguration({
  payload: { portalKeyBoolean, handleErrorAction }
}: {
  payload: {
    portalKeyBoolean:
      | 'customerAppEnabled'
      | 'agentAppEnabled'
      | 'clientAppEnabled';
    handleErrorAction: SimpleActionCreator<unknown>;
  };
}): Generator {
  try {
    // TODO add backend API call support
    // const conf = yield call(get, PORTAL_CONFIGURATION);
    let conf = troyDeConf;
    if (
      window.location.host.includes('.nutun.') ||
      window.location.search.includes('nutun=1')
    ) {
      if (nutunConf[portalKeyBoolean]) {
        conf = nutunConf;
      }
    } else if (
      window.location.host.includes('klantenportaal') ||
      window.location.search.includes('klantenportaal=1')
    ) {
      if (troyNlConf[portalKeyBoolean]) {
        conf = troyNlConf;
      }
    }
    yield delay(500);
    yield put(portalConfigurationReceived(conf));
  } catch (err) {
    yield put(handleErrorAction(err));
  }
}

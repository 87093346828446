import moment from 'moment';
import { Address, Contact } from '../types';

export const findLatestAddress = (addresses: Address[]): Address => {
  const addressesSorted = [...addresses].sort((a, b) =>
    moment(a.datetime) > moment(b.datetime) ? -1 : 1
  );

  return addressesSorted.length
    ? addressesSorted[0]
    : {
        datetime: '',
        street_and_house_nr: '',
        city: '',
        zip: '',
        country: '',
        address_line_2: ''
      };
};

export const findLatestPhoneMobile = (contacts: Contact[]): Contact => {
  const contactsSorted = [...contacts]
    .filter(c => c.type === 'PHONE' || c.type === 'MOBILE')
    .sort((a, b) => (moment(a.created) > moment(b.created) ? -1 : 1));

  return contactsSorted.length
    ? contactsSorted[0]
    : {
        created: '',
        identifier: '',
        type: 'MOBILE'
      };
};

export const findLatestEmail = (contacts: Contact[]): Contact => {
  const contactsSorted = [...contacts]
    .filter(c => c.type === 'MAIL')
    .sort((a, b) => (moment(a.created) > moment(b.created) ? -1 : 1));

  return contactsSorted.length
    ? contactsSorted[0]
    : {
        created: '',
        identifier: '',
        type: 'MAIL'
      };
};

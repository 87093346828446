import React from 'react';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';

import { UINotification } from '../../../types/notifications';
import { Theme } from '@mui/material';

import { Text } from '../index';
import { CustomColor } from '../Text';

interface PostBoxItemTagProps {
  colorScheme: UINotification['colorScheme'];
  tag: UINotification['headerTag'];
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    padding: `${theme.spacing(0.5)} ${theme.spacing(1)}`,
    background: theme.palette.action.disabled,
    borderRadius: Number(theme.shape.borderRadius),
    border: `1px solid ${theme.palette.text.secondary}`,
    boxSizing: 'border-box'
  },
  containerTertiary: {
    background: theme.palette.tertiary.light,
    borderColor: theme.palette.tertiary.main
  },
  containerWarning: {
    background: theme.customColors.warningDarkFill,
    borderColor: theme.palette.warning.dark
  }
}));

const PostBoxItemTag: React.FC<PostBoxItemTagProps> = ({
  colorScheme,
  tag
}) => {
  const classes = useStyles();

  const color: CustomColor =
    colorScheme === 'Tertiary'
      ? 'tertiaryDark'
      : colorScheme === 'Warning'
      ? 'warningDarkFocus'
      : 'textSecondary';

  return (
    <div
      className={clsx(
        classes.container,
        colorScheme === 'Tertiary' && classes.containerTertiary,
        colorScheme === 'Warning' && classes.containerWarning
      )}
    >
      <Text translated component="span" variant="subtitle2" color={color}>
        {tag}
      </Text>
    </div>
  );
};

export default PostBoxItemTag;

import React from 'react';
import { FieldValidator } from 'formik';
import { Checkbox as MaterialCheckBox, FormControlLabel } from '@mui/material';

import { onClickWrapper } from '../../../utils/ui';
import { FormField, Translation } from '../index';
import { FormikFieldRenderProps } from '../../../types/formik';
import { FormikProps } from 'formik/dist/types';

interface CheckboxProps {
  translated?: boolean;
  name?: string;
  label?: string;
  value?: boolean;
  disabled?: boolean;
  id?: string;
  onClick: (e: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
  classNameChecked?: string;
  classNameTranslation?: string;
  classNameWrapper?: string;
  classNameLabel?: string;
  validate?: FieldValidator;
  icon?: React.ReactElement;
  checkedIcon?: React.ReactElement;
  form?: FormikProps<never>;
}

const Checkbox: React.FC<CheckboxProps & FormikFieldRenderProps<boolean>> = ({
  translated,
  label,
  value,
  disabled,
  name,
  id,
  onClick,
  className,
  classNameChecked,
  classNameTranslation,
  classNameWrapper,
  classNameLabel,
  icon,
  checkedIcon,
  form,
  field
}) => {
  const changeHandler = (...args: never[]): void => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    form
      ? form.setFieldValue(name, args[1])
      : field
      ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        field.onChange(...args)
      : // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        onClick(...args);
  };

  return label ? (
    <FormControlLabel
      control={
        <MaterialCheckBox
          checked={field ? !!field.value : value}
          value={name}
          disabled={disabled}
          id={id}
          onChange={onClickWrapper(id, changeHandler)}
          className={className}
          classes={{
            checked: classNameChecked
          }}
          icon={icon}
          checkedIcon={checkedIcon}
        />
      }
      label={
        translated ? (
          <Translation keyIfNoValue className={classNameTranslation}>
            {label}
          </Translation>
        ) : (
          label
        )
      }
      classes={{
        root: classNameWrapper,
        label: classNameLabel
      }}
    />
  ) : (
    <MaterialCheckBox
      checked={field ? !!field.value : value}
      value={name}
      id={id}
      onChange={onClickWrapper(id, changeHandler)}
      className={className}
    />
  );
};

interface FormCheckBoxProps {
  translated?: boolean;
  name?: string;
  label?: string;
  value?: boolean;
  disabled?: boolean;
  id?: string;
  onClick: (e: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
  classNameChecked?: string;
  classNameTranslation?: string;
  classNameWrapper?: string;
  classNameLabel?: string;
  validate?: FieldValidator;
  icon?: React.ReactElement;
  checkedIcon?: React.ReactElement;
}

const FormCheckBox: React.FC<FormCheckBoxProps> = ({
  translated,
  name,
  label,
  value,
  disabled,
  id,
  onClick,
  className,
  classNameChecked,
  classNameTranslation,
  classNameWrapper,
  classNameLabel,
  validate,
  icon,
  checkedIcon
}): React.ReactElement => {
  const customProps = {
    translated,
    label,
    value,
    disabled,
    id,
    onClick,
    className,
    classNameChecked,
    classNameTranslation,
    classNameWrapper,
    classNameLabel,
    icon,
    checkedIcon
  };

  return name ? (
    <FormField
      onChange={onClick}
      value={value}
      component={Checkbox}
      {...customProps}
      name={name}
      validate={validate}
    />
  ) : (
    <Checkbox
      {...customProps}
      field={{
        value,
        onChange: onClick,
        name: name || ''
      }}
    />
  );
};

export default FormCheckBox;

import React, { forwardRef, useMemo } from 'react';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import {
  alpha,
  FilledInput,
  FormControl,
  Input,
  InputAdornment,
  InputLabel,
  OutlinedInput
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import { FormField, Translation } from '@troy/shared/src/components/common';
import { translationsValuesSelector } from '../../../store/selectors';

export const RawInput = React.forwardRef(({ input, ...props }, ref) => (
  <input ref={ref} {...props} {...input} />
));

const useStyles = makeStyles(theme => ({
  startIcon: {
    position: 'absolute',
    top: '50%',
    marginTop: '-10px',
    zIndex: 3,
    marginLeft: '13px'
  },
  outlinedRoot: {
    borderRadius: theme.shape.borderRadius * 8,
    '&$focused $notchedOutline': {
      // borderWidth: 1,
      boxShadow: '0px 2px 12px 0px #00000033'
    }
  },
  filledRoot: {
    border: `1px solid ${theme.palette.text.secondary}`,
    borderRadius: theme.shape.borderRadius * 8,
    borderTopLeftRadius: theme.shape.borderRadius * 8,
    borderTopRightRadius: theme.shape.borderRadius * 8,
    backgroundColor: theme.palette.background.default,
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:hover': {
      backgroundColor: theme.palette.background.default
    },
    '&$focused': {
      backgroundColor: theme.palette.background.default,
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
      borderColor: theme.palette.primary.main
    }
  },
  filled: {
    paddingRight: 25
  },
  input: {
    [`&:-webkit-autofill, 
    &:-webkit-autofill:hover, 
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active,
    &:-internal-autofill-selected`]: {
      backgroundColor: 'transparent !important',
      animationName: 'none',
      WebkitBoxShadow: '0 0 0 30px white inset'
    }
  },
  filledInput: {
    padding: '27px 12px 10px 0'
  },
  textInput: {
    textAlign: 'center',
    padding: '6px',
    border: '0',
    backgroundColor: alpha(theme.palette.background.default, 0.2),
    color: theme.palette.primary.contrastText,
    fontSize: '1.125em',

    '&:focus': {
      outline: 'none !important'
    },
    '&::placeholder': {
      color: alpha(theme.palette.background.default, 0.6)
    }
  },
  multiline: {
    borderRadius: theme.shape.borderRadius * 4
  }
}));

const useLabelStyles = makeStyles(() => ({
  filled: {
    '&.MuiInputLabel-shrink': {
      transform: 'translate(44px, 10px) scale(0.75)'
    },
    transform: 'translate(44px, 24px) scale(1)'
  },
  shrink: {
    transform: 'translate(44px, 10px) scale(0.75)'
  }
}));

export const TextField = forwardRef(
  (
    {
      field,
      classes,
      className,
      inputClassName,
      inputRootClassName,
      color = 'primary',
      variant,
      label,
      labelFixed,
      labelTranslated,
      labelClassName,
      id,
      type,
      min,
      step,
      startLabelIcon: StartLabelIcon,
      startIcon: StartIcon,
      endIcon: EndIcon,
      startIconClassName,
      endIconClassName,
      endAdornmentClassName,
      inputFocusedClassName,
      inputNotchedOutlineClassName,
      placeholder: inputPlaceholder,
      ...props
    },
    ref
  ) => {
    const c = useStyles();
    const labelClasses = useLabelStyles();
    const InputComponent = useMemo(
      () =>
        variant === 'outlined'
          ? OutlinedInput
          : variant === 'filled'
          ? FilledInput
          : Input,
      [variant]
    );

    const translations = useSelector(translationsValuesSelector);

    return (
      <FormControl className={className}>
        {StartLabelIcon ? (
          <div className={c.startIcon}>
            <StartLabelIcon className={startIconClassName} />
          </div>
        ) : null}
        {label && (
          <InputLabel
            className={labelClassName}
            classes={{
              root: labelClassName,
              filled: labelClasses.filled,
              shrink: labelClasses.shrink
            }}
            htmlFor={id}
            shrink={labelFixed}
            variant={variant}
          >
            {labelTranslated ? (
              <Translation keyIfNoValue isFragment>
                {label}
              </Translation>
            ) : (
              label
            )}
          </InputLabel>
        )}
        <InputComponent
          inputRef={ref}
          id={id}
          color={color}
          classes={{
            root: clsx(
              variant === 'outlined' && c.outlinedRoot,
              variant === 'filled' && c.filledRoot,
              !EndIcon && variant === 'filled' && c.filled,
              inputRootClassName
            ),
            input: clsx(
              c.input,
              variant === 'filled' && c.filledInput,
              inputClassName
            ),
            focused: inputFocusedClassName,
            notchedOutline: inputNotchedOutlineClassName,
            multiline: c.multiline
          }}
          startAdornment={
            StartIcon ? (
              <InputAdornment position="start">
                <StartIcon className={startIconClassName} />
              </InputAdornment>
            ) : null
          }
          placeholder={
            inputPlaceholder && translations[inputPlaceholder]
              ? translations[inputPlaceholder]
              : inputPlaceholder
          }
          endAdornment={
            EndIcon ? (
              <InputAdornment className={endAdornmentClassName} position="end">
                <EndIcon className={endIconClassName} />
              </InputAdornment>
            ) : null
          }
          inputProps={{
            type
          }}
          {...props}
          {...field}
        />
      </FormControl>
    );
  }
);

export const LoginRawInput = ({ field, ...props }) => {
  const classes = useStyles();
  return (
    <input
      {...props}
      className={clsx(classes.textInput, props.className)}
      {...field}
    />
  );
};

export const FormLoginRawInput = ({ name, ...props }) =>
  name ? (
    <FormField name={name} component={LoginRawInput} {...props} />
  ) : (
    <LoginRawInput {...props} />
  );

export const FormTextField = ({ name, validate, onChange, ...props }) =>
  name ? (
    <FormField
      name={name}
      component={TextField}
      validate={validate}
      onChange={onChange}
      {...props}
    />
  ) : (
    <TextField {...props} />
  );

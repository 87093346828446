import React from 'react';
import { makeStyles } from '@mui/styles';

import { FileIcon, SyncIcon, Widget } from '../../../common';

import Carousel from '../../../common/Carousel';

const useItemStyles = makeStyles(() => ({
  file: {
    display: 'flex',
    fontSize: '0.75em',
    margin: '20px 0',
    textAlign: 'left'
  },
  fileIcon: {
    marginRight: '15px'
  }
}));

const Item = props => {
  const classes = useItemStyles();
  return (
    <div {...props}>
      <div className={classes.file}>
        <FileIcon className={classes.fileIcon} />
        <div>
          <div>142-IchbineinDateiname</div>
          <div>Zweilzeilig.pdf, 94kb</div>
        </div>
      </div>
      <div className={classes.file}>
        <FileIcon className={classes.fileIcon} />
        <div>
          <div>142-IchbineinDateiname</div>
          <div>Zweilzeilig.pdf, 136kb</div>
        </div>
      </div>
    </div>
  );
};

const data = [
  {
    key: 1,
    component: <Item />
  },
  {
    key: 2,
    component: <Item />
  },
  {
    key: 3,
    component: <Item />
  }
];

const useStyles = makeStyles(() => ({
  wrapper: {
    padding: '10px',
    display: 'flex',
    flexDirection: 'column'
  }
}));

export default ({ removeItem, ...props }) => {
  const classes = useStyles();
  return (
    <Widget {...props} icon={SyncIcon} onActionClick={removeItem}>
      <div className={classes.wrapper}>
        <Carousel items={data.map(item => item.component)} />
      </div>
    </Widget>
  );
};

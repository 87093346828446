import { get } from '../utils/api';
import { TRELLO_BOARD_INFO, TRELLO_USER_INFO } from '../constants/api';

class TrelloService {
  static getUserInfo = token => get(TRELLO_USER_INFO(token));
  static getBoardInfo = (token, boardId) =>
    get(TRELLO_BOARD_INFO(token, boardId));
}

export default TrelloService;

import { createReducer } from 'redux-act';

import * as actions from '../actions/confluence';

const DEFAULT_STATE = {
  token: '',
  accessibleResources: null,
  resourceContent: null
};

export default createReducer(
  {
    [actions.confluenceTokenReceived]: (state, token) => ({
      ...state,
      token
    }),
    [actions.confluenceAccessibleResourcesReceived]: (
      state,
      accessibleResources
    ) => ({
      ...state,
      accessibleResources
    }),
    [actions.confluenceResourceContentReceived]: (
      state,
      { content, contentId, cloudId }
    ) => {
      let resourceContent = state.resourceContent;
      if (contentId) {
        resourceContent = {
          ...resourceContent,
          [cloudId]: {
            ...(resourceContent || {})[cloudId],
            [contentId]: {
              ...((resourceContent || {})[cloudId] || {})[contentId],
              ...content
            }
          }
        };
      } else if (content && content.results && content.results.length) {
        resourceContent = {
          ...resourceContent,
          [cloudId]: {
            ...(resourceContent || {})[cloudId],
            ...content.results.reduce(
              (acc, { id, ...props }) => ({ ...acc, [id]: props }),
              {}
            )
          }
        };
      }
      return { ...state, resourceContent };
    },
    [actions.clearConfluenceStore]: state => ({ ...DEFAULT_STATE })
  },
  DEFAULT_STATE
);

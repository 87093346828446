import React, { useCallback, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';

import { Translation } from '@troy/shared/src/components/common';

import { widgetWidth2Down } from '../../../../../assets/styles/jss_functions';

import {
  Button,
  CheckIcon,
  ClearIcon,
  Dialog,
  Form,
  SelectField
} from '../../../../common';

import { CLOSE_CASE_FORM_NAME } from '../../../../../constants/formNames';
import { required } from '../../../../../utils/form';

import { ReactComponent as DeactivatedActiveIcon } from '../../../../../assets/icons/widgets/users-widget/deactivated_active.svg';
import { ReactComponent as DeactivatedInactiveIcon } from '../../../../../assets/icons/widgets/users-widget/deactivated_inactive.svg';
import { ReactComponent as ExpandIcon } from '../../../../../assets/icons/widgets/users-widget/expand.svg';

const useStyles = makeStyles(theme => ({
  header: {
    fontWeight: 'bold',
    margin: '4px 0 12px 0',

    ...widgetWidth2Down({
      ...theme.typography.h4
    })
  },
  description: {
    ...theme.typography.body2,
    color: theme.palette.text.secondary,
    marginBottom: 16
  },
  selectField: {
    minWidth: 300,
    background: theme.palette.background.default
  },
  selectFieldLabel: {
    zIndex: 1000,
    color: `${theme.palette.text.primary} !important`
  },
  selectFieldIcon: {
    position: 'absolute',
    top: 'calc(50% - 8px)',
    right: 8,
    pointerEvents: 'none'
  },
  buttonContainer: {
    position: 'relative',
    marginTop: 16
  },
  button: {
    minWidth: 240,
    position: 'absolute',
    left: 72,
    top: 31
  },
  dialog: {
    maxWidth: 360
  }
}));

export default ({ onSubmit, className, closeReasons }) => {
  const classes = useStyles();
  const [isDialogOpen, setDialogOpen] = useState(false);

  const onDialogClose = useCallback(() => {
    setDialogOpen(false);
  }, []);

  const onLeverClick = useCallback(() => {
    setDialogOpen(true);
  }, []);

  return (
    <Form
      initialValues={{ closeReason: null }}
      onSubmit={() => {}}
      name={CLOSE_CASE_FORM_NAME}
      className={className}
    >
      {({ dirty, isValid, isSubmitting, values }) => {
        const onYesClick = () => {
          onSubmit((values && values.closeReason) || null);
          setDialogOpen(false);
        };
        return (
          <>
            <div>
              <Translation component="h3" className={classes.header}>
                CP_INDIVIDUALCASE_EDIT_CLOSECASE_INFO
              </Translation>
              <Translation component="p" className={classes.description}>
                CP_INDIVIDUALCASE_EDIT_CLOSECASE_INFOADDITION
              </Translation>
              <Translation
                component={SelectField}
                propPlace="label"
                name="closeReason"
                validate={required}
                className={classes.selectField}
                classNameLabel={classes.selectFieldLabel}
                iconComponent={
                  <ExpandIcon className={classes.selectFieldIcon} />
                }
                options={closeReasons}
              >
                CP_INDIVIDUALCASE_EDIT_CLOSECASE_DROPDOWN
              </Translation>
              <div className={classes.buttonContainer}>
                {!dirty || !isValid ? (
                  <DeactivatedInactiveIcon />
                ) : (
                  <DeactivatedActiveIcon />
                )}

                <Translation
                  component={Button}
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={onLeverClick}
                  type="button"
                  disabled={!dirty || !isValid || isSubmitting}
                >
                  CP_INDIVIDUALCASE_EDIT_CLOSECASE_CLOSEBUTTON
                </Translation>
              </div>
            </div>
            <Translation
              component={({ children, ...props }) => (
                <Translation
                  component={Dialog}
                  componentChildren={children}
                  propPlace="title"
                  {...props}
                >
                  CP_INDIVIDUALCASE_EDIT_CLOSECASE_CLOSEBUTTON_CONFIRMATION_HEADER
                </Translation>
              )}
              className={classes.dialog}
              open={isDialogOpen}
              actions={
                <>
                  <Translation
                    component={Button}
                    color="secondary"
                    variant="outlined"
                    startIcon={ClearIcon}
                    type="button"
                    onClick={onDialogClose}
                  >
                    CP_INDIVIDUALCASE_EDIT_CLOSECASE_CLOSEBUTTON_CONFIRMATION_NO
                  </Translation>
                  <Translation
                    component={Button}
                    color="primary"
                    variant="contained"
                    startIcon={CheckIcon}
                    type="submit"
                    onClick={onYesClick}
                  >
                    CP_INDIVIDUALCASE_EDIT_CLOSECASE_CLOSEBUTTON_CONFIRMATION_YES
                  </Translation>
                </>
              }
            >
              CP_INDIVIDUALCASE_EDIT_CLOSECASE_CLOSEBUTTON_CONFIRMATION_INFO
            </Translation>
          </>
        );
      }}
    </Form>
  );
};

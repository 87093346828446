import React from 'react';
import clsx from 'clsx';
import { Paper, usePaperStyles } from '@troy/shared/src/components/common';
import { Dialog as MaterialDialog } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { motion } from 'framer-motion';

const useStyles = makeStyles(() => ({
  modal: {
    padding: 10
  }
}));

const item = {
  hidden: {
    y: -30,
    opacity: 0
  },
  visible: {
    y: 0,
    opacity: 1
  }
};

const MyTransition = ({ in: open, onEnter, onExited, children }) => {
  return (
    <motion.div
      variants={item}
      initial="hidden"
      animate={open ? 'visible' : 'hidden'}
      onAnimationComplete={onExited}
      onAnimationStart={onEnter}
    >
      {children}
    </motion.div>
  );
};

export default ({ children, className, isOpen, onClose, withoutPaper }) => {
  const c = useStyles();
  const classes = usePaperStyles();

  return (
    <MaterialDialog
      open={isOpen}
      onClose={onClose}
      data-cy="modal"
      TransitionComponent={MyTransition}
      PaperProps={{
        classes: {
          root: classes.root,
          rounded: classes.rounded,
          elevation1: classes.elevation1
        }
      }}
      keepMounted
      closeAfterTransition
    >
      <div>
        {!withoutPaper ? (
          <Paper className={clsx(c.modal, className)}>{children}</Paper>
        ) : (
          children
        )}
      </div>
    </MaterialDialog>
  );
};

import { Action, Dispatch } from 'redux';
import {
  ApiNotification,
  ApiNotificationChannel,
  ApiNotificationDocument,
  LANotification,
  UIDirectionFilter,
  UIFilterOption,
  UINotificationChannelFilter,
  UINotificationFile
} from '../../types/notifications';
import { enqueueSnackbar } from '../../store/actions';

import { SupportedIcon } from '../../components/common/CustomIcon/iconData';
import { getImageFileName } from '../images';

export const MAX_COLLAPSED_TEXT_SIZE = 150;

export const MAX_FILENAME_SIZE_LG = 50;
export const MAX_FILENAME_SIZE_TABLET = 28;
export const MAX_FILENAME_SIZE_MOBILE = 20;

export const UIChannelFilerMapping: Partial<
  Record<ApiNotificationChannel, UINotificationChannelFilter>
> = {
  LETTER: 'POST',
  PAYMENT: 'POST',
  INVOICE: 'POST',
  EMAIL: 'EMAIL',
  DOCUMENT: 'EMAIL',
  PHONE: 'PHONE',
  SMS: 'TEXT_MESSAGE',
  WHATSAPP: 'TEXT_MESSAGE',
  CHAT: 'CHAT',
  CLIENT_CHAT: 'CLIENT',
  CUSTOMERCENTER: 'CUSTOMER_CENTER',
  INTERNAL_NOTE: 'INTERNAL_NOTE',
  ERROR: 'INTERNAL_NOTE',
  CLIENT_EMAIL: 'CLIENT'
};

export const UIDirectionFilters: UIDirectionFilter[] = [
  {
    label: 'FILTER_ALL_MESSAGES',
    type: 'all',
    id: 'postbox.filter.all-message-type'
  },
  {
    label: 'FILTER_INBOX',
    type: 'FILTER_INBOX',
    id: 'postbox.filter.inbox-message-type'
  },
  {
    label: 'FILTER_SENT',
    type: 'FILTER_SENT',
    id: 'postbox.filter.sent-message-type'
  }
];

export const UIFilterOptions: UIFilterOption[] = [
  {
    label: 'FILTER_POST',
    type: 'POST'
  },
  {
    label: 'FILTER_EMAIL',
    type: 'EMAIL'
  },
  {
    label: 'TCM_CUSTOMER_PHONE',
    type: 'PHONE'
  },
  {
    label: 'FILTER_SMS',
    type: 'TEXT_MESSAGE'
  },
  {
    label: 'FILTER_CHAT',
    type: 'CHAT'
  },
  {
    label: 'FILTER_KUNDENCENTER',
    type: 'CUSTOMER_CENTER'
  }
];

export const UIFilterOptionInternal: UIFilterOption = {
  label: 'FILTER_INTERNAL',
  type: 'INTERNAL_NOTE'
};

export const UIFilterOptionClient: UIFilterOption = {
  label: 'FILTER_CLIENT',
  type: 'CLIENT'
};

export const UIAllFilterOptions: UIFilterOption[] = [
  ...UIFilterOptions,
  UIFilterOptionInternal,
  UIFilterOptionClient
];

export const ICON_MAP: Record<string, SupportedIcon> = {
  'email_icon.svg': 'mailIcon',
  'pdf_icon.svg': 'pdfIcon',
  'image_icon.svg': 'imageIcon',
  'documents_icon.png': 'wordIcon',
  'word_icon.svg': 'wordIcon'
};

export const isChatChannel = (channel: ApiNotification['channel']): boolean =>
  ['CHAT', 'CLIENT_CHAT'].includes(channel);

export const detectJSONChat = (
  channel: ApiNotification['channel'],
  text: ApiNotification['text']
): boolean => {
  const isJSONChat = (text || '').startsWith('{"');
  return isChatChannel(channel) && isJSONChat;
};

export const convertUniCodes = (text: string): string => {
  if (text && text.indexOf('&#') >= 0) {
    return text.replace(/&#(\w+);/g, (m, code) => String.fromCodePoint(code));
  }
  return text;
};

export const formatText = (
  text: string,
  isOpen: boolean,
  maxCollapsedSize: number
): string => {
  const convertedText = convertUniCodes(text);
  if (convertedText.length > maxCollapsedSize) {
    return isOpen
      ? convertedText
      : convertedText.slice(0, maxCollapsedSize) + ' [...]';
  } else {
    return convertedText;
  }
};

export const onTextCopy = (dispatch: Dispatch): Action =>
  dispatch(
    enqueueSnackbar({
      message: 'UNIC_UXCOPIES_COPY_COPIED',
      options: {
        variant: 'success'
      }
    })
  );

export const parseFileData = (
  d: ApiNotificationDocument
): UINotificationFile => {
  const logoFileName = getImageFileName(d.logo_url);
  return {
    url: d.file_url,
    title: d.text,
    icon: ICON_MAP[logoFileName] || 'mailIcon'
  };
};

// LiveAgent files are as separate messages,
// but they should be under one message
export const mergeLiveAgentMessageAndFiles = (
  data: LANotification,
  documents: ApiNotification['documents']
): LANotification => {
  const result = data;
  const messages = result.chatMessages;
  let i = 1;

  if (messages.length > 1) {
    while (i < messages.length) {
      const prevMsg = messages[i - 1];
      const msg = messages[i];
      if (msg.filename) {
        let fileData: ApiNotificationDocument | undefined;
        if (msg.fileStoredId) {
          fileData = documents.find(f => f.file_url.includes(msg.fileStoredId));
        }
        if (!fileData) {
          fileData = documents.find(f => f.text === msg.filename);
        }
        if (
          !!fileData &&
          prevMsg.filename === '' &&
          msg.direction === prevMsg.direction &&
          msg.timeat.hour === prevMsg.timeat.hour &&
          msg.timeat.minute === prevMsg.timeat.minute &&
          msg.timeat.second === prevMsg.timeat.second
        ) {
          if (!prevMsg.files) {
            prevMsg.files = [];
          }
          prevMsg.files.push(parseFileData(fileData));
          messages.splice(i, 1);
        } else if (!!fileData) {
          if (msg.body === `<file attachment: ${msg.filename} >`) {
            msg.body = '';
          }
          msg.files = [];
          msg.files.push(parseFileData(fileData));
          i++;
        } else {
          // file not found
          i++;
        }
      } else {
        i++;
      }
    }
  }
  result.chatMessages = messages;
  return result;
};

import React from 'react';
import makeStyles from '@mui/styles/makeStyles';

import { Theme } from '@mui/material';
import { shrinkName } from '../../../utils/postbox-item';
import { UINotification } from '../../../types/notifications';
import {
  MAX_FILENAME_SIZE_LG,
  MAX_FILENAME_SIZE_MOBILE,
  MAX_FILENAME_SIZE_TABLET
} from '../../../utils/post-box/utils';

import { Button, CustomIcon } from '../../common';

const useStyles = makeStyles((theme: Theme) => ({
  fileList: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(3),
    marginBottom: 0,
    padding: 0
  },
  file: {
    width: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    textAlign: 'left',
    padding: `calc(${theme.spacing(1)} - 1px) ${theme.spacing(1)}`,
    border: `1px solid ${theme.palette.text.disabled}`,
    borderRadius: Number(theme.shape.borderRadius) * 2,
    background: theme.palette.background.default,
    cursor: 'pointer',
    transition: theme.transitions.custom.fast,

    '&:not(:first-child)': {
      marginTop: theme.spacing(1)
    },
    '&:hover, &:focus, &:focus-visible, &:active': {
      outline: 'none',
      borderColor: theme.palette.text.hint,
      boxShadow: theme.customShadows.active
    }
  },
  fileIcon: {
    marginRight: theme.spacing(1)
  },
  fileName: {
    ...theme.typography.subtitle2,
    paddingTop: theme.spacing(0.5),
    color: theme.palette.text.secondary,
    wordBreak: 'break-word',
    whiteSpace: 'normal'
  }
}));

interface PostBoxItemFileListProps {
  colorScheme: UINotification['colorScheme'];
  files: UINotification['files'];
  isTabletWidth: boolean;
  isMobileWidth: boolean;
  customerToken: string;
  onFileAttachmentClick: (customerToken: string, url: string) => () => void;
}

const PostBoxItemFileList: React.FC<PostBoxItemFileListProps> = ({
  colorScheme,
  files,
  isMobileWidth,
  isTabletWidth,
  customerToken,
  onFileAttachmentClick
}): React.ReactElement => {
  const classes = useStyles();

  return (
    <ul className={classes.fileList}>
      {files.map(({ url, icon, title }, index) => (
        <Button
          key={url}
          variant="native"
          className={classes.file}
          onClick={onFileAttachmentClick(customerToken, url)}
          testId={`notification.file-link.${index}`}
        >
          <CustomIcon
            className={classes.fileIcon}
            size="small"
            icon={icon}
            variant={
              colorScheme === 'Hidden'
                ? 'textHint'
                : colorScheme === 'Primary'
                ? 'primary'
                : colorScheme === 'Secondary'
                ? 'secondaryMain'
                : colorScheme === 'Tertiary'
                ? 'tertiary'
                : colorScheme === 'Warning'
                ? 'warningDark'
                : colorScheme === 'WarningLight'
                ? 'warningLight'
                : 'textPrimary'
            }
          />
          <div className={classes.fileName}>
            {shrinkName(
              title,
              !isMobileWidth && !isTabletWidth
                ? MAX_FILENAME_SIZE_LG
                : isMobileWidth
                ? MAX_FILENAME_SIZE_MOBILE
                : MAX_FILENAME_SIZE_TABLET
            )}
          </div>
        </Button>
      ))}
    </ul>
  );
};

export default PostBoxItemFileList;

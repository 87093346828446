import React, { useMemo } from 'react';
import clsx from 'clsx';
import {
  Button,
  ButtonTypeMap,
  CircularProgress,
  Fade,
  Theme
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { CustomIcon, IconSize, IconVariant, SupportedIcon } from '../';
import { svgFill } from '../../../utils/styles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    ...theme.typography.body1Bold,
    padding: `${theme.spacing(0.5)} ${theme.spacing(3)}`,
    borderRadius: Number(theme.shape.borderRadius) * 4,
    boxShadow: 'none',
    transition: `background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    width 200ms linear,
    min-width 200ms linear,
    padding 200ms linear,
    color 100ms ease-in-out`
  },
  rootLoading: {
    minWidth: '32px !important',
    width: '32px !important',
    padding: '0px !important',
    pointerEvents: 'none',
    color: 'transparent !important'
  },
  rootMediumPaddingLoading: {
    minWidth: '40px !important',
    width: '40px !important'
  },
  rootMediumPadding: {
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
    borderRadius: Number(theme.shape.borderRadius) * 5
  },
  rootLargePadding: {
    padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
    boxShadow: theme.customShadows.active
  },
  primaryContained: {
    '&:hover': {
      background: theme.palette.primary.light,
      color: theme.palette.secondary.dark,

      ...svgFill(theme.transitions.custom.fast, theme.palette.secondary.dark)
    }
  },
  disabledContained: {
    '&:disabled,&[disabled]': {
      color: theme.palette.primary.contrastText,
      background: theme.palette.text.disabled
    }
  },
  disabledOutlined: {
    '&:disabled,&[disabled]': {
      color: theme.palette.text.disabled,
      borderColor: theme.palette.text.disabled
    }
  },
  disabledContainedAlternative: {
    '&:disabled,&[disabled]': {
      color: theme.palette.text.disabled,
      background: theme.palette.action.disabled,
      boxShadow: theme.customShadows.module
    }
  },
  outlined: {
    padding: `calc(${theme.spacing(0.5)} - 1px) calc(${theme.spacing(3)} - 1px)`
  },
  outlinedMedium: {
    padding: `calc(${theme.spacing(1)} - 1px) calc(${theme.spacing(2)} - 1px)`
  },
  outlinedLarge: {
    padding: `calc(${theme.spacing(2)} - 1px) calc(${theme.spacing(3)} - 1px)`
  },
  label: {
    textTransform: 'none'
  },
  small: {
    minWidth: '98px'
  },
  medium: {
    minWidth: '152px'
  },
  large: {
    minWidth: '288px'
  },
  full: {
    width: '100%'
  },
  textPrimary: {
    color: theme.palette.text.primary
  },
  textSecondary: {
    color: theme.palette.text.secondary
  },
  warningDark: {
    color: theme.palette.warning.dark,
    borderColor: theme.palette.warning.dark
  },
  loader: {
    display: 'flex',
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',

    '& circle': {
      stroke: theme.palette.primary.contrastText
    }
  }
}));

interface ActionButtonProps {
  id: string;
  className?: string;
  type?: 'button' | 'submit' | 'reset';
  variant?: 'text' | 'contained' | 'outlined';
  href?: string;
  target?: string;
  rel?: string;
  onClick?: () => void;
  disabled?: boolean;
  disabledStyle?: 'default' | 'alternative';
  size?: 'small' | 'medium' | 'large' | 'full';
  padding?: 'small' | 'medium' | 'large';
  color?:
    | ButtonTypeMap['props']['color']
    | 'textPrimary'
    | 'textSecondary'
    | 'warningDark';
  startIcon?: SupportedIcon;
  startIconColor?: IconVariant;
  startIconSize?: IconSize;
  isLoading?: boolean;
  children: React.ReactNode;
}

const ActionButton: React.FC<ActionButtonProps> = ({
  id,
  className,
  type = 'button',
  variant = 'contained',
  href,
  target,
  rel,
  onClick,
  disabled,
  disabledStyle = 'default',
  size = 'small',
  padding = 'small',
  color = 'primary',
  startIconColor,
  startIcon,
  startIconSize = 'xs',
  isLoading,
  children
}) => {
  const classes = useStyles();

  const disabledClass = useMemo(() => {
    if (disabledStyle === 'default') {
      return variant === 'contained'
        ? classes.disabledContained
        : classes.disabledOutlined;
    } else if (disabledStyle === 'alternative') {
      return variant === 'contained'
        ? classes.disabledContainedAlternative
        : classes.disabledOutlined;
    }
  }, [variant]);

  const colorClass = useMemo(() => {
    if (color === 'textPrimary') {
      return classes.textPrimary;
    } else if (color === 'textSecondary') {
      return classes.textSecondary;
    } else if (color === 'warningDark') {
      return classes.warningDark;
    }
    return '';
  }, [color]);

  const buttonColor = useMemo(() => {
    return color === 'textPrimary' || color === 'textSecondary'
      ? 'secondary'
      : color === 'warningDark'
      ? 'inherit'
      : color;
  }, [color]);

  return (
    <Button
      classes={{
        root: clsx(
          classes.root,
          padding === 'medium' && classes.rootMediumPadding,
          padding === 'large' && classes.rootLargePadding,
          variant === 'contained' &&
            color === 'primary' &&
            classes.primaryContained,
          disabledClass,
          classes[size],
          variant === 'outlined' && padding === 'small' && classes.outlined,
          variant === 'outlined' &&
            padding === 'medium' &&
            classes.outlinedMedium,
          variant === 'outlined' &&
            padding === 'large' &&
            classes.outlinedLarge,
          colorClass,
          classes.label,
          isLoading && classes.rootLoading,
          isLoading && padding === 'medium' && classes.rootMediumPaddingLoading,
          className
        )
      }}
      href={href}
      target={target}
      rel={rel}
      id={id}
      type={type}
      color={buttonColor}
      variant={variant}
      onClick={isLoading ? undefined : onClick}
      disabled={disabled}
      startIcon={
        startIcon ? (
          <CustomIcon
            icon={startIcon}
            variant={
              disabled && disabledStyle === 'alternative'
                ? 'textDisabled'
                : startIconColor
            }
            size={startIconSize}
          />
        ) : null
      }
    >
      {!isLoading && children}
      <Fade in={isLoading} timeout={{ enter: 200, exit: 100 }} unmountOnExit>
        <div className={classes.loader}>
          <CircularProgress size={26} />
        </div>
      </Fade>
    </Button>
  );
};

export default ActionButton;

import React, { ErrorInfo } from 'react';
import { Logger } from 'cloudwatch-front-logger';

interface LoggerComponentProps {
  logger: Logger;
}

class LoggerComponent extends React.Component<LoggerComponentProps> {
  componentDidCatch(e: Error, info: ErrorInfo): void {
    this.props.logger.onError(e, {
      ...info,
      type: 'react'
    });
  }

  render(): React.ReactNode {
    return this.props.children;
  }
}

export default LoggerComponent;

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

import { BillingIcon, CircularProgress, Widget } from '../../../common';
import InvoicePreviews from './InvoicePreviews';
import InvoiceList from './InvoiceList';

import { getInvoices, openInvoice } from '../../../../store/actions';
import {
  invoiceLoadingSelector,
  invoicesSelector
} from '../../../../store/selectors';
import DownloadButton from './DownloadButton';

const useStyles = makeStyles(() => ({
  wrapper: {
    padding: '0px !important'
  },
  extended: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column'
  },
  loader: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  }
}));

const InvoiceWidget = ({ removeItem, setExtended, extended, ...props }) => {
  const classes = useStyles();
  const invoices = useSelector(invoicesSelector);
  const invoiceLoading = useSelector(invoiceLoadingSelector);

  const dateSortedInvoices = useMemo(() => {
    const arr = [...(invoices || [])];
    arr.sort((a, b) => a.creationDate >= b.creationDate);
    return arr;
  }, [invoices]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!invoices) {
      dispatch(getInvoices());
    }
  }, []);

  const invoicesPreview = useMemo(
    () =>
      dateSortedInvoices && dateSortedInvoices.length
        ? dateSortedInvoices.slice(0, 2)
        : [],
    [dateSortedInvoices]
  );

  const onButtonClick = useCallback(() => {
    setExtended();
  }, []);

  const onDocDownload = useCallback(
    id => {
      dispatch(openInvoice(id));
    },
    [dispatch]
  );

  const [dateFilteredInvoices, setDateFilteredInvoices] = useState(null);
  const onDateInvoiceFilterChange = useCallback(
    (start, end) => {
      if (start || end) {
        setDateFilteredInvoices(
          dateSortedInvoices.filter(
            invoice =>
              (!start || (start && invoice.creationDate >= start)) &&
              (!end || (end && invoice.creationDate <= end))
          )
        );
      } else {
        setDateFilteredInvoices(null);
      }
    },
    [dateSortedInvoices]
  );

  return (
    <Widget
      {...props}
      icon={BillingIcon}
      onActionClick={removeItem}
      isExtended={extended}
      buttonName="CP_START_BILLING_BUTTON"
      isButtonDisabled={invoiceLoading}
      onButtonClick={onButtonClick}
    >
      <div
        className={clsx(classes.wrapper, extended && classes.extended)}
      >
        {invoiceLoading ? (
          <div className={classes.loader}>
            <CircularProgress />
          </div>
        ) : !extended ? (
          <InvoicePreviews
            invoices={invoicesPreview}
            onDocDownload={onDocDownload}
            downloadButton={DownloadButton}
          />
        ) : (
          <InvoiceList
            invoices={dateFilteredInvoices || dateSortedInvoices}
            onDocDownload={onDocDownload}
            downloadButton={DownloadButton}
            onDateRangeChange={onDateInvoiceFilterChange}
          />
        )}
      </div>
    </Widget>
  );
};

export default InvoiceWidget;

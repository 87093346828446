const onClickWrapper =
  (identity, handler) =>
  (event, ...args) => {
    if (handler) {
      handler(event, ...args);
    }
  };

export const registerOnClickWrapperHandler = () => {
  window.onClickWrapper = onClickWrapper;
};

import React, { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';

import { Translation } from '@troy/shared/src/components/common';

import { Button, Dialog, Form } from '../../../../common';
import { FormDateInput } from '../DateInput';

import { ON_HOLD_FORM_NAME } from '../../../../../constants/formNames';

import {
  addMonths,
  format as formatDate,
  getLocaleFormat
} from '../../../../../utils/date-utils';
import { localeFromBrowserCode } from '../../../../../utils/translations';
import { widgetWidth2Down } from '../../../../../assets/styles/jss_functions';
import { languageSelector } from '../../../../../store/selectors';

const MONTH_DIFF_POPUP = 3;

const useStyles = makeStyles(theme => ({
  header: {
    ...theme.typography.h3,

    ...widgetWidth2Down({
      ...theme.typography.h4
    })
  },
  description: {
    ...theme.typography.body2,
    color: theme.palette.text.secondary,
    marginTop: 8
  },
  fields: {
    width: 279,
    marginTop: 32,

    ...widgetWidth2Down({
      width: '100%'
    })
  },
  field: {
    '&:not(:first-child)': {
      marginTop: 16
    }
  },
  label: {
    ...theme.typography.body1Bold,
    marginBottom: 8
  },
  date: {
    width: '100%',
    maxWidth: '100%'
  },
  dateInput: {
    height: 32,
    boxSizing: 'border-box'
  },
  dateIcon: {
    top: '50%',
    right: 76
  },
  outline: {
    borderColor: theme.palette.text.primary
  },
  sendWrapper: {
    width: 279,
    marginTop: 16,
    alignSelf: 'flex-end',

    ...widgetWidth2Down({
      width: '100%'
    })
  },
  send: {
    width: '100%',
    padding: '4px 8px'
  }
}));

const FormContent = ({ onSubmit, isValid, isSubmitting, values }) => {
  const classes = useStyles();
  const language = useSelector(languageSelector);

  const isDialogNeeded = useMemo(
    () =>
      values &&
      (values.date >= addMonths(new Date(), MONTH_DIFF_POPUP) ||
        values.isEndless),
    [values]
  );

  const [isDialogOpen, setDialogOpen] = useState(false);
  const onDialogOpen = useCallback(() => {
    setDialogOpen(true);
  }, []);

  const onDialogClose = useCallback(() => {
    setDialogOpen(false);
  }, []);

  const onYesClick = useCallback(() => {
    onSubmit(values);
    setDialogOpen(false);
  }, [values, onSubmit]);

  return (
    <>
      <Translation component="h3" className={classes.header}>
        CP_INDIVIDUALCASE_EDIT_DUNNINGSTOP_INFO
      </Translation>
      <Translation component="p" className={classes.description}>
        CP_INDIVIDUALCASE_EDIT_ENTERCREDITNOTE_NOTE
      </Translation>
      <div className={classes.fields}>
        <div className={classes.field}>
          <Translation component="p" className={classes.label}>
            CP_INDIVIDUALCASE_EDIT_DUNNINGSTOP_PAUSE
          </Translation>
          <FormDateInput
            name="date"
            placeholder={formatDate(new Date())}
            dateFormat={getLocaleFormat()}
            locale={localeFromBrowserCode(language)}
            popperPlacement="right"
            minDate={new Date()}
            inputProps={{
              variant: 'outlined',
              className: classes.date,
              inputClassName: classes.dateInput,
              endIconClassName: classes.dateIcon
            }}
            inputNotchedOutlineClassName={classes.outline}
            disabled={values && values.isEndless}
          />
        </div>
        {/* optional note */}
      </div>
      <div className={classes.sendWrapper}>
        <Translation
          component={Button}
          className={classes.send}
          color="primary"
          variant="contained"
          type={isDialogNeeded ? 'button' : 'submit'}
          onClick={isDialogNeeded ? onDialogOpen : undefined}
          disabled={
            !isValid || isSubmitting || !(values.date || values.isEndless)
          }
          fullWidth
        >
          CP_INDIVIDUALCASE_EDIT_DUNNINGSTOP_SENDBUTTON
        </Translation>
      </div>
      <Dialog
        open={isDialogOpen}
        title={
          <Translation>CP_INDIVIDUALCASE_EDIT_DUNNINGSTOP_CHECK</Translation>
        }
        actions={
          <>
            <Button type="button" onClick={onDialogClose}>
              <Translation>CP_INDIVIDUALCASE_EDIT_DUNNINGSTOP_NO</Translation>
            </Button>
            <Button type="submit" onClick={onYesClick}>
              <Translation>CP_INDIVIDUALCASE_EDIT_DUNNINGSTOP_YES</Translation>
            </Button>
          </>
        }
      />
    </>
  );
};
export default ({ className, onSubmit }) => (
  <Form
    name={ON_HOLD_FORM_NAME}
    className={className}
    onSubmit={onSubmit}
    initialValues={{ date: new Date() }}
  >
    {props => <FormContent {...props} onSubmit={onSubmit} />}
  </Form>
);

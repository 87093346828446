import { createAction } from 'redux-act';
import { SnackbarNotificationType } from '../../types/snackbar';
import { SnackbarKey } from 'notistack';

export const enqueueSnackbar = createAction<SnackbarNotificationType>(
  'Add notification to display'
);

export const closeSnackbar = createAction<SnackbarKey>('Close notification');

export const removeSnackbar = createAction<SnackbarKey>(
  'Remove all notifications'
);

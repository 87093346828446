import React from 'react';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';

import { formatLiveAgentTime } from '../../../utils/postbox-item';
import { Theme } from '@mui/material';
import {
  LANotificationMessage,
  LANotificationTimeUtc,
  UINotification
} from '../../../types/notifications';

import { Text } from '../../common';

import PostBoxItemFileList from './PostBoxItemFileList';

const useStyles = makeStyles((theme: Theme) => ({
  marginTop: {
    marginTop: theme.spacing(2)
  },
  notificationWrapper: {
    display: 'flex',
    flexDirection: 'row',
    padding: `0 ${theme.spacing(1.5)}`
  },
  notificationWrapperVisitor: {
    justifyContent: 'flex-end'
  },
  notification: {
    width: 393,
    maxWidth: '100%',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    background: theme.palette.background.default,
    justifyContent: 'space-between',
    color: theme.palette.text.primary,
    border: `1px solid ${theme.palette.text.disabled}`,
    borderRadius: Number(theme.shape.borderRadius) * 2,
    padding: `${theme.spacing(1)} ${theme.spacing(1.5)}`,
    boxSizing: 'border-box',
    transition: theme.transitions.custom.fast,

    '&::before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      left: -1,
      top: 'calc(10px + 1.34314575051px)',
      width: 10.313708499,
      height: 10.313708499,
      transform: 'translate(-50%) rotate(-45deg)',
      background: theme.palette.background.default,
      borderTop: `1px solid ${theme.palette.text.disabled}`,
      borderLeft: `1px solid ${theme.palette.text.disabled}`,
      transition: theme.transitions.custom.fast
    },

    '&:hover': {
      borderColor: theme.palette.text.hint,
      boxShadow: theme.customShadows.active,

      '&::before': {
        borderTopColor: theme.palette.text.hint,
        borderLeftColor: theme.palette.text.hint
      }
    }
  },
  notificationPrimary: {
    borderColor: theme.palette.secondary.light,

    '&::before': {
      borderTopColor: theme.palette.secondary.light,
      borderLeftColor: theme.palette.secondary.light
    },

    '&:hover': {
      borderColor: theme.palette.secondary.main,

      '&::before': {
        borderTopColor: theme.palette.secondary.main,
        borderLeftColor: theme.palette.secondary.main
      }
    }
  },
  notificationVisitor: {
    '&::before': {
      left: 'unset',
      right: -1,
      transform: 'translate(50%) rotate(135deg)'
    }
  },
  notificationVisitorPrimary: {
    borderColor: theme.palette.primary.light,

    '&::before': {
      borderTopColor: theme.palette.primary.light,
      borderLeftColor: theme.palette.primary.light
    },

    '&:hover': {
      borderColor: theme.palette.primary.main,

      '&::before': {
        borderTopColor: theme.palette.primary.main,
        borderLeftColor: theme.palette.primary.main
      }
    }
  },
  body: {
    whiteSpace: 'normal'
  },
  time: {
    marginTop: theme.spacing(1),
    display: 'flex',
    flexDirection: 'row'
  },
  manager: {
    marginLeft: theme.spacing(1)
  }
}));

interface PostBoxItemLAChatMessagesProps {
  messages: LANotificationMessage[];
  startdatetimeutc: LANotificationTimeUtc;
  casemanager: string;
  colorScheme: UINotification['colorScheme'];
  isTabletWidth: boolean;
  isMobileWidth: boolean;
  customerToken: string;
  onFileAttachmentClick: (customerToken: string, url: string) => () => void;
}

const PostBoxItemLAChatMessages: React.FC<PostBoxItemLAChatMessagesProps> = ({
  messages,
  startdatetimeutc,
  casemanager,
  colorScheme,
  isTabletWidth,
  isMobileWidth,
  customerToken,
  onFileAttachmentClick
}): React.ReactElement => {
  const classes = useStyles();

  return (
    <>
      {messages.map(({ timeat, user, body, direction, files }, index) => (
        <div
          key={index}
          className={clsx(
            classes.notificationWrapper,
            classes.marginTop,
            direction === 'FROM_CUSTOMER' && classes.notificationWrapperVisitor
          )}
        >
          <div
            className={clsx(
              classes.notification,
              direction === 'FROM_CUSTOMER' && classes.notificationVisitor,
              colorScheme === 'Primary' &&
                direction === 'TO_CUSTOMER' &&
                classes.notificationPrimary,
              colorScheme === 'Primary' &&
                direction === 'FROM_CUSTOMER' &&
                classes.notificationVisitorPrimary
            )}
          >
            <Text
              className={classes.body}
              component="p"
              variant="body2"
              color="textPrimary"
            >
              {body}
            </Text>
            {files && files.length > 0 && (
              <PostBoxItemFileList
                colorScheme={colorScheme}
                files={files}
                isTabletWidth={isTabletWidth}
                isMobileWidth={isMobileWidth}
                customerToken={customerToken}
                onFileAttachmentClick={onFileAttachmentClick}
              />
            )}
            <div className={classes.time}>
              <Text component="span" variant="subtitle2" color="textHint">
                {formatLiveAgentTime(startdatetimeutc, timeat)}
              </Text>
              <Text
                component="span"
                className={classes.manager}
                variant="subtitle2"
                color="textHint"
              >
                {direction === 'FROM_CUSTOMER' ? user : casemanager}
              </Text>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default PostBoxItemLAChatMessages;

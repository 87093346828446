import React from 'react';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material';
import { Checkbox, CustomIcon, IconVariant } from '../';

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    marginLeft: theme.spacing(-1),
    alignItems: 'center'
  },
  wrapperAlignStart: {
    alignItems: 'flex-start'
  },
  checkbox: {
    padding: theme.spacing(1)
  },
  icon: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: `1px solid ${theme.palette.text.primary}`,
    borderRadius: Number(theme.shape.borderRadius),
    boxSizing: 'border-box'
  },
  iconDisabled: {
    background: theme.customColors.actionsInfo,
    borderColor: theme.palette.text.disabled
  },
  labelBold: {
    ...theme.typography.body2Bold
  },
  label: {
    ...theme.typography.body2
  },
  labelMedium: {
    ...theme.typography.body1
  },
  labelSmall: {
    ...theme.typography.subtitle2,
    paddingLeft: theme.spacing(1)
  },
  labelAlignStart: {
    marginTop: theme.spacing(1.25)
  }
}));

interface FormCheckboxProps {
  id?: string;
  className?: string;
  name?: string;
  label?: string;
  labelClassName?: string;
  color?: IconVariant;
  variant?: 'normal' | 'selectedBold' | 'alwaysBold' | 'small' | 'medium';
  value?: boolean;
  disabled?: boolean;
  onClick?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  alignItems?: 'start' | 'center';
}

const FormCheckbox: React.FC<FormCheckboxProps> = ({
  id,
  className,
  name,
  label,
  labelClassName,
  color = 'primary',
  variant = 'selectedBold',
  value,
  disabled,
  onClick,
  alignItems = 'center'
}): React.ReactElement => {
  const classes = useStyles();

  const labelClass =
    variant === 'alwaysBold' || (variant === 'selectedBold' && value)
      ? classes.labelBold
      : variant === 'medium'
      ? classes.labelMedium
      : variant === 'small'
      ? classes.labelSmall
      : classes.label;

  return (
    <Checkbox
      translated
      id={id}
      name={name}
      label={label}
      value={value}
      disabled={disabled}
      onClick={onClick}
      classNameWrapper={clsx(
        classes.wrapper,
        alignItems === 'start' && classes.wrapperAlignStart,
        className
      )}
      className={classes.checkbox}
      classNameLabel={clsx(
        labelClass,
        alignItems === 'start' && classes.labelAlignStart,
        labelClassName
      )}
      icon={
        <span
          className={clsx(classes.icon, disabled && classes.iconDisabled)}
        />
      }
      checkedIcon={
        <span className={clsx(classes.icon, disabled && classes.iconDisabled)}>
          <CustomIcon icon="confirm" size="xs" variant={color} />
        </span>
      }
    />
  );
};

export default FormCheckbox;

import React from 'react';
import { makeStyles } from '@mui/styles';

import { GraphIcon, Widget } from '../../../common';
import StatisticsLookerMiniWidget from './StatisticsLookerMiniWidget';
import StatisticsLookerExtendedWidget from './StatisticsLookerExtendedWidget';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'row'
  }
}));

const StatisticsLookerWidget = ({
  extended,
  setExtended,
  removeItem,
  ...props
}) => {
  const classes = useStyles();

  return (
    <Widget
      {...props}
      icon={GraphIcon}
      onActionClick={removeItem}
      buttonName="CP_START_STATISTICS_BUTTON"
      onButtonClick={setExtended}
      isExtended={extended}
    >
      <div className={classes.root}>
        {!extended && <StatisticsLookerMiniWidget />}
        {extended && <StatisticsLookerExtendedWidget />}
      </div>
    </Widget>
  );
};

export default StatisticsLookerWidget;

import { LanguageSelect } from '@troy/shared/src/components/common';

const CustomLanguageSelect = ({ isCompact, onChange, languages, value }) => {
  return (
    <LanguageSelect
      isCompact={isCompact}
      onChange={onChange}
      languages={languages}
      value={value}
      expandIconVariant='light'
    />
  );
};

export default CustomLanguageSelect;

import { createReducer } from 'redux-act';

import * as actions from '../actions/looker';

const DEFAULT_STATE = {
  dashboards: [],
  loadingDashboards: false,
  dashboardUrls: {
    123: {
      dashboardId: '123',
      url: ''
    },
    234: {
      dashboardId: '123',
      url: ''
    }
  },
  loadingDashboardUrls: {}
};

export default createReducer(
  {
    [actions.getLookerDashboards]: state => ({
      ...state,
      loadingDashboards: true
    }),
    [actions.lookerDashboardsReceived]: (state, data) => ({
      ...state,
      dashboards: data,
      loadingDashboards: false
    }),
    [actions.getLookerDashboard]: (state, id) => ({
      ...state,
      loadingDashboardUrls: { ...state.loadingDashboardUrls, [id]: true }
    }),
    [actions.lookerDashboardReceived]: (state, data = {}) => ({
      ...state,
      dashboardUrls: { ...state.dashboardUrls, [data.dashboardId]: data },
      loadingDashboardUrls: {
        ...state.loadingDashboardUrls,
        [data.dashboardId]: false
      }
    })
  },
  DEFAULT_STATE
);

import React, { useCallback } from 'react';
import { Box, CircularProgress, Fade } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { Dropzone, Translation } from '@troy/shared/src/components/common';

import { AddIcon } from '../../../../common/Icons';
import {
  Button,
  FilePreview,
  Form,
  FormTextField,
  Grid,
  List,
  UploadIcon
} from '../../../../common';

import {
  CSV,
  DOC,
  DOCX,
  JPEG,
  PDF,
  PNG,
  XLS,
  XLSX
} from '../../../../../constants/file-upload';
import { CONTACT_FORM_NAME } from '../../../../../constants/formNames';
import { useAppWidthClass } from '../../../../../utils/hooks';

const ACCEPTED_TYPES = [PDF, DOC, DOCX, JPEG, PNG, CSV, XLSX, XLS];
const MAX_SIZE = 25 * 1024 * 1024; // 25 Mb
const MAX_FILE_COUNT = 10;

const fileToDocConvert = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      const fileAsBinaryString = reader.result;
      const converted = fileAsBinaryString.split('base64,')[1];
      const document = {
        byteData: converted,
        fileName: file.name,
        extension: file.type
      };
      resolve(document);
    };
    reader.onabort = () => reject('file reading was aborted');
    reader.onerror = () => reject('file reading has failed');
    reader.readAsDataURL(file);
  }).catch(console.error);

const useStyles = makeStyles(theme => ({
  fields: {
    margin: '-4px -4px 8px -4px',

    '& > div': {
      padding: '4px !important'
    }
  },
  fieldWrapper: {
    position: 'relative'
  },
  fieldInfo: {
    width: '100%',
    height: 102,

    '& div': {
      height: '100%'
    },
    '& textarea': {
      height: '100% !important'
    }
  },
  send: {
    marginTop: '16px !important',
    width: '100%'
  },
  dropzoneWrapper: {
    width: '100%'
  },
  dropzoneUpload: {
    position: 'relative',
    minWidth: 260,
    minHeight: 160,
    padding: 20,
    border: `4px dashed ${theme.palette.text.disabled}`,
    borderRadius: theme.shape.borderRadius * 2
  },
  dropzoneHeader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'bold',
    color: theme.palette.secondary.main,

    '& > :not(:last-child)': {
      marginRight: 16
    }
  },
  dropzoneUploadContent: {
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.palette.text.disabled,
    padding: 10
  },
  dropzoneUploadIcon: {
    width: 20,
    height: 20,
    margin: 10
  },
  fileList: {
    padding: 0
  },
  file: {
    '&:not(:last-child)': {
      marginBottom: 8
    }
  },
  loader: {
    display: 'flex',
    '& circle': {
      stroke: theme.palette.primary.contrastText
    }
  }
}));

const ContactForm = ({ dirty, isSubmitting, change, values }) => {
  const classes = useStyles();

  const onFileRemove = useCallback(
    file => () => {
      if (values && values.files && values.files.length) {
        const filesCopy = values.files.slice();
        const fileIdx = filesCopy.indexOf(file);
        filesCopy.splice(fileIdx, 1);
        change('files', filesCopy);
      }
    },
    [values]
  );

  const hasFiles = values && values.files && values.files.length;
  const hasMessage = values && values.message;
  const valid = hasFiles || hasMessage;

  const { isTwoColumnWidth } = useAppWidthClass();

  return (
    <Grid
      spacing={1}
      container
      direction={isTwoColumnWidth ? 'row' : 'column-reverse'}
      className={classes.fields}
    >
      <Grid item xs={isTwoColumnWidth ? 6 : false}>
        <div className={classes.fieldWrapper}>
          <FormTextField
            name="message"
            placeholder="CP_CLIENT_CONTACTFORM_PREFILL"
            className={classes.fieldInfo}
            multiline
            variant="outlined"
            rows={11}
          />
          <Button
            className={classes.send}
            disabled={!dirty || !valid || isSubmitting}
            type="submit"
            color="primary"
            variant="contained"
          >
            {!isSubmitting ? (
              <Translation>
                CP_INDIVIDUALCASE_EDIT_DUNNINGSTOP_SENDBUTTON
              </Translation>
            ) : (
              <Fade
                in={isSubmitting}
                timeout={{ enter: 200, exit: 100 }}
                unmountOnExit
              >
                <Box className={classes.loader}>
                  <CircularProgress size={24} />
                </Box>
              </Fade>
            )}
          </Button>
        </div>
      </Grid>
      <Grid
        item
        container
        xs={isTwoColumnWidth ? 6 : false}
        justifyContent="center"
        alignItems="flex-end"
      >
        <Grid className={classes.dropzoneWrapper}>
          <Dropzone
            name="files"
            converter={fileToDocConvert}
            maxFileCount={MAX_FILE_COUNT}
            maxSize={MAX_SIZE}
            className={classes.dropzoneUpload}
            accept={ACCEPTED_TYPES}
            id="page.contact-form.component.file-dropzone"
            multiple
            multipleAdding
          >
            <div>
              <div className={classes.dropzoneHeader}>
                <UploadIcon />
                <Translation component="h3">CP_UPLOAD_HEADLINE</Translation>
              </div>
              <div className={classes.dropzoneUploadContent}>
                <AddIcon className={classes.dropzoneUploadIcon} />
                <Translation component="p">
                  CP_UPLOAD_ADD_ATTACHMENTS
                </Translation>
              </div>
              {hasFiles ? (
                <List className={classes.fileList}>
                  {values.files.map((file, index) => (
                    <FilePreview
                      key={index}
                      className={classes.file}
                      file={file}
                      removeButtonId={`page.postbox.component.file-upload-modal.file-remove-button${
                        index > 0 ? `.${index}` : ''
                      }`}
                      onRemove={onFileRemove(file)}
                    />
                  ))}
                </List>
              ) : null}
            </div>
          </Dropzone>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ({ onSubmit, className }) => (
  <Form
    initialValues={{ message: '', files: [] }}
    name={CONTACT_FORM_NAME}
    className={className}
    onSubmit={onSubmit}
  >
    {ContactForm}
  </Form>
);

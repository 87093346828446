import enLocale from 'date-fns/locale/en-GB';
import deLocale from 'date-fns/locale/de';
import nlLocale from 'date-fns/locale/nl';
import trLocale from 'date-fns/locale/tr';
import frLocale from 'date-fns/locale/fr';

import { getDomainLanguage } from '../utils/browser';

export const codeToLocale = {
  GB: enLocale,
  DE: deLocale,
  NL: nlLocale,
  TR: trLocale,
  FR: frLocale
};

export const EN = 'en';
export const DE = 'de';
export const NL = 'nl';
export const BE = 'be';
export const TR = 'tr';
export const SV = 'sv';

export const BROWSER_LANGUAGE = navigator.language || navigator.userLanguage;
export const DOMAIN_LANGUAGE = getDomainLanguage() || DE;

export const isDomainDE = () => DOMAIN_LANGUAGE === DE;
export const isDomainNL = () => DOMAIN_LANGUAGE === NL;
export const isDomainBE = () => DOMAIN_LANGUAGE === BE;

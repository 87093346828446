import React, { useCallback } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import { List, ListItem } from '@mui/material';

import { Translation } from '@troy/shared/src/components/common';

import { ProfileIcon, Skeleton } from '../../../../../../common';
import {
  DATA_TYPE_KEY,
  DATA_TYPES
} from '../../../../../../../constants/browser';
import ScrollFade from '../../../../../../common/ScrollFade';

const useStyles = makeStyles(theme => ({
  skeleton: {
    height: 48,
    borderRadius: theme.shape.borderRadius * 2
  },
  list: {
    maxHeight: 230,
    overflow: 'auto',
    paddingRight: 1,

    '& > :not(:last-child)': {
      marginBottom: 8
    },

    '&::-webkit-scrollbar': {
      width: 5
    },
    '&::-webkit-scrollbar-track': {
      background: '#f1f1f1'
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#888'
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#555'
    }
  },
  caseId: {
    fontSize: '14px',
    color: theme.palette.text.secondary,
    marginTop: 4
  },
  option: {
    borderRadius: theme.shape.borderRadius * 2,
    background: theme.palette.grey[100],
    padding: '8px 12px',

    '&:hover': {
      background: theme.palette.secondary.main,
      color: theme.palette.secondary.contrastText
    },
    '&:hover $caseId': {
      color: theme.palette.secondary.contrastText
    }
  },
  icon: {
    color: theme.palette.primary.main,
    marginRight: 20
  }
}));

const SkeletonLoader = ({ count = 1, className }) => {
  const classes = useStyles();

  return (
    <>
      {Array(count)
        .fill(0)
        .map((_, index) => (
          <Skeleton key={index} className={clsx(classes.skeleton, className)} />
        ))}
    </>
  );
};

const SearchResultsList = ({
  className,
  skeletonClassName,
  optionClassName,
  isLoading,
  count,
  rows,
  onRowClick
}) => {
  const classes = useStyles();

  const handleRowClick = useCallback(
    option => event => {
      onRowClick && onRowClick(event, option);
    },
    [onRowClick]
  );

  return (
    <List disablePadding className={clsx(classes.list, className)}>
      {isLoading ? (
        <SkeletonLoader count={count} className={skeletonClassName} />
      ) : rows.length ? (
        <>
          {count < rows.length && <ScrollFade />}
          {rows.map((option, idx) => (
            <ListItem
              button
              className={clsx(classes.option, optionClassName)}
              key={idx}
              onClick={handleRowClick(option)}
              {...{
                [DATA_TYPE_KEY]: DATA_TYPES.listOption
              }}
            >
              <ProfileIcon className={classes.icon} />
              <div>
                <div>{option.name}</div>
                <div className={classes.caseId}>
                  <Translation isFragment>
                    CP_INDIVIDUALCASE_INFOBOX_FILENUMBER
                  </Translation>
                  : {option.liabilityRefNo}
                </div>
              </div>
            </ListItem>
          ))}
        </>
      ) : null}
    </List>
  );
};

export default SearchResultsList;

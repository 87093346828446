// react libraries are doing unsafe inline script loading
// which does not work with CSP
export const initializeTagManager = () => {
  if (window.Cypress) {
    return;
  }
  const scriptId = 'gtm-script';
  if (!!document.getElementById(scriptId)) {
    return;
  }
  const s = 'script';
  const l = 'dataLayer';
  const id = process.env.REACT_APP_GTM_TAG_ID;

  window[l] = window[l] || [];
  window[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
  let f = document.getElementsByTagName(s)[0],
    j = document.createElement(s),
    dl = l !== 'dataLayer' ? '&l=' + l : '';
  j.async = true;
  j.id = scriptId;
  j.src = 'https://www.googletagmanager.com/gtm.js?id=' + id + dl;
  j.nonce = 'nonce-gtag-defined-by-troy';
  f.parentNode.insertBefore(j, f);

  const noscript = document.createElement('noscript');
  let iframe = document.createElement('iframe');
  iframe.src = 'https://www.googletagmanager.com/ns.html?id=' + id;
  iframe.width = '0';
  iframe.height = '0';
  iframe.style.display = 'none';
  iframe.style.visibility = 'hidden';
  noscript.appendChild(iframe);
  document.body.insertAdjacentElement('afterbegin', noscript);
};

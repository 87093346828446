import React, { useState } from 'react';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import { motion } from 'framer-motion';
import { Theme } from '@mui/material';

import { LANotification, UINotification } from '../../../types/notifications';
import { Decision, DecisionProps, Text, Translation } from '../../common';
import NotificationScrollButton from './NotificationScrollButton';
import PostBoxItemLAChatMessages from './PostBoxItemLAChatMessages';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    overflow: 'hidden'
  },
  messages: {
    position: 'relative'
  },
  section: {
    marginTop: theme.spacing(2),
    whiteSpace: 'normal'
  },
  showMore: {
    ...theme.typography.subtitle1,
    alignSelf: 'flex-start',
    marginTop: theme.spacing(3),
    padding: 0,
    border: 0,
    background: 'none',
    color: theme.palette.text.secondary,
    textDecoration: 'underline',
    textAlign: 'left',
    cursor: 'pointer'
  },
  shadow: {
    position: 'absolute',
    left: 0,
    bottom: 0,
    width: '100%',
    height: 80,
    background: 'linear-gradient(180deg, transparent 0%, white 100%)',
    pointerEvents: 'none'
  }
}));

interface PostBoxItemLAChatContentProps {
  data: LANotification;
  notificationRef: React.RefObject<HTMLLIElement>;
  colorScheme: UINotification['colorScheme'];
  isTabletWidth: boolean;
  isMobileWidth: boolean;
  customerToken: string;
  onFileAttachmentClick: (customerToken: string, url: string) => () => void;
  showPostboxLiveAgentChatDebugSelector: DecisionProps['selector'];
  scrollToNotificationStart: (itemRef: React.RefObject<HTMLLIElement>) => void;
}

const PostBoxItemLAChatContent: React.FC<PostBoxItemLAChatContentProps> = ({
  data,
  notificationRef,
  colorScheme,
  isTabletWidth,
  isMobileWidth,
  customerToken,
  onFileAttachmentClick,
  showPostboxLiveAgentChatDebugSelector,
  scrollToNotificationStart
}): React.ReactElement => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const [mountAnimationDone, setMountAnimationDone] = useState(false);

  const handleClick = (): void => {
    setIsOpen(!isOpen);
  };

  const { startdatetimeutc, debug, chatEndedBy, casemanager } = data.header;

  const first2Messages = data.chatMessages.slice(0, 2);
  const restMessages = data.chatMessages.slice(2);

  const messagesHidden = !isOpen && data.chatMessages.length > 2;

  const onAnimationComplete = (definition: string): void => {
    if (definition === 'collapsed') {
      if (mountAnimationDone) {
        scrollToNotificationStart(notificationRef);
      } else {
        setMountAnimationDone(true);
      }
    }
  };

  return (
    <>
      <div className={classes.container}>
        <Decision selector={showPostboxLiveAgentChatDebugSelector}>
          <div className={classes.section}>
            {debug.map(line => (
              <Text
                key={line}
                component="div"
                variant="body2"
                color="warningLight"
              >
                {line}
              </Text>
            ))}
            <div>
              <Text
                translated
                component="span"
                variant="body2"
                color="warningLight"
              >
                POSTBOX_NESTEDCHAT_ENDEDBY
              </Text>
              <span> </span>
              <Text component="span" variant="body2" color="warningLight">
                {chatEndedBy}
              </Text>
            </div>
          </div>
        </Decision>
        <div className={classes.messages}>
          <PostBoxItemLAChatMessages
            messages={first2Messages}
            startdatetimeutc={startdatetimeutc}
            casemanager={casemanager}
            colorScheme={colorScheme}
            isMobileWidth={isMobileWidth}
            isTabletWidth={isTabletWidth}
            customerToken={customerToken}
            onFileAttachmentClick={onFileAttachmentClick}
          />
          <motion.section
            key="content"
            variants={{
              open: { height: 'auto' },
              collapsed: { height: 0 }
            }}
            animate={isOpen ? 'open' : 'collapsed'}
            transition={{ duration: 0.3, ease: 'easeInOut' }}
            onAnimationComplete={onAnimationComplete}
          >
            <PostBoxItemLAChatMessages
              messages={restMessages}
              startdatetimeutc={startdatetimeutc}
              casemanager={casemanager}
              colorScheme={colorScheme}
              isMobileWidth={isMobileWidth}
              isTabletWidth={isTabletWidth}
              customerToken={customerToken}
              onFileAttachmentClick={onFileAttachmentClick}
            />
          </motion.section>
          {messagesHidden && <div className={clsx(classes.shadow)} />}
        </div>
      </div>
      {data.chatMessages.length > 2 && (
        <button
          id={`page.case-view.component.notification.show-${
            isOpen ? 'less' : 'more'
          }`}
          onClick={handleClick}
          className={clsx(classes.showMore)}
        >
          <Translation keyIfNoValue isFragment inline>
            {isOpen ? 'SHOW_LESS' : 'SHOW_MORE'}
          </Translation>
        </button>
      )}
      {isOpen && (
        <NotificationScrollButton
          onClick={() => scrollToNotificationStart(notificationRef)}
        />
      )}
    </>
  );
};

export default PostBoxItemLAChatContent;

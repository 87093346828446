import config from '../../config/sso-config';

export const COGNITO_URL = process.env.REACT_APP_COGNITO_URL;

const getLoginUrl = () => {
  const { scope, ...params } = config;
  const encodeParams = new URLSearchParams({
    ...params
  }).toString();
  return `${COGNITO_URL}/oauth2/authorize?${encodeParams}&scope=${scope.join(
    '+'
  )}`;
};

export const LOGIN_URL = getLoginUrl(config);
export const TOKEN_URL = `${COGNITO_URL}/oauth2/token`;

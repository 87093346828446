import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import { TooltipProps } from '@mui/material';

import { useTranslationsContext } from '../TranslationProvider';
import Tooltip from '../Tooltip';
import { onClickWrapper } from '../../../utils/ui';

const useStyles = makeStyles(() => ({
  translation: {
    whiteSpace: 'pre-wrap'
  },

  inline: {
    display: 'inline'
  }
}));

interface TranslationProps {
  className?: string;
  children?: string;
  isHtml?: boolean;
  inline?: boolean;
  format?: (text: string) => string;
  ending?: string;
  keyIfNoValue?: boolean;
  component?: React.ElementType;
  componentChildren?: React.ReactNode;
  showTranslationTooltips?: boolean;
  placement?: TooltipProps['placement'];
  propPlace?: string;
  isFragment?: boolean;
  noWrap?: boolean;
  id?: string;
  htmlFor?: string;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
}

const Translation: React.FC<TranslationProps> = ({
  className,
  children,
  isHtml,
  inline,
  format,
  ending = '',
  keyIfNoValue,
  component: Component,
  componentChildren = null,
  showTranslationTooltips,
  placement,
  propPlace = 'children',
  isFragment,
  noWrap,
  id,
  htmlFor,
  onClick,
  ...componentProps
}) => {
  const { translate } = useTranslationsContext();
  const classes = useStyles();

  if (children) {
    let translation = translate(children, keyIfNoValue);
    if (!!format) {
      translation = format(translation);
    }
    const TranslationComponent: React.ElementType | string = Component || 'div';

    let translationDiv;
    if (isHtml) {
      translationDiv = (
        <TranslationComponent
          id={id}
          className={className}
          htmlFor={htmlFor}
          dangerouslySetInnerHTML={{ __html: translation }}
          onClick={onClick ? onClickWrapper(id, onClick) : undefined}
          {...componentProps}
        />
      );
    } else if (isFragment) {
      translationDiv = <>{translation + ending}</>;
    } else {
      translationDiv = (
        <TranslationComponent
          id={id}
          className={clsx(
            !noWrap && classes.translation,
            inline && classes.inline,
            className
          )}
          htmlFor={htmlFor}
          onClick={onClick ? onClickWrapper(id, onClick) : undefined}
          {...componentProps}
          // eslint-disable-next-line react/no-children-prop
          children={componentChildren}
          {...{ [propPlace]: translation + ending }}
        />
      );
    }

    if (showTranslationTooltips) {
      translationDiv = (
        <Tooltip title={children} placement={placement || 'top'}>
          {translationDiv}
        </Tooltip>
      );
    }
    return translationDiv;
  } else {
    return null;
  }
};

export default Translation;

import { FormikProps } from 'formik';
import React from 'react';
import ReactDropzone, { DropzoneProps } from 'react-dropzone';
import makeStyles from '@mui/styles/makeStyles';

import { FormikFieldRenderProps } from '../../../types/formik';
import FormField from '../FormField';

type CustomDropzoneProps = {
  name?: string;
  id?: string;
  children?: React.ReactNode;
  validate?: () => void;
  className?: string;
  value?: File[];
  maxSize?: number;
  maxFileCount?: number;
  multipleAdding?: boolean;
  converter?: (file: File) => File;
  onMaxSizeExcess?: (files: string[]) => void;
  form?: FormikProps<any>;
  onDrop?: (files: File[]) => void;
  onDropzoneUploadHandler: (status: boolean) => void;
  disabled: boolean;
  setDragActive: (dragActive: boolean) => void;
  onFileInputFocus?: React.FocusEventHandler<HTMLInputElement>;
  onFileInputBlur?: React.FocusEventHandler<HTMLInputElement>;
  inputAriaLabelledBy?: string;
};

const useStyles = makeStyles(theme => ({
  input: {
    display: 'block !important',
    width: 0,
    height: 0
  }
}));

class Dropzone extends React.Component<DropzoneProps & CustomDropzoneProps> {
  static renderDropzone = ({
    className,
    id,
    children,
    onDrop,
    value,
    accept,
    noClick,
    noDrag,
    multiple,
    maxSize,
    maxFileCount,
    multipleAdding,
    onMaxSizeExcess,
    onDropzoneUploadHandler,
    setDragActive,
    disabled,
    field,
    form,
    name,
    converter,
    onFileInputFocus,
    onFileInputBlur,
    inputAriaLabelledBy
  }: DropzoneProps & CustomDropzoneProps & FormikFieldRenderProps<File[]>) => {
    const classes = useStyles();

    return (
      <ReactDropzone
        onDrop={async (files: File[], rejectedFiles): Promise<void> => {
          if (!!setDragActive) {
            setDragActive(false);
          }
          if (maxSize) {
            const excessed = files
              .filter(file => file.size > maxSize)
              .map(file => file.name);
            if (excessed.length && onMaxSizeExcess) {
              onMaxSizeExcess(excessed);
            }
            files = files.filter(file => file.size <= maxSize);
          }
          if (converter) {
            files = await Promise.all(
              files.map(async file => await converter(file))
            );
          }
          if (multipleAdding && ((field && field.value) || value)) {
            files = [...((field && field.value) || value), ...files];
          }
          if (maxFileCount && files.length > maxFileCount) {
            files = files.slice(0, maxFileCount);
          }
          form ? form.setFieldValue(name, files) : onDrop && onDrop(files);
          if (!!onDropzoneUploadHandler) {
            onDropzoneUploadHandler(rejectedFiles.length > 0);
          }
        }}
        {...{ noClick, noDrag, multiple, accept, disabled }}
      >
        {({ getRootProps, getInputProps }): React.ReactElement => {
          const rootProps = getRootProps({
            tabIndex: undefined
          });
          return (
            <div
              {...rootProps}
              style={noClick ? {} : { cursor: 'pointer' }}
              className={className}
              id={id}
            >
              <input
                {...getInputProps()}
                className={classes.input}
                tabIndex={0}
                onFocus={onFileInputFocus}
                onBlur={onFileInputBlur}
                aria-labelledby={inputAriaLabelledBy}
              />
              {children}
            </div>
          );
        }}
      </ReactDropzone>
    );
  };

  render() {
    const {
      name,
      onDrop,
      validate,
      children,
      className,
      id,
      noClick,
      noDrag,
      accept,
      multiple,
      maxSize,
      maxFileCount,
      multipleAdding,
      onMaxSizeExcess,
      converter,
      value,
      onDropzoneUploadHandler,
      setDragActive,
      disabled,
      onFileInputFocus,
      onFileInputBlur,
      inputAriaLabelledBy
    } = this.props;

    const customProps = {
      children,
      onDrop,
      className,
      id,
      noClick,
      noDrag,
      accept,
      multiple,
      maxSize,
      maxFileCount,
      multipleAdding,
      onMaxSizeExcess,
      converter,
      value,
      onDropzoneUploadHandler,
      setDragActive,
      disabled,
      onFileInputFocus,
      onFileInputBlur,
      inputAriaLabelledBy
    };

    return name ? (
      <FormField
        component={Dropzone.renderDropzone}
        name={name}
        validate={validate}
        {...customProps}
      />
    ) : (
      Dropzone.renderDropzone(customProps)
    );
  }
}

export default Dropzone;

import React from 'react';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material';

interface PostBoxItemHTMLChatContentProps {
  content: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  content: {
    ...theme.typography.body2,
    paddingTop: theme.spacing(2),
    color: theme.palette.text.primary,
    wordBreak: 'break-word',
    whiteSpace: 'pre-line',

    '& a': {
      color: theme.palette.secondary.main
    }
  }
}));

const PostBoxItemHTMLChatContent: React.FC<PostBoxItemHTMLChatContentProps> = ({
  content
}) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.content)}>
      <div dangerouslySetInnerHTML={{ __html: content }} />
    </div>
  );
};

export default PostBoxItemHTMLChatContent;

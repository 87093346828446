export const onClickWrapper = (
  identity: string,
  handler: (...args: never[]) => void
) =>
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  window.onClickWrapper(identity, handler);

// TODO fav icon should be set already either in deployment pipeline or
// the web server should set the correct one
export const setDynamicFavIcon = (favIcon: string): void => {
  let link: HTMLLinkElement = document.querySelector("link[rel~='icon']");
  if (!link) {
    link = document.createElement<'link'>('link');
    link.rel = 'icon';
    document.getElementsByTagName('head')[0].appendChild(link);
  }
  link.href = favIcon;
};

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Tab, Tabs } from '@mui/material';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';

import { Translation } from '@troy/shared/src/components/common';

import { Button } from '../Button';

const TABS_ON_VIEW = 4;

const useStyles = makeStyles(theme => ({
  wrapper: {
    display: 'flex',
    justifyContent: 'center'
  },
  pageButton: {
    height: 40,
    background: '#656565',
    color: theme.palette.background.default,

    '&:last-child': {
      borderLeft: `2px solid ${theme.palette.background.default}`
    },
    '&:first-child': {
      borderRight: `2px solid ${theme.palette.background.default}`
    }
  },
  icon: {
    fill: theme.palette.background.default,
    height: 16,
    marginBottom: '0 !important',
    marginRight: 6,
    fontSize: '1rem'
  },
  tabRoot: {
    textTransform: 'none',
    minHeight: 40
  },
  tabTextColorPrimary: {
    color: theme.palette.text.hint
  },
  tabSelected: {
    fontWeight: 'bold',
    color: `${theme.palette.text.primary} !important`
  }
}));

export default ({
  options,
  pageable,
  onChange,
  tabClassName,
  selectedTabClassName,
  tabsWrapperClassName,
  tabWrapperClassName,
  iconClassName,
  labelContainerClassName,
  withoutIndicator,
  vertical,
  removeSelected,
  value,
  tabClasses: tabClassesProp,
  ...props
}) => {
  const classes = useStyles();

  const filteredOptions = useMemo(
    () =>
      options && options.length
        ? removeSelected
          ? options.filter(({ value: tabValue }) => tabValue !== value)
          : options
        : [],
    [options, value, removeSelected]
  );
  const maxTabPage = useMemo(
    () =>
      Math.floor((filteredOptions && filteredOptions.length) / TABS_ON_VIEW),
    [filteredOptions]
  );

  const [viewTabs, setViewTabs] = useState([]);
  const [tabPage, setTabPage] = useState(0);

  useEffect(() => {
    if (filteredOptions && filteredOptions.length) {
      setViewTabs(
        pageable
          ? filteredOptions.slice(
              tabPage * TABS_ON_VIEW,
              (tabPage + 1) * TABS_ON_VIEW
            )
          : filteredOptions
      );
    }
  }, [filteredOptions]);

  const tabPageChange = useCallback(
    term => {
      setTabPage(tabPage => {
        const newPage = tabPage + term;
        const newViewTabs = filteredOptions.slice(
          newPage * TABS_ON_VIEW,
          (newPage + 1) * TABS_ON_VIEW
        );
        setViewTabs(newViewTabs);
        onChange(null, newViewTabs[0].value);
        return newPage;
      });
    },
    [filteredOptions, onChange]
  );

  const onNextPage = useCallback(() => {
    tabPageChange(1);
  }, [tabPageChange]);

  const onPrevPage = useCallback(() => {
    tabPageChange(-1);
  }, [tabPageChange]);

  const tabClasses = useMemo(
    () => ({
      root: clsx(classes.tabRoot, tabClassName),
      textColorPrimary: classes.tabTextColorPrimary,
      selected: clsx(classes.tabSelected, selectedTabClassName),
      wrapper: tabWrapperClassName,
      labelContainer: labelContainerClassName,
      ...tabClassesProp
    }),
    [tabClassName, tabWrapperClassName, labelContainerClassName, tabClassesProp]
  );

  return filteredOptions && filteredOptions.length ? (
    <div className={clsx(classes.wrapper, tabsWrapperClassName)}>
      {(pageable && tabPage !== 0 && (
        <Button onClick={onPrevPage} className={classes.pageButton}>
          ...
        </Button>
      )) ||
        null}
      <Tabs
        {...props}
        value={value}
        orientation={vertical ? 'vertical' : undefined}
        onChange={onChange}
        indicatorColor="primary"
        textColor="primary"
        TabIndicatorProps={
          withoutIndicator ? { style: { display: 'none' } } : undefined
        }
      >
        {viewTabs.map(({ icon: Icon, label, value, translated }, key) => {
          const props = {
            icon: Icon && (
              <Icon className={clsx(classes.icon, iconClassName)} />
            ),
            classes: tabClasses,
            value,
            key
          };

          return translated ? (
            <Translation {...props} component={Tab} propPlace="label">
              {label}
            </Translation>
          ) : (
            <Tab {...props} label={label} />
          );
        })}
      </Tabs>
      {(pageable && tabPage !== maxTabPage && (
        <Button onClick={onNextPage} className={classes.pageButton}>
          ...
        </Button>
      )) ||
        null}
    </div>
  ) : null;
};

import React from 'react';
import {
  Menu as MaterialMenu,
  MenuItem as MaterialMenuItem
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import {
  Translation,
  usePaperStyles
} from '@troy/shared/src/components/common';

const useStyles = makeStyles(theme => ({
  item: {
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)'
    }
  }
}));

const MenuItem = props => {
  const classes = useStyles();
  return <MaterialMenuItem classes={{ root: classes.item }} {...props} />;
};

const Menu = ({ id, anchorEl, open, onClose, options }) => {
  const classes = usePaperStyles();

  return (
    <MaterialMenu
      PaperProps={{
        classes: {
          root: classes.root,
          rounded: classes.rounded,
          elevation1: classes.elevation1
        }
      }}
      {...{ id, anchorEl, open, onClose }}
    >
      {options &&
        options.length &&
        options.map(({ onClick, name, translated }, idx) => {
          const props = {
            onClick,
            key: idx,
            children: name
          };

          return translated ? (
            <Translation {...props} component={MenuItem} />
          ) : (
            <MenuItem {...props} />
          );
        })}
    </MaterialMenu>
  );
};

export default Menu;

import { put } from 'redux-saga/effects';

import { enqueueSnackbar } from '@troy/shared/src/store/actions';

import { setError } from '../actions';

export function* handleError({ payload: err }) {
  yield put(setError(err));
  yield put(
    enqueueSnackbar({
      message: err.message,
      options: {
        variant: 'error'
      }
    })
  );
  // if (err.status >= 500) {
  //   yield put(errorRedirectAction(SERVER_ERROR));
  // } else if (err.status === 401 || err.status === 403) {
  //   yield put(logout());
  // } else if (!err.message) {
  //   yield put(errorRedirectAction(EXCEPTION));
  // } else {
  //   return put(toastr.error(messageTypes.ERROR, err.message));
  // }
}

import { authorizedGet, post } from '../utils/api';
import {
  CONFLUENCE_GET_ACCESSIBLE_RESOURCES,
  CONFLUENCE_GET_CONTENT,
  CONFLUENCE_GET_TOKEN
} from '../constants/api';

const CLIENT_ID = process.env.REACT_APP_CONFLUENCE_APP_KEY;
const CLIENT_SECRET = process.env.REACT_APP_CONFLUENCE_CLIENT_SECRET;

class ConfluenceService {
  static getAccessToken = code =>
    post(CONFLUENCE_GET_TOKEN, {
      grant_type: 'authorization_code',
      client_id: CLIENT_ID,
      client_secret: CLIENT_SECRET,
      code: code,
      redirect_uri: window.location.href.split('?')[0]
    });

  static getAccessibleResources = token =>
    authorizedGet(CONFLUENCE_GET_ACCESSIBLE_RESOURCES, token);

  static getContent = (token, cloudId, contentId) =>
    authorizedGet(CONFLUENCE_GET_CONTENT(cloudId, contentId), token);
}

export default ConfluenceService;

import logger from '../../utils/logger';

const createLoggerMiddleware = logger => () => next => action => {
  try {
    return next(action);
  } catch (e) {
    logger.onError(e, {
      action,
      type: 'redux'
    });
  }
};
export default createLoggerMiddleware(logger);

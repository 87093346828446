import { createSelector } from 'reselect';

const translationsSelector = state => state.translations;

export const availableLanguagesSelector = state =>
  translationsSelector(state).availableLanguages;

export const flagSelectLanguagesSelector = createSelector(
  availableLanguagesSelector,
  availableLanguages =>
    availableLanguages
      ? availableLanguages.reduce(
          (acc, { fullCode, language }) => ({
            ...acc,
            [fullCode]: language
          }),
          {}
        )
      : []
);

export const translationsValuesSelector = state =>
  translationsSelector(state).translations;

export const languageSelector = state => translationsSelector(state).language;

export const languageCodeSelector = createSelector(languageSelector, lang =>
  lang.toLowerCase()
);

export const hasStaticTextsSelector = createSelector(
  translationsSelector,
  ({ hasStaticTexts }) => hasStaticTexts
);

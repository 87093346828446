import React, { RefObject, useMemo, useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import {
  FormControl,
  Input,
  InputAdornment,
  InputLabel,
  Theme
} from '@mui/material';

import {
  CustomIcon,
  FormField,
  IconVariant,
  Translation,
  useTranslationsContext
} from '@troy/shared/src/components/common';
import { SupportedIcon } from '@troy/shared/src/components/common/CustomIcon/iconData';
import { FormikFieldRenderProps } from '@troy/shared/src/types/formik';
import { InputBaseProps } from '@mui/material/InputBase/InputBase';
import { CustomColors, CustomColorsType } from '../Text';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: `0 ${theme.spacing(3)}`,
    background: theme.palette.background.default,
    borderRadius: Number(theme.shape.borderRadius) * 8,
    boxSizing: 'border-box',
    transition: theme.transitions.custom.fast,
    overflow: 'hidden'
  },
  rootAdornmentEnd: {
    paddingRight: 8
  },
  border: {
    position: 'relative',

    '&:before': {
      content: '" "',
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      border: `1px solid ${theme.palette.text.secondary}`,
      borderRadius: Number(theme.shape.borderRadius) * 8
    }
  },
  rootShadow: {
    filter: `drop-shadow(0px 2px 12px rgba(0, 0, 0, 0.2))`
  },
  warningDark: {
    '&:before': {
      borderColor: theme.palette.warning.dark
    },

    '& input': {
      color: theme.palette.warning.dark
    }
  },
  label: {
    zIndex: 1,
    left: theme.spacing(3),
    color: theme.palette.text.hint,
    lineHeight: 1,
    transform: `translate(0, ${theme.spacing(3)}) scale(1)`,

    '&$labelShrink': {
      transform: `translate(0, ${theme.spacing(1)}) scale(0.75)`,
      color: theme.palette.text.hint
    }
  },
  labelShrink: {},
  labelWithIcon: {
    left: theme.spacing(7)
  },
  inputRoot: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  inputRootBorder: {
    marginTop: `calc(${theme.spacing(2)} - 1px) !important`,
    marginBottom: `calc(${theme.spacing(2)} - 1px)`
  },
  input: {
    '&:-webkit-autofill,&:-webkit-autofill:hover,&:-webkit-autofill:focus,&:-webkit-autofill:active,&:-internal-autofill-selected':
      {
        animationName: 'none',
        WebkitBoxShadow: '0 0 0 20px white inset'
      },
    '&::placeholder': {
      color: theme.palette.text.hint,
      opacity: 1,
      lineHeight: 'normal'
    },
    '&::-moz-placeholder': {
      color: theme.palette.text.hint,
      opacity: 1
    },
    '&:-ms-input-placeholder': {
      color: theme.palette.text.hint,
      opacity: 1
    }
  },
  inputWithIcon: {
    marginLeft: theme.spacing(1)
  }
}));

interface TextInputProps {
  noShadow?: boolean;
  withBorder?: boolean;
  className?: string;
  inputClassName?: string;
  labelClassName?: string;
  id?: string;
  name?: string;
  type?: 'text' | 'password';
  placeholder?: string;
  inputPlaceholder?: string;
  onChange?: (e: React.ChangeEvent) => void;
  value?: string | number;
  onEnterPress?: () => void;
  initialValue?: string;
  button?: React.ReactElement;
  startIcon?: SupportedIcon;
  inputRef?: RefObject<HTMLInputElement>;
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
  autoComplete?: string;
  onFocus?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onKeyPress?: (event: React.KeyboardEvent<Element>) => void;
  color?: InputBaseProps['color'] | CustomColorsType;
  startIconVariant?: IconVariant;
}

const TextInputField: React.FC<TextInputProps & FormikFieldRenderProps> = ({
  noShadow,
  field,
  withBorder,
  className,
  inputClassName,
  labelClassName,
  id,
  name,
  type = 'text',
  placeholder,
  inputPlaceholder,
  onChange,
  value,
  onEnterPress,
  onKeyPress,
  button,
  startIcon,
  inputRef,
  onClick,
  autoComplete,
  onFocus,
  onBlur,
  color = 'primary',
  startIconVariant = 'primary'
}) => {
  const classes = useStyles();
  const { translate } = useTranslationsContext();
  const [focus, setFocus] = useState(false);

  const currentValue = field ? field.value : value;

  const handleKeyPress = (e: React.KeyboardEvent): void => {
    if (!!onEnterPress && e.key === 'Enter') {
      onEnterPress();
    } else if (onKeyPress) {
      onKeyPress(e);
    }
  };

  const handleFocus = (
    e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    setFocus(true);
    if (!!onFocus) {
      onFocus(e);
    }
  };

  const handleBlur = (
    e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    setFocus(false);
    if (!!field && !!field.onBlur) {
      field.onBlur(e);
    } else if (!!onBlur) {
      onBlur(e);
    }
  };

  const needsManualLabelShrinkManagement = !!startIcon && !inputPlaceholder;
  const shrinkLabel =
    focus ||
    (currentValue != null &&
      ((typeof currentValue === 'string' && currentValue.length > 0) ||
        typeof currentValue === 'number'));

  const colorClass = useMemo(() => {
    let cClass;
    if (CustomColors.includes(color as CustomColorsType)) {
      cClass = classes[color as CustomColorsType];
    }
    return cClass;
  }, [color, classes]);

  return (
    <FormControl
      className={clsx(
        withBorder && classes.border,
        !noShadow && focus && classes.rootShadow,
        colorClass,
        !!button && classes.rootAdornmentEnd,
        classes.root,
        className
      )}
    >
      {placeholder && (
        <InputLabel
          classes={{
            root: clsx(
              labelClassName,
              classes.label,
              !!startIcon && classes.labelWithIcon
            ),
            shrink: classes.labelShrink
          }}
          htmlFor={id}
          shrink={needsManualLabelShrinkManagement ? shrinkLabel : undefined}
        >
          <Translation keyIfNoValue isFragment>
            {placeholder}
          </Translation>
        </InputLabel>
      )}
      <Input
        disableUnderline
        inputRef={inputRef}
        id={id}
        name={field ? field.name : name}
        type={type}
        color={colorClass ? undefined : (color as InputBaseProps['color'])}
        classes={{
          root: clsx(classes.inputRoot, inputClassName),
          input: clsx(classes.input, !!startIcon && classes.inputWithIcon)
        }}
        value={currentValue}
        onChange={field ? field.onChange : onChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onKeyPress={handleKeyPress}
        onClick={onClick}
        autoComplete={autoComplete}
        placeholder={
          inputPlaceholder ? translate(inputPlaceholder, true) : undefined
        }
        startAdornment={
          !!startIcon && (
            <CustomIcon
              icon={startIcon}
              size="small"
              variant={startIconVariant}
            />
          )
        }
        endAdornment={
          !!button && <InputAdornment position="end">{button}</InputAdornment>
        }
      />
    </FormControl>
  );
};

const TextInput: React.FC<TextInputProps> = ({ name, onChange, ...props }) => {
  const customProps = {
    onChange,
    ...props
  };

  return name ? (
    <FormField
      component={TextInputField}
      name={name}
      onChange={onChange}
      {...props}
    />
  ) : (
    <TextInputField {...customProps} />
  );
};

export default TextInput;

import { createSelector } from 'reselect';

const invoiceSelector = state => state.invoice;

export const invoicesSelector = createSelector(
  invoiceSelector,
  invoice => invoice.invoices
);

export const invoiceLoadingSelector = state => invoiceSelector(state).isLoading;

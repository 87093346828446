import React from 'react';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material';

import { Text } from '../index';
import { CustomColor } from '../Text';
import { UINotification } from '../../../types/notifications';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    padding: `${theme.spacing(0.5)} ${theme.spacing(1)}`,
    background: theme.palette.action.disabled,
    borderRadius: Number(theme.shape.borderRadius)
  },
  containerActionsInfo: {
    background: theme.customColors.actionsInfo
  },
  containerWarningDarkFocus: {
    background: theme.customColors.warningDarkFocus
  },
  containerPrimary: {
    background: theme.palette.primary.main
  },
  containerWarningLight: {
    background: theme.palette.warning.light
  },
  separator: {
    margin: `0 ${theme.spacing(1)}`,
    width: 1,
    height: 16,
    background: theme.palette.text.hint
  },
  separatorWarningDark: {
    background: theme.palette.warning.dark
  },
  separatorPrimaryLight: {
    background: theme.palette.primary.light
  },
  separatorPrimary: {
    background: theme.palette.primary.contrastText
  },
  separatorWarningLightHover: {
    background: theme.customColors.warningLightHover
  },
  separatorTextDisabled: {
    background: theme.palette.text.disabled
  },
  value: {
    marginLeft: theme.spacing(1)
  }
}));

const emojiMap: Record<UINotification['mlValue'], string> = {
  MLNEUTRAL: '😶',
  MLANGRY: '😡',
  MLHAPPY: '🙂',
  MLSAD: '😔',
  MLCONFUSED: '😕',
  MLANNOYED: '🙄',
  POSITIVE: '🙂',
  NEGATIVE: '😔',
  OTHER: '😶'
};

interface PostBoxItemSentimentProps {
  value: UINotification['mlValue'];
  confidence: UINotification['mlConfidence'];
  colorScheme: UINotification['colorScheme'];
}

const PostBoxItemSentiment: React.FC<PostBoxItemSentimentProps> = ({
  value,
  confidence,
  colorScheme
}): React.ReactElement => {
  const classes = useStyles();

  let color: CustomColor = 'textPrimary';
  let containerClass: string | null = null;
  let separatorClass: string | null = null;

  if (colorScheme === 'Hidden') {
    color = 'textSecondary';
  } else if (value === 'MLNEUTRAL') {
    containerClass = classes.containerActionsInfo;
  } else if (
    value === 'MLANGRY' ||
    value === 'MLANNOYED' ||
    value === 'NEGATIVE'
  ) {
    color = 'primaryContrast';
    containerClass = classes.containerWarningDarkFocus;
    separatorClass = classes.separatorWarningDark;
  } else if (value === 'MLHAPPY' || value === 'POSITIVE') {
    color = 'primaryDark';
    containerClass = classes.containerPrimary;
    separatorClass = classes.separatorPrimaryLight;
  } else if (value === 'MLSAD' || value === 'MLCONFUSED') {
    containerClass = classes.containerWarningLight;
    separatorClass = classes.separatorWarningLightHover;
  } else if (value === 'OTHER') {
    color = 'textDisabled';
    separatorClass = classes.separatorTextDisabled;
  }

  return (
    <div className={clsx(classes.container, containerClass)}>
      <Text component="span" variant="subtitle2" color={color}>
        {confidence}
      </Text>
      <div className={clsx(classes.separator, separatorClass)} />
      <Text component="span" variant="subtitle2" color={color}>
        {emojiMap[value]}
      </Text>
      <Text
        translated
        className={classes.value}
        component="span"
        variant="subtitle2"
        color={color}
      >
        {`ALL_SENTIMENT_${value}`}
      </Text>
    </div>
  );
};

export default PostBoxItemSentiment;

import whitelabelAssets from '../../assets/whitelabel';

async function loadFonts(portalId: string): Promise<void> {
  const fontRegularUrl =
    whitelabelAssets[`${portalId}/font-regular.woff2`] ||
    whitelabelAssets[`${portalId}/font-regular.ttf`] ||
    whitelabelAssets[`troy_de_DE/font-regular.woff2`];

  const fontBoldUrl =
    whitelabelAssets[`${portalId}/font-bold.woff2`] ||
    whitelabelAssets[`${portalId}/font-bold.ttf`] ||
    whitelabelAssets[`troy_de_DE/font-bold.woff2`];

  const font = new FontFace('CustomFont-Regular', `url(${fontRegularUrl})`);
  await font.load();
  document.fonts.add(font);

  const font2 = new FontFace('CustomFont-Bold', `url(${fontBoldUrl})`);
  await font2.load();
  document.fonts.add(font2);
}

export default loadFonts;
